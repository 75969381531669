import React from 'react';
import Layout from '../../layout/default';
import { useAuth } from '../../container/authContext';

function NotFound() {
  const { user } = useAuth();
  return (
    <Layout title="403 Forbidden" className={`${!user ? 'forbidden-page-wrapper' : ''}`}>
      <div className={'forbidden-page'}>
        <div>
          <h1>403 Forbidden</h1>
          <p>Access to this resource is forbidden. You do not have permission to view the requested file or directory.</p>
        </div>
      </div>
    </Layout>
  );
}

export default NotFound;
