// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submission-forms-table .tr:nth-child(n+1) .td {
  padding-left: 16px;
  padding-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/corporate-library/submission/styles.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;EACA,mBAAA;AADN","sourcesContent":[".submission-forms-table {\n  .tr:nth-child(n + 1) {\n    .td {\n      padding-left: 16px;\n      padding-right: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
