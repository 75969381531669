import React from 'react';
import Rating from 'react-rating';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';

export const RatingElement = function FromElement({ element, handlerChange, formHasOnlyOneSubmitter }) {
  const isRequiredElement = useIsRequiredElement(element, formHasOnlyOneSubmitter);
  return (
    <>
      <label className="form-label w-full">
        {element.options.name} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <Rating
        className={`form-control-star form-control-star-${element.options.size} ${element.options.readOnly ? 'readonly' : ''}`}
        fractions={2}
        emptySymbol="icon-star-empty"
        fullSymbol="icon-star-full"
        readOnly={element.options.readOnly}
        initialRating={element.options.valueInput}
        onChange={(e) => handlerChange(element, e)}
      />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
