/**
 * @param {string} fileName
 * @return {string | undefined}
 */
export function getFileExtFromFileName(fileName) {
  if (!fileName) {
    return;
  }
  const match = fileName.match(/\.([^.]+)$/);
  return match ? match[1].toLowerCase() : undefined;
}
