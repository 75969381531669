import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * Converts a local Date object or a date-time string to a UTC Date object.
 *
 * @param {Date | string | undefined} localDate - The local date or date-time string to convert.
 * @returns {Date | undefined} - Returns the UTC Date object or `undefined` if the input is invalid.
 * @throws {Error} - Throws an error if the input is not a valid Date object or string.
 */
export const localToUTCDate = (localDate) => {
  if (!localDate) {
    return undefined; // Return undefined if localDate is not provided
  }

  let date;

  // Check if localDate is a string and convert it to a Date object
  if (typeof localDate === 'string') {
    date = new Date(localDate);
  } else if (localDate instanceof Date) {
    date = localDate;
  } else {
    throw new Error(`Invalid local date: ${localDate}`);
  }

  if (isNaN(date.getTime())) {
    throw new Error(`Invalid date for conversion: ${localDate}`);
  }

  return dayjs(date).utc().toDate(); // Convert to UTC and return the Date object
};

/**
 * Converts a local Date object or a date-time string to a UTC date-time string in ISO format.
 *
 * @param {Date | string | undefined} localDate - The local date or date-time string to convert.
 * @returns {string | undefined} - Returns the UTC date-time string in ISO format or `undefined` if the input is invalid.
 * @throws {Error} - Throws an error if the input is not a valid Date object or string.
 */
export const localToUTCDateString = (localDate) => {
  const utcDate = localToUTCDate(localDate); // Reuse the localToUTCDate function

  if (utcDate) {
    return utcDate.toISOString(); // Return the UTC date in ISO format
  }

  return undefined; // Return undefined if the input is invalid
};
