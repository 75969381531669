import { SUPPORTED_WEB_VIDEO_FORMATS } from '../../constants';

const videoExtensions = [...SUPPORTED_WEB_VIDEO_FORMATS, 'mov', 'avi', 'wmv', 'flv', 'mkv'];

/**
 * @param {string} fileExt
 * @return {boolean}
 */
export function isVideoByFileExt(fileExt) {
  return fileExt ? videoExtensions.includes(fileExt.toLowerCase()) : false;
}
