import React, { useRef, useState } from 'react';
import './styles.scss';
import useOutsideClick from '../../../hook/useOutsideClick';

// menu = ReactNode[];
export const FormActionsMenu = ({ menu }) => {
  const dropdownMenuRef = useRef(null);
  let layout = null;

  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(dropdownMenuRef, () => setIsOpen(false));

  if (menu?.length) {
    layout = (
      <div className={'form__actions__menu'}>
        <button
          className="btn btn-outline btn-small"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
              fill="#667085"
            />
            <path
              d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
              fill="#667085"
            />
            <path
              d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
              fill="#667085"
            />
            <path
              d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
              stroke="#667085"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {isOpen && !!menu?.length && (
          <div className={'dropdown__menu'} ref={dropdownMenuRef}>
            {menu.map((item, index) => {
              return (
                <div key={index} className={'dropdown__menu__item'} onClick={() => setIsOpen(false)}>
                  {item}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return layout;
};
