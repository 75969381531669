import { CNA_TABS } from '../../constants';
import PageTabs from '../../../../components/other/page-tabs';
import React from 'react';
import { useAuth } from '../../../../container/authContext';

const Header = () => {
  const { user = {} } = useAuth();
  const { is_unit_user } = user;
  let layout = null;
  if (!is_unit_user) {
    layout = <PageTabs tabs={CNA_TABS} />;
  }
  return layout;
};

export default Header;
