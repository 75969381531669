import React from 'react';

const FileLoader = () => {
  return (
    <div className="loader">
      <div className="loader__content">
        <span className="loader__item"></span>
      </div>
    </div>
  );
};

export default FileLoader;
