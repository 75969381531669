import React, { memo } from 'react';
import Modal from 'react-modal';
import './styles.scss';

const DeleteInitiationModal = ({ isOpen, onDeleteInitiation, onClose }) => {
  const render = () => {
    let layout = null;
    if (isOpen) {
      layout = (
        <Modal
          id={'deleteInitiationModal'}
          className="modal-default delete-initiation-modal"
          overlayClassName={'delete-initiation-modal-overlay'}
          isOpen={true}
          onRequestClose={onClose}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <h2 className="modal-header">
            <div className={'title'}>
              <img src="/images/alert-icon.svg" alt="" />
              Please confirm deletion
            </div>
            <button className="btn btn-icon" onClick={onClose}>
              <i className="icon-icon-x" />
            </button>
          </h2>
          <div className="modal-body">
            <p className={'content'}>
              Are you sure you want to delete this initiation? <br />
              All associated data will be permanently lost.
            </p>
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline" onClick={onClose}>
              Cancel
            </button>
            <button className={'btn btn-remove delete-initiation-btn'} onClick={onDeleteInitiation}>
              <span>Delete Initiation</span>
            </button>
          </div>
        </Modal>
      );
    }
    return layout;
  };

  return render();
};

export default memo(DeleteInitiationModal);
