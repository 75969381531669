import React, { memo, useCallback } from 'react';
import cx from 'classnames';

const RadioButton = ({ styles, className, id, checked, onChange, label, disabled, isPrint }) => {
  const handleChange = useCallback(() => {
    if (onChange && !disabled) {
      onChange(id);
    }
  }, [disabled, id, onChange]);

  return (
    <div className={cx('radiobutton-wrapper', className)} style={styles}>
      <div
        className={cx('form-control-radio', {
          checked,
          print: isPrint,
          disabled,
        })}
      >
        <label className={'radiobutton-label'} htmlFor={id}>
          {isPrint ? (
            <span className={cx('radiobutton-button', { checked })} />
          ) : (
            <input id={id} disabled={disabled} name={label} type="radio" value={id} checked={checked} onChange={handleChange} />
          )}
          <span />
          {label}
        </label>
      </div>
    </div>
  );
};

export default memo(RadioButton);
