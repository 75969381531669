import React from 'react';
import SignaturePad from 'react-signature-canvas';
export const SignatureElement = function FromElement({ element }) {
  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
      </label>
      <div className={`sigCanvas-wrapper sigCanvas-${element.options.size}`}>
        <SignaturePad penColor="black" canvasProps={{ height: 100, className: `sigCanvas sigCanvas-${element.options.size}` }} />
      </div>
      <span className="sigCanvas-clear">Clear</span>
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
