import React, { useMemo, useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';
import cx from 'classnames';

export const DropdownElement = function FromElement({ element, handlerChange, error, formHasOnlyOneSubmitter }) {
  const selectRef = useRef();
  const isRequiredElement = useIsRequiredElement(element, formHasOnlyOneSubmitter);
  const [otherInputValue, setOtherInputValue] = useState('');

  const options = useMemo(() => {
    const optionsList = element.inputs.map((item) => ({
      label: item.label,
      value: item.id,
    }));

    if (element.options.addOtherOption) {
      optionsList.push({ value: 'other', label: 'Other' });
    }

    return optionsList;
  }, [element.inputs]);

  const shouldUseOtherInput = () => {
    return element.options.addOtherOption && element.options.valueInput?.label === 'Other';
  };

  useEffect(() => {
    if (shouldUseOtherInput()) {
      setOtherInputValue(element.options.otherInputValue);
    }
  }, [element.options.addOtherOption]);

  const getInputValue = () => {
    if (shouldUseOtherInput()) {
      return otherInputValue;
    }

    return element.options.valueInput?.label || '';
  };

  const handleInputChange = (value) => {
    if (shouldUseOtherInput() && (value || (otherInputValue.length === 1 && value.length === 0))) {
      setOtherInputValue(value);
      handlerChange(element, { value: 'other', label: 'Other', otherInputValue: value });
    }
  };

  const SingleValue = ({ children, ...props }) => <components.SingleValue {...props} />;

  const handleChangeValue = (e) => {
    if (e.label !== 'Other') {
      resetOtherInputValue();
    } else {
      setTimeout(() => {
        if (selectRef.current) {
          selectRef.current.focus();
        }
      }, 0);
    }
    handlerChange(element, e);
  };

  const resetOtherInputValue = () => {
    setOtherInputValue('');
    selectRef.current.blur();
  };

  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <Select
        ref={selectRef}
        id={element.id}
        options={options}
        menuPosition={'fixed'}
        inputValue={getInputValue()}
        components={{ SingleValue }}
        name={element.options.name}
        classNamePrefix="react-select"
        menuPortalTarget={document.body}
        readOnly={element.options.readOnly}
        value={element.options.valueInput}
        filterOption={null}
        styles={{
          menu: (provided) => ({
            ...provided,
          }),
          input: (provided) => ({
            ...provided,
            input: {
              opacity: '1 !important',
            },
          }),
          menuList: (provided) => ({
            ...provided,
            minHeight: options.length > 3 ? 168 : undefined,
            maxHeight: 248,
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9 }),
        }}
        className={cx('form-control-select', { [`form-control-${element.options.size}`]: element.options.size })}
        onChange={(e, meta) => handleChangeValue(e, meta)}
        onInputChange={handleInputChange}
      />
      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
