import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FromBuilderItem } from './form-item';
import { TitleElement } from './elements/title-element';
import dayjs from 'dayjs';
import { cloneDeep, get } from 'lodash';
import { DATE_FORMAT } from '../../constants';
import { useUserFormRole } from '../../hook/useUserFormRole';
import { ADD_TO_COPY_GROUP_SETTING_KEY } from '../from-builder/elements-settings/components/add-to-copy-group-setting';

export const RenderForm = function RenderForm({
  formData,
  formElement: formElements,
  title,
  handelInitSettings,
  isPrint,
  isSubmitter,
  isDisabled,
  errors,
  duplicateElement,
  removeElement,
}) {
  const userFormRole = useUserFormRole();

  const [lastIndex, setLastIndex] = useState(null);

  const isExistElementsCopyGroup = useMemo(() => {
    let value = false;
    if (Array.isArray(formElements)) {
      value = formElements.some((item) => !!get(item, `options[${ADD_TO_COPY_GROUP_SETTING_KEY}]`));
    }
    return value;
  }, [formElements]);

  const lastCopyGroupElementIndex = useMemo(() => {
    if (Array.isArray(formElements)) {
      return formElements.findLastIndex((item) => !!get(item, `options[${ADD_TO_COPY_GROUP_SETTING_KEY}]`));
    }
    return -1;
  }, [formElements]);

  const errorsObj = useMemo(() => {
    return errors?.reduce((acc, item) => {
      const [id, errorMessage] = Object.entries(item)?.flat() ?? [];
      acc[Number(id)] = errorMessage;
      return acc;
    }, {});
  }, [errors]);

  useEffect(() => {
    if (formElements) {
      setLastIndex(formElements.findLastIndex((item) => item.options.canAddNew));
    }
  }, [formElements]);

  const handlerChange = useCallback(
    (field, value, isTable, index, rowIndex) => {
      const formFields = cloneDeep(formElements);
      const currentIndex = formElements.findIndex((item) => item.id === field.id);

      if (isTable) {
        formFields.forEach((item) => {
          if (item.type === 'table') {
            if (item.rows?.length) {
              if (!get(item, 'rows[0].cols')) {
                item.rows.forEach((row) => {
                  row['cols'] = item.cols.map((col) => {
                    const { autoSetValueFor, type: colType } = col;
                    if (colType?.value === 'date' || colType?.value === 'time') {
                      let value = null;
                      if (autoSetValueFor === userFormRole) {
                        value = new Date();
                      }
                      return {
                        ...col,
                        value,
                      };
                    }
                    return { value: '', id: col.id, type: col.type };
                  });
                });
              }
            }
          }
        });

        if (!formFields[currentIndex].rows[rowIndex]?.cols) {
          formFields[currentIndex].rows.forEach((row, index) => {
            if (!row['cols']) {
              row['cols'] = formFields[currentIndex].cols.map((col) => {
                const { autoSetValueFor, type: colType } = col;

                if (colType?.value === 'date' || colType?.value === 'time') {
                  let value = null;
                  if (autoSetValueFor === userFormRole) {
                    value = new Date();
                  }
                  return {
                    ...col,
                    value,
                  };
                }

                return { value: '', id: col.id, type: col.type };
              });
            }
          });

          if (formFields[currentIndex]?.rows[rowIndex]?.cols[index]) {
            if (formFields[currentIndex].rows[rowIndex].cols[index].type?.value === 'date') {
              formFields[currentIndex].rows[rowIndex].cols[index]['value'] = formFields[currentIndex].rows[rowIndex].cols[index].editable
                ? new Date()
                : null;
            } else if (formFields[currentIndex].rows[rowIndex].cols[index].type?.value === 'time') {
              formFields[currentIndex].rows[rowIndex].cols[index]['value'] = formFields[currentIndex].rows[rowIndex].cols[index].editable
                ? new Date()
                : null;
            } else {
              formFields[currentIndex].rows[rowIndex].cols[index]['value'] = value ?? '';
            }
          }
        } else {
          if (formFields[currentIndex].rows[rowIndex]?.cols[index]?.hasOwnProperty('value')) {
            formFields[currentIndex].rows[rowIndex].cols[index]['value'] = value;
          } else {
            formFields.forEach((item) => {
              if (item.type === 'table') {
                if (!item.rows[0]['cols']) {
                  item.rows.forEach((row) => {
                    row['cols'] = item.cols.map((col) => {
                      if (col?.type?.value === 'date') {
                        return {
                          value: col.editable ? new Date() : null,
                          id: col.id,
                          type: col.type,
                          editable: col.editable,
                        };
                      } else if (col?.type?.value === 'time') {
                        return {
                          value: col.editable ? new Date() : null,
                          id: col.id,
                          type: col.type,
                          editable: col.editable,
                        };
                      }
                      return { value: '', id: col.id, type: col.type };
                    });
                  });
                }
              }
            });
          }
        }
      } else if (index >= 0) {
        formFields[currentIndex].inputs = formFields[currentIndex].inputs.map((input, i) => {
          if (i === index) {
            return {
              ...input,
              valueInput: value,
            };
          }
          return input;
        });
      } else {
        formFields[currentIndex].options = {
          ...formFields[currentIndex].options,
          valueInput: value,
        };
      }

      if (formFields[currentIndex].options.enableScroll) {
        try {
          const tableRows = formFields[currentIndex].rows.map((item) => {
            delete item.scrollToRow;
            return item;
          });
          const lastNoEmptyRowIndex = tableRows.findLastIndex((row) => {
            let isNoEmptyRow = false;
            if (row.label !== '' && row.label !== null) {
              isNoEmptyRow = true;
            } else if (row.cols) {
              for (const col of row.cols) {
                if (col?.value !== '' && col?.value !== null) {
                  isNoEmptyRow = true;
                  break;
                }
              }
            }
            return isNoEmptyRow;
          });
          const tableRowsLength = tableRows?.length;
          if (lastNoEmptyRowIndex !== -1) {
            tableRows[lastNoEmptyRowIndex].scrollToRow = true;
          } else {
            tableRows[tableRowsLength - 1].scrollToRow = true;
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (field?.type === 'link' || field?.type === 'checkbox') {
        formFields[currentIndex] = field;
      }

      if (field?.type === 'checkbox' && typeof value === 'boolean' && typeof index === 'number' && formFields[currentIndex].inputs[index]) {
        formFields[currentIndex].inputs[index].valueInput = value;
      }

      handelInitSettings(formFields, 'form_fields');
    },
    [formElements, handelInitSettings, userFormRole],
  );

  const handlerChangeFile = (field, value) => {
    const formFields = JSON.parse(JSON.stringify(formElements));

    const currentIndex = formElements.findIndex((item) => item.id === field.id);
    formFields[currentIndex].options['file'] = value;

    handelInitSettings(formFields, 'form_fields');
  };

  const addNewLogin = (field) => {
    let newElement = { ...JSON.parse(JSON.stringify(field)) };
    const index = formElements.findIndex((item) => item.id === field.id);
    newElement.id = new Date().getTime();
    newElement.options.valueInput = '';
    newElement.options.isClone = true;
    const formFields = JSON.parse(JSON.stringify(formElements));
    formFields.splice(index + 1, 0, newElement);
    handelInitSettings(formFields, 'form_fields');
  };

  const getNewRowData = (rowCols, newRowData = {}) => {
    return {
      label: '',
      newRow: true,
      id: 'new' + new Date().getTime(),
      cols: rowCols.map((col) => {
        const colType = col.type?.value;
        if (colType === 'date' || colType === 'time') {
          const { autoSetValueFor } = col;
          let value = null;

          if (autoSetValueFor === userFormRole) {
            value = new Date();
          }

          return { ...col, value };
        } else if (colType === 'dropdown') {
          const { defaultOptionId, options } = col;
          const newCol = cloneDeep(col);
          if (defaultOptionId && Array.isArray(options)) {
            newCol.value = options.find((o) => o.id === defaultOptionId);
          }

          return newCol;
        }

        return { ...col, value: '' };
      }),
      ...newRowData,
    };
  };

  const addNewRow = (element, newRowIndex) => {
    const formFields = JSON.parse(JSON.stringify(formElements));
    const currentElementIndex = formElements.findIndex((item) => item.id === element.id);
    const newRow = getNewRowData(formFields[currentElementIndex].cols);

    // If newRowIndex is provided, insert at the specified position, otherwise append to the end of the array
    if (typeof newRowIndex === 'number' && newRowIndex >= 0) {
      const originalIndex = Math.floor(newRowIndex / 2);
      formFields[currentElementIndex].rows.splice(originalIndex + 1, 0, newRow);
    } else {
      formFields[currentElementIndex].rows.push(newRow);
    }

    handelInitSettings(formFields, 'form_fields');
  };

  const handlerChangeLabel = (field, value, indexRow) => {
    const formFields = JSON.parse(JSON.stringify(formElements));
    const currentIndex = formElements.findIndex((item) => item.id === field.id);
    formFields[currentIndex].rows[indexRow].label = value;
    handelInitSettings(formFields, 'form_fields');
  };

  const handleCopyFieldsGroup = () => {
    const groupFields = cloneDeep(formElements.filter((item) => !!get(item, `options[${ADD_TO_COPY_GROUP_SETTING_KEY}]`)));

    if (groupFields.length) {
      const copyGroupID = new Date().getTime();
      // Creates a new ID and adding a copyGroupID for deletion (group)
      const formattedGroupFields = groupFields.map((item, index) => {
        const newField = { ...item, id: new Date().getTime() + index };
        newField.options.copyGroupID = copyGroupID;

        delete newField.options[ADD_TO_COPY_GROUP_SETTING_KEY];

        // Deleting previous values if they are readable
        if (newField.options.readOnly !== 'readonly') {
          if ('valueInput' in newField.options) {
            newField.options.valueInput = '';
          }
          if ('file' in newField.options) {
            delete newField.options.file;
          }
          if (newField.type === 'table') {
            if (newField.rows?.length) {
              newField.rows = newField.rows.filter((row) => !row.newRow);
              newField.rows.forEach((row, rowIndex) => {
                if (row?.cols) {
                  row.cols.forEach((col, colIndex) => {
                    if (col?.readOnly !== 'readonly') {
                      if (col?.value?.length) {
                        // Set empty col value
                        newField.rows[rowIndex].cols[colIndex].value = '';
                      }
                    }
                  });
                }

                return row;
              });
            }
          }

          if (newField.type === 'checkbox') {
            if (newField.inputs?.length) {
              newField.inputs = newField.inputs.map((input) => {
                input.valueInput = false;
                return input;
              });
            }
          }
        }

        return newField;
      });

      const lastCopyGroupIndex = formElements.findLastIndex((item) => !!item.options.copyGroupID);
      let mergedFields;

      if (lastCopyGroupElementIndex === -1) {
        mergedFields = [...formElements, ...formattedGroupFields];
      } else {
        const insertIndex = lastCopyGroupIndex === -1 ? lastCopyGroupElementIndex + 1 : lastCopyGroupIndex + 1;

        mergedFields = [...formElements.slice(0, insertIndex), ...formattedGroupFields, ...formElements.slice(insertIndex)];
      }

      handelInitSettings(mergedFields, 'form_fields');
    }
  };

  const handleDeleteCopiedFieldsGroup = (copyGroupID) => {
    const filteredFields = cloneDeep(
      formElements.filter((item) => {
        return +get(item, 'options.copyGroupID') !== +copyGroupID;
      }),
    );
    handelInitSettings(filteredFields, 'form_fields');
  };

  const renderFromBuilderItem = (field, index) => {
    const error = get(errorsObj, `${field.id}`);
    return (
      <>
        <FromBuilderItem
          error={error}
          handlerChangeFile={handlerChangeFile}
          lastIndex={lastIndex}
          index={index}
          addNewLogin={addNewLogin}
          isSubmitter={isSubmitter}
          isPrint={isPrint}
          key={field.id}
          element={field}
          handlerChange={handlerChange}
          addNewRow={addNewRow}
          handlerChangeLabel={handlerChangeLabel}
          duplicateElement={duplicateElement}
          removeElement={removeElement}
        />
        {index === lastCopyGroupElementIndex && !isPrint && isSubmitter && isExistElementsCopyGroup && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              marginBottom: 24,
              paddingRight: 24,
              justifyContent: 'flex-end',
            }}
          >
            <button className={'btn'} onClick={handleCopyFieldsGroup}>
              Copy a group of fields
            </button>
          </div>
        )}
      </>
    );
  };

  const renderFields = () => {
    const renderedFields = [];
    let currentGroupID = null;

    for (let index = 0; index < formElements?.length; index++) {
      const field = formElements[index];
      const groupID = field.options.copyGroupID;

      if (groupID && groupID !== currentGroupID) {
        // If this is a new groupID, start a new group
        currentGroupID = groupID;

        const groupItems = [];

        // Add group elements until we encounter an element without groupID
        while (index < formElements.length && formElements[index].options.copyGroupID === currentGroupID) {
          groupItems.push(renderFromBuilderItem(formElements[index], index));
          index++;
        }

        renderedFields.push(
          <div key={currentGroupID} className="render-form__copy-group">
            {groupItems}
            <div className="render-form__copy-group__actions">
              <button
                className="btn btn-remove btn-icon render-form__copy-group__remove-btn"
                onClick={() => handleDeleteCopiedFieldsGroup(groupID)}
              >
                <i className="icon-remove" />
                <span className={'label'}>Delete group</span>
              </button>
            </div>
          </div>,
        );

        index--;
        currentGroupID = null;
      } else if (!groupID) {
        renderedFields.push(renderFromBuilderItem(field, index));
      }
    }

    return renderedFields;
  };

  return (
    <div className={`${isDisabled ? 'render-form-disabled' : ''} render-form`}>
      {isDisabled && <span className={'is-disabled'}>Deactivated: {dayjs(new Date(isDisabled)).format(DATE_FORMAT)}</span>}
      <TitleElement formData={formData} element={title}></TitleElement>
      {renderFields()}
    </div>
  );
};
