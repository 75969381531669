import client from '../../../api';
import qs from 'qs';

let cancel;

export const getCNAsService = async (assignmentID) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!assignmentID) return;
  try {
    return await client.get(`/api/cna-assignments/${assignmentID}/cna-group-users/`);
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const getCNANursesService = async (assignmentID) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!assignmentID) return;
  try {
    return await client.get(`/api/cna-assignments/${assignmentID}/nurses/`);
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const getCNAAssignmentService = async (assignmentID, orderSettings) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!assignmentID) return;
  try {
    return await client.get(`/api/cna-assignments/${assignmentID}/`, {
      params: orderSettings,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const updateCNAAssignmentShiftService = async ({ shiftID, ...body }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!shiftID) return;
  try {
    return await client.patch(`/api/cna-assignments/shifts/${shiftID}/`, body);
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const assignCNAToShiftRoomService = async ({ shiftID, cnaData }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!shiftID || !cnaData) return;
  try {
    return await client.post(`/api/cna-assignments/shifts/${shiftID}/assign-cna/`, cnaData);
  } catch (e) {
    console.error(e);
    return e;
  }
};
// TASK
export const assignCNATasksToShiftRoomService = async ({ shiftRecordID, body }) => {
  if (cancel !== undefined) {
    cancel();
  }

  try {
    return await client.post(`/api/cna-assignments/shift-records/${shiftRecordID}/tasks/`, body);
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const updateCNARoomTaskService = async ({ shiftRecordID, taskID, taskTitle, default_task_id, period_settings }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!shiftRecordID || !taskID) return;
  try {
    const body = {
      period_settings,
    };

    if (typeof default_task_id === 'number') {
      body.default_task_id = default_task_id;
    } else {
      body.name = taskTitle;
    }

    return await client.patch(`/api/cna-assignments/shift-records/${shiftRecordID}/tasks/${taskID}/`, body);
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const deleteCNARoomTaskService = async ({ shiftRecordID, taskID }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!shiftRecordID || !taskID) return;
  try {
    return await client.delete(`/api/cna-assignments/shift-records/${shiftRecordID}/tasks/${taskID}/`);
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const deleteShiftCNAService = async ({ shiftID, cnaID }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!shiftID || !cnaID) return;
  try {
    return await client.delete(`/api/cna-assignments/shifts/${shiftID}/cna/${cnaID}/`);
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const changeShiftActiveCNAService = async ({ shiftID, currentCnaId, ...newCNAData }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!shiftID || !currentCnaId || !newCNAData) return;
  try {
    return await client.post(`/api/cna-assignments/shifts/${shiftID}/cna/${currentCnaId}/replace/`, newCNAData);
  } catch (e) {
    console.error(e);
    return e;
  }
};

// Task Comments
export const createCNATaskCommentService = async ({ taskID, comment }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!taskID || !comment) return;
  try {
    return await client.post(`/api/cna-assignments/shift-records-tasks/${taskID}/comments/`, { text: comment });
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const deleteCNATaskCommentService = async ({ taskID, commentID }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!taskID || !commentID) return;
  try {
    return await client.delete(`/api/cna-assignments/shift-records-tasks/${taskID}/comments/${commentID}/`);
  } catch (e) {
    console.error(e);
    return e;
  }
};
export const updateNATaskCommentService = async ({ taskID, commentID, commentText }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!taskID || !commentID) return;
  try {
    return await client.patch(`/api/cna-assignments/shift-records-tasks/${taskID}/comments/${commentID}/`, { text: commentText });
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const saveAsANewTemplateService = async ({ shiftID, name }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!shiftID) return;
  try {
    return await client.post(`/api/cna-assignments/shifts/${shiftID}/templates/`, { name });
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const updateTemplateService = async ({ shiftID, templateID }) => {
  if (cancel !== undefined) {
    cancel();
  }

  if (!shiftID) return;

  try {
    return await client.put(`/api/cna-assignments/shifts/${shiftID}/templates/${templateID}/`);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const editTemplateService = async ({ shiftID, templateID, name }) => {
  if (cancel !== undefined) {
    cancel();
  }

  if (!shiftID | !name) return;

  try {
    return await client.patch(`/api/cna-assignments/shifts/${shiftID}/templates/${templateID}/`, { name });
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getTemplatesService = async ({ shiftID }) => {
  if (cancel !== undefined) {
    cancel();
  }

  if (!shiftID) return;

  try {
    return await client.get(`/api/cna-assignments/shifts/${shiftID}/templates/`);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const deleteTemplateService = async ({ shiftID, templateID }) => {
  if (cancel !== undefined) {
    cancel();
  }

  if (!shiftID | !templateID) return;

  try {
    return await client.delete(`/api/cna-assignments/shifts/${shiftID}/templates/${templateID}`);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const applyTemplateService = async ({ shiftID, templateID }) => {
  if (cancel !== undefined) {
    cancel();
  }

  if (!shiftID | !templateID) return;

  try {
    return await client.post(`/api/cna-assignments/shifts/${shiftID}/templates/${templateID}/apply/`);
  } catch (e) {
    console.log(e);
    return e;
  }
};
