import React, { memo } from 'react';

const AttachPdfToEmailSetting = ({ value = false, onChange, id }) => {
  return (
    <div className={`form-control-checkbox mt-2`}>
      <input name={id} type="checkbox" onChange={(e) => onChange(e.target.checked, id)} checked={value} />
      <span style={{ color: '#101828', fontWeight: 500 }}>Attach PDF with submissions to the email</span>
    </div>
  );
};

export default memo(AttachPdfToEmailSetting);
