import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SubmissionSettingsInitPublic } from './submition-settings-public';
import { SubmissionSettingsInitUnregister } from './submition-settings-unregister';
import { SubmissionSettingsInitSystem } from './submition-settings-system';
import { SubmissionSettingsInitContact } from './submition-settings-contact';
import { SubmissionSettingsInitAvailability } from './submission-settings-availability';
import { SubmissionSettingsInitFrequency } from './submition-settings-frequency';
import { CAN_INITIATOR_SELECT_ASSIGNED_FORMS_SUBFOLDER_KEY } from '../../from-builder/form-settings/special-settings';
import { getFormsAssignedToMeAllFolders } from '../../../services/corporate-library/forms-assigned-to-me';
import { isResponseOk } from '../../../utility/isResponseOk';
import { useAuth } from '../../../container/authContext';
import useOutsideClick from '../../../hook/useOutsideClick';

const findFolderByID = (folders, id) => {
  for (const folder of folders) {
    if (folder.id === id) {
      return folder;
    }
    if (folder.folders && !!folder.folders.length) {
      const found = findFolderByID(folder.folders, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const MenuItem = ({ data, onSelectFolder, activeFolderID }) => {
  const { id, name, folders } = data;
  const isActive = id === activeFolderID;

  return (
    <li className={isActive ? 'active-folder' : ''}>
      <span className={'cursor-pointer'} onClick={() => onSelectFolder(data.id)}>
        {!folders?.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M20.0005 17.4C20.0005 17.8774 19.8108 18.3352 19.4733 18.6728C19.1357 19.0104 18.6779 19.2 18.2005 19.2H3.80049C3.3231 19.2 2.86526 19.0104 2.5277 18.6728C2.19013 18.3352 2.00049 17.8774 2.00049 17.4V4.8C2.00049 4.32261 2.19013 3.86477 2.5277 3.52721C2.86526 3.18964 3.3231 3 3.80049 3H8.30049L10.1005 5.7H18.2005C18.6779 5.7 19.1357 5.88964 19.4733 6.22721C19.8108 6.56477 20.0005 7.02261 20.0005 7.5V17.4Z"
                stroke="#98A2B3"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>
        )}
        {!!folders?.length && (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
              <path
                d="M2.50049 3.75L5.00049 6.25L7.50049 3.75"
                stroke="#98A2B3"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="M20.7502 11.5484V20.2984C20.7502 20.4841 20.6753 20.6621 20.5419 20.7934C20.4086 20.9247 20.2277 20.9984 20.0391 20.9984H4.7502C4.53802 20.9984 4.33454 20.9155 4.18451 20.7678C4.03448 20.6201 3.9502 20.4198 3.9502 20.2109V9.18594C3.9502 8.97708 4.03448 8.77678 4.18451 8.62909C4.33454 8.48141 4.53802 8.39844 4.7502 8.39844H9.28353C9.45662 8.39844 9.62505 8.4537 9.76353 8.55594L12.5369 10.6034C12.6753 10.7057 12.8438 10.7609 13.0169 10.7609H19.9502C20.1624 10.7609 20.3659 10.8439 20.5159 10.9916C20.6659 11.1393 20.7502 11.3396 20.7502 11.5484Z"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.05029 7.34922V4.98672C6.05029 4.77786 6.13985 4.57756 6.29925 4.42987C6.45866 4.28219 6.67486 4.19922 6.90029 4.19922H11.717C11.9009 4.19922 12.0798 4.25448 12.227 4.35672L15.1736 6.40422C15.3208 6.50645 15.4997 6.56172 15.6836 6.56172H23.0503C23.2757 6.56172 23.4919 6.64469 23.6513 6.79237C23.8107 6.94006 23.9003 7.14036 23.9003 7.34922V16.0992C23.9003 16.1911 23.8807 16.2822 23.8428 16.3671C23.8048 16.452 23.7492 16.5292 23.679 16.5942C23.6088 16.6592 23.5255 16.7108 23.4339 16.7459C23.3422 16.7811 23.244 16.7992 23.1447 16.7992H21.7003"
                  stroke="#98A2B3"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </>
        )}
        {name}
      </span>

      {!!folders?.length && (
        <ul className="folder-select-ul-inner">
          {folders.map((i) => (
            <MenuItem data={i} key={i.id} onSelectFolder={onSelectFolder} activeFolderID={activeFolderID} />
          ))}
        </ul>
      )}
    </li>
  );
};

export const SubmissionSettingsInit = function SubmissionSettingsInit(props) {
  const { formData, handelInitSettings, initData, errors, tabs, changeTab, handleSubmitSettings } = props;
  const { user } = useAuth();
  const formsAssignedToMeSelectFolderRef = useRef(null);
  const formsAssignedToMeFoldersMenuRef = useRef(null);

  const [tab, setTab] = useState(null);
  const [tabBack, setTabBack] = useState(null);
  const [isOpenFormsAssignedToMeFoldersSelect, setIsOpenFormsAssignedToMeFoldersSelect] = useState(false);
  const [assignedToMeFolders, setAssignedToMeFolders] = useState(null);

  const userCompanyID = user?.company?.id;
  const canInitiatorSelectAssignedFormsSubfolder = formData.submission_settings?.[CAN_INITIATOR_SELECT_ASSIGNED_FORMS_SUBFOLDER_KEY];
  const selectedFormsAssignedToMeSubfolderID = initData.submitters_settings?.forms_assigned_to_me_folder_id;

  const selectedFormsAssignedToMeSubfolder = useMemo(() => {
    if (!selectedFormsAssignedToMeSubfolderID || !assignedToMeFolders?.length) {
      return null;
    }
    return findFolderByID(assignedToMeFolders, selectedFormsAssignedToMeSubfolderID);
  }, [assignedToMeFolders, selectedFormsAssignedToMeSubfolderID]);

  useEffect(() => {
    const recipientTab = tabs.find((item) => item.component === 'recipient-settings-init');
    if (!recipientTab?.isDisable) {
      setTab(recipientTab);
    } else {
      const notificationTab = tabs.find((item) => item.component === 'notification-settings-init');
      if (!notificationTab?.isDisable) {
        setTab(notificationTab);
      }
    }

    setTabBack(tabs.find((item) => item.component === 'view-form'));
  }, [tabs]);

  useEffect(() => {
    if (userCompanyID && canInitiatorSelectAssignedFormsSubfolder) {
      getFormsAssignedToMeAllFolders({ company_id: userCompanyID }).then((res) => {
        if (isResponseOk(res) && res.data) {
          setAssignedToMeFolders(res.data);
        }
      });
    }
  }, [canInitiatorSelectAssignedFormsSubfolder, userCompanyID]);

  useEffect(() => {
    const updateMaxHeight = () => {
      const element = formsAssignedToMeFoldersMenuRef.current;
      if (!element) return;

      const rect = element.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      // 106 - height of form-control-actions
      const spaceBelow = viewportHeight - rect.top - 106;
      const elementHeight = element.scrollHeight;

      // 440 - default maxHeight of folder-select-menu
      if (elementHeight > spaceBelow && spaceBelow < 440) {
        element.style.maxHeight = `${spaceBelow}px`;
      } else {
        element.style.maxHeight = '';
      }
    };

    if (isOpenFormsAssignedToMeFoldersSelect) {
      updateMaxHeight();
      window.addEventListener('resize', updateMaxHeight);
      window.addEventListener('scroll', updateMaxHeight);
    }

    return () => {
      window.removeEventListener('resize', updateMaxHeight);
      window.removeEventListener('scroll', updateMaxHeight);
    };
  }, [isOpenFormsAssignedToMeFoldersSelect]);

  const onChangeTab = () => {
    changeTab(tab);
  };
  const onBackTab = () => {
    changeTab(tabBack);
  };

  const handleSelectAssignedToMeSubfolder = (folderID) => {
    handelInitSettings(
      {
        ...(initData?.submitters_settings ?? {}),
        forms_assigned_to_me_folder_id: folderID,
      },
      'submitters_settings',
    );
  };

  useOutsideClick(formsAssignedToMeSelectFolderRef, () => setIsOpenFormsAssignedToMeFoldersSelect(false));

  return (
    <>
      <div className="form-builder-setting-tab-content">
        <div className={'form-builder-recipient-settings form-init-settings'}>
          {formData?.name && (
            <div className="from-builder-title from-builder-title-setting">
              <h1>
                <img src="/images/file-big-icon.png" className="mr-2" alt="" />
                {formData.name}
              </h1>
            </div>
          )}

          {formData.filling_type === 'PUBLIC' && (
            <SubmissionSettingsInitPublic formData={formData} handelInitSettings={handelInitSettings} errors={errors} initData={initData} />
          )}

          {formData.filling_type === 'SPECIAL' && (
            <>
              {canInitiatorSelectAssignedFormsSubfolder && (
                <>
                  <div className="form-builder-setting-tab-content">
                    <div className="form-builder-recipient-settings">
                      <div className="form-control-item-settings">
                        <div className="form-settings-label">
                          <label className="form-label form-label-big">Select Form's Destination After Initiation</label>
                          <span>Select where the form after the initiation should be placed.</span>
                        </div>
                        <div className="form-settings-inputs">
                          <div className={`form-control-item-group form-item form-item-select`}>
                            <label className="form-label form-label-big">Forms assigned to me</label>
                            <div className="folder-select" ref={formsAssignedToMeSelectFolderRef}>
                              <div
                                className="folder-select-input cursor-pointer"
                                onClick={() => setIsOpenFormsAssignedToMeFoldersSelect((prevState) => !prevState)}
                              >
                                {!selectedFormsAssignedToMeSubfolder && (
                                  <span className="folder-select-input-placeholder">Choose folder</span>
                                )}
                                {selectedFormsAssignedToMeSubfolder && selectedFormsAssignedToMeSubfolder.name}
                                <i className="icon-arrow-down"></i>
                              </div>
                              {isOpenFormsAssignedToMeFoldersSelect && (
                                <div className="folder-select-menu" ref={formsAssignedToMeFoldersMenuRef}>
                                  <ul className="folder-select-ul">
                                    {assignedToMeFolders?.map((folder) => (
                                      <MenuItem
                                        data={folder}
                                        key={folder.id}
                                        activeFolderID={selectedFormsAssignedToMeSubfolderID}
                                        onSelectFolder={handleSelectAssignedToMeSubfolder}
                                      />
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {formData.submission_settings.can_initiator_select_submitters && (
                <>
                  {formData.submission_settings.submitters.submitters_type === 'user' && (
                    <SubmissionSettingsInitSystem
                      errors={errors}
                      formData={formData}
                      handelInitSettings={handelInitSettings}
                      initData={initData}
                    />
                  )}
                  {formData.submission_settings.submitters.submitters_type === 'unregistered' && (
                    <SubmissionSettingsInitUnregister
                      errors={errors}
                      formData={formData}
                      handelInitSettings={handelInitSettings}
                      initData={initData}
                    />
                  )}
                  {formData.submission_settings.submitters.submitters_type === 'contact' && (
                    <SubmissionSettingsInitContact
                      errors={errors}
                      formData={formData}
                      handelInitSettings={handelInitSettings}
                      initData={initData}
                    />
                  )}
                </>
              )}
              <SubmissionSettingsInitAvailability
                errors={errors}
                formData={formData}
                handelInitSettings={handelInitSettings}
                initData={initData}
              />
              <SubmissionSettingsInitFrequency
                errors={errors}
                formData={formData}
                handelInitSettings={handelInitSettings}
                initData={initData}
              />
            </>
          )}
        </div>
      </div>
      <div className="form-control-actions form-init-content">
        <div>
          <button onClick={onBackTab} className={`btn btn-outline mr-2 btn-wide`}>
            <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15.8327 10.0013H4.16602M4.16602 10.0013L9.99935 15.8346M4.16602 10.0013L9.99935 4.16797"
                stroke="#101828"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </button>
          {tab && !tab?.isDisable ? (
            <button disabled={tab?.isDisable} className="btn btn-outline btn-outline-secondary btn-wide" onClick={onChangeTab}>
              Next
              <svg className={'ml-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M4.16602 10.0013H15.8327M15.8327 10.0013L9.99935 4.16797M15.8327 10.0013L9.99935 15.8346"
                  stroke="#7F56D9"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          ) : (
            <button className="btn btn-wide" onClick={handleSubmitSettings} style={{ height: 41 }}>
              Submit
            </button>
          )}
        </div>
      </div>
    </>
  );
};
