import client from '../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancel;
const CancelTokenInitiators = axios.CancelToken;
let cancelInitiators;

const CancelTokenPositions = axios.CancelToken;
let cancelPositions;

const CancelTokenSubmitters = axios.CancelToken;
let cancelSubmitters;
const CancelTokenInfo = axios.CancelToken;
let cancelInfo;

export const getSubmissionFacilities = async (corporate_form_id, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/submissions-folder/forms/${corporate_form_id}/facilities/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};

export const getSubmissionInitiators = async (corporate_form_id, params) => {
  if (cancelInitiators !== undefined) {
    cancelInitiators();
  }

  const response = await client.get(`/api/corporate-library/submissions-folder/forms/${corporate_form_id}/initiators/`, {
    params,
    cancelToken: new CancelTokenInitiators(function executor(c) {
      cancelInitiators = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};

export const getSubmissionPositions = async (corporate_form_id, params) => {
  if (cancelPositions !== undefined) {
    cancelPositions();
  }

  const response = await client.get(`/api/corporate-library/submissions-folder/forms/${corporate_form_id}/positions/`, {
    params,
    cancelToken: new CancelTokenPositions(function executor(c) {
      cancelPositions = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};
export const getSubmissionSubmitters = async (corporate_form_id, params) => {
  if (cancelSubmitters !== undefined) {
    cancelSubmitters();
  }

  const response = await client.get(`/api/corporate-library/submissions-folder/forms/${corporate_form_id}/submitters/`, {
    params,
    cancelToken: new CancelTokenSubmitters(function executor(c) {
      cancelSubmitters = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};
export const getSubmissionInfo = async (corporate_form_id) => {
  if (cancelInfo !== undefined) {
    cancelInfo();
  }

  const response = await client.get(`/api/corporate-library/submissions-folder/forms/${corporate_form_id}/info/`, {
    params: { status: 'all' },
    cancelToken: new CancelTokenInfo(function executor(c) {
      cancelInfo = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};

export const getSubmissionProfileInfo = async () => {
  if (cancelInfo !== undefined) {
    cancelInfo();
  }

  const response = await client.get(`/api/users/my-submissions/`, {
    cancelToken: new CancelTokenInfo(function executor(c) {
      cancelInfo = c;
    }),
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });

  return response.data;
};
