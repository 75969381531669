import React, { useLayoutEffect } from 'react';

function FormBuilder({ title, content, pageClassName, ...props }) {
  useLayoutEffect(() => {
    document.title = `${title}`;
  });

  return (
    <div className="form-builder-layout form-builder-layout-full">
      <header className="header-wrapper">
        <div className="header-left">
          <div className="header-logo">
            <img src="/images/logo.svg" alt="" />
          </div>
        </div>
      </header>
      <div className="form-builder-wrapper">
        <main className={pageClassName}>{props.children}</main>
      </div>
    </div>
  );
}

export default FormBuilder;
