export const statusesOptions = [
  {
    value: undefined,
    label: 'All',
  },
  {
    value: 'ACTIVE',
    label: 'Current',
  },
  {
    // As "string-array" of statuses
    value: 'MANUAL_STOP_FUTURE,MANUAL_STOP_ALL,END_DATETIME_STOP,FREQUENCY_STOP,DELETE',
    label: 'Closed',
  },
];
export const tableColumns = [
  {
    key: 'name',
    label: 'Form name + attached text field',
  },
  {
    key: 'initiator',
    label: 'Form Initiator',
  },
  {
    key: 'initiationDate',
    label: 'Initiation date',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
export const initialFiltersState = {
  initiators: [],
  // All
  status: statusesOptions[0],
  initiationDate: {
    start: null,
    end: null,
  },
};
