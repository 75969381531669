import React, { useMemo, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { MultiSelect } from '../../../../other/MultiSelect';

const EditPreviousSubmissionsSetting = ({ settings, handelSettings, errors, handleRemoveAllowedSubmissionsEditor }) => {
  const allowedSubmissionsEditorsOptions = useMemo(() => {
    if (settings.submitters?.individual_users?.length) {
      const usersOptions = settings.submitters.individual_users.map((option) => ({
        ...option,
        type: 'user',
      }));
      const groupsOptions = settings.submitters.user_groups.map((option) => ({
        ...option,
        type: 'group',
      }));
      return [...groupsOptions, ...usersOptions];
    } else if (settings.submitters?.user_groups?.length) {
      return settings.submitters.user_groups.map((option) => ({
        ...option,
        type: 'group',
      }));
    } else if (settings.submitters?.facilities?.length) {
      return settings.submitters.facilities.map((option) => ({
        ...option,
        type: 'facility',
      }));
    }

    return [];
  }, [settings.submitters.individual_users, settings.submitters.user_groups, settings.submitters.facilities]);

  useEffect(() => {
    if (!settings?.allowed_submissions_editors?.length) return;

    const newSelectedSubmitters = settings.allowed_submissions_editors.filter((submitter) => {
      const { individual_users, user_groups, facilities } = settings.submitters;

      switch (submitter.type) {
        case 'user':
          return individual_users.some((user) => user.value === submitter.value);

        case 'group':
          return user_groups.some((group) => group.value === submitter.value);

        case 'facility':
          return (
            individual_users.length === 0 && user_groups.length === 0 && facilities.some((facility) => facility.value === submitter.value)
          );

        default:
          return false;
      }
    });

    handelSettings(newSelectedSubmitters, 'allowed_submissions_editors');
  }, [settings.submitters.individual_users, settings.submitters.user_groups, settings.submitters.facilities]);

  return (
    <>
      <div className="form-control-item-settings">
        <div className="form-settings-label">
          <label className="form-label form-label-big">Can Submitters Edit Previous Submissions?</label>
          <span>Please determine if submitters will have the option to make edits to previous submission.</span>
        </div>
        <div className="form-settings-inputs">
          <div className="d-flex">
            <div className={`form-control-radio`}>
              <input
                name="can_edit_previous_submission"
                type="radio"
                onChange={() => handelSettings(true, 'can_edit_previous_submission')}
                checked={settings.can_edit_previous_submission === true}
              />
              <span>Yes</span>
              <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_edit_previous_submission`}></i>
              <Tooltip opacity={1} id={`table-tooltip-can_edit_previous_submission`} place="bottom">
                The form submitter can edit previous submissions, added by them or other submitters
              </Tooltip>
            </div>
            <div className={`form-control-radio`}>
              <input
                name="can_edit_previous_submission"
                type="radio"
                onChange={() => handelSettings(false, 'can_edit_previous_submission')}
                checked={!settings.can_edit_previous_submission}
              />
              <span>No</span>
              <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-can_edit_previous_submission-false`}></i>
              <Tooltip opacity={1} id={`table-tooltip-can_edit_previous_submission-false`} place="bottom">
                The form submitter can edit previous submissions, added by them or other submitters
              </Tooltip>
            </div>
          </div>
          <span className="form-error mb-2 d-flex">{errors?.can_edit_previous_submission && errors.can_edit_previous_submission[0]}</span>
        </div>
      </div>

      {settings.can_edit_previous_submission && (
        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Define who can edit previous submissions</label>
            <span>You can select one or multiple form submitters who can edit previous submissions</span>
          </div>
          <div className="form-settings-inputs">
            <div className={`form-item form-item-select mb-4`}>
              <label className="form-label form-label-big">Select</label>
              <div className="select-multy-wrapper">
                <span className="select-placeholder">Select</span>
                <MultiSelect
                  options={allowedSubmissionsEditorsOptions}
                  value={settings.allowed_submissions_editors}
                  onChange={(e) => handelSettings(e, 'allowed_submissions_editors')}
                  placeholder="Select"
                  allName="All"
                  menuPlacement="top"
                ></MultiSelect>
                {settings.allowed_submissions_editors?.length > 0 && (
                  <>
                    <ul>
                      {settings.allowed_submissions_editors.map((item, key) => (
                        <li key={key}>
                          {item.label}
                          <button className="btn btn-icon" onClick={() => handleRemoveAllowedSubmissionsEditor(item)}>
                            <img src="/images/x-circle.svg" alt="" />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
              <span className="form-error mb-2 d-flex">
                {errors?.allowed_submissions_editors && errors.allowed_submissions_editors.non_field_errors[0]}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditPreviousSubmissionsSetting;
