import React, { useRef, useState } from 'react';
import useOutsideClick from '../../hook/useOutsideClick';

function PermissionsItem({ item, handelEdit, handelDeactivate }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef();
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);
  return (
    <div className="item mr-2">
      <div className="item-title">
        {item.action.name}
        <div className="dropdown ml-4" ref={ref}>
          <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn btn-outline">
            <img src="/images/more-vertical.svg" alt="" />
          </button>
          {isDropdownOpen && (
            <ul className="dropdown-menu">
              <li>
                <button className="btn btn-left btn-full btn-text" onClick={() => handelEdit(item)}>
                  <i className="icon-icon-edit mr-2"></i>Edit Permission
                </button>
              </li>
              <li>
                <button className="btn btn-left btn-full btn-danger btn-text" onClick={() => handelDeactivate(item)}>
                  <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="trash-2">
                      <path
                        id="Icon"
                        d="M2.5 5.00033H4.16667M4.16667 5.00033H17.5M4.16667 5.00033V16.667C4.16667 17.109 4.34226 17.5329 4.65482 17.8455C4.96738 18.1581 5.39131 18.3337 5.83333 18.3337H14.1667C14.6087 18.3337 15.0326 18.1581 15.3452 17.8455C15.6577 17.5329 15.8333 17.109 15.8333 16.667V5.00033H4.16667ZM6.66667 5.00033V3.33366C6.66667 2.89163 6.84226 2.46771 7.15482 2.15515C7.46738 1.84259 7.89131 1.66699 8.33333 1.66699H11.6667C12.1087 1.66699 12.5326 1.84259 12.8452 2.15515C13.1577 2.46771 13.3333 2.89163 13.3333 3.33366V5.00033M8.33333 9.16699V14.167M11.6667 9.16699V14.167"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                  Delete Permission
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      {item.influenced_facilities.length > 0 && (
        <>
          <p>This permission applies to the following facilties:</p>
          <ul className="d-flex flex-wrap ">
            {item.influenced_facilities.map((item, key) => (
              <li key={key}>{item.name};</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default PermissionsItem;
