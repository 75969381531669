import React, { useEffect, useState } from 'react';

export const TitleElement = function FromElement({ element, isActive, handlerTitleSettings }) {
  const [style, setStyle] = useState({
    fontSize: element.options.fontSize,
    textAlign: element.options.alignment,
  });

  useEffect(() => {
    setStyle(() => {
      return {
        fontSize: `${element.options.fontSize}px`,
        textAlign: element.options.alignment,
      };
    });
  }, [element]);

  return (
    <div className="from-builder-title form-builder-element-preview">
      <h1 style={style}>
        <i className="icon-file-text"></i>
        {element.options.value}
      </h1>

      <div className={`form-builder-element-preview-action ${isActive ? 'form-builder-element-preview-action-active' : ''}`}>
        <button onClick={handlerTitleSettings} className="btn btn-settings btn-icon">
          <i className="icon-settings"></i>
        </button>
      </div>
    </div>
  );
};
