import React, { memo, useCallback, useMemo } from 'react';
import './styles.scss';
import Select from 'react-select';
import { notificationOptions } from '../../../constants/forms/options';

const StrictOrderUserNotificationSettings = (props) => {
  const { stepIndex, onSetStepUserNotification, notificationData = {} } = props;
  const {
    should_receive_notification: isActive,
    notification_type: notificationType,
    notification_text: notificationMessage = '',
    errors,
  } = notificationData ?? {};

  const notificationTypeOption = useMemo(() => {
    return notificationType ? notificationOptions.find(({ value }) => value === notificationType) : undefined;
  }, [notificationType]);
  const radioInputName = `${stepIndex}-StrictOrderUserNotification`;

  const handleSetStepUserNotification = useCallback(
    (key, value) => {
      if (onSetStepUserNotification) {
        const newData = {
          should_receive_notification: isActive,
          notification_type: notificationType,
          notification_text: notificationMessage,
          [key]: value,
        };
        if (!newData.should_receive_notification) {
          newData.notification_type = undefined;
          newData.notification_text = undefined;
        }
        onSetStepUserNotification(newData, 'submitters_order', stepIndex);
      }
    },
    [notificationData, onSetStepUserNotification, stepIndex],
  );

  const handleIsActive = () => handleSetStepUserNotification('should_receive_notification', !isActive);

  const handleChangeNotificationType = (e) => handleSetStepUserNotification('notification_type', e.value);

  const handleChangeNotificationMessage = (e) => handleSetStepUserNotification('notification_text', e.target.value);

  return (
    <div className={'strict-order-user-notification-settings'}>
      <label className="form-label form-label-big">Notify user</label>
      <div className="d-flex">
        <div className="form-control-radio">
          <input type="radio" name={radioInputName} onChange={handleIsActive} checked={isActive} />
          <span>Yes</span>
        </div>
        <div className="form-control-radio">
          <input type="radio" name={radioInputName} onChange={handleIsActive} checked={!isActive} />
          <span>No</span>
        </div>
      </div>
      {isActive && (
        <>
          <div className="form-control-item-group">
            <label className="form-label">Notification type</label>
            <div className="select-multy-wrapper">
              <Select
                hideSelectedOptions={false}
                isSearchable={false}
                options={notificationOptions}
                value={notificationTypeOption}
                onChange={handleChangeNotificationType}
                className="form-control-select"
                placeholder="Sms / Email"
                classNamePrefix="react-select"
              />
            </div>
            <span className="form-error">{errors?.notification_type}</span>
          </div>

          <div className="form-control-item-group">
            <label className="form-label">Notification text</label>
            <textarea
              className="form-control"
              value={notificationMessage}
              placeholder={'Enter notification or message text that will be sent to users  who were assigned as form submitters'}
              onChange={handleChangeNotificationMessage}
            />
            <span className="form-error">{errors?.notification_text}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(StrictOrderUserNotificationSettings);
