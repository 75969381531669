// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-to-general-library-modal .folder-select {
  max-width: 470px;
  margin: 0 auto;
}
.copy-to-general-library-modal .folder-select .folder-select-menu {
  position: initial;
}`, "",{"version":3,"sources":["webpack://./src/components/library/form-item/components/copy-to-general-library-modal/styles.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,cAAA;AAAJ;AAEI;EACE,iBAAA;AAAN","sourcesContent":[".copy-to-general-library-modal {\n  .folder-select {\n    max-width: 470px;\n    margin: 0 auto;\n\n    .folder-select-menu {\n      position: initial;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
