import React, { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { getCompanies } from '../../services/company';
import { shareWithCompany } from '../../services/form';
import CopyModal from '../company/copy-modal';

export const ShareWithCompany = function ViewForm({ formData }) {
  const [companyOptions, setCompanyOptions] = useState([]);
  const [company, setCompany] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    getCompanies('getAll')
      .then((res) => {
        setCompanyOptions(res.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [id]);

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const handelCopy = () => {
    const bodyRequest = {
      target_company_id: company.value,
      source_form_id: Number(id),
    };
    setError(null);
    shareWithCompany(bodyRequest)
      .then((res) => {
        console.log(res);
        openModal();
        setCompany('');
      })
      .catch((e) => {
        setError(e.response.data);
      });
  };

  return (
    <div className="init-form-tab share-with-company">
      {formData?.name && (
        <div className="from-builder-title from-builder-title-setting">
          <h1>
            <img src="/images/file-big-icon.png" className="mr-2" alt="" />
            {formData.name}
          </h1>
        </div>
      )}

      <div className="init-form-tab-content">
        <div className="share-with-company-header">
          <img src="/images/company-add-icon-1.svg" className="mr-2" alt="" />
          Share copy with company
        </div>
        <div className="share-with-company-body">
          <div className="form-item mb-4">
            <label className="form-label form-label-big">Select Company</label>
            <Select
              classNamePrefix="react-select"
              className={`form-control-select`}
              options={companyOptions}
              value={company}
              onChange={(e) => {
                setCompany(e);
                setError(null);
              }}
            ></Select>
            <span className="form-error mb-2">{error?.source_form_id && error.source_form_id[0]}</span>
            <span className="form-error mb-2">{error?.target_company_id && error.target_company_id[0]}</span>
          </div>
        </div>
        <div className="share-with-company-footer">
          <Link to={`/corporate-library`} className="btn btn-outline">
            Cancel
          </Link>
          <button disabled={!company} onClick={handelCopy} className="btn">
            Share
          </button>
        </div>
      </div>

      <div className="form-control-actions">
        <Link to={`/corporate-library`} className="btn btn-outline">
          Back to General Library
        </Link>
      </div>

      <CopyModal
        isOpen={isOpen}
        closeModal={closeModal}
        handelSuccess={closeModal}
        button={'Ok'}
        title={'Share copy with company'}
        subtitle={'The form has been successfully copied to the company'}
      />
    </div>
  );
};
