/**
 * Converts a date-time string to an ISO 8601 formatted string.
 *
 * @param {string | Date} [dateTime] - The date-time string to convert.
 *                                    Expected format: any format recognized by the JavaScript `Date` constructor.
 * @returns {string} - The ISO 8601 formatted string representation of the provided date
 *                     or the current date if no input is given.
 * @throws {Error} - Throws an error if the provided `dateTimeString` is invalid.
 */
export const dateToISOString = (dateTime) => {
  let isoString;

  if (dateTime) {
    let date;
    if (typeof dateTime === 'string') {
      date = new Date(dateTime);
    } else if (dateTime instanceof Date) {
      date = dateTime;
    } else {
      throw new Error(`Unsupported type for dateTime: ${typeof dateTime}`);
    }

    if (!isNaN(date.getTime())) {
      isoString = date.toISOString();
    } else {
      throw new Error(`Invalid date for conversion: ${dateTime}`);
    }
  } else {
    isoString = new Date().toISOString();
  }

  return isoString;
};
