import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { UserMenu } from '../../components/auth/UserMenu';
import { SideBarMenu } from '../../components/other/SideBarMenu';
import { useAuth } from '../../container/authContext';
import { usePermission } from '../../container/permissionContext';
import { getNotificationsList } from '../../services/notifications';
import HeaderLogOutButton from '../../components/header/log-out-button';

// import {useAuth} from "../../container/authContext";

function FormBuilder({ title, content, pageClassName, togglePreview, ...props }) {
  const { id } = useParams();
  const location = useLocation();
  const { user } = useAuth();
  const { isSuperAdmin } = usePermission();
  const backToFormUrl = isSuperAdmin() && !user.company ? `/general-view-form/${id}` : `/initialization-form/${id}`;

  const [activeLink, setActiveLink] = useState('');
  const [countNotification, setCountNotification] = useState(0);

  useLayoutEffect(() => {
    document.title = `${title}`;
  });

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    getNotificationsList()
      .then((res) => {
        setCountNotification(res.unread_count);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="form-builder-layout">
      <header className="header-wrapper">
        <div className="header-left">
          <div className="header-logo">
            <img src="/images/logo.svg" alt="" />
          </div>
          <ul className="form-builder-header">
            {/* eslint-disable-next-line */}
            <li className={`${activeLink.indexOf('constructor') > 0 ? 'active' : ''} ${!id ? 'disabled-link' : ''}`}>
              <Link to={`/form-builder/${id}/constructor`}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M5.8 5.8H5.807M5.8 14.2H5.807M4.4 3H15.6C16.3732 3 17 3.6268 17 4.4V7.2C17 7.9732 16.3732 8.6 15.6 8.6H4.4C3.6268 8.6 3 7.9732 3 7.2V4.4C3 3.6268 3.6268 3 4.4 3ZM4.4 11.4H15.6C16.3732 11.4 17 12.0268 17 12.8V15.6C17 16.3732 16.3732 17 15.6 17H4.4C3.6268 17 3 16.3732 3 15.6V12.8C3 12.0268 3.6268 11.4 4.4 11.4Z"
                      stroke="#6941C6"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                Constructor
              </Link>
            </li>
            {/* eslint-disable-next-line */}
            {user.company && (
              <>
                <li className={`${activeLink.indexOf('settings') > 0 ? 'active' : ''} ${!id ? 'disabled-link' : ''}`}>
                  <Link to={`/form-builder/${id}/settings`}>
                    <i className="icon-settings"></i>Settings
                  </Link>
                </li>
                <li className={`${activeLink.indexOf('place-in-form') > 0 ? 'active' : ''} ${!id ? 'disabled-link' : ''}`}>
                  <Link to={`/form-builder/${id}/place-in-form`}>
                    <i className="icon-icon-folder"></i>Place the form
                  </Link>
                </li>
              </>
            )}
            {isSuperAdmin() && !user.company && (
              <li
                className={`${activeLink.indexOf('place-in-form') > 0 ? 'active' : ''} ${activeLink.indexOf('place-in-general-form') > 0 ? 'active' : ''} ${!id ? 'disabled-link' : ''}`}
              >
                <Link to={`/form-builder/${id}/place-in-general-form`}>
                  <i className="icon-icon-folder"></i>Place the form
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="header-right">
          <div className="header-right__main">
            <Link to={backToFormUrl} className={'btn btn-outline btn-preview'}>
              <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M15.8327 10.0013H4.16602M4.16602 10.0013L9.99935 15.8346M4.16602 10.0013L9.99935 4.16797"
                  stroke="#101828"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back to form
            </Link>

            <button onClick={() => togglePreview(true)} className={'btn btn-outline btn-preview'}>
              <svg className={'mr-2'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 4.375C3.75 4.375 1.25 10.0006 1.25 10.0006C1.25 10.0006 3.75 15.625 10 15.625C16.25 15.625 18.75 10.0006 18.75 10.0006C18.75 10.0006 16.25 4.375 10 4.375Z"
                  stroke="#202024"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z"
                  stroke="#202024"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Preview
            </button>
            <UserMenu />
            <Link
              className={`btn btn-outline btn-notification ${location.pathname.includes('notifications') ? 'btn-notification-active' : ''}`}
              to={'/notifications'}
            >
              {countNotification > 0 && <span className={'counter'}>{countNotification}</span>}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                  stroke="#7F56D9"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>

          <HeaderLogOutButton />
        </div>
      </header>
      <div className="form-builder-wrapper">
        <aside className="sidebar-menu sidebar-menu-mini">
          <SideBarMenu />
        </aside>
        <main className={pageClassName}>{props.children}</main>
      </div>
    </div>
  );
}

export default FormBuilder;
