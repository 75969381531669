import client from '../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancel;
let cancelUser;
let cancelRoles;

export const getUsersByCompany = async (idCompany, page, facility_id, positions_id, status) => {
  if (cancel !== undefined) {
    cancel();
  }
  let params = {};

  if (page === 'getAll') {
    params['get_all'] = true;
  } else if (page) {
    params['page'] = page;
  }
  if (facility_id) {
    params['facilities_ids'] = facility_id;
  }
  if (positions_id) {
    params['positions_ids'] = positions_id;
  }
  if (status) {
    params['status'] = status;
  }

  const response = await client.get(`api/companies/${idCompany}/users`, {
    params,
    headers: {
      'Content-Type': 'application/json',
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getUserById = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/users/${id}`, {
    params: { status: 'all' },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getProfile = async () => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/users/profile/`, {
    params: { status: 'all' },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getUsersList = async (idCompany, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/companies/${idCompany}/users`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getRoles = async () => {
  if (cancelRoles !== undefined) {
    cancelRoles();
  }

  const response = await client.get(`api/roles?get_all=true`, {
    cancelToken: new CancelToken(function executor(c) {
      cancelRoles = c;
    }),
  });

  return response.data;
};

export const createUser = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post('api/users/', data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const importUser = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post('api/users/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: { company_id: id },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const updateUser = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/users/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const resetPassword = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/users/reset-password/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deactivateUser = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/users/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const activateUser = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/users/${id}/restore/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const resendSetLoginLink = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`api/users/${id}/resend-set-login-password-link/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getUsersListSettings = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/corporate-library/forms/settings/individual-users/`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getUsersListFileSettings = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/corporate-library/files/folder/access-settings/individual-users/`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getUsersListSettingsInit = async (params) => {
  if (cancelUser !== undefined) {
    cancelUser();
  }

  const response = await client.get(`api/corporate-library/forms/initiate/settings/individual-users/`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUser = c;
    }),
  });

  return response.data;
};
