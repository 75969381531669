import React, { memo, useEffect, useRef } from 'react';

const AutoHeightTextArea = (props) => {
  const { value } = props;
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value]);

  return (
    <textarea
      style={{ minHeight: 'auto', paddingTop: 11, paddingBottom: 11, resize: 'none', overflow: 'hidden' }}
      rows={1}
      {...props}
      ref={textAreaRef}
    />
  );
};

export default memo(AutoHeightTextArea);
