import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { createGeneralFolder, editGeneralFolder } from '../../services/folder/form-avaliable';

function FacilityModal({ isOpen, closeModal, folderData, handelCreate, parent_id }) {
  const [errorFolder, setErrorFolder] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [folder, setFolder] = useState({
    name: '',
    parent_id: parent_id,
  });

  useEffect(() => {
    if (folderData) {
      setIsDisabled(false);
      setFolder({
        name: folderData.name,
        parent_id: parent_id,
      });
    }
    // eslint-disable-next-line
  }, [folderData]);

  const handleChange = (value, key) => {
    setFolder({
      ...folder,
      [key]: value,
    });

    if (errorFolder.hasOwnProperty(key)) {
      delete errorFolder[key];
    }
  };

  const resetForm = () => {
    setFolder({
      name: '',
      parent_id: parent_id,
    });
    setErrorFolder({});
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };

  const handleSubmit = async () => {
    setIsDisabled(true);
    if (folderData) {
      editGeneralFolder(folder, folderData.id)
        .then(() => {
          setIsDisabled(false);
          handelCreate();
          handelCloseModal();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setIsDisabled(false);
            setErrorFolder(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      createGeneralFolder({
        name: folder.name,
        parent_id: parent_id,
      })
        .then(() => {
          setIsDisabled(false);
          handelCreate();
          handelCloseModal();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setIsDisabled(false);
            setErrorFolder(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };

  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {folderData ? 'Edit' : 'Add New'} folder{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/folder-icon-default.png" alt="" />
          Folder information
        </h3>
        <div className={`form-item ${errorFolder.name && errorFolder.name[0] ? 'form-item-error' : ''}`}>
          <label className="form-label">Folder Name</label>
          <input
            placeholder="Enter the folder name"
            value={folder.name}
            onChange={(e) => handleChange(e.target.value, 'name')}
            type="text"
            className="form-control"
          />
          <span className="form-error">{errorFolder.name && errorFolder.name[0]}</span>
          <span className="form-error">{errorFolder.parent_id && errorFolder.parent_id[0]}</span>
          <span className="form-error">{errorFolder.company_id && errorFolder.company_id[0]}</span>
        </div>
      </div>

      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button disabled={isDisabled} className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default FacilityModal;
