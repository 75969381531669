import Header from '../components/header';
import Layout from '../../../layout/default';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './styles.scss';
import ContextMenu from '../../../components/other/context-menu';
import { deleteCNADefaultTaskService, getCNADefaultTasksService } from '../../../services/cna/default-tasks';
import { isResponseOk } from '../../../utility/isResponseOk';
import { useAuth } from '../../../container/authContext';
import { useDebounce } from 'use-debounce';
import ManageTaskModal from './components/manage-task-modal';
import PageLoader from '../../../components/other/PageLoader';

const CNADefaultTasksPage = () => {
  const { user } = useAuth();
  const companyID = user?.company?.id;

  const [search, setSearch] = useState('');
  const [isOpenCreateTaskModal, setIsOpenCreateTaskModal] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [editTaskData, setEditTask] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [debouncedSearch] = useDebounce(search, 300);

  const filteredTasks = useMemo(() => {
    let list = tasks;
    if (tasks?.length) {
      const formattedSearch = search.trim().toLowerCase();
      if (formattedSearch.length) {
        list = tasks.filter(({ text }) => text.toLowerCase().includes(formattedSearch));
      }
    }

    return list;
  }, [search, tasks]);

  const filteredTasksLength = filteredTasks?.length ?? 0;

  const handleGetTasks = useCallback(async () => {
    try {
      setIsLoading(true);

      const params = {
        companyID,
      };

      if (debouncedSearch.trim().length) {
        params.search = debouncedSearch;
      }

      const res = await getCNADefaultTasksService(params);
      if (isResponseOk(res) && res.data) {
        setTasks(res.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [companyID, debouncedSearch]);

  useEffect(() => {
    handleGetTasks();
  }, [handleGetTasks]);

  const handleOpenAddTaskModal = (isOpen) => {
    setIsOpenCreateTaskModal((prevState) => {
      return typeof isOpen === 'boolean' ? isOpen : !prevState;
    });
  };

  const handleCloseManageTaskModal = () => {
    setIsOpenCreateTaskModal(false);
    setEditTask(null);
  };

  const handleDeleteTask = async (taskID) => {
    try {
      const res = await deleteCNADefaultTaskService({ taskID });
      if (isResponseOk(res)) {
        await handleGetTasks();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const renderTopBar = () => {
    return (
      <div className={'library-filter'}>
        <div className="library-filter-search form-control-item">
          <input value={search} type="text" placeholder="Search" className="form-control" onChange={(e) => setSearch(e.target.value)} />
          <i className="icon-search" />
        </div>

        <button className={'btn open-add-task-modal-btn'} onClick={handleOpenAddTaskModal}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.99984 4.16406V15.8307M4.1665 9.9974H15.8332"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {'Add Default Task'}
        </button>
      </div>
    );
  };

  const renderTasks = () => {
    let layout;

    if (tasks?.length) {
      layout = (
        <>
          <div className="tasks__header">Name</div>
          <div className="tasks__list">
            {filteredTasks.map((task) => {
              const { id, text } = task;
              return (
                <div className={'tasks__list__item'} key={id}>
                  <p>{text}</p>
                  <ContextMenu
                    onToggle={undefined}
                    size={'small'}
                    menu={[
                      {
                        label: 'Edit',
                        icon: <i className="icon-icon-edit" />,
                        action: ({ onCloseMenu }) => {
                          onCloseMenu();
                          setEditTask(task);
                        },
                      },
                      {
                        label: 'Delete',
                        color: '#B32318',
                        icon: <i className="icon-remove" />,
                        action: ({ onCloseMenu }) => {
                          onCloseMenu();
                          handleDeleteTask(id);
                        },
                      },
                    ]}
                  />
                </div>
              );
            })}
          </div>
        </>
      );
    } else if (search.length && !filteredTasksLength) {
      layout = <div className={'empty-tasks-list-label'}>Tasks not found</div>;
    } else {
      layout = <div className={'empty-tasks-list-label'}></div>;
    }

    return <div className={'tasks'}>{layout}</div>;
  };

  return (
    <Layout title="CNA default tasks" className={'cna-default-tasks-layout'}>
      <div className="page-wrapper default-tasks">
        <Header />
        {renderTopBar()}
        {renderTasks()}
        {(isOpenCreateTaskModal || editTaskData) && (
          <ManageTaskModal
            editTaskData={editTaskData}
            companyID={companyID}
            onRefetchTasks={handleGetTasks}
            onClose={handleCloseManageTaskModal}
          />
        )}
        {isLoading && <PageLoader />}
      </div>
    </Layout>
  );
};

export default CNADefaultTasksPage;
