export const submitterTypesOptions = [
  { value: 'user', label: 'System users' },
  { value: 'contact', label: 'Contacts' },
  { value: 'unregistered', label: 'Unregistered' },
];

export const contactPersonSubmitterTypes = [
  { value: 'contact', label: 'Residents' },
  { value: 'representative', label: 'Representatives' },
  { value: 'both', label: 'Both' },
];
