import React from 'react';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';
import cx from 'classnames';

export const RadiobuttonElement = function FromElement({ element, handlerChange, error, isPrint }) {
  const isRequiredElement = useIsRequiredElement(element);
  const { options, id: elementId } = element;
  const { alignment } = options ?? {};

  return (
    <div className={'element-wrapper radiobutton'}>
      <div className="element-inner">
        <label htmlFor={element.id} className="form-label w-full">
          {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
        </label>
        <div className="form-control-radio-wrapper">
          {element.inputs.map((option, index) => {
            const { id: optionId } = option;
            const isChecked = element.options.valueInput === optionId;
            const inputLabelId = `${elementId}-${optionId}`;
            return (
              <div
                key={index}
                className={cx('form-control-radio element', {
                  [alignment]: alignment,
                  print: isPrint,
                  checked: isChecked,
                  [`form-control-radio-${element.options.size}`]: element.options.size,
                })}
              >
                <label className={'radiobutton-label'} htmlFor={inputLabelId}>
                  {!isPrint && (
                    <input
                      id={inputLabelId}
                      name={element.options.label}
                      type="radio"
                      value={optionId}
                      checked={isChecked}
                      onChange={() => handlerChange(element, optionId)}
                    />
                  )}
                  <span className={cx('radiobutton-button', { checked: isChecked })} />
                  {option.label}
                </label>
              </div>
            );
          })}
        </div>
        <ElementErrorMessage message={error} />
      </div>
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </div>
  );
};
