// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cna-add-task-modal .form-control {
  min-height: 60px;
  max-height: 240px;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  resize: vertical;
}
.cna-add-task-modal .form-control::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #98A2B3;
}`, "",{"version":3,"sources":["webpack://./src/pages/cna/default-tasks/components/manage-task-modal/styles.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAAN","sourcesContent":[".cna-add-task-modal {\n  .form-control {\n    min-height: 60px;\n    max-height: 240px;\n    display: inline-block;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    resize: vertical;\n\n    &::placeholder {\n      font-size: 16px;\n      font-weight: 400;\n      line-height: 24px;\n      color: #98A2B3;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
