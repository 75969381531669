// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  max-height: 230px;
  margin-top: 8px;
  overflow-y: auto;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1019607843);
}
.table-container table {
  width: 100%;
  border-collapse: collapse;
}
.table-container table th, .table-container table td {
  text-align: left;
}
.table-container table thead {
  border-bottom: 1px solid var(--divider, #EAECF0);
}
.table-container table thead th {
  top: 0;
  padding: 13px 24px;
  position: sticky;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: #f9fafb;
  z-index: 1;
}
.table-container table thead th:nth-child(1) {
  border-top-left-radius: 8px;
}
.table-container table thead th:last-child {
  border-top-right-radius: 8px;
}
.table-container table tbody td {
  width: 33.3333333333%;
  padding: 16px 24px;
  vertical-align: top;
}`, "",{"version":3,"sources":["webpack://./src/components/library/form-management-modal/components/form-settings-modal/components/table/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;EACA,kBAAA;EACA,0DAAA;AACF;AACE;EACE,WAAA;EACA,yBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEI;EACE,gDAAA;AAAN;AAEM;EACE,MAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,UAAA;AAAR;AAEQ;EACE,2BAAA;AAAV;AAGQ;EACE,4BAAA;AADV;AAOM;EACE,qBAAA;EACA,kBAAA;EACA,mBAAA;AALR","sourcesContent":[".table-container {\n  max-height: 230px;\n  margin-top: 8px;\n  overflow-y: auto;\n  position: relative;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  box-shadow: 0px 1px 3px 0px #1018281A;\n\n  table {\n    width: 100%;\n    border-collapse: collapse;\n\n    th, td {\n      text-align: left;\n    }\n\n    thead {\n      border-bottom: 1px solid var(--divider, #EAECF0);\n\n      th {\n        top: 0;\n        padding: 13px 24px;\n        position: sticky;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 18px;\n        background-color: #f9fafb;\n        z-index: 1;\n\n        &:nth-child(1) {\n          border-top-left-radius: 8px;\n        }\n\n        &:last-child {\n          border-top-right-radius: 8px;\n        }\n      }\n    }\n\n    tbody {\n      td {\n        width: calc(100% / 3);\n        padding: 16px 24px;\n        vertical-align: top;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
