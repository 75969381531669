import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { MultiSelect } from '../../other/MultiSelect';
import { notificationOptions } from '../../../constants/forms/options';

export const NotificationSettingsInit = function NotificationSettingsInit(props) {
  const { formData, handelInitSettings, initData, errors, tabs, changeTab, handleSubmitSettings } = props;
  const [notifications_settings, setNotificationsSettings] = useState({
    should_notify_about_submitting_deadline: false,
    submitting_deadline_notification_lead_time: '',
    submitting_deadline_notification_type: '',
    submitting_deadline_notification_text: '',

    should_notify_about_late_submission: false,
    late_submission_notification_recipients: [
      {
        value: 'FORM_SUBMITTERS',
        label: 'form Submitters',
      },
    ],
    late_submission_notification_type: '',
    late_submission_notification_text: '',

    should_notify_about_form_activity: false,
    form_activity_notification_recipients: [],
    form_activity_notification_type: '',
    form_activity_notification_settings: {
      amount_of_people_who_submitted: true,
      amount_of_people_who_did_not_submit: true,
      list_of_people_who_submitted: true,
      list_of_people_who_did_not_submit: true,
      completion_percentage: true,
      notification_time: 0,
      before_or_after_deadline: '',
    },
  });

  const [notificationDeadlineOptions] = useState([
    {
      value: 'BEFORE',
      label: 'Before',
    },
    {
      value: 'AFTER',
      label: 'After',
    },
  ]);
  // const [notificationHwoOptions] = useState([{
  //     value: 'FORM_INITIATOR',
  //     label: 'Form Initiator'
  // },
  // {
  //     value: 'FORM_RECIPIENTS',
  //     label: 'Form Recipients'
  // },
  // {
  //     value: 'FORM_SUBMITTERS',
  //     label: 'form Submitters'
  // }]);
  const [notificationHwoOptionsShort] = useState([
    {
      value: 'FORM_INITIATOR',
      label: 'Form Initiator',
    },
    {
      value: 'FORM_RECIPIENTS',
      label: 'Form Recipients',
    },
  ]);

  const [notificationHourOptions] = useState([
    {
      value: 2,
      label: '2 hours',
    },
    {
      value: 4,
      label: '4 hours',
    },
    {
      value: 8,
      label: '8 hours',
    },
    {
      value: 24,
      label: '1 day',
    },
    {
      value: 48,
      label: '2 days',
    },
    {
      value: 72,
      label: '3 days',
    },
    {
      value: 186,
      label: '7 days',
    },
    {
      value: 186,
      label: '14 days',
    },
    {
      value: 720,
      label: '30 days',
    },
    {
      value: 1440,
      label: '60 days',
    },
  ]);

  useEffect(() => {
    if (initData.notification_settings) {
      setNotificationsSettings({ ...notifications_settings, ...initData.notification_settings });
    }
    // eslint-disable-next-line
  }, [initData]);

  const handelSettings = (value, key) => {
    setNotificationsSettings((prevState) => {
      const newState = { ...prevState };
      newState[key] = value;

      handelInitSettings(newState, 'notification_settings');

      return newState;
    });
  };

  const handelActivitySettings = (value, key) => {
    const prevState = { ...notifications_settings };
    if (!prevState['form_activity_notification_settings']) {
      prevState['form_activity_notification_settings'] = {};
    }
    prevState['form_activity_notification_settings'][key] = value;
    setNotificationsSettings(() => {
      return prevState;
    });

    handelInitSettings(prevState, 'notification_settings');
  };
  const handelRemove = (key, value) => {
    const prevState = { ...notifications_settings };
    prevState[key] = prevState[key].filter((item) => item.value !== value.value);
    setNotificationsSettings(() => {
      return prevState;
    });

    handelInitSettings(prevState, 'notification_settings');
  };

  const [tab, setTab] = useState(null);
  const [tabBack, setTabBack] = useState(null);

  useEffect(() => {
    const recipientTab = tabs.find((item) => item.component === 'recipient-settings-init');
    if (!recipientTab?.isDisable) {
      setTabBack(recipientTab);
    } else {
      const submissionTab = tabs.find((item) => item.component === 'submission-settings-init');
      if (!submissionTab?.isDisable) {
        setTabBack(submissionTab);
      }
    }

    setTab(tabs.find((item) => item.component === 'view-form'));
  }, [tabs]);

  const onChangeTab = () => {
    changeTab(tab);
  };
  const onBackTab = () => {
    changeTab(tabBack);
  };

  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-recipient-settings form-init-settings">
        {formData?.name && (
          <div className="from-builder-title from-builder-title-setting">
            <h1>
              <img src="/images/file-big-icon.png" className="mr-2" alt="" />
              {formData.name}
            </h1>
          </div>
        )}

        <p className="info-text">
          <i className="icon-icon-info"></i> Set rules for notifications regarding Form submissions.
        </p>
        <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>
        <>
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Notify submitters about approaching submitting deadline</label>
              <span>Notification, which will be sent as a reminder to form submitters if the form has a submitting deadline</span>
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    name="should_notify_about_submitting_deadline"
                    type="radio"
                    value="true"
                    onChange={() => handelSettings(true, 'should_notify_about_submitting_deadline')}
                    checked={notifications_settings.should_notify_about_submitting_deadline}
                  />
                  <span>Yes</span>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    name="should_notify_about_submitting_deadline"
                    type="radio"
                    value="true"
                    onChange={() => handelSettings(false, 'should_notify_about_submitting_deadline')}
                    checked={notifications_settings.should_notify_about_submitting_deadline === false}
                  />
                  <span>No</span>
                </div>
              </div>
              <span className="form-error">
                {errors?.notification_settings?.should_notify_about_submitting_deadline &&
                  errors.notification_settings?.should_notify_about_submitting_deadline[0]}
              </span>

              {notifications_settings.should_notify_about_submitting_deadline && (
                <>
                  <div className="form-control-item-group">
                    <label className="form-label">Notification Lead Time</label>
                    <div className="select-multy-wrapper">
                      <Select
                        hideSelectedOptions={false}
                        isSearchable={false}
                        options={notificationHourOptions}
                        value={notifications_settings.submitting_deadline_notification_lead_time}
                        onChange={(e) => handelSettings(e, 'submitting_deadline_notification_lead_time')}
                        className="form-control-select"
                        placeholder="Select Notification Timing"
                        classNamePrefix="react-select"
                      ></Select>
                    </div>
                    <span className="form-error">
                      {errors?.notification_settings?.submitting_deadline_notification_lead_time &&
                        errors.notification_settings?.submitting_deadline_notification_lead_time[0]}
                    </span>
                  </div>
                  <div className="form-control-item-group">
                    <label className="form-label">Notification type</label>
                    <div className="select-multy-wrapper">
                      <Select
                        hideSelectedOptions={false}
                        isSearchable={false}
                        options={notificationOptions}
                        value={notifications_settings.submitting_deadline_notification_type}
                        onChange={(e) => handelSettings(e, 'submitting_deadline_notification_type')}
                        className="form-control-select"
                        placeholder="Sms / Email"
                        classNamePrefix="react-select"
                      ></Select>
                    </div>
                    <span className="form-error">
                      {errors?.notification_settings?.submitting_deadline_notification_type &&
                        errors.notification_settings?.submitting_deadline_notification_type[0]}
                    </span>
                  </div>
                  <div className="form-control-item-group">
                    <label className="form-label">Notification text</label>
                    <textarea
                      onChange={(e) => handelSettings(e.target.value, 'submitting_deadline_notification_text')}
                      value={notifications_settings.submitting_deadline_notification_text}
                      placeholder={'Enter notification or message text that will be sent to users  who were assigned as form submitters'}
                      className="form-control"
                    ></textarea>
                    <span className="form-error">
                      {errors?.notification_settings?.submitting_deadline_notification_text &&
                        errors.notification_settings?.submitting_deadline_notification_text[0]}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Notification that the form submission deadline is missed</label>
              <span>Notification to the submitters (who missed the deadline) that the form submission deadline is missed.</span>
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    name="should_notify_about_late_submission"
                    type="radio"
                    value="true"
                    onChange={() => handelSettings(true, 'should_notify_about_late_submission')}
                    checked={notifications_settings.should_notify_about_late_submission}
                  />
                  <span>Yes</span>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    name="should_notify_about_late_submission"
                    type="radio"
                    value="true"
                    onChange={() => handelSettings(false, 'should_notify_about_late_submission')}
                    checked={notifications_settings.should_notify_about_late_submission === false}
                  />
                  <span>No</span>
                </div>
              </div>
              <span className="form-error">
                {errors?.notification_settings?.should_notify_about_late_submission &&
                  errors.notification_settings?.should_notify_about_late_submission[0]}
              </span>

              {notifications_settings.should_notify_about_late_submission && (
                <>
                  {/*<div className="form-control-item-group">*/}
                  {/*    <label className="form-label">Notification recipients</label>*/}
                  {/*    <div className="select-multy-wrapper">*/}
                  {/*        <span className="select-placeholder">Select  who will be notified</span>*/}
                  {/*        <MultiSelect*/}
                  {/*            options={notificationHwoOptions}*/}
                  {/*            value={notifications_settings.late_submission_notification_recipients}*/}
                  {/*            onChange={(e) => handelSettings(e, 'late_submission_notification_recipients')}*/}
                  {/*            placeholder=""*/}
                  {/*            allName="All"*/}
                  {/*        ></MultiSelect>*/}
                  {/*        {notifications_settings.late_submission_notification_recipients?.length > 0 &&*/}
                  {/*            <>*/}
                  {/*                <ul>*/}
                  {/*                    {notifications_settings.late_submission_notification_recipients.map((item, key) =>*/}
                  {/*                        <li key={key}>*/}
                  {/*                            {item.label}*/}
                  {/*                            <button className="btn btn-icon" onClick={() => handelRemove('late_submission_notification_recipients', item)}>*/}
                  {/*                                <img src="/images/x-circle.svg" alt=""/>*/}
                  {/*                            </button>*/}
                  {/*                        </li>*/}
                  {/*                    )}*/}
                  {/*                </ul>*/}
                  {/*            </>*/}
                  {/*        }*/}
                  {/*    </div>*/}
                  {/*    <span className="form-error">{errors?.notification_settings?.late_submission_notification_recipients && errors.notification_settings?.late_submission_notification_recipients[0]}</span>*/}
                  {/*</div>*/}
                  <div className="form-control-item-group">
                    <label className="form-label">Notification type</label>
                    <div className="select-multy-wrapper">
                      <Select
                        hideSelectedOptions={false}
                        isSearchable={false}
                        options={notificationOptions}
                        value={notifications_settings.late_submission_notification_type}
                        onChange={(e) => handelSettings(e, 'late_submission_notification_type')}
                        className="form-control-select"
                        placeholder="Sms / Email"
                        classNamePrefix="react-select"
                      ></Select>
                    </div>
                    <span className="form-error">
                      {errors?.notification_settings?.late_submission_notification_type &&
                        errors.notification_settings?.late_submission_notification_type[0]}
                    </span>
                  </div>
                  <div className="form-control-item-group">
                    <label className="form-label">Notification text</label>
                    <textarea
                      onChange={(e) => handelSettings(e.target.value, 'late_submission_notification_text')}
                      value={notifications_settings.late_submission_notification_text}
                      placeholder={'Enter notification or message text that will be sent to users  who were assigned as form submitters'}
                      className="form-control"
                    ></textarea>
                    <span className="form-error">
                      {errors?.notification_settings?.late_submission_notification_text &&
                        errors.notification_settings?.late_submission_notification_text[0]}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Notify form initiator or recipient of form activity</label>
              <span>Provide notification to the form initiator or form recipient regarding the form activity.</span>
            </div>
            <div className="form-settings-inputs">
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    name="notifyRecipients"
                    type="radio"
                    value="true"
                    checked={initData?.notification_settings?.should_notify_about_form_activity}
                    onChange={() => handelSettings(true, 'should_notify_about_form_activity')}
                  />
                  <span>Yes</span>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    name="notifyRecipients"
                    type="radio"
                    value="true"
                    checked={!initData?.notification_settings?.should_notify_about_form_activity}
                    onChange={() => handelSettings(false, 'should_notify_about_form_activity')}
                  />
                  <span>No</span>
                </div>
              </div>
            </div>
          </div>
          <span className="form-error">
            {errors?.notification_settings?.should_notify_about_form_activity &&
              errors.notification_settings?.should_notify_about_form_activity[0]}
          </span>

          {notifications_settings.should_notify_about_form_activity && (
            <div className="form-control-item-settings">
              <div className="form-settings-label">
                <label className="form-label form-label-big">Select who will be notified</label>
              </div>
              <div className="form-settings-inputs">
                <>
                  <div className="form-control-item-group">
                    <label className="form-label">Notification recipients</label>
                    <div className="select-multy-wrapper">
                      <span className="select-placeholder">Select  who will be notified</span>
                      <MultiSelect
                        options={notificationHwoOptionsShort}
                        value={notifications_settings.form_activity_notification_recipients}
                        onChange={(e) => handelSettings(e, 'form_activity_notification_recipients')}
                        placeholder=""
                        allName="All"
                      ></MultiSelect>
                      {notifications_settings.form_activity_notification_recipients?.length > 0 && (
                        <>
                          <ul>
                            {notifications_settings.form_activity_notification_recipients.map((item, key) => (
                              <li key={key}>
                                {item.label}
                                <button
                                  className="btn btn-icon"
                                  onClick={() => handelRemove('form_activity_notification_recipients', item)}
                                >
                                  <img src="/images/x-circle.svg" alt="" />
                                </button>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                    <span className="form-error">
                      {errors?.notification_settings?.form_activity_notification_recipients &&
                        errors.notification_settings?.form_activity_notification_recipients[0]}
                    </span>
                  </div>
                  <div className="form-control-item-group">
                    <label className="form-label">Notification type</label>
                    <div className="select-multy-wrapper">
                      <Select
                        hideSelectedOptions={false}
                        isSearchable={false}
                        options={notificationOptions}
                        value={notifications_settings.form_activity_notification_type}
                        onChange={(e) => handelSettings(e, 'form_activity_notification_type')}
                        className="form-control-select"
                        placeholder="Sms / Email"
                        classNamePrefix="react-select"
                      ></Select>
                    </div>
                    <span className="form-error">
                      {errors?.notification_settings?.form_activity_notification_type &&
                        errors.notification_settings?.form_activity_notification_type[0]}
                    </span>
                  </div>
                  <div className={`form-control-checkbox mb-4`}>
                    <input
                      name="completion_percentage"
                      type="checkbox"
                      value="true"
                      onChange={() =>
                        handelActivitySettings(
                          !notifications_settings?.form_activity_notification_settings?.completion_percentage,
                          'completion_percentage',
                        )
                      }
                      checked={notifications_settings.form_activity_notification_settings?.completion_percentage}
                    />
                    <span>Form completion percentage</span>
                  </div>
                  <div className={`form-control-checkbox mb-4`}>
                    <input
                      name="amount_of_people_who_submitted"
                      type="checkbox"
                      value="true"
                      onChange={() =>
                        handelActivitySettings(
                          !notifications_settings.form_activity_notification_settings.amount_of_people_who_submitted,
                          'amount_of_people_who_submitted',
                        )
                      }
                      checked={notifications_settings.form_activity_notification_settings.amount_of_people_who_submitted}
                    />
                    <span>Amount of people who submitted form</span>
                  </div>
                  <div className={`form-control-checkbox mb-4`}>
                    <input
                      name="amount_of_people_who_did_not_submit"
                      type="checkbox"
                      value="true"
                      onChange={() =>
                        handelActivitySettings(
                          !notifications_settings.form_activity_notification_settings.amount_of_people_who_did_not_submit,
                          'amount_of_people_who_did_not_submit',
                        )
                      }
                      checked={notifications_settings.form_activity_notification_settings.amount_of_people_who_did_not_submit}
                    />
                    <span>Amount of people who didn't submit the form</span>
                  </div>
                  <div className={`form-control-checkbox mb-4`}>
                    <input
                      name="list_of_people_who_submitted"
                      type="checkbox"
                      value="true"
                      onChange={() =>
                        handelActivitySettings(
                          !notifications_settings.form_activity_notification_settings.list_of_people_who_submitted,
                          'list_of_people_who_submitted',
                        )
                      }
                      checked={notifications_settings.form_activity_notification_settings.list_of_people_who_submitted}
                    />
                    <span>The list of people who submitted form</span>
                  </div>
                  <div className={`form-control-checkbox mb-4`}>
                    <input
                      name="list_of_people_who_submitted"
                      type="checkbox"
                      value="true"
                      onChange={() =>
                        handelActivitySettings(
                          !notifications_settings.form_activity_notification_settings.list_of_people_who_did_not_submit,
                          'list_of_people_who_did_not_submit',
                        )
                      }
                      checked={notifications_settings.form_activity_notification_settings.list_of_people_who_did_not_submit}
                    />
                    <span>The list of people who didn't submit a form</span>
                  </div>

                  <div className={'d-flex mb-0 time-select'}>
                    <div className={'form-item form-item-select mb-4'}>
                      <label className="form-label">Notification Time</label>
                      <div className="select-multy-wrapper">
                        <Select
                          hideSelectedOptions={false}
                          isSearchable={false}
                          options={notificationHourOptions}
                          value={notifications_settings.form_activity_notification_settings.notification_time}
                          onChange={(e) => handelActivitySettings(e, 'notification_time')}
                          className="form-control-select"
                          placeholder="Select Notification Timing"
                          classNamePrefix="react-select"
                        ></Select>
                      </div>
                      <span className="form-error">
                        {errors?.notification_settings?.form_activity_notification_settings?.notification_time &&
                          errors.notification_settings.form_activity_notification_settings.notification_time[0]}
                      </span>
                    </div>
                    <div className={'form-item form-item-select mb-4'}>
                      <label className="form-label">Before / after the deadline</label>
                      <div className="select-multy-wrapper">
                        <Select
                          hideSelectedOptions={false}
                          isSearchable={false}
                          options={notificationDeadlineOptions}
                          value={notifications_settings?.form_activity_notification_settings?.before_or_after_deadline}
                          onChange={(e) => handelActivitySettings(e, 'before_or_after_deadline')}
                          className="form-control-select"
                          placeholder="Select Notification Timing"
                          classNamePrefix="react-select"
                        ></Select>
                      </div>
                      <span className="form-error">
                        {errors?.notification_settings?.form_activity_notification_settings?.before_or_after_deadline &&
                          errors.notification_settings.form_activity_notification_settings.before_or_after_deadline[0]}
                      </span>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </>
      </div>
      <div className="form-control-actions form-init-content">
        <div>
          {tabBack && (
            <button onClick={onBackTab} className={`btn btn-outline btn-wide`}>
              <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M15.8327 10.0013H4.16602M4.16602 10.0013L9.99935 15.8346M4.16602 10.0013L9.99935 4.16797"
                  stroke="#101828"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back
            </button>
          )}
          {tab && !tab?.isDisable && (
            <button className="btn btn-wide" onClick={handleSubmitSettings} style={{ height: 41 }}>
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
