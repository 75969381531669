import React, { useState, useEffect, useCallback, useRef } from 'react';
import Layout from '../../../layout/default';
import { Breadcrumbs } from '../../../components/library/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { getFormsAssignedToMe } from '../../../services/folder/form-avaliable';
import { useAuth } from '../../../container/authContext';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { SubmissionItem } from '../../../components/library/submission-item';
import TabsMobile from '../mobile-tabs';
import withLibraryViewMode from '../../../HOCs/withLibraryViewMode';
import PageLoader from '../../../components/other/PageLoader';
import { useDebounce } from 'use-debounce';
import { FolderItem } from '../../../components/library/folders/folder';
import { isResponseOk } from '../../../utility/isResponseOk';
import { usePermission } from '../../../container/permissionContext';
import ManageFolderModal from './components/manage-folder-modal';
import { deleteFormsAssignedToMeFolder } from '../../../services/corporate-library/forms-assigned-to-me';

const commonBreadcrumbs = [
  {
    name: 'Company Library',
    url: '/corporate-library',
  },
  {
    name: 'Forms assigned to me',
    url: '/corporate-library/forms/forms-assigned-to-me',
  },
];

const getBreadcrumbs = (breadcrumbs) => {
  const list = [];

  function getProp(o) {
    list.push(o);
    if (o.children) {
      getProp(o.children);
    }
  }

  if (breadcrumbs) {
    getProp(breadcrumbs);
  }

  return list;
};

function CorporateLibrary({ ViewModeSwitcher, libraryViewMode }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { hasPermissionByCodeName } = usePermission();
  const firstFolderIdChange = useRef(true);

  const [breadcrumbs, setBreadcrumbs] = useState(commonBreadcrumbs);
  const [folders, setFolders] = useState([]);
  const [forms, setForms] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [manageFolderData, setManageFolderData] = useState(null);
  const [rootFolderID, setRootFolderID] = useState(null);
  const isOpenManageFolderModal = !!manageFolderData;

  const isExistForms = !!forms?.length;
  const isListMode = libraryViewMode === 'LIST';
  const [debouncedSearch] = useDebounce(search, 300);

  const handleGetFormsAssignedToMe = useCallback(
    (folderID) => {
      setIsLoading(true);

      const bodyRequest = { get_all: true, company_id: user.company.id, search: debouncedSearch, parent_id: folderID };
      if (!debouncedSearch) {
        delete bodyRequest.search;
      }

      getFormsAssignedToMe(bodyRequest)
        .then((res) => {
          if (isResponseOk(res)) {
            if (debouncedSearch) {
              setFolders([]);
              setForms(res.data);
            } else {
              const { breadcrumbs, data } = res.data;

              setBreadcrumbs([commonBreadcrumbs[0], ...getBreadcrumbs(breadcrumbs)]);

              const newCurrentFolderId = getBreadcrumbs(breadcrumbs)[getBreadcrumbs(breadcrumbs).length - 1]?.id;
              setCurrentFolderId(newCurrentFolderId);
              setRootFolderID(breadcrumbs?.id);

              if (Array.isArray(data)) {
                setFolders(data.filter((item) => item.type === 'folder'));
                setForms(data.filter((item) => item.type === 'form'));
              }
            }
          }
        })
        .catch((e) => console.log(e))
        .finally(() => setIsLoading(false));
    },
    [debouncedSearch, user.company.id],
  );

  useEffect(() => {
    if (firstFolderIdChange.current) {
      if (currentFolderId !== null) {
        firstFolderIdChange.current = false;
        return;
      }
    }

    handleGetFormsAssignedToMe(currentFolderId);
  }, [user, debouncedSearch, isListMode, currentFolderId, handleGetFormsAssignedToMe]);

  const goToPrev = (id, type) => {
    if (type === 'root') {
      navigate(id, { replace: true });
    } else if (type === 'folder') {
      setCurrentFolderId(id);
    }
  };

  const changeFolder = (newFolderID) => setCurrentFolderId(newFolderID);

  const backToMobile = () => {
    let backToLink = breadcrumbs[breadcrumbs.length - 2]
      ? breadcrumbs[breadcrumbs.length - 2]
      : {
          name: 'Company Library',
          url: '/corporate-library',
        };

    if (backToLink.id) {
      changeFolder(backToLink.id);
    } else {
      if (backToLink.url) {
        navigate(backToLink.url);
      } else {
        navigate('/corporate-library');
      }
    }
  };

  const handelOpenCreateFolderModal = () => setManageFolderData({});

  const handleCloseManageFolderModal = () => setManageFolderData(null);

  const handelEditFolder = (folder) => setManageFolderData(folder);

  const handelDeleteFolder = (folder) => {
    deleteFormsAssignedToMeFolder(folder.id).then(() => handleGetFormsAssignedToMe(currentFolderId));
  };

  // Save action callback
  const handleManagedFolder = (newFolderID) => {
    if (newFolderID) {
      // Created new folder
      setCurrentFolderId(newFolderID);
    } else {
      handleGetFormsAssignedToMe(currentFolderId);
    }
  };

  const renderFilters = () => {
    return (
      <>
        <div className="library-filter">
          <div className="library-filter-search form-control-item">
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="Search"
            />
            <i className="icon-search"></i>
          </div>

          <ViewModeSwitcher styles={{ marginLeft: 16 }} />

          {hasPermissionByCodeName('corporate_library_management') && (
            <button className={'btn btn-outline'} style={{ height: 44 }} onClick={handelOpenCreateFolderModal}>
              <i className="icon-icon-folder mr-2" />
              Create folder
            </button>
          )}
        </div>
      </>
    );
  };

  const renderFormsAndFolders = () => {
    let layout;
    if (isLoading) {
      layout = <PageLoader />;
    } else {
      layout = (
        <>
          {isExistForms && isListMode && (
            <h3 className="library-subtitle mt-2">
              Forms <span className={'total'}>(Total: {forms.length} forms)</span>
            </h3>
          )}

          {!!folders?.length && (
            <>
              {!isListMode && <h3 className="library-subtitle">Folders</h3>}
              <div className="library-wrapper" style={{ rowGap: isListMode ? 0 : undefined }}>
                {folders.map((item) => {
                  const { can_manage } = item;
                  return (
                    <FolderItem
                      key={item.id}
                      item={item}
                      editMenuItemLabel={'Edit'}
                      isListItem={isListMode}
                      handelEdit={can_manage ? handelEditFolder : undefined}
                      handelDelete={can_manage ? handelDeleteFolder : undefined}
                      changeFolder={changeFolder}
                    />
                  );
                })}
              </div>
            </>
          )}

          {isExistForms && (
            <>
              {!isListMode && <h3 className="library-subtitle mt-2">Forms</h3>}
              <div className="library-wrapper forms" style={{ rowGap: isListMode ? 0 : undefined }}>
                {forms.map((item) => (
                  <SubmissionItem isListItem={isListMode} key={item.id} url={`/form-view/${item.id}`} item={item} />
                ))}
              </div>
            </>
          )}

          {!forms?.length && !!debouncedSearch.length && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
                <h4 className="empty-page-subtitle">
                  Try to change the range of keywords. We're here to help you find what you're looking for.
                </h4>
              </div>
            </>
          )}
          {!forms?.length && !folders?.length && !debouncedSearch.length && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">This folder is empty</h3>
                <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
              </div>
            </>
          )}
        </>
      );
    }
    return layout;
  };

  return (
    <Layout title="Company Library">
      <div className="library">
        <DndProvider backend={HTML5Backend}>
          <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
        </DndProvider>

        <TabsMobile />
        <h2 className={'library-title-mobile'}>
          <svg
            onClick={backToMobile}
            className={'mr-2 cursor-pointer'}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
              stroke="#101828"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {breadcrumbs[breadcrumbs.length - 1] ? breadcrumbs[breadcrumbs.length - 1].name : ''}
        </h2>
        <h2 className="library-title">
          <Link to={'/corporate-library/forms/'} className="mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
                stroke="#101828"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          Forms assigned to me
        </h2>

        {renderFilters()}

        {renderFormsAndFolders()}
      </div>

      {isOpenManageFolderModal && (
        <ManageFolderModal
          rootFolderID={rootFolderID}
          folderID={currentFolderId}
          editFolderData={manageFolderData}
          onSaved={handleManagedFolder}
          onClose={handleCloseManageFolderModal}
        />
      )}
    </Layout>
  );
}

export default withLibraryViewMode(CorporateLibrary);
