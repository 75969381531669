import React, { useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { getFileGeneralInfo } from '../../../../services/facility';
import { getFileById } from '../../../../services/upload';
import useOutsideClick from '../../../../hook/useOutsideClick';
import FileLoader from '../../../other/FileLoader';
import { useDrag } from 'react-dnd';
import { isResponseOk } from '../../../../utility/isResponseOk';
import { usePermission } from '../../../../container/permissionContext';
import { patchGeneralLibraryFile } from '../../../../services/libraries/general-library';
import { isWebVideoByFileExt } from '../../../../utility/files/isWebVideoByFileExt';
import { getFileExtFromFileName } from '../../../../utility/files/getFileExtFromFileName';

export const GeneralFile = ({ item, deleteFile, copyFile, activeFile, moveFile, onFilePreview }) => {
  const { isSuperAdmin } = usePermission();
  const wrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const [, drag] = useDrag(() => ({
    type: 'file',
    item,
    canDrag: isSuperAdmin(),
    end: async (item, monitor) => {
      try {
        const dropResult = monitor.getDropResult();
        if (dropResult?.item) {
          if (dropResult?.item.id !== item.id) {
            const dragFile = item;
            const dropFolder = dropResult?.item;

            const patchFileRes = await patchGeneralLibraryFile(dragFile.id, { ...dragFile, folder_id: dropFolder.id });

            if (isResponseOk(patchFileRes)) {
              moveFile(dropFolder.id);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const closeMenu = () => {
    setIsOpen(false);
  };

  useOutsideClick(wrapperRef, closeMenu);

  const downloadFile = ({ name, downloadUrl }) => {
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = name;
    a.click();
  };

  const handleClickFile = () => {
    setIsOpenTooltip(false);
    setLoader(true);

    getFileGeneralInfo(item.id)
      .then((fileGeneralInfoRes) => {
        if (fileGeneralInfoRes?.file?.uuid) {
          getFileById(fileGeneralInfoRes.file.uuid)
            .then((fileByIdRes) => {
              if (fileByIdRes?.config === 'success' && fileByIdRes?.message) {
                const fileExtension = getFileExtFromFileName(fileGeneralInfoRes.file.name);
                const isWebVideo = isWebVideoByFileExt(fileExtension);
                const fileData = {
                  ...fileGeneralInfoRes?.file,
                  url: fileByIdRes.message,
                  downloadUrl: fileByIdRes.message,
                };

                if (isWebVideo) {
                  onFilePreview(fileData);
                } else {
                  downloadFile(fileData);
                }
              }

              setLoader(false);
            })
            .catch((e) => console.log(e))
            .finally(() => setLoader(false));
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Tooltip isOpen={isOpenTooltip} opacity={1} id={`table-tooltip-${item.id}`} place="top" content={item.name} />
      <div
        ref={drag}
        className="library-item cursor-pointer"
        data-tooltip-id={`table-tooltip-${item.id}`}
        onClick={handleClickFile}
        onMouseEnter={() => setIsOpenTooltip(true)}
        onMouseLeave={() => setIsOpenTooltip(false)}
      >
        <div className="library-item-text">
          <img src="/images/file-big-icon.png" alt="" /> {item.name}
        </div>
        <div ref={wrapperRef} className={`library-item-button ${isOpen ? 'library-item-button-open' : ''}`}>
          <button
            className="btn btn-outline btn-small"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
                fill="#667085"
              />
              <path
                d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
                fill="#667085"
              />
              <path
                d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
                fill="#667085"
              />
              <path
                d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
                stroke="#667085"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
                stroke="#667085"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
                stroke="#667085"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {isOpen && (
            <div className="library-item-menu file">
              {!item.deleted_at && (
                <>
                  <button
                    className="btn btn-full btn-dark"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(false);
                      copyFile(item);
                    }}
                  >
                    <i className="icon-copy mr-2"></i>Share copy
                  </button>
                  <button
                    className="btn btn-full btn-remove"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(false);
                      deleteFile(item);
                    }}
                  >
                    <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <g clipPath="url(#clip0_5002_22844)">
                        <path
                          d="M12.5003 7.5013L7.50033 12.5013M7.50033 7.5013L12.5003 12.5013M18.3337 10.0013C18.3337 14.6037 14.6027 18.3346 10.0003 18.3346C5.39795 18.3346 1.66699 14.6037 1.66699 10.0013C1.66699 5.39893 5.39795 1.66797 10.0003 1.66797C14.6027 1.66797 18.3337 5.39893 18.3337 10.0013Z"
                          stroke="#B42318"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5002_22844">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    Archive
                  </button>
                </>
              )}
              {item.deleted_at && (
                <button
                  className="btn btn-full btn-active"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                    activeFile(item);
                  }}
                >
                  <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clipPath="url(#clip0_5002_8679)">
                      <path
                        d="M18.3337 9.23159V9.99826C18.3326 11.7953 17.7507 13.5438 16.6748 14.9831C15.5988 16.4224 14.0864 17.4753 12.3631 17.9848C10.6399 18.4944 8.79804 18.4332 7.11238 17.8104C5.42673 17.1877 3.98754 16.0367 3.00946 14.5291C2.03138 13.0216 1.56682 11.2383 1.68506 9.44519C1.80329 7.65206 2.498 5.9452 3.66556 4.57915C4.83312 3.21311 6.41098 2.26108 8.16382 1.86505C9.91665 1.46902 11.7505 1.65021 13.392 2.38159M18.3337 3.33159L10.0003 11.6733L7.50033 9.17326"
                        stroke="#12B76A"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5002_8679">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Active
                </button>
              )}
            </div>
          )}
        </div>
        {loader && <FileLoader />}
      </div>
    </>
  );
};
