import client from '../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancel;
let cancelUserGroup;
export const getCompanyContactsList = async (id, params) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  const response = await client.get(`api/companies/${id}/contacts`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};
export const createContacts = async (data) => {
  const response = await client.post('api/contacts/', data);

  return response.data;
};
export const editContacts = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/contacts/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getContactById = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/contacts/${id}/`, {
    params: { status: 'all' },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const importContact = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post('api/contacts/import', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: { company_id: id },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deactivateContacts = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/contacts/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const activateContacts = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/contacts/${id}/restore/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getContactsListSettings = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/corporate-library/forms/settings/contacts/`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
