import React, { useEffect, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { updateForm } from '../../../services/form';
import { getFacilitiesByCompanySettings } from '../../../services/facility';
import { getUsersListSettings } from '../../../services/user';
import { getCompanyUserGroupListAccessSettings } from '../../../services/company';
import { useAuth } from '../../../container/authContext';
import { MultiSelect } from '../../other/MultiSelect';

export const AccessSettings = function AccessSettings({ formData }) {
  const { id } = useParams();
  const { user } = useAuth();
  const companyId = user.company?.id;
  const [facility, setFacility] = useState([]);
  const [usersGroups, setUsersGroups] = useState([]);
  const [individualUsers, setIndividualUsers] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [usersGroupsOptions, setUsersGroupsOptions] = useState([]);
  const [individualUsersOptions, setIndividualUsersOptions] = useState([]);
  const [errors, setErrors] = useState(null);
  const [preventLoopUsers, setPreventLoopUsers] = useState(false);
  const [preventLoopUserGroup, setPreventLoopUserGroup] = useState(false);

  useEffect(() => {
    getFacilities();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (facility.length) {
      if (!preventLoopUserGroup) {
        getCompanyUserGroup();
      }
    } else {
      setUsersGroupsOptions([]);
      setUsersGroups([]);
      setPreventLoopUserGroup(false);
    }
    // eslint-disable-next-line
  }, [facility]);
  useEffect(() => {
    if (facility.length) {
      if (!preventLoopUsers) {
        getUsersList();
      }
    } else {
      setIndividualUsersOptions([]);
      setIndividualUsers([]);
      setPreventLoopUsers(false);
    }
    // eslint-disable-next-line
  }, [usersGroups, facility]);

  const getFacilities = () => {
    getFacilitiesByCompanySettings(companyId, 'getAll')
      .then((resFacilities) => {
        setFacilityOptions(resFacilities.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => console.log(e));
  };

  const getUsersList = () => {
    setPreventLoopUsers(true);
    getUsersListSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: [...facility.map((item) => item.value)],
      user_groups_ids: [...usersGroups.map((item) => item.value)],
    })
      .then((resUsers) => {
        const options = resUsers.map((item) => ({ value: item.id, label: item.last_name + ' ' + item.first_name }));
        setIndividualUsersOptions(options);
        setIndividualUsers((prevState) => {
          const newState = [];
          prevState.forEach((item) => {
            if (options.find((option) => option.value === item.value)) {
              newState.push(item);
            }
          });
          return newState;
        });

        setPreventLoopUsers(false);
      })
      .catch((e) => console.log(e));
  };

  const getCompanyUserGroup = () => {
    setPreventLoopUserGroup(true);
    getCompanyUserGroupListAccessSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: [...facility.map((item) => item.value)],
    })
      .then((res) => {
        const options = res.map((item) => ({ value: item.id, label: item.name }));
        setUsersGroupsOptions(options);
        setUsersGroups((prevState) => {
          const newState = [];
          prevState.forEach((item) => {
            if (options.find((option) => option.value === item.value)) {
              newState.push(item);
            }
          });
          return newState;
        });
        setPreventLoopUserGroup(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (formData.access_settings) {
      setFacility(formData.access_settings.facilities.map((item) => ({ value: item.id, label: item.name })));
      setUsersGroups(formData.access_settings.user_groups.map((item) => ({ value: item.id, label: item.name })));
      setIndividualUsers(
        formData.access_settings.individual_users.map((item) => ({ value: item.id, label: item.first_name + ' ' + item.last_name })),
      );
    }
  }, [formData]);
  const handelRemove = (handlerChange, elementToRemove) => {
    handlerChange((prevState) => {
      return prevState.filter((item) => item.value !== elementToRemove.value);
    });
  };
  const navigation = useNavigate();
  const onUpdate = () => {
    const formData = {
      access_settings: {
        facilities: facility.map((item) => item.value),
        individual_users: individualUsers.map((item) => item.value),
        user_groups: usersGroups.map((item) => item.value),
      },
    };
    updateForm(id, formData)
      .then((res) => {
        console.log(res);
        navigation(`/form-builder/${id}/settings/submission`);
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.data?.access_settings) {
          setErrors(e.response.data.access_settings);
        }
      });
  };
  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-access-settings">
        {formData?.name && (
          <div className="from-builder-title from-builder-title-setting">
            <h1>
              <img src="/images/file-big-icon.png" className="mr-2" alt="" />
              {formData.name}
            </h1>
          </div>
        )}
        <p className="info-text">
          <i className="icon-icon-info"></i> Set users, who will be able to see and initiate this form.
        </p>
        <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>
        <div className={`form-item form-item-select form-control-item-settings ${errors?.facilities ? 'form-item-error' : ''}`}>
          <label className="form-label form-label-big">Facility</label>
          <div className="select-multy-wrapper">
            <span className="select-placeholder">Choose one or several facilities</span>
            <MultiSelect
              isDisabled={!facilityOptions.length}
              options={facilityOptions}
              value={facility}
              onChange={setFacility}
              placeholder=""
              allName="All facilities"
            ></MultiSelect>
            {facility.length > 0 && (
              <>
                <ul>
                  {facility.map((item, key) => (
                    <li key={key}>
                      {item.label}
                      <button className="btn btn-icon" onClick={() => handelRemove(setFacility, item)}>
                        <img src="/images/x-circle.svg" alt="" />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <span className="form-error">{errors?.facilities && errors.facilities[0]}</span>
        </div>

        <div className={`form-item form-item-select form-control-item-settings ${errors?.user_groups ? 'form-item-error' : ''}`}>
          <label className="form-label form-label-big">Users Groups</label>
          <div className="select-multy-wrapper">
            <span className="select-placeholder">Choose one or several users groups</span>
            <MultiSelect
              isDisabled={!usersGroupsOptions.length}
              options={usersGroupsOptions}
              value={usersGroups}
              onChange={setUsersGroups}
              placeholder=""
              allName="All user groups"
            ></MultiSelect>
            {usersGroups.length > 0 && (
              <>
                <ul>
                  {usersGroups.map((item, key) => (
                    <li key={key}>
                      {item.label}
                      <button className="btn btn-icon" onClick={() => handelRemove(setUsersGroups, item)}>
                        <img src="/images/x-circle.svg" alt="" />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
          <span className="form-error">{errors?.user_groups && errors.user_groups[0]}</span>
        </div>

        <div className={`form-item form-item-select form-control-item-settings ${errors?.individual_users ? 'form-item-error' : ''}`}>
          <label className="form-label form-label-big">Individual Users</label>
          <div className="select-multy-wrapper">
            <span className="select-placeholder">Choose one or several users</span>
            <MultiSelect
              isDisabled={!individualUsersOptions.length}
              options={individualUsersOptions}
              value={individualUsers}
              onChange={setIndividualUsers}
              placeholder=""
              allName="All individual users"
            ></MultiSelect>
            {individualUsers.length > 0 && (
              <>
                <p>Access you already provided:</p>
                <ul className={'individual-users-list'}>
                  {individualUsers
                    .sort(function (a, b) {
                      if (a.label < b.label) {
                        return -1;
                      }
                      if (a.label > b.label) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((item, key) => (
                      <li key={key}>
                        {item.label}
                        <button className="btn btn-icon" onClick={() => handelRemove(setIndividualUsers, item)}>
                          <img src="/images/x-circle.svg" alt="" />
                        </button>
                      </li>
                    ))}
                </ul>
              </>
            )}
          </div>
          <span className="form-error">{errors?.individual_users && errors.individual_users[0]}</span>
        </div>
      </div>

      <div className="form-control-actions">
        <Link to={`/form-builder/${id}/constructor`} className="btn btn-outline">
          Back to Constructor
        </Link>
        <button className="btn" onClick={onUpdate}>
          Save Changes
        </button>
      </div>
    </div>
  );
};
