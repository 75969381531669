import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';

const SettingsModal = ({ element, isOpen, onClose, handlerChange }) => {
  const {
    options: { link, value },
  } = element;
  let layout = null;

  const [label, setLabel] = useState(value);
  const [url, setUrl] = useState(link);

  useEffect(() => {
    setUrl(link);
    setLabel(value);
  }, [link, value]);

  const handleUpdateLinkSettings = () => {
    try {
      const newElement = {
        ...element,
        options: {
          ...(element.options ?? {}),
          link: url,
          value: label,
        },
      };
      handlerChange(newElement);
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  if (isOpen) {
    layout = (
      <Modal className="modal-default" isOpen={isOpen} onRequestClose={onClose} contentLabel="Example Modal" ariaHideApp={false}>
        <h2 className="modal-header">
          <span>{'Web Link Attachment Properties'}</span>
          <button className="btn btn-icon" onClick={onClose}>
            <i className="icon-icon-x" />
          </button>
        </h2>
        <div>
          <div className={'w-full'}>
            <div className="form-control-item w-50">
              <label className="form-label">Link Url</label>
              <input
                value={url}
                placeholder={'Enter Link Url'}
                onChange={(e) => setUrl(e.target.value)}
                type="text"
                className="form-control"
              />
              {/*<div className={'form-error mt-1'}>{errors?.first_name && errors?.first_name[0]}</div>*/}
            </div>
          </div>
          <div className={'w-full'}>
            <div className="form-control-item w-50">
              <label className="form-label">Link Name</label>
              <input
                value={label}
                placeholder={'Enter Link Name'}
                onChange={(e) => setLabel(e.target.value)}
                type="text"
                className="form-control"
              />
              {/*<div className={'form-error mt-1'}>{errors?.first_name && errors?.first_name[0]}</div>*/}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-outline" onClick={onClose}>
            Cancel
          </button>
          <button className="btn d-flex" onClick={handleUpdateLinkSettings}>
            Update
          </button>
        </div>
      </Modal>
    );
  }

  return layout;
};

export default memo(SettingsModal);
