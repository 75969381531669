import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

function ImportModal({ isOpen, closeModal, title, errors, isPending, onSubmit, handelError }) {
  const [errorFile, setErrorFile] = useState(errors);

  useEffect(() => {
    setErrorFile(errors);
  }, [errors]);
  const [file, setFile] = useState({
    file: null,
  });

  const resetForm = () => {
    handelError(null);
    setFile({ file: null });
  };
  const handleSubmit = () => {
    onSubmit(file);
  };

  const handleFile = (event) => {
    if (event.target.files[0]) {
      setFile({ file: event.target.files[0] });
      event.target.value = '';
      event.target.files = null;
    }
  };

  const handelCloseModal = () => {
    resetForm();
    closeModal();
  };
  return (
    <Modal
      className="modal-default modal-no-footer"
      isOpen={isOpen}
      onRequestClose={handelCloseModal}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        {title}{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div className="form">
        <div className="form-item">
          <div className={`form-control-file form-control-file-default`}>
            <input accept=".xlsx" type="file" id="contract_file" onChange={handleFile} />
            <div>
              <img className="icon-icon-upload" src="/images/upload-svg.svg" alt="" />
              <span>Click to upload</span>
              <span className="sub-text">Supported File Formats: XLSX</span>
            </div>
          </div>
          {!file.file && <p className="text-secondary mt-2">No files uploaded</p>}
          {errorFile?.errors && (
            <div className="form-error mt-2">
              The file has been processed, but some errors occurred during the import. Please review the following issues:<br></br>
              <br></br>
              {typeof errorFile.errors !== 'string' &&
                Object.keys(errorFile.errors).map((key) => (
                  <span key={key}>
                    {errorFile.errors[key] && <span>Row - {key}</span>}
                    <ul className={'mb-1'}>
                      {Object.keys(errorFile.errors[key]).map((item) => {
                        if (item === 'representatives') {
                          return Object.keys(errorFile.errors[key][item]).map((representativ) =>
                            Object.keys(errorFile.errors[key][item][representativ]).map((representativItem) => (
                              <li key={representativItem}>
                                representatives {representativ}: {representativItem} -{' '}
                                {errorFile.errors[key][item][representativ][representativItem][0]}
                              </li>
                            )),
                          );
                        }
                        return (
                          <li key={item}>
                            {item} - {errorFile.errors[key][item][0]}
                          </li>
                        );
                      })}
                    </ul>
                  </span>
                ))}
              {typeof errorFile.errors === 'string' && <span>{errorFile.errors}</span>}
            </div>
          )}

          {file.file && (
            <>
              <ul className="file-list mt-2">
                <li className={'align-center mb-0'}>
                  <div className="d-flex align-center">
                    <img src="/images/icon-file-list.svg" alt="" />
                    <p>{file.file.name}</p>
                    <button className="btn btn-icon btn-remove ml-2 mr-2" onClick={resetForm}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.4998 7.50033L7.49984 12.5003M7.49984 7.50033L12.4998 12.5003M18.3332 10.0003C18.3332 14.6027 14.6022 18.3337 9.99984 18.3337C5.39746 18.3337 1.6665 14.6027 1.6665 10.0003C1.6665 5.39795 5.39746 1.66699 9.99984 1.66699C14.6022 1.66699 18.3332 5.39795 18.3332 10.0003Z"
                          stroke="#B42318"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  {!isPending && (
                    <button className="btn" onClick={handleSubmit}>
                      <i className="icon-plus-svgrepo-com"></i> Import
                    </button>
                  )}
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ImportModal;
