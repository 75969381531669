// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form__actions__menu {
  position: relative;
}
.form__actions__menu .dropdown__menu {
  top: calc(100% + 8px);
  left: 0;
  width: 129px;
  position: absolute;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.form__actions__menu .dropdown__menu__item {
  padding-top: 4px;
  padding-bottom: 4px;
}
.form__actions__menu .dropdown__menu__item .btn {
  display: inline-flex;
  align-items: center;
  border: 0;
  box-shadow: none;
  background-color: #fff;
  color: var(--gray-900, #101828);
}
.form__actions__menu .dropdown__menu__item .btn i {
  font-size: 16px;
}
.form__actions__menu .dropdown__menu__item .btn i:before {
  color: var(--gray-900, #101828);
}
.form__actions__menu .dropdown__menu__item .btn.btn-remove {
  color: var(--tertiary-600, #B42318);
}
.form__actions__menu .dropdown__menu__item .btn.btn-remove i {
  font-size: 16px;
}
.form__actions__menu .dropdown__menu__item .btn.btn-remove i:before {
  color: var(--tertiary-600, #B42318);
}`, "",{"version":3,"sources":["webpack://./src/components/other/FormActionsMenu/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,qBAAA;EACA,OAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,2CAAA;EACA,WAAA;AACJ;AAEI;EACE,gBAAA;EACA,mBAAA;AAAN;AAEM;EACE,oBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,sBAAA;EACA,+BAAA;AAAR;AAEQ;EACE,eAAA;AAAV;AAEU;EACE,+BAAA;AAAZ;AAIQ;EACE,mCAAA;AAFV;AAIU;EACE,eAAA;AAFZ;AAIY;EACE,mCAAA;AAFd","sourcesContent":[".form__actions__menu {\n  position: relative;\n\n  .dropdown__menu {\n    top: calc(100% + 8px);\n    left: 0;\n    width: 129px;\n    position: absolute;\n    border-radius: 8px;\n    background: #FFF;\n    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);\n    z-index: 99;\n\n\n    &__item {\n      padding-top: 4px;\n      padding-bottom: 4px;\n\n      .btn {\n        display: inline-flex;\n        align-items: center;\n        border: 0;\n        box-shadow: none;\n        background-color: #fff;\n        color: var(--gray-900, #101828);\n\n        i {\n          font-size: 16px;\n\n          &:before {\n            color: var(--gray-900, #101828);\n          }\n        }\n\n        &.btn-remove {\n          color: var(--tertiary-600, #B42318);\n\n          i {\n            font-size: 16px;\n\n            &:before {\n              color: var(--tertiary-600, #B42318);\n            }\n          }\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
