import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
export const DraftItem = function SubmissionItem({ item, url, setShowModal, setCurrentId }) {
  const navigate = useNavigate();

  return (
    <div
      className="library-item cursor-pointer"
      onClick={() => {
        navigate(url ? url : `/initialization-form/${item.id}`);
      }}
      data-tooltip-id={`table-tooltip-${item.id}`}
    >
      <div className="library-item-text">
        <img src="/images/file-big-icon.png" alt="" /> {item.name}
        <Tooltip opacity={1} id={`table-tooltip-${item.id}`} place="top" content={item.name} />
      </div>
      <button
        style={{ marginRight: 16 }}
        className={'btn btn-remove btn-icon'}
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
          setCurrentId(item.id);
        }}
      >
        <i className={'icon-remove'}></i>
      </button>
    </div>
  );
};
