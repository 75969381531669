import React, { useEffect, useMemo, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../container/authContext';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { getFacilitiesByCompany } from '../../services/facility';
import DeactivateModal from '../../components/company/deactivate-modal';
import UnitsTab from '../../components/company/units-tab';
import UnitsModal from '../../components/company/units-modal';
import { deactivateUnit, getCompanyUnitsList } from '../../services/units';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';

function CompanyList() {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [totalUserPage, setTotalUserPage] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [filtersUser, setFiltersUser] = useState({
    facilities_ids: [],
    status: 'active',
    search: '',
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalDeactivateIsOpen, setDeactivateIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const { user } = useAuth();
  const [facilities, setFacilities] = useState([]);
  const [pageBreadcrumbs] = useState([
    {
      url: `/company/${id}`,
      name: `${user.company.name}`,
    },
    {
      url: `/company/${id}/units`,
      name: 'Units List',
    },
  ]);
  const [defaultIndex, setDefaultIndex] = useState(2);
  const navigation = useNavigate();
  const { isEmployee } = usePermission();
  useEffect(() => {
    if (isEmployee()) {
      redirectToLibrary(navigation);
    }
  }, [isEmployee, navigation]);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [id, userPage, filtersUser]);

  const handlePageUserClick = (event) => {
    setUserPage(event.selected + 1);
  };
  const initPage = () => {
    let params = {
      page: userPage,
      ...filtersUser,
    };

    getCompanyUnitsList(id, params)
      .then((resUsers) => {
        setUsers(resUsers.results);
        setTotalUserPage(resUsers.total_pages);
        setTotalUser(resUsers.count);
      })
      .catch((e) => console.log(e));

    getFacilitiesByCompany(id, 'getAll')
      .then((resFacilities) => {
        setFacilities(resFacilities);
      })
      .catch((e) => console.log(e));
  };

  function closeAddModal() {
    setIsOpen(false);
    setCurrentItem(null);
  }

  const handelCreateUserGroup = () => {
    initPage();
    closeAddModal();
  };
  const openAddModal = () => {
    setIsOpen(true);
  };

  const openDeactivateModal = (item) => {
    setDeactivateIsOpen(true);
    setCurrentItem(item);
  };

  const openEditModal = (item) => {
    setIsOpen(true);
    setCurrentItem(item);
  };

  function closeDeactivateModal() {
    setDeactivateIsOpen(false);
    setCurrentItem(null);
  }

  const applyFilters = (filters) => {
    setFiltersUser(filters);
  };

  const handelDeactivate = () => {
    deactivateUnit(currentItem.id)
      .then((res) => {
        initPage();
        closeDeactivateModal();
      })
      .catch((e) => console.log(e));
  };

  const editUnitData = useMemo(() => {
    return currentItem && users?.length ? users.find(({ id }) => id === currentItem.id) : null;
  }, [currentItem, users]);

  return (
    <Layout title={'Unit List'} breadcrumbs={pageBreadcrumbs}>
      <div className={`page-wrapper`}>
        <div className="company-profile">
          <div className="company-profile-tab">
            <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
              <TabList>
                <Tab onClick={() => navigation(`/company/${id}/user-groups`)}>All User Groups</Tab>
                <Tab onClick={() => navigation(`/company/${id}/positions`)}>Positions</Tab>
                <Tab>Units</Tab>
              </TabList>

              <TabPanel>User Groups</TabPanel>
              <TabPanel>
                {/*<h3 className="company-profile-title">Users</h3>*/}
                Positions
              </TabPanel>
              <TabPanel>
                <UnitsTab
                  onUpdate={initPage}
                  openDeactivateModal={openDeactivateModal}
                  openEditModal={openEditModal}
                  facilities={facilities}
                  handelFilter={applyFilters}
                  filtersUser={filtersUser}
                  totalItem={totalUser}
                  totalPage={totalUserPage}
                  handlePageClick={handlePageUserClick}
                  userGroup={users}
                  openAddModal={openAddModal}
                />
                <UnitsModal
                  userGroupData={editUnitData}
                  companyName={user.company.name}
                  facilities={facilities}
                  closeModal={closeAddModal}
                  isOpen={modalIsOpen}
                  openModal={openAddModal}
                  handelCreateUserGroup={handelCreateUserGroup}
                  onRefetchUnits={initPage}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>

      <DeactivateModal
        isOpen={modalDeactivateIsOpen}
        closeModal={closeDeactivateModal}
        handelSuccess={handelDeactivate}
        title="Unit Deactivation"
        subtitle="Deactivation will temporarily hide this unit from the system. You can reactivate it anytime in this unit."
      ></DeactivateModal>
    </Layout>
  );
}

export default CompanyList;
