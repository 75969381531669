import React, { useEffect, useState } from 'react';

export const LinkElement = function FromElement({ element }) {
  const [style, setStyle] = useState({
    fontSize: element.options.fontSize,
    textAlign: element.options.alignment,
  });

  useEffect(() => {
    setStyle(() => {
      return {
        fontSize: `${element.options.fontSize}px`,
        textAlign: element.options.alignment,
      };
    });
  }, [element]);

  return (
    <>
      {element.options.label && <label className={'form-label'}>{element.options.label}</label>}
      {/* eslint-disable-next-line */}
      {element.options.link ? (
        <p style={style}>
          <a target={'_blank'} href={element.options.link} rel="noreferrer">
            {element.options.value}
          </a>
        </p>
      ) : (
        'Empty Link'
      )}
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
