import { STATIC_ROUTES } from '../../constants/routes';

export const CNA_TABS = [
  {
    url: STATIC_ROUTES.CNA_ASSIGNMENTS,
    label: 'Assignments',
  },
  {
    url: STATIC_ROUTES.CNA_DEFAULT_TASKS,
    label: 'Default Tasks',
  },
];
