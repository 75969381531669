import client from '../api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;

export const getFromById = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const params = { status: 'all' };

  const response = await client.get(`api/corporate-library/forms/${id}/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getPublicForm = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/initiated-form/${id}/get-form-view/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getGeneralFromById = async (id, params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/general-library/forms/${id}/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getInitFromById = async (id, token) => {
  if (cancel !== undefined) {
    cancel();
  }

  const params = {};

  if (token) {
    params['token'] = token;
  }

  const response = await client.get(`api/initiated-form/${id}/get-form/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const updateForm = async (id, data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/corporate-library/forms/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const updateFormAdmin = async (id, data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/general-library/forms/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const createForm = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`api/corporate-library/forms/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const createFormAdmin = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`api/general-library/forms/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const shareWithCompany = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`/api/general-library/forms/share-with-company/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const initForm = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`api/corporate-library/forms/initiate/${id}/initialize/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const deactivateForm = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/corporate-library/forms/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deactivateGeneralForm = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/general-library/forms/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const activateForm = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(
    `api/corporate-library/forms/${id}/restore/`,
    {},
    {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    },
  );

  return response.data;
};
export const activateGeneralForm = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(
    `api/general-library/forms/${id}/restore/`,
    {},
    {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    },
  );

  return response.data;
};

export const submitForm = async (id, data, token) => {
  if (cancel !== undefined) {
    cancel();
  }

  const params = {};

  if (token) {
    params['token'] = token;
  }

  const response = await client.post(`/api/initiated-form/${id}/store-submission/`, data, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
