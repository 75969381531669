/**
 * Generates a URL string based on the provided configuration object.
 *
 * @param {Object} config - Configuration object for generating the URL.
 * @param {string} config.basePath - The base path of the URL.
 * @param {Object} [config.params={}] - An optional object containing query parameters as key-value pairs.
 * @returns {string|undefined} - The generated URL string with query parameters or `undefined` if the basePath is invalid.
 *
 * @example
 * // Returns '/path/to/resource?param1=value1&param2=value2'
 * createURLString({ basePath: '/path/to/resource', params: { param1: 'value1', param2: 'value2' } });
 */
export const createURLString = ({ basePath, params = {} }) => {
  if (!basePath || !basePath.length) return;

  const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));
  const queryString = new URLSearchParams(filteredParams).toString();
  return `${basePath}${queryString ? `?${queryString}` : ''}`;
};
