import React, { useEffect, useState } from 'react';
import File from '../../library/files/file';
import Modal from 'react-modal';
import { getSubmissions } from '../../../services/folder/form-avaliable';
import { isResponseOk } from '../../../utility/isResponseOk';

const SubmissionsModal = ({ isOpen, closeModal, handelFormSelected, company_id }) => {
  const [formsSearch, setFormsSearch] = useState([]);
  const [form, setForm] = useState(null);
  const [isDisable, setIsDisable] = useState(true);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (isOpen) {
      changeFolder();
    }
    // eslint-disable-next-line
  }, [isOpen, search]);

  useEffect(() => {
    if (form) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [form]);

  const changeFolder = () => {
    const bodyRequest = { get_all: true, search: search, company_id: company_id };

    if (!search) {
      delete bodyRequest.search;
    }

    if (!company_id) {
      delete bodyRequest.company_id;
    }
    getSubmissions(bodyRequest)
      .then((res) => {
        if (isResponseOk(res)) {
          setFormsSearch(res.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const handelForm = () => {
    handelFormSelected(form);
    setForm(null);
    setSearch('');
    closeModal();
  };
  const handelAttach = (folderItem) => {
    setForm(folderItem);
  };

  return (
    <Modal className="modal-default  modal-with-tabs modal-export pt-6" isOpen={isOpen} onRequestClose={closeModal} ariaHideApp={false}>
      <div className={'modal-header'}>
        <h2 className={'modal-title'}>Select Form</h2>
        <button className="btn btn-icon" onClick={closeModal}>
          <i className="icon-icon-x"></i>
        </button>
      </div>
      <div className="modal-body ">
        <div className="library">
          <div className="library-filter">
            <div className="library-filter-search form-control-item">
              <input
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search"
              />
              <i className="icon-search"></i>
            </div>
          </div>
          {formsSearch.length > 0 && (
            <>
              <h3 className="library-subtitle">
                Forms <span className={'total'}>(Total: {formsSearch.length} Forms)</span>
              </h3>
              <div className="library-wrapper">
                {formsSearch.map((item) => (
                  <File
                    file={form}
                    handelAttach={handelAttach}
                    isAttach
                    deleteFile={() => null}
                    item={item}
                    url={`${item.id}`}
                    key={item.id}
                  />
                ))}
              </div>
            </>
          )}
          {!formsSearch.length && !!search && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
                <h4 className="empty-page-subtitle">
                  Try to change the range of keywords. We're here to help you find what you're looking for.
                </h4>
              </div>
            </>
          )}
          {!formsSearch.length && !search && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">This folder is empty</h3>
                <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn d-flex" onClick={handelForm} disabled={isDisable}>
          Select
        </button>
      </div>
    </Modal>
  );
};

export default SubmissionsModal;
