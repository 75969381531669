import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Layout from '../../../layout/default';
import { Breadcrumbs } from '../../../components/library/Breadcrumbs';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import {
  deleteFolder,
  getPublicFolderForms,
  archiveInitiatedForm,
  activateInitiatedForm,
  deleteInitiatedForm,
} from '../../../services/folder/form-avaliable';
import { useAuth } from '../../../container/authContext';
import { FolderItemWithDnD } from '../../../components/library/folders/folder-with-dnd';
import CreateModal from '../../../components/library/create-modal';
import Select from 'react-select';
import ErrorModal from '../../../components/library/error-modal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormItem } from '../../../components/library/form-item';
import { usePermission } from '../../../container/permissionContext';
import PrintModal from '../../../components/init-form/print-modal';
import { getPublicForm } from '../../../services/form';
import TabsMobile from '../mobile-tabs';
import { isResponseOk } from '../../../utility/isResponseOk';
import { useDebounce } from 'use-debounce';
import PageLoader from '../../../components/other/PageLoader';
import withLibraryViewMode from '../../../HOCs/withLibraryViewMode';
import { formStatusOptions } from '../../../constants/forms/options';
import cx from 'classnames';
import { useIsMobile } from '../../../hook/useIsMobile';

const initialStatusOption = {
  value: 'active',
  label: 'Active',
};

const getBreadcrumbs = (breadcrumbs) => {
  const list = [];

  function getProp(o) {
    list.push(o);
    if (o.children) {
      getProp(o.children);
    }
  }

  if (breadcrumbs) {
    getProp(breadcrumbs);
  }

  return list;
};

function CorporateLibrary({ ViewModeSwitcher, libraryViewMode }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { hasPermissionByCodeName } = usePermission();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useIsMobile();

  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      name: 'Company Library',
      url: '/corporate-library',
    },
  ]);
  const [folders, setFolders] = useState([]);
  const [forms, setForms] = useState([]);
  const [formData, setFormData] = useState(null);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(initialStatusOption);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openPublicModal, setOpenPublicModal] = useState(false);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [isErrorMoveModal, setIsErrorMoveModal] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isFilterMode, setIsFilterMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formsWithoutFolders, setFormsWithoutFolders] = useState(false);

  const [debouncedSearch] = useDebounce(search, 300);
  const isFirstFormsLoadingRef = useRef(true);

  const isListMode = libraryViewMode === 'LIST';
  const isExistForms = !!forms?.length;
  const isExistLibraryItems = Boolean(isExistForms || folders?.length);
  const isFiltered = useMemo(() => !!debouncedSearch?.length || status?.value !== 'active', [debouncedSearch?.length, status?.value]);

  const closeModal = () => {
    setOpenModal(false);
    setCurrentFolder(null);
  };

  const closeErrorModal = () => {
    setIsErrorModal(false);
  };

  const closeErrorMoveModal = () => {
    setIsErrorMoveModal(false);
  };

  useEffect(() => {
    let folderId = null;
    if (searchParams.has('parent_id')) {
      folderId = Number(searchParams.get('parent_id'));
      searchParams.delete('parent_id');
      setSearchParams(searchParams);
    }
    getForms(folderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentFolderId) {
      if (isFirstFormsLoadingRef.current) {
        isFirstFormsLoadingRef.current = false;
      } else {
        getForms(currentFolderId);
      }
    }
    // eslint-disable-next-line
  }, [status?.value, debouncedSearch, isListMode, currentFolderId, formsWithoutFolders]);

  const goToPrev = (id, type) => {
    if (type === 'root') {
      navigate(id, { replace: true });
    }
    if (type === 'folder') {
      changeFolder(id);
    }
  };

  const getForms = (folderId) => {
    setIsLoading(true);

    const requestBody = { company_id: user.company.id, folder_id: folderId };
    let searchParam = debouncedSearch;

    if (formsWithoutFolders && !debouncedSearch.length) {
      searchParam = ' ';
    }

    requestBody.search = searchParam;

    if (status) {
      requestBody['status'] = status.value;
    }

    getPublicFolderForms(requestBody)
      .then((res) => {
        if (res) {
          if (debouncedSearch || formsWithoutFolders) {
            setFolders([]);
            setForms(res);
          } else {
            const { breadcrumbs, data } = res;
            setBreadcrumbs([
              {
                name: 'Company Library',
                url: '/corporate-library',
              },
              ...getBreadcrumbs(breadcrumbs),
            ]);

            const currentFolderId = getBreadcrumbs(breadcrumbs)[getBreadcrumbs(breadcrumbs).length - 1]?.id;

            setCurrentFolderId(currentFolderId);
            setFolders(data?.filter((item) => item.type === 'folder'));
            setForms(data?.filter((item) => item.type === 'form'));
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  };

  const changeFolder = (folderId) => setCurrentFolderId(folderId);

  const onFolderCreated = (newFolderId) => {
    if (newFolderId) {
      changeFolder(newFolderId);
    } else {
      getForms(currentFolderId);
    }
  };

  const clearFilter = () => {
    if (isFiltered) {
      setSearch('');
      setStatus(initialStatusOption);
    }
  };

  const handelOpenModal = () => {
    setOpenModal(true);
  };

  const handelOpenPublicModal = (id) => {
    getPublicForm(id)
      .then((res) => {
        if (res) {
          setFormData({ id, ...res });
        }
        setOpenPublicModal(true);
      })
      .catch((e) => console.log(e));
  };

  const handelClosePublicModal = () => {
    setOpenPublicModal(false);
    setFormData(false);
  };
  const handelEdit = (item) => {
    handelOpenModal();
    setCurrentFolder(item);
  };

  const handelMove = (id) => {
    changeFolder(id);
  };

  const handelDelete = (item) => {
    deleteFolder(item.id)
      .then(() => {
        getForms();
      })
      .catch((e) => {
        setIsErrorModal(true);
        console.log(e);
      });
  };
  const backToMobile = () => {
    let backToLink = breadcrumbs[breadcrumbs.length - 2]
      ? breadcrumbs[breadcrumbs.length - 2]
      : {
          name: 'Company Library',
          url: '/corporate-library',
        };

    if (backToLink.id) {
      changeFolder(backToLink.id);
    } else {
      if (backToLink.url) {
        navigate(backToLink.url);
      } else {
        navigate('/corporate-library');
      }
    }
  };

  const handelArchived = async (form) => {
    archiveInitiatedForm(form?.id)
      .then(() => {
        getForms(currentFolderId);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDeleteForm = useCallback(
    async (formId) => {
      const deletePublicFormRes = await deleteInitiatedForm(formId);
      if (isResponseOk(deletePublicFormRes)) {
        getForms(currentFolderId);
      }
    },
    [currentFolderId, getForms],
  );

  const handleActivateForm = useCallback(
    async (formId) => {
      const activateInitiatedFormRes = await activateInitiatedForm(formId);
      if (isResponseOk(activateInitiatedFormRes)) {
        getForms(currentFolderId);

        if (formData?.id) {
          handelOpenPublicModal(formData.id);
        }
      }
    },
    [currentFolderId, formData?.id, getForms],
  );

  const renderFilters = () => {
    return (
      <>
        <>
          <div className="library-filter">
            <div className="library-filter-search form-control-item">
              <input value={search} type="text" placeholder="Search" className="form-control" onChange={(e) => setSearch(e.target.value)} />
              <i className="icon-search" />
            </div>
            {!isMobile && (
              <>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <button
                    className={`btn-outline btn ${isFilterMode ? 'btn-active' : ''}`}
                    style={{ height: 44, width: 44 }}
                    onClick={() => setIsFilterMode(!isFilterMode)}
                  >
                    <i className="icon-icon-sliders"></i>
                  </button>

                  <button
                    className={`btn-outline btn`}
                    style={{ height: 44, width: 44 }}
                    onClick={() => setFormsWithoutFolders((prevState) => !prevState)}
                  >
                    {!formsWithoutFolders ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                          d="M2.50033 4.16797C2.04009 4.16797 1.66699 4.54106 1.66699 5.0013C1.66699 5.46154 2.04009 5.83464 2.50033 5.83464H2.50866C2.9689 5.83464 3.34199 5.46154 3.34199 5.0013C3.34199 4.54106 2.9689 4.16797 2.50866 4.16797H2.50033Z"
                          fill="#101828"
                        />
                        <path
                          d="M6.66699 4.16797C6.20676 4.16797 5.83366 4.54106 5.83366 5.0013C5.83366 5.46154 6.20676 5.83464 6.66699 5.83464H17.5003C17.9606 5.83464 18.3337 5.46154 18.3337 5.0013C18.3337 4.54106 17.9606 4.16797 17.5003 4.16797H6.66699Z"
                          fill="#101828"
                        />
                        <path
                          d="M6.66699 9.16797C6.20676 9.16797 5.83366 9.54106 5.83366 10.0013C5.83366 10.4615 6.20676 10.8346 6.66699 10.8346H17.5003C17.9606 10.8346 18.3337 10.4615 18.3337 10.0013C18.3337 9.54106 17.9606 9.16797 17.5003 9.16797H6.66699Z"
                          fill="#101828"
                        />
                        <path
                          d="M5.83366 15.0013C5.83366 14.5411 6.20676 14.168 6.66699 14.168H17.5003C17.9606 14.168 18.3337 14.5411 18.3337 15.0013C18.3337 15.4615 17.9606 15.8346 17.5003 15.8346H6.66699C6.20676 15.8346 5.83366 15.4615 5.83366 15.0013Z"
                          fill="#101828"
                        />
                        <path
                          d="M1.66699 10.0013C1.66699 9.54106 2.04009 9.16797 2.50033 9.16797H2.50866C2.9689 9.16797 3.34199 9.54106 3.34199 10.0013C3.34199 10.4615 2.9689 10.8346 2.50866 10.8346H2.50033C2.04009 10.8346 1.66699 10.4615 1.66699 10.0013Z"
                          fill="#101828"
                        />
                        <path
                          d="M2.50033 14.168C2.04009 14.168 1.66699 14.5411 1.66699 15.0013C1.66699 15.4615 2.04009 15.8346 2.50033 15.8346H2.50866C2.9689 15.8346 3.34199 15.4615 3.34199 15.0013C3.34199 14.5411 2.9689 14.168 2.50866 14.168H2.50033Z"
                          fill="#101828"
                        />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                          d="M8.44444 3H3V8.44444H8.44444V3Z"
                          stroke="#101828"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17 3H11.5556V8.44444H17V3Z"
                          stroke="#101828"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17 11.5556H11.5556V17H17V11.5556Z"
                          stroke="#101828"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.44444 11.5556H3V17H8.44444V11.5556Z"
                          stroke="#101828"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </button>

                  <ViewModeSwitcher styles={{ marginLeft: 16 }} />

                  {hasPermissionByCodeName('corporate_library_management') && (
                    <button className={'btn btn-outline'} style={{ height: 44 }} onClick={handelOpenModal}>
                      <i className="icon-icon-folder mr-2" />
                      Create folder
                    </button>
                  )}
                </div>
              </>
            )}
          </div>

          {isFilterMode && (
            <div className="library-filters">
              <div className="library-filters-wrapper">
                <div className="form-item">
                  <label className="form-label">Status</label>
                  <Select
                    options={formStatusOptions}
                    value={status}
                    onChange={(e) => setStatus(e)}
                    className="form-control-select"
                    classNamePrefix="react-select"
                  ></Select>
                </div>
              </div>

              <button className="btn btn-outline" onClick={clearFilter}>
                Clear All
              </button>
            </div>
          )}
        </>
      </>
    );
  };

  const renderFormsAndFolders = () => {
    let layout;
    if (isLoading) {
      layout = <PageLoader />;
    } else {
      layout = (
        <>
          {isExistLibraryItems && isListMode && !formsWithoutFolders && (
            <div className="library-forms-available-to-me-list-header">Name</div>
          )}
          {isExistForms && formsWithoutFolders && (
            <h3 className="library-subtitle mt-2">
              Forms <span className={'total'}>(Total: {forms.length} forms)</span>
            </h3>
          )}

          {!!folders?.length && (
            <>
              {!formsWithoutFolders && !isListMode && <h3 className="library-subtitle">Folders</h3>}
              <div className="library-wrapper" style={{ rowGap: isListMode ? 0 : undefined }}>
                {folders.map((item) => (
                  <FolderItemWithDnD
                    item={item}
                    key={item.id}
                    isListItem={isListMode}
                    handelEdit={handelEdit}
                    handelMove={handelMove}
                    handelDelete={handelDelete}
                    changeFolder={changeFolder}
                    handelError={setIsErrorMoveModal}
                  />
                ))}
              </div>
            </>
          )}

          {isExistForms && (
            <>
              {!formsWithoutFolders && !isListMode && <h3 className="library-subtitle mt-2">Forms</h3>}
              <div className="library-wrapper forms" style={{ rowGap: isListMode ? 0 : undefined }}>
                {forms.map((item) => (
                  <FormItem
                    isPublic
                    key={item.id}
                    item={item}
                    isListItem={isListMode}
                    handelError={setIsErrorMoveModal}
                    wrapperClassnames={cx('forms-available-to-me-form', { 'list-item': isListMode })}
                    url={`/form-view/${item.id}?parent_id=${item.parent_id ? item.parent_id : null}&isPublic=true`}
                    handelMove={handelMove}
                    deleteForm={handleDeleteForm}
                    handelOpenPublicModal={handelOpenPublicModal}
                    handelArchived={handelArchived}
                  />
                ))}
              </div>
            </>
          )}

          {!forms?.length && !!debouncedSearch && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
                <h4 className="empty-page-subtitle">
                  Try to change the range of keywords. We're here to help you find what you're looking for.
                </h4>
              </div>
            </>
          )}
          {!forms?.length && !folders?.length && !debouncedSearch && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">This folder is empty</h3>
                <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
              </div>
            </>
          )}
        </>
      );
    }

    return layout;
  };

  return (
    <Layout title="Company Library">
      <DndProvider backend={HTML5Backend}>
        <div className="library" style={{ paddingBottom: 90 }}>
          <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />

          <TabsMobile />
          <h2 className={'library-title-mobile'}>
            <svg
              onClick={backToMobile}
              className={'mr-2 cursor-pointer'}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
                stroke="#101828"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {breadcrumbs[breadcrumbs.length - 1] ? breadcrumbs[breadcrumbs.length - 1].name : ''}
          </h2>
          <h2 className="library-title">
            {breadcrumbs[breadcrumbs.length - 1] && (
              <svg
                className={'mr-2 cursor-pointer'}
                onClick={backToMobile}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
                  stroke="#101828"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {!breadcrumbs[breadcrumbs.length - 1] && (
              <Link to={'/corporate-library/forms/'} className="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
                    stroke="#101828"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            )}
            Public Forms
          </h2>

          {renderFilters()}

          {renderFormsAndFolders()}
        </div>
      </DndProvider>
      <CreateModal
        closeModal={closeModal}
        isOpen={openModal}
        company_id={user.company.id}
        handelCreate={onFolderCreated}
        folderData={currentFolder}
        parent_id={currentFolderId}
      />
      <ErrorModal
        isOpen={isErrorModal}
        closeModal={closeErrorModal}
        title={'Empty Folder Required for Deletion'}
        subtitle={
          'You can only delete folders when they are empty. Please remove all items from this folder before proceeding with the deletion.'
        }
      />

      <ErrorModal isOpen={isErrorMoveModal} closeModal={closeErrorMoveModal} title={'Parent cannot be changed.'} subtitle={''}></ErrorModal>
      {openPublicModal && formData && (
        <PrintModal
          formData={formData}
          isOpen={openPublicModal}
          deleteForm={handleDeleteForm}
          archiveForm={handelArchived}
          activateForm={handleActivateForm}
          closeModal={handelClosePublicModal}
        />
      )}

      {isLoading && <PageLoader />}
    </Layout>
  );
}

export default withLibraryViewMode(CorporateLibrary);
