import React, { useEffect, useState } from 'react';
import Layout from '../../layout/full-page';
import { useLocation, useParams } from 'react-router-dom';
import { getInitFromById, submitForm } from '../../services/form';
import { RenderForm } from '../../components/init-form/render-form';
import { removeToken } from '../../services/api';
import { useAuth } from '../../container/authContext';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';
import { utcToLocalDate } from '../../utility/dates/utcToLocalDate';

function SendPublicForm() {
  const [formData, setFormData] = useState({});
  const [initData, setInitData] = useState({});
  const [isFinished, setIsFinished] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const { setUser } = useAuth();

  const getToken = () => {
    if (location.search.includes('?token=')) {
      const token = location.search.split('?token=')[1];

      return token;
    }

    return null;
  };

  useEffect(() => {
    removeToken();
    setUser(null);
    if (id) {
      getInitFromById(id, getToken())
        .then((res) => {
          setFormData(res);

          if (res?.submitted_data.length) {
            const form_fields = res?.form_fields.map((form_field) => {
              const submitted_item = res?.submitted_data.find((item) => form_field.id === item.id);
              const saved_item = res?.saved_data.find((item) => form_field.id === item.id);
              if (submitted_item) {
                return isReadOnly(submitted_item, res);
              }

              if (saved_item) {
                return saved_item;
              }

              return isReadOnly(form_field, res);
            });

            setInitData({ ...initData, form_fields: JSON.parse(JSON.stringify(form_fields)) });
          } else if (res?.form_fields) {
            if (res?.saved_data.length) {
              const form_fields = res?.form_fields.map((form_field) => {
                const submitted_item = res?.saved_data.find((item) => form_field.id === item.id);

                if (submitted_item) {
                  return submitted_item;
                }

                return isReadOnly(form_field, res);
              });

              setInitData({ form_fields: JSON.parse(JSON.stringify(form_fields)), ...initData });
            } else {
              const form_fields = res?.form_fields.map((item) => {
                return isReadOnly(item, res);
              });

              setInitData({ form_fields: form_fields, ...initData });
            }
          }
        })
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line
  }, [id, location]);

  const handelInitSettings = (res, key) => {
    setInitData({ ...initData, [key]: res });
  };
  const isReadOnly = (element, res) => {
    if (res.can_edit_previous_submissions) {
      return element;
    }

    if (element.type === 'name' || element.type === 'address' || element.type === 'checkbox') {
      element.inputs.map((input) => {
        if (input.valueInput) {
          input['readOnly'] = 'readonly';
        }

        return input;
      });
    }

    if (element.type === 'table') {
      element.rows.map((row) => {
        if (row.cols) {
          row.cols.forEach((col) => {
            if (col.value) {
              col['readOnly'] = 'readonly';
            }
          });
        }
        return row;
      });
    }

    if (element.options.valueInput) {
      element.options['readOnly'] = 'readonly';
    }

    return element;
  };

  const onClearForm = () => {
    if (formData?.submitted_data.length) {
      const form_fields = formData?.form_fields.map((form_field) => {
        const submitted_item = formData?.submitted_data.find((item) => form_field.id === item.id);
        if (submitted_item) {
          return isReadOnly(submitted_item, formData);
        }

        return isReadOnly(form_field, formData);
      });
      setInitData({ ...initData, form_fields: JSON.parse(JSON.stringify(form_fields)) });
    } else if (formData?.form_fields) {
      const form_fields = formData?.form_fields.map((item) => {
        return isReadOnly(item, formData);
      });

      setInitData({ ...initData, form_fields: form_fields });
    }
  };

  const onSend = (isSaved) => {
    setIsDisabled(true);
    const bodyRequest = {
      data: initData.form_fields,
      is_saved: isSaved,
    };
    submitForm(id, bodyRequest, getToken())
      .then((res) => {
        setIsFinished(true);
        setIsDisabled(false);
      })
      .catch((e) => {
        console.log(e);
        setIsDisabled(false);
      });
  };
  return (
    <Layout title="Send Form" pageClassName="form-builder-wrapper-settings init-page send-page">
      {!isFinished && (
        <div>
          <div className="d-flex send-form-wrapper">
            <div className={'send-form-title'}>
              {formData.deadline && (
                <>
                  <strong>Deadline:</strong> {dayjs(utcToLocalDate(formData.deadline)).format(`${DATE_FORMAT} hh:mm A`)}
                </>
              )}
            </div>
            <div className={'d-flex'}>
              <button className={'btn btn-outline'} onClick={onClearForm}>
                Clear form
              </button>
            </div>
          </div>
          <RenderForm
            isSubmitter={true}
            isPrint={false}
            handelInitSettings={handelInitSettings}
            title={
              formData?.title_options
                ? {
                    ...formData?.title_options,
                    options: { ...formData?.title_options.options, value: formData.name },
                  }
                : formData.name
            }
            formElement={initData?.form_fields}
          />
          <div className="form-control-actions">
            <button className={'btn btn-outline btn-mobile'} onClick={onClearForm}>
              Clear form
            </button>
            {!formData?.deleted_at && (
              <>
                <button disabled={isDisabled} className="btn" onClick={() => onSend(false)}>
                  Submit the form
                </button>
                <button disabled={isDisabled} className="btn btn-mobile" onClick={() => onSend(false)}>
                  Submit
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {isFinished && (
        <div className={'success-page'}>
          <img src="/images/sussess-icon.png" alt="" />
          <h2>Submission is Successful!</h2>
          <p>Thank you for submitting the form. Your information has been received successfully.</p>
        </div>
      )}
    </Layout>
  );
}

export default SendPublicForm;
