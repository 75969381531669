import client from '../../api';
import { CancelToken } from 'axios';
import qs from 'qs';

let cancel;

export const getCNADefaultTasksService = async ({ companyID, search }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!companyID) return;
  try {
    return await client.get(`/api/cna-assignments/default-tasks/`, {
      params: { company_id: companyID, search },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
export const createCNADefaultTaskService = async ({ companyID, taskDescription }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!companyID || !taskDescription?.length) return;
  try {
    const body = {
      company_id: companyID,
      text: taskDescription,
    };
    return await client.post(`/api/cna-assignments/default-tasks/`, body, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
export const deleteCNADefaultTaskService = async ({ taskID }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!taskID) return;
  try {
    return await client.delete(`/api/cna-assignments/default-tasks/${taskID}/`, {
      params: { id: taskID },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
export const updateCNADefaultTaskService = async ({ taskID, taskDescription }) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!taskID || !taskDescription?.length) return;
  try {
    const body = {
      text: taskDescription,
    };
    return await client.patch(`/api/cna-assignments/default-tasks/${taskID}/`, body, {
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
