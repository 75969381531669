import React, { memo, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import Table from './components/table';
import { getCorporateFormManagementFormDetails } from '../../../../../services/libraries/corporate-library/form-management';
import { isResponseOk } from '../../../../../utility/isResponseOk';
import { get } from 'lodash';
import {
  all_months,
  deadlineRangeOptions,
  frequencyOptions,
  qualityFrequencyOptions,
  rangeFullOptions,
  typeOptions as deadlineTypeOption,
} from '../../../../init-form/settings/submition-settings-frequency/constants';
import { formatDateValue, formatTimeValueByDateString, getQuestionAnswerByValue } from './utils';
import {
  archiveRelativeToInitiationOptions,
  archiveTimeOffsetUnitsOptions,
  weekDays,
} from '../../../../init-form/settings/submition-settings-public/contstants';
import { utcToLocalDate } from '../../../../../utility/dates/utcToLocalDate';

import './styles.scss';
import { contactPersonSubmitterTypes, submitterTypesOptions } from '../../../../../constants/from-builder/options';

const renderNotificationTypeCell = (info) => {
  const value = info.getValue();
  return <span style={{ textTransform: 'capitalize' }}>{value}</span>;
};

const renderFacilityCell = (info) => {
  const value = info.getValue();
  return (
    <>
      {Array.isArray(value) && !!value.length
        ? value.map((item) => {
            return <div key={item.id}>{item.name}</div>;
          })
        : '-'}
    </>
  );
};

const renderIndividualUsersCell = (info) => {
  const value = info.getValue();
  return (
    <>
      {Array.isArray(value) && !!value.length
        ? value.map((item) => {
            let layout = null;

            if (item) {
              const { first_name, last_name } = item;
              let fullName = ``;
              if (first_name) {
                fullName += first_name;
              }

              if (last_name) {
                fullName += ` ${last_name}`;
              }

              layout = <div key={item.id}>{fullName}</div>;
            }
            return layout;
          })
        : '-'}
    </>
  );
};

const renderUnregisteredUsersCell = (info) => {
  const value = info.getValue();
  return (
    <>
      {Array.isArray(value)
        ? value.map((item) => {
            let layout = null;

            if (item) {
              const { first_name, last_name, email, phone } = item;
              let fullName = ``;
              if (first_name) {
                fullName += first_name;
              }

              if (last_name) {
                fullName += ` ${last_name}`;
              }

              layout = (
                <div key={item.id} style={{ maxWidth: 400, display: 'inline-block', wordBreak: 'break-all' }}>
                  {fullName} <br />
                  {email} <br />
                  {phone}
                </div>
              );
            }
            return layout;
          })
        : null}
    </>
  );
};

const FormSettingsModal = ({ onClose, formId, settingsFormId, setIsLoading }) => {
  const isOpen = Boolean(formId && settingsFormId);
  let layout = null;

  const [settings, setSettings] = useState(null);

  const {
    recipient_settings,
    access_settings,
    submission_settings,
    notification_settings,
    archive_settings,
    frequency_settings,
    start_date_time,
    end_date_time,
    immediately_initiation,
    deadline_settings,
  } = settings ?? {};
  const { filling_type, folder, submitters = {}, include_in_statistics } = submission_settings ?? {};
  const { unregistered: unregisteredSubmitters = [], submitters_type, contact_person_submitter_type } = submitters ?? {};
  const formType = filling_type?.toLowerCase();
  const isGeneralForm = filling_type === 'SPECIAL';
  const isPublicForm = filling_type === 'PUBLIC';
  const isExistValidArchiveSettings = archive_settings ? Object.values(archive_settings)?.some(Boolean) : false;
  const isExistValidFrequencySettings = frequency_settings ? Object.values(frequency_settings)?.some(Boolean) : false;
  const hasPeriodOfAvailability = Boolean(start_date_time || end_date_time || immediately_initiation);
  const isPublicFormOneTimeInitiation = hasPeriodOfAvailability;

  useEffect(() => {
    if (settingsFormId) {
      (async () => {
        try {
          setIsLoading(true);
          const response = await getCorporateFormManagementFormDetails(formId, settingsFormId);
          if (isResponseOk(response)) {
            setSettings(response.data);
          }
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [formId, setIsLoading, settingsFormId]);

  useEffect(() => {
    !isOpen && setSettings(null);
  }, [isOpen]);

  useEffect(() => {
    const parentModal = document.querySelector('.form-management-modal-overlay');
    if (parentModal) {
      if (isOpen) {
        parentModal.style.overflow = 'hidden';
      } else {
        parentModal.style.overflow = 'auto';
      }
    }
  }, [isOpen]);

  const accessSettingsColumns = useMemo(() => {
    return [
      {
        accessorKey: 'facilities',
        header: 'Facility',
        cell: renderFacilityCell,
      },
      {
        accessorKey: 'user_groups',
        header: 'User groups',
        cell: renderFacilityCell,
      },
      {
        accessorKey: 'individual_users',
        header: 'Individual users',
        cell: renderIndividualUsersCell,
      },
    ];
  }, []);

  const accessSettingsData = useMemo(() => {
    let data = [];
    if (!access_settings) return data;

    const rowData = accessSettingsColumns.reduce((acc, { accessorKey }) => {
      const value = get(access_settings, `[${accessorKey}]`);
      if (Array.isArray(value) && value.length) {
        acc[accessorKey] = value;
      }
      return acc;
    }, {});

    if (Object.keys(rowData).length) {
      data.push(rowData);
    }

    return data;
  }, [accessSettingsColumns, access_settings]);

  const submittersTableColumns = useMemo(() => {
    let columns = [];
    if (submitters_type === 'contact') {
      columns = [
        {
          accessorKey: 'facilities',
          header: 'Facility',
          cell: renderFacilityCell,
        },
        {
          accessorKey: 'units',
          header: 'Units',
          cell: renderFacilityCell,
        },
        {
          accessorKey: 'contacts',
          header: 'Contacts',
          cell: renderUnregisteredUsersCell,
        },
      ];
    } else {
      columns = [
        {
          accessorKey: 'facilities',
          header: 'Facility',
          cell: renderFacilityCell,
        },
        {
          accessorKey: 'user_groups',
          header: 'User groups',
          cell: renderFacilityCell,
        },
        ...[
          unregisteredSubmitters?.length
            ? {
                accessorKey: 'unregistered',
                header: 'Unregistered',
                cell: renderUnregisteredUsersCell,
              }
            : {
                accessorKey: 'individual_users',
                header: 'Individual users',
                cell: renderIndividualUsersCell,
              },
        ],
      ];
    }

    return columns;
  }, [submitters_type, unregisteredSubmitters?.length]);

  const submittersTableData = useMemo(() => {
    const data = [];
    if (!submission_settings) return data;

    const rowData = submittersTableColumns.reduce((acc, { accessorKey }) => {
      const value = get(submitters, `[${accessorKey}]`);
      if (Array.isArray(value) && value.length) {
        acc[accessorKey] = value;
      }
      return acc;
    }, {});

    if (Object.keys(rowData).length) {
      data.push(rowData);
    }

    return data;
  }, [submission_settings, submitters, submittersTableColumns]);

  const recipientsTableColumns = useMemo(
    () => [
      { accessorKey: 'facilities', header: 'Facility', cell: renderFacilityCell },
      { accessorKey: 'user_groups', header: 'User groups', cell: renderFacilityCell },
      { accessorKey: 'individual_users', header: 'Individual users', cell: renderIndividualUsersCell },
    ],
    [],
  );

  const recipientsTableData = useMemo(() => {
    return [
      recipientsTableColumns.reduce((acc, { accessorKey }) => {
        const value = get(recipient_settings, `[${accessorKey}]`);
        if (value) {
          acc[accessorKey] = value;
        }
        return acc;
      }, {}),
    ];
  }, [recipient_settings, recipientsTableColumns]);

  const notificationsTableColumns = useMemo(
    () => [
      { accessorKey: 'notification', header: 'Notification' },
      { accessorKey: 'notificationType', header: 'Notification type', cell: renderNotificationTypeCell },
      { accessorKey: 'notificationText', header: 'Notification text' },
    ],
    [],
  );

  const notificationsTableData = useMemo(() => {
    let data = undefined;
    if (notification_settings) {
      const {
        submitters_notification_type,
        submitters_notification_text,
        form_recipients_notification_text,
        form_recipients_notification_type,
        form_initiator_notification_text,
        form_initiator_notification_type,
      } = notification_settings;

      data = [
        ...(submitters_notification_type
          ? [
              {
                notification: 'Notification to form submitters',
                notificationType: submitters_notification_type ? String(submitters_notification_type).toLowerCase() : '-',
                notificationText: submitters_notification_text ?? '-',
              },
            ]
          : []),
        ...(form_recipients_notification_type
          ? [
              {
                notification: 'Notify recipients about filled form',
                notificationType: form_recipients_notification_type ? String(form_recipients_notification_type).toLowerCase() : '-',
                notificationText: form_recipients_notification_text ?? '-',
              },
            ]
          : []),
        ...(form_initiator_notification_type
          ? [
              {
                notification: 'Notify initiator about filled form',
                notificationType: form_initiator_notification_type ? String(form_initiator_notification_type).toLowerCase() : '-',
                notificationText: form_initiator_notification_text ?? '-',
              },
            ]
          : []),
      ];
    }
    return data;
  }, [notification_settings]);

  if (isOpen) {
    const renderAccessSettings = () => {
      let layout = null;
      if (accessSettingsData.length) {
        layout = (
          <div className={'settings-block'}>
            <h3 className="settings-block-title">{'Access settings'}</h3>
            <Table data={accessSettingsData} columns={accessSettingsColumns} />
          </div>
        );
      }
      return layout;
    };

    const renderSubmissionSettings = () => {
      let layout = null;
      if (submission_settings) {
        const { can_edit_previous_submission, order_of_submitters = [], only_one_submission } = submission_settings;
        const submittersType = submitters_type ? submitterTypesOptions.find((o) => o.value === submitters_type)?.label ?? '-' : '-';
        const contactPersonSubmitterType = contact_person_submitter_type
          ? contactPersonSubmitterTypes.find((o) => o.value === contact_person_submitter_type)?.label ?? '-'
          : '-';

        layout = (
          <div className={'settings-block'}>
            <h3 className="settings-block-title">{'Submission settings'}</h3>
            <div className="option">
              <div className="option-label">{'Form type: '}</div>
              <div className="option-value" style={{ textTransform: 'capitalize' }}>
                {formType}
              </div>
            </div>
            {isPublicForm && (
              <div className="option">
                <div className="option-label">{'Folder: '}</div>
                <div className="option-value">{folder ?? '-'}</div>
              </div>
            )}
            <>
              {!!submittersTableData.length && (
                <>
                  <div className="option">
                    <div className="option-label">{'Form submitters: '}</div>
                  </div>
                  <Table data={submittersTableData} columns={submittersTableColumns} />
                </>
              )}
              {!!order_of_submitters.length && (
                <div className="option">
                  <div className="option-label">{'Order of submitters: '}</div>
                  <div className="option-value">{order_of_submitters.map((item) => item)}</div>
                </div>
              )}
              <div className="option">
                <div className="option-label">{'Can submitters edit previous submissions?'}</div>
                <div className="option-value">{getQuestionAnswerByValue(can_edit_previous_submission)}</div>
              </div>
              <div className="option">
                <div className="option-label">{'Defined form submitters type:'}</div>
                <div className="option-value">{submittersType}</div>
              </div>

              {submitters_type === 'user' && (
                <div className="option">
                  <div className="option-label">{'Add this form to Statistics?'}</div>
                  <div className="option-value">{getQuestionAnswerByValue(include_in_statistics)}</div>
                </div>
              )}

              <div className="option">
                <div className="option-label">{'Specified submissions number:'}</div>
                <div className="option-value">{only_one_submission ? 'One' : 'Multiple'}</div>
              </div>
              {submitters_type === 'contact' && (
                <>
                  <div className="option">
                    <div className="option-label">{'Defined form receivers:'}</div>
                    <div className="option-value">{contactPersonSubmitterType}</div>
                  </div>
                </>
              )}
            </>
          </div>
        );
      }
      return layout;
    };

    const renderRecipientSettings = () => {
      const {
        facilities = [],
        individual_users = [],
        user_groups = [],
        can_initiator_select_recipients,
        recipients_only_from_initiator_facility,
      } = recipient_settings ?? {};
      return (
        <div className={'settings-block'}>
          <h3 className="settings-block-title">{'Recipient settings'}</h3>
          <div className="option">
            <div className="option-label">{'Can form initiator select form recipients?'}</div>
            <div className="option-value">{getQuestionAnswerByValue(can_initiator_select_recipients)}</div>
          </div>
          <div className="option">
            <div className="option-label">{'Can the initiator assign recipients only from their facility?'}</div>
            <div className="option-value">{getQuestionAnswerByValue(recipients_only_from_initiator_facility)}</div>
          </div>
          {(!!facilities?.length || !!individual_users?.length || !!user_groups?.length) && (
            <Table data={recipientsTableData} columns={recipientsTableColumns} />
          )}
        </div>
      );
    };

    const renderNotificationsSettings = () => {
      let layout = null;
      if (notificationsTableData?.length) {
        layout = (
          <div className={'settings-block'}>
            <h3 className="settings-block-title">{'Notification settings'}</h3>
            <Table columns={notificationsTableColumns} data={notificationsTableData} />
          </div>
        );
      }
      return layout;
    };

    const renderAvailabilitySettings = () => {
      let layout = null;
      if (hasPeriodOfAvailability) {
        layout = (
          <div className={'settings-block'}>
            <h3 className="settings-block-title">{'Availability settings'}</h3>
            <div className="option">
              <div className="option-label">{'Will the form be initiated immediately?'}</div>
              <div className="option-value">{getQuestionAnswerByValue(immediately_initiation)}</div>
            </div>
            {!immediately_initiation && start_date_time && end_date_time && (
              <>
                <div className="option">
                  <div className="option-label">{'Start day:'}</div>
                  <div className="option-value">{formatDateValue(start_date_time)}</div>
                </div>
                <div className="option">
                  <div className="option-label">{'Start time:'}</div>
                  <div className="option-value">{formatTimeValueByDateString(start_date_time)}</div>
                </div>
                {end_date_time && (
                  <>
                    <div className="option">
                      <div className="option-label">{'End day:'}</div>
                      <div className="option-value">{formatDateValue(end_date_time)}</div>
                    </div>
                    <div className="option">
                      <div className="option-label">{'End time:'}</div>
                      <div className="option-value">{formatTimeValueByDateString(end_date_time)}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        );
      }
      return layout;
    };

    const renderFrequencySettings = () => {
      let layout = null;
      if ((isGeneralForm || isPublicForm) && isExistValidFrequencySettings) {
        const {
          first_initiation_day,
          last_initiation_day,
          frequency_type,
          time,
          times,
          specific_dates,
          week_days,
          month_day,
          months,
          type,
        } = frequency_settings;
        const { deadline_type, time: deadlineTime, deadline_period_type, deadline_period } = deadline_settings ?? {};

        const frequencyType = frequency_type ? frequencyOptions.find((o) => o.value === frequency_type)?.label ?? '-' : '-';
        const customIntervalPeriodQuality = times ? qualityFrequencyOptions.find((o) => o.value === times)?.label ?? '-' : '-';
        const customIntervalPeriodUnit = type ? rangeFullOptions.find((o) => o.value === type)?.label ?? '-' : '-';
        const customIntervalWeekDays = Array.isArray(week_days) ? week_days.map((dayIndex) => `${weekDays[dayIndex]};`) : '-';
        const customIntervalMonthDays = Array.isArray(month_day) ? month_day.map((day) => `${day};`) : '-';
        const customIntervalYearMonths = Array.isArray(months) ? months.map((monthNum) => `${all_months[monthNum - 1]};`) : '-';
        const customDatesYearMonths = Array.isArray(specific_dates)
          ? specific_dates.map((dateString) => `${formatDateValue(dateString)};`)
          : '-';
        const deadlineType = deadline_type ? deadlineTypeOption.find((o) => o.value === deadline_type)?.label ?? '-' : '-';
        const deadlinePeriodType = deadline_period_type
          ? deadlineRangeOptions.find((o) => o.value === deadline_period_type)?.label ?? '-'
          : '-';
        // PUBLIC form
        const isPublicFormRecurrentInitiation = !!first_initiation_day;

        layout = (
          <div className={'settings-block'}>
            <h3 className="settings-block-title">{'Frequency settings'}</h3>

            {isPublicForm && (
              <>
                <div className="option">
                  <div className="option-label">{'One-time initiation?'}</div>
                  <div className="option-value">{getQuestionAnswerByValue(isPublicFormOneTimeInitiation)}</div>
                </div>
                <div className="option">
                  <div className="option-label">{'Recurrent initiation?'}</div>
                  <div className="option-value">{getQuestionAnswerByValue(isPublicFormRecurrentInitiation)}</div>
                </div>

                {isPublicFormOneTimeInitiation && !immediately_initiation && (
                  <>
                    <div className="option">
                      <div className="option-label">{'Start day:'}</div>
                      <div className="option-value">{formatDateValue(first_initiation_day)}</div>
                    </div>
                    <div className="option">
                      <div className="option-label">{'Start time:'}</div>
                      <div className="option-value">{formatTimeValueByDateString(last_initiation_day)}</div>
                    </div>
                  </>
                )}
              </>
            )}

            {isGeneralForm && (
              <>
                <div className="option">
                  <div className="option-label">{'Has period of availability?'}</div>
                  <div className="option-value">{getQuestionAnswerByValue(hasPeriodOfAvailability)}</div>
                </div>
                <div className="option">
                  <div className="option-label">{'Has frequency?'}</div>
                  <div className="option-value">{getQuestionAnswerByValue(isExistValidFrequencySettings)}</div>
                </div>

                {frequency_type && (
                  <>
                    <div className="option">
                      <div className="option-label">{'Frequency type:'}</div>
                      <div className="option-value">{frequencyType}</div>
                    </div>
                  </>
                )}

                {frequency_type === 'specific_dates' && (
                  <>
                    <div className="option">
                      <div className="option-label">{'Initiation dates:'}</div>
                      <div className="option-value">{customDatesYearMonths}</div>
                    </div>
                  </>
                )}
              </>
            )}

            {(isGeneralForm || isPublicForm) && (
              <>
                {frequency_type === 'custom_interval' && (
                  <>
                    <div className="option">
                      <div className="option-label">{'First initiation day:'}</div>
                      <div className="option-value">{formatDateValue(first_initiation_day)}</div>
                    </div>
                    <div className="option">
                      <div className="option-label">{'Last initiation day:'}</div>
                      <div className="option-value">{formatDateValue(last_initiation_day)}</div>
                    </div>
                    <div className="option">
                      <div className="option-label">{'Period quality:'}</div>
                      <div className="option-value">{customIntervalPeriodQuality}</div>
                    </div>
                    <div className="option">
                      <div className="option-label">{'Period:'}</div>
                      <div className="option-value">{customIntervalPeriodUnit}</div>
                    </div>
                    {type === 'weekly' && (
                      <>
                        <div className="option">
                          <div className="option-label">{'Repeat on these days:'}</div>
                          <div className="option-value">{customIntervalWeekDays}</div>
                        </div>
                      </>
                    )}
                    {type === 'yearly' && (
                      <>
                        <div className="option">
                          <div className="option-label">{'Repeat on these months:'}</div>
                          <div className="option-value">{customIntervalYearMonths}</div>
                        </div>
                      </>
                    )}
                    {(type === 'monthly' || type === 'yearly') && (
                      <>
                        <div className="option">
                          <div className="option-label">{'Initiation days:'}</div>
                          <div className="option-value">{customIntervalMonthDays}</div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {(frequency_type === 'specific_dates' || frequency_type === 'custom_interval') && (
                  <div className="option">
                    <div className="option-label">{'Initiation time:'}</div>
                    <div className="option-value">{time}</div>
                  </div>
                )}
              </>
            )}

            {deadline_type && (
              <>
                <div className="option">
                  <div className="option-label">{'Submission deadline:'}</div>
                  <div className="option-value">{deadlineType}</div>
                </div>
                {deadlineTime && (
                  <div className="option">
                    <div className="option-label">{'Submission end time:'}</div>
                    <div className="option-value">{deadlineTime}</div>
                  </div>
                )}
                {deadline_period_type && (
                  <>
                    <div className="option">
                      <div className="option-label">{'Deadline period:'}</div>
                      <div className="option-value">{deadlinePeriodType}</div>
                    </div>
                    <div className="option">
                      <div className="option-label">{'Deadline period quality:'}</div>
                      <div className="option-value">{deadline_period}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        );
      }
      return layout;
    };

    const renderArchiveSettings = () => {
      let layout = null;
      if (isPublicForm && archive_settings) {
        const { archive_datetime, archive_type, time_relation, time_unit, time_value } = archive_settings ?? {};
        const localArchiveDateTime = archive_datetime ? utcToLocalDate(archive_datetime) : undefined;
        const archiveTimeOffsetUnit = time_unit ? archiveTimeOffsetUnitsOptions.find((o) => o.value === time_unit)?.label ?? '-' : '-';
        const archiveRelativeToInitiation = time_relation
          ? archiveRelativeToInitiationOptions.find((o) => o.value === time_relation)?.label ?? '-'
          : '-';
        layout = (
          <div className={'settings-block'}>
            <h3 className="settings-block-title">{'Archive settings'}</h3>
            <div className="option">
              <div className="option-label">{'Automatic archiving?'}</div>
              <div className="option-value">{getQuestionAnswerByValue(isExistValidArchiveSettings)}</div>
            </div>
            {isExistValidArchiveSettings && (
              <>
                {archive_type === 'ONE_TIME' && archive_datetime && (
                  <>
                    <div className="option">
                      <div className="option-label">{'Archive date:'}</div>
                      <div className="option-value">{formatDateValue(localArchiveDateTime)}</div>
                    </div>
                    <div className="option">
                      <div className="option-label">{'Archive time:'}</div>
                      <div className="option-value">{formatTimeValueByDateString(localArchiveDateTime)}</div>
                    </div>
                  </>
                )}

                {archive_type === 'RECURRING' && (
                  <>
                    <div className="option">
                      <div className="option-label">{'Number:'}</div>
                      <div className="option-value">{time_value}</div>
                    </div>
                    <div className="option">
                      <div className="option-label">{'Hours / days:'}</div>
                      <div className="option-value">{archiveTimeOffsetUnit}</div>
                    </div>
                    <div className="option">
                      <div className="option-label">{'Before / after next initiation:'}</div>
                      <div className="option-value">{archiveRelativeToInitiation}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        );
      }
      return layout;
    };

    layout = (
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        className="modal-default form-management-settings-modal"
        overlayClassName={'form-management-settings-modal-overlay'}
        onRequestClose={onClose}
      >
        <h2 className="modal-header">
          {'Form Settings'}
          <button className="btn btn-icon" onClick={onClose}>
            <i className="icon-icon-x" />
          </button>
        </h2>
        <div className="modal-body">
          {renderAccessSettings()}
          {renderSubmissionSettings()}
          {renderRecipientSettings()}
          {renderNotificationsSettings()}
          {renderAvailabilitySettings()}
          {renderFrequencySettings()}
          {renderArchiveSettings()}
        </div>
      </Modal>
    );
  }

  return layout;
};

export default memo(FormSettingsModal);
