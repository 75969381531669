import React, { memo, useMemo, useState } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import { updateNATaskCommentService } from '../../../../../../services/cna/assignments/cna';
import { isResponseOk } from '../../../../../../utility/isResponseOk';
import './styles.scss';

const EditCommentModal = ({ commentData, onClose, onRefetchAssignment }) => {
  const { id: commentID, text: originalCommentText, taskID } = commentData;

  const [commentText, setCommentText] = useState(originalCommentText ?? '');

  const isValidNewComment = useMemo(() => {
    const trimComment = commentText.trim();
    const trimCommentLength = trimComment.length;
    return trimCommentLength && trimCommentLength < 255 && trimComment !== originalCommentText;
  }, [commentText, originalCommentText]);

  const handleSave = async () => {
    try {
      const res = await updateNATaskCommentService({ taskID, commentID, commentText });
      if (isResponseOk(res)) {
        onRefetchAssignment();
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeComment = (e) => setCommentText(e.target.value);

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      className={cx('modal-default edit-comment-modal')}
      overlayClassName={'modal-overlay'}
      onRequestClose={onClose}
    >
      <h2 className="modal-header">
        {'Edit Comment'}
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <div className="group">
          <div className="group__body" style={{ marginTop: 0 }}>
            <label className="form-label">Comment</label>
            <textarea
              value={commentText}
              className="form-control"
              placeholder="Enter comment"
              style={{
                fontWeight: 400,
                fontSize: '16px',
                fontFamily: 'Inter',
                lineHeight: '24px',
              }}
              onChange={handleChangeComment}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isValidNewComment} onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(EditCommentModal);
