export const openPDF = (blob) => {
  if (blob instanceof Blob) {
    const url = URL.createObjectURL(blob);
    const newWin = window.open(url, '_blank');
    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      const a = document.createElement('a');
      a.href = url;
      a.click();
      URL.revokeObjectURL(url);
    }
  }
};
