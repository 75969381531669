import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { validateEmail } from '../../utility';
import InputMask from 'react-input-mask';
import { createContacts, editContacts } from '../../services/contacts';
import { getCompanyUnit } from '../../services/company';

function UserModal({ isOpen, companyId, closeModal, companyName, userData, handelCreateUser, facilities }) {
  const [errorUser, setErrorUser] = useState({});

  const [facilityOptions, setFacilityOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    room_number: '',
    facility_id: '',
    unit_id: '',
    representatives: [],
  });

  useEffect(() => {
    if (userData) {
      const newState = [...userData.representatives];
      console.log(newState);
      setUser({
        ...userData,
        facility_id: userData.facility ? { value: userData.facility.id, label: userData.facility.name } : '',
        unit_id: userData.unit ? { value: userData.unit.id, label: userData.unit.name } : '',
        representatives: [...newState],
      });
    }
  }, [userData, isOpen]);

  useEffect(() => {
    if (user.facility_id && user.facility_id.value) {
      getCompanyUnit(companyId, 'getAll', user.facility_id.value)
        .then((res) => {
          setUnitOptions(res.map((item) => ({ value: item.id, label: item.name })));
        })
        .catch((e) => console.log(e));
    } else {
      setUnitOptions([]);
    }
    // eslint-disable-next-line
  }, [companyId, isOpen, user]);

  useEffect(() => {
    setFacilityOptions(facilities.filter((item) => !item.deleted_at).map((item) => ({ value: item.id, label: item.name })));
    // eslint-disable-next-line
  }, [facilities, isOpen]);

  const handleChange = (value, key) => {
    setUser({
      ...user,
      [key]: value,
    });

    if (key === 'facility_id' && value !== user.facility_id) {
      setUser({
        ...user,
        [key]: value,
        unit_id: '',
      });
    }

    if (key === 'email' && value) {
      if (validateEmail(value)) {
        delete errorUser[key];
      }
      return;
    }

    if (errorUser.hasOwnProperty(key)) {
      delete errorUser[key];
    }
  };

  const handleChangeRepresentative = (value, key, index) => {
    setUser((prevState) => {
      let newState = [...user.representatives];
      newState[index][key] = value;
      return {
        ...user,
        representatives: [...newState],
      };
    });
  };

  const resetForm = () => {
    setUser({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      room_number: '',
      facility_id: '',
      unit_id: '',
      representatives: [],
    });
    setErrorUser({});
  };

  const handleSubmit = () => {
    const formDate = { ...user };

    if (user.facility_id?.value) {
      formDate.facility_id = user.facility_id.value;
    }
    if (user.unit_id && user.unit_id.value) {
      formDate.unit_id = user.unit_id.value;
    }

    if (userData) {
      delete formDate.id;
      delete formDate.company;
      delete formDate.facility;
      delete formDate.facility_id;
      delete formDate.unit;
      delete formDate.deleted_at;
      editContacts(formDate, userData.id)
        .then((res) => {
          resetForm();
          handelCreateUser(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUser(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      createContacts(formDate)
        .then((res) => {
          resetForm();
          handelCreateUser(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUser(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };

  const addMoreRepresentative = () => {
    setUser({
      ...user,
      representatives: [
        ...user.representatives,
        {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          relation_with_resident: null,
        },
      ],
    });
  };
  const removeRepresentative = (index) => {
    let newState = [...user.representatives];
    newState.splice(index, 1);
    setUser({
      ...user,
      representatives: [...newState],
    });
  };
  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {userData ? 'Edit' : 'Add New'} Resident{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      {!!companyName && <h3 className="modal-subheader">for {companyName} company</h3>}
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-3.svg" alt="" />
          Resident information
        </h3>
        <div className={`form-item form-item-half ${errorUser.first_name ? 'form-item-error' : ''}`}>
          <label className="form-label">Resident Name</label>
          <input
            placeholder="Enter First Name"
            value={user.first_name}
            onChange={(e) => handleChange(e.target.value, 'first_name')}
            type="text"
            className="form-control"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.first_name && errorUser.first_name[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorUser.last_name ? 'form-item-error' : ''}`}>
          <label className="form-label">Resident Last Name</label>
          <input
            placeholder="Enter Last Name"
            value={user.last_name}
            onChange={(e) => handleChange(e.target.value, 'last_name')}
            type="text"
            className="form-control"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.last_name && errorUser.last_name[0]}</span>
        </div>
        <div className={`form-item form-item-half form-item-mail ${errorUser.email ? 'form-item-error' : ''}`}>
          <label className="form-label">
            Email <span className="optional">optional</span>
          </label>
          <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
          <input
            placeholder="mail@example.com"
            id="email"
            value={user.email}
            onChange={(e) => handleChange(e.target.value, 'email')}
            type="text"
            className="form-control"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.email && errorUser.email[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorUser.phone ? 'form-item-error' : ''}`}>
          <label className="form-label">Phone Number</label>
          <InputMask
            placeholder="XXX-XXX-XXXX"
            mask="999-999-9999"
            value={user.phone}
            onChange={(e) => handleChange(e.target.value, 'phone')}
            className="form-control"
          ></InputMask>

          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.phone && errorUser.phone[0]}</span>
        </div>
        <div className={`form-item form-item-select ${errorUser.facility_id ? 'form-item-error' : ''}`}>
          <label className="form-label">Facility</label>
          <Select
            isDisabled={!facilityOptions.length}
            options={facilityOptions}
            className="form-control-select"
            classNamePrefix="react-select"
            value={user.facility_id}
            placeholder="Select Facility"
            onChange={(e) => handleChange(e, 'facility_id')}
          ></Select>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.facility_id && errorUser.facility_id[0]}</span>
        </div>
        <div className={`form-item form-item-half form-item-select ${errorUser.unit_id ? 'form-item-error' : ''}`}>
          <label className="form-label">Unit</label>
          <Select
            isDisabled={!unitOptions.length}
            options={unitOptions}
            className="form-control-select"
            classNamePrefix="react-select"
            value={user.unit_id}
            placeholder="Select Unit"
            onChange={(e) => handleChange(e, 'unit_id')}
          ></Select>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.unit_id && errorUser.unit_id[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorUser.room_number ? 'form-item-error' : ''}`}>
          <label className="form-label">Room Number</label>
          <input
            placeholder="Enter Room Number"
            id="number"
            value={user.room_number}
            onChange={(e) => handleChange(e.target.value, 'room_number')}
            type="text"
            className="form-control"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.room_number && errorUser.room_number[0]}</span>
        </div>
      </div>

      {errorUser.non_field_errors && (
        <div className="form-error mb-4">
          <img className="mr-2" src="/images/red-alert.svg" alt="" /> {errorUser.non_field_errors[0]}
        </div>
      )}

      {user.representatives.map((item, index) => (
        <div key={index} className="form-wrapper">
          <h3 className="form-wrapper-title">
            <img src="/images/company-add-icon-3.svg" alt="" />
            Representative information{' '}
            <button className="btn btn-icon mr-a" onClick={() => removeRepresentative(index)}>
              <i className="icon-remove"></i>
            </button>
          </h3>
          <div
            className={`form-item form-item-half ${errorUser.representatives && errorUser.representatives[index].first_name ? 'form-item-error' : ''}`}
          >
            <label className="form-label">Representative Name</label>
            <input
              placeholder="Enter First Name"
              value={item.first_name}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'first_name', index)}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">
              {errorUser.representatives && errorUser.representatives[index]?.first_name && errorUser.representatives[index].first_name[0]}
            </span>
          </div>
          <div
            className={`form-item form-item-half ${errorUser.representatives && errorUser.representatives[index]?.last_name ? 'form-item-error' : ''}`}
          >
            <label className="form-label">Representative Last Name</label>
            <input
              placeholder="Enter Last Name"
              value={item.last_name}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'last_name', index)}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">
              {errorUser.representatives && errorUser.representatives[index]?.last_name && errorUser.representatives[index].last_name[0]}
            </span>
          </div>
          <div
            className={`form-item form-item-half form-item-mail ${errorUser.representatives && errorUser.representatives[index]?.email ? 'form-item-error' : ''}`}
          >
            <label className="form-label">
              Email <span className="optional">optional</span>
            </label>
            <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
            <input
              placeholder="mail@example.com"
              id="email"
              value={item.email}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'email', index)}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">
              {errorUser.representatives && errorUser.representatives[index]?.email && errorUser.representatives[index].email[0]}
            </span>
          </div>
          <div
            className={`form-item form-item-half ${errorUser.representatives && errorUser.representatives[index]?.phone ? 'form-item-error' : ''}`}
          >
            <label className="form-label">Phone Number</label>
            <InputMask
              placeholder="XXX-XXX-XXXX"
              mask="999-999-9999"
              value={item.phone}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'phone', index)}
              className="form-control"
            ></InputMask>

            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">
              {errorUser.representatives && errorUser.representatives[index]?.phone && errorUser.representatives[index].phone[0]}
            </span>
          </div>

          <div
            className={`form-item form-item-select ${errorUser.representatives && errorUser.representatives[index]?.relation_with_resident ? 'form-item-error' : ''}`}
          >
            <label className="form-label">Relation with Resident</label>
            <input
              placeholder="Enter Relation"
              value={item.relation_with_resident}
              onChange={(e) => handleChangeRepresentative(e.target.value, 'relation_with_resident', index)}
              type="text"
              className="form-control"
            />

            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">
              {errorUser.representatives &&
                errorUser.representatives[index]?.relation_with_resident &&
                errorUser.representatives[index].relation_with_resident[0]}
            </span>
          </div>
        </div>
      ))}

      {user.representatives.length < 2 && (
        <span className="add-facility cursor-pointer" onClick={() => addMoreRepresentative()}>
          <i className="icon-plus-svgrepo-com"></i> Add Representative
        </span>
      )}

      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default UserModal;
