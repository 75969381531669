import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../container/authContext';
import { usePermission } from '../../../container/permissionContext';
import { ReactComponent as CorporateLibraryIcon } from '../../../assets/images/corporate-library.svg';
import { ReactComponent as CNAAssignmentsIcon } from '../../../assets/images/cna-assignments.svg';
import { STATIC_ROUTES } from '../../../constants/routes';
import './styles.scss';

const SideBarMenu = () => {
  const { user } = useAuth();
  const { isSuperAdmin, isEmployee, hasPermissionByCodeName, hasPermission, isCompanySystemAdministrator } = usePermission();

  const allowedCNA = useMemo(() => {
    let isAllowed = false;
    if (user) {
      const { company, user_groups = [] } = user;
      if (company) {
        const isAdmin = isSuperAdmin() || isCompanySystemAdministrator();
        if (isAdmin) {
          isAllowed = isAdmin;
        } else {
          const isNurse = user_groups.findIndex(({ name }) => name === 'Nurse') !== -1;
          isAllowed = hasPermissionByCodeName('corporate_library_management') || isNurse || user.is_unit_user;
        }
      }
    }
    return isAllowed;
  }, [hasPermissionByCodeName, isCompanySystemAdministrator, isSuperAdmin, user]);

  return (
    <div className="tablet-sidebar">
      <ul className="tablet-sidebar-menu">
        {!isSuperAdmin() && !user.is_unit_user && (
          <li className={`tablet-sidebar-menu-item ${location.pathname.includes('corporate-library') ? 'active' : ''}`}>
            <Link to={STATIC_ROUTES.CORPORATE_LIBRARY} className="tablet-sidebar-menu-link">
              <CorporateLibraryIcon />
              <span>Corporate Library</span>
            </Link>
          </li>
        )}

        {allowedCNA && (
          <li className={`tablet-sidebar-menu-item ${location.pathname.includes('/cna') ? 'active' : ''}`}>
            <Link to={STATIC_ROUTES.CNA_ASSIGNMENTS} className="tablet-sidebar-menu-link">
              <CNAAssignmentsIcon />
              <span>CNA Assignments</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SideBarMenu;
