import React from 'react';

export const LoginElement = function FromElement({ element }) {
  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
      </label>
      <input
        name={element.options.name}
        id={element.id}
        type="text"
        readOnly
        className={`form-control form-control-${element.options.size}`}
      />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
