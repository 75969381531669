import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cx from 'classnames';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isTablet } from 'react-device-detect';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ExportPDFIcon } from '../../../../assets/images/download-icon.svg';
import { ReactComponent as SaveTemplateIcon } from '../../../../assets/images/save-template.svg';
import { ReactComponent as TemplatesListIcon } from '../../../../assets/images/template-lists.svg';
import { ReactComponent as UpdateTemplateIcon } from '../../../../assets/images/update-template.svg';
import ContextMenu from '../../../../components/other/context-menu';
import PageLoader from '../../../../components/other/PageLoader';
import { WEEKDAYS } from '../../../../constants';
import { STATIC_ROUTES } from '../../../../constants/routes';
import { useAuth } from '../../../../container/authContext';
import { usePermission } from '../../../../container/permissionContext';
import useOutsideClick from '../../../../hook/useOutsideClick';
import Layout from '../../../../layout/default';
import {
  assignCNAToShiftRoomService,
  deleteCNARoomTaskService,
  deleteCNATaskCommentService,
  deleteShiftCNAService,
  getCNAAssignmentService,
  getCNANursesService,
  getCNAsService,
  updateCNAAssignmentShiftService,
  updateTemplateService,
} from '../../../../services/cna/assignments/cna';
import { isResponseOk } from '../../../../utility/isResponseOk';
import AddNewTemplateModal from './components/add-new-template-modal';
import TaskCommentsModal from './components/add-task-comment-modal';
import ChangeCNAModal from './components/change-cna-modal';
import EditCommentModal from './components/edit-comment-modal';
import ExportToPdfModal from './components/export-to-pdf-modal';
import ManageCNAsModal from './components/manage-cnas-modal';
import ManageNursesModal from './components/manage-nurses-modal';
import EditTaskModal from './components/manage-task-modal/EditTasksModal';
import AddTasksModal from './components/manage-task-modal/AddTasksModal';
import DeleteTaskModal from './components/delete-task-modal';
import TemplatesListModal from './components/templates-list-modal';
import { cnaColors, tableColumns } from './constants';
import './styles.scss';
import { extractBedParts } from '../../../../utility';

const StaffEditBtn = ({ onClick }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    onClick={onClick}
  >
    <g clipPath="url(#clip0_10626_59049)">
      <path
        d="M9.16675 3.33027H3.33341C2.89139 3.33027 2.46746 3.50586 2.1549 3.81842C1.84234 4.13098 1.66675 4.55491 1.66675 4.99693V16.6636C1.66675 17.1056 1.84234 17.5296 2.1549 17.8421C2.46746 18.1547 2.89139 18.3303 3.33341 18.3303H15.0001C15.4421 18.3303 15.866 18.1547 16.1786 17.8421C16.4912 17.5296 16.6667 17.1056 16.6667 16.6636V10.8303M15.4167 2.08027C15.7483 1.74875 16.1979 1.5625 16.6667 1.5625C17.1356 1.5625 17.5852 1.74875 17.9167 2.08027C18.2483 2.41179 18.4345 2.86143 18.4345 3.33027C18.4345 3.79911 18.2483 4.24875 17.9167 4.58027L10.0001 12.4969L6.66675 13.3303L7.50008 9.99693L15.4167 2.08027Z"
        stroke="#7F56D9"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10626_59049">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const CNAUnitPage = () => {
  let previousRowSelected = null;
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const filterIsActive = queryParams.get('is_active');

  const { user = {} } = useAuth();
  const { isSuperAdmin, isCompanySystemAdministrator, hasPermissionByCodeName } = usePermission();
  const { assignmentID } = useParams();
  const tableRef = useRef(null);
  const staffCNAListRef = useRef(null);

  const { state: locationState } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [nursesSuggest, setNursesSuggest] = useState(null);
  const [cnaSuggest, setCNAsSuggest] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [activeShiftID, setActiveShiftID] = useState(null);
  const [isOpenManageNursesModal, setIsOpenManageNursesModal] = useState(false);
  const [isOpenManageCNAsModal, setIsOpenManageCNAsModal] = useState(false);
  const [changeCNAID, setChangeCNAID] = useState(null);
  const [editTaskData, setEditTaskData] = useState(null);
  const [selectedSiftRooms, setSelectedSiftRooms] = useState(new Set());
  const [taskCommentsModalData, setTaskCommentsModalData] = useState(null);
  const [addNewTemplateModalData, setAddNewTemplateModalData] = useState(null);
  const [isOpenTemplatesListModal, setIsOpenTemplatesListModal] = useState(false);
  const [deleteTaskModalData, setDeleteTaskModalData] = useState(null);
  const [isOpenExportToPdfModal, seIsOpenExportToPdfModal] = useState(false);
  const [editCommentModalData, setEditCommentModalData] = useState(null);
  const [addTasksModalData, setAddTasksModalData] = useState(null);
  const isOpenEditTaskModal = !!editTaskData;
  const isOpenAddTasksModal = !!addTasksModalData;
  const [orderSettings, setOrderSettings] = useState({ field: 'room', order: 'asc' });
  const { date: assignmentDate, shifts = [], unit: assignmentUnit } = assignment ?? {};
  const { is_unit_user, user_groups = [] } = user;

  const hasTemplateWritePermission = useMemo(() => {
    return !!(isSuperAdmin() || isCompanySystemAdministrator() || hasPermissionByCodeName('cna_templates_management'));
  }, [user, isSuperAdmin, isCompanySystemAdministrator, hasPermissionByCodeName]);

  const dateTitle = useMemo(() => {
    if (assignmentDate) {
      const date = new Date(assignmentDate);
      return `${WEEKDAYS[date.getDay()].slice(0, 3)}, ${assignmentDate}`;
    }
    return '';
  }, [assignmentDate]);

  const handleTableOutsideClick = () => setSelectedSiftRooms(new Set());

  useOutsideClick([tableRef, staffCNAListRef], handleTableOutsideClick);

  const activeShift = useMemo(() => {
    let shift = {};
    if (activeShiftID && shifts.length) {
      shift = shifts.find(({ id }) => id === activeShiftID);
    }
    return shift || {};
  }, [activeShiftID, shifts]);

  const { is_active: isActiveShift, nurses: activeShiftNurses, cna } = activeShift;

  const isCurrentShiftActive = () => {
    const now = new Date();
    const nyDate = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(now);

    const nyHours = parseInt(
      new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/New_York',
        hour: 'numeric',
        hour12: false,
      }).format(now),
      10,
    );

    const getYesterdayStr = () => {
      const dateParts = nyDate.split('/');
      const date = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
      date.setDate(date.getDate() - 1);
      return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
    };

    const yesterdayStr = getYesterdayStr();

    if (activeShift && activeShift.is_active == true) {
      switch (activeShift.time_type) {
        case 'SHIFT 7-3': {
          return assignment.date == nyDate && nyHours >= 7 && nyHours < 15;
        }
        case 'SHIFT 3-11': {
          return assignment.date == nyDate && nyHours >= 15 && nyHours < 23;
        }
        case 'SHIFT 11-7': {
          return (assignment.date == yesterdayStr && nyHours < 7) || (assignment.date == nyDate && nyHours >= 23);
        }
        default: {
          return false;
        }
      }
    }
  };

  const activeShiftRecords = useMemo(() => {
    return activeShift?.records || [];
  }, [activeShift]);

  // Based on permissions and current shift status
  const isEditableAssignment = useMemo(() => {
    return isActiveShift && user.has_cna_permission;
  }, [isActiveShift]);

  const canManageTasks = useMemo(() => {
    return isActiveShift && user.has_cna_permission && !is_unit_user;
  }, [isActiveShift, is_unit_user]);

  const activeShiftCNA = useMemo(() => {
    return cna?.map((item, index) => ({ ...item, colors: cnaColors[index] }));
  }, [cna]);

  const activeShiftCNAColors = useMemo(() => {
    return (
      activeShiftCNA?.reduce((acc, { id, colors }) => {
        acc[id] = colors;
        return acc;
      }, {}) ?? {}
    );
  }, [activeShiftCNA]);

  const table = useReactTable({
    data: activeShiftRecords,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const getActiveShiftRoomIDByIndex = useCallback((rowIndex) => activeShiftRecords[rowIndex]?.id, [activeShiftRecords]);

  const getInitialActiveShiftID = (assignmentResponse) => {
    const shouldActiveBeArchivedShift = locationState?.status == 'archived';
    const nextIndex = locationState?.nextIndex;

    if (shouldActiveBeArchivedShift) {
      return get(assignmentResponse, 'data.shifts[0].id');
    }

    if (filterIsActive === 'false' && nextIndex !== undefined) {
      const index = nextIndex || 0;

      if (index == -1) {
        return get(assignmentResponse, 'data.shifts', []).slice(-1)[0]?.id;
      }

      return get(assignmentResponse, `data.shifts[${index}].id`);
    }

    if (filterIsActive === 'true' && nextIndex !== undefined) {
      const index = nextIndex || 0;

      if (index == -1) {
        return get(assignmentResponse, 'data.shifts', []).slice(-1)[0]?.id;
      }

      return get(assignmentResponse, `data.shifts[${index}].id`);
    }

    const now = new Date();
    const nyDate = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(now);

    const nyHours = parseInt(
      new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/New_York',
        hour: 'numeric',
        hour12: false,
      }).format(now),
      10,
    );

    const responseDate = assignmentResponse.data.date;

    let defaultShiftID = get(assignmentResponse, 'data.shifts[0].id');

    const getYesterdayStr = () => {
      const dateParts = nyDate.split('/');
      const date = new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
      date.setDate(date.getDate() - 1);
      return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
    };

    const yesterdayStr = getYesterdayStr();

    if ((nyHours < 7 && responseDate == yesterdayStr) || (nyHours >= 23 && responseDate == nyDate)) {
      const checkShift = get(assignmentResponse, `data.shifts[2]`);
      return checkShift?.is_active ? checkShift.id : defaultShiftID;
    } else if (nyHours >= 15 && nyHours < 23 && responseDate == nyDate) {
      const checkShift = get(assignmentResponse, `data.shifts[1]`);
      return checkShift?.is_active ? checkShift.id : defaultShiftID;
    }

    return defaultShiftID;
  };

  const isNextButtonDisabled = useMemo(() => {
    if (!activeShiftID) {
      return true;
    }
    const isLastShift = shifts[shifts.length - 1]?.id === activeShiftID;
    const nextShiftActiveStatus = assignment?.next_shift?.is_active;
    const activeShiftIndex = shifts.findIndex((item) => item.id === activeShiftID);

    if (filterIsActive == 'true') {
      return isLastShift && nextShiftActiveStatus !== true;
    } else if (filterIsActive == 'false') {
      return (
        (isLastShift && nextShiftActiveStatus !== false) ||
        (shifts.length && !isLastShift && shifts[activeShiftIndex + 1].is_active === true)
      );
    } else {
      return isLastShift && nextShiftActiveStatus === undefined;
    }
  }, [shifts, activeShiftID, assignment, filterIsActive]);

  const isPreviousButtonDisabled = useMemo(() => {
    if (!activeShiftID) {
      return true;
    }

    const isFirstShift = shifts[0]?.id === activeShiftID;
    const prevShiftActiveStatus = assignment?.previous_shift?.is_active;
    const activeShiftIndex = shifts.findIndex((item) => item.id === activeShiftID);

    if (filterIsActive == 'true') {
      return (
        (isFirstShift && prevShiftActiveStatus !== true) ||
        (shifts.length && !isFirstShift && shifts[activeShiftIndex - 1].is_active === false)
      );
    } else if (filterIsActive == 'false') {
      return isFirstShift && prevShiftActiveStatus !== false;
    } else {
      return isFirstShift && prevShiftActiveStatus === undefined;
    }
  }, [shifts, activeShiftID, assignment, filterIsActive]);

  const handleGetAssignment = useCallback(async () => {
    const assignmentResponse = await getCNAAssignmentService(assignmentID, orderSettings);
    if (isResponseOk(assignmentResponse) && assignmentResponse.data) {
      const { data } = assignmentResponse;
      setAssignment({
        ...data,
        shifts: data.shifts?.map((item, index) => ({ ...item, index })),
      });
      if (typeof activeShiftID !== 'number') {
        setActiveShiftID(getInitialActiveShiftID(assignmentResponse));
      }
    }
  }, [activeShiftID, assignmentID, JSON.stringify(orderSettings)]);

  useEffect(() => {
    (async () => {
      try {
        if (assignmentID) {
          setIsLoading(true);

          // Execute both requests in parallel
          const [, { value: cnasResponse }, { value: nursesResponse }] = await Promise.allSettled([
            handleGetAssignment(),
            getCNAsService(assignmentID),
            getCNANursesService(assignmentID),
          ]);

          if (isResponseOk(cnasResponse) && cnasResponse.data) {
            setCNAsSuggest(cnasResponse.data);
          }
          if (isResponseOk(nursesResponse) && nursesResponse.data) {
            setNursesSuggest(nursesResponse.data);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [assignmentID, handleGetAssignment]);

  const handleChangeShift = (shiftIndex) => {
    if (shiftIndex >= 0 && shiftIndex <= 2) {
      if (shifts.length < shiftIndex + 1 && assignment?.next_assignment_id) {
        navigate(`/cna/assignments/${assignment.next_assignment_id}?is_active=${filterIsActive}`, { state: { nextIndex: 0 } });
        setActiveShiftID(assignment.next_shift_id);
      } else {
        const newActiveShift = shifts[shiftIndex];
        setActiveShiftID(newActiveShift.id);
      }
    } else if (shiftIndex < 0 && assignment?.previous_assignment_id) {
      navigate(`/cna/assignments/${assignment.previous_assignment_id}?is_active=${filterIsActive}`, { state: { nextIndex: -1 } });
      setActiveShiftID(assignment.previous_shift_id);
    } else if (shiftIndex > 2 && assignment?.next_assignment_id) {
      navigate(`/cna/assignments/${assignment.next_assignment_id}?is_active=${filterIsActive}`, { state: { nextIndex: 0 } });
      setActiveShiftID(assignment.next_shift_id);
    }
  };

  const handleUpdateStaff = useCallback(
    async (key, value) => {
      try {
        setIsLoading(true);

        const data = {
          shiftID: activeShiftID,
          nurses: activeShiftNurses.map(({ id }) => ({ id })),
          cna: activeShiftCNA.map(({ id }) => ({ id })),
          [key]: value,
        };

        const res = await updateCNAAssignmentShiftService(data);
        if (isResponseOk(res)) {
          await handleGetAssignment();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [activeShiftCNA, activeShiftID, activeShiftNurses, handleGetAssignment],
  );

  const handleDeleteCNA = useCallback(
    async (cnaID) => {
      try {
        setIsLoading(true);
        const res = await deleteShiftCNAService({ shiftID: activeShiftID, cnaID });
        if (isResponseOk(res)) {
          await handleGetAssignment();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [activeShiftID, handleGetAssignment],
  );

  const toggleTableRow = (rowIndex, add) => {
    const rowID = activeShiftRecords[rowIndex]?.id;

    if (typeof rowID === 'number') {
      setSelectedSiftRooms((prevState) => {
        const newSelectedRows = new Set(prevState);

        if (add) {
          newSelectedRows.add(rowID);
        } else {
          newSelectedRows.delete(rowID);
        }

        return newSelectedRows;
      });
    }
  };

  const handleTableRowMouseDown = (rowIndex) => {
    if (isActiveShift && activeShiftCNA?.length) {
      toggleTableRow(rowIndex, true);

      const handleMouseEnter = (e) => {
        const rowElement = e.target.closest('tr');

        if (rowElement) {
          const toIndex = Number(rowElement.dataset.index);
          if (toIndex !== undefined) {
            const minIndex = Math.min(rowIndex, toIndex);
            const maxIndex = Math.max(rowIndex, toIndex);

            const newSelectedRows = new Set();
            for (let i = minIndex; i <= maxIndex; i++) {
              const rowID = getActiveShiftRoomIDByIndex(i);
              if (typeof rowID === 'number') {
                newSelectedRows.add(rowID);
              }
            }
            setSelectedSiftRooms(newSelectedRows);
          }
        }
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseEnter);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseEnter);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };

  const handleTableRowTouchMove = (e, rowIndex) => {
    const touch = e.touches[0];
    const x = touch.clientX;
    const y = touch.clientY;

    const rowElementFromPoint = document.elementFromPoint(x, y);

    // Disables scrolling
    e.preventDefault();

    if (rowElementFromPoint) {
      const rowElement = document.elementFromPoint(x, y).closest('tr');

      const viewportHeight = window.innerHeight;

      if (viewportHeight - y < viewportHeight * 0.15) {
        window.scrollBy({ top: 200, behavior: 'smooth' });
      }

      if (y < viewportHeight * 0.15) {
        window.scrollBy({ top: -200, behavior: 'smooth' });
      }

      if (rowElement) {
        const toIndex = Number(rowElement.dataset.index);
        if (toIndex !== undefined) {
          const minIndex = Math.min(rowIndex, toIndex);
          const maxIndex = Math.max(rowIndex, toIndex);

          const newSelectedRows = new Set();
          for (let i = minIndex; i <= maxIndex; i++) {
            const rowID = getActiveShiftRoomIDByIndex(i);
            if (typeof rowID === 'number') {
              newSelectedRows.add(rowID);
            }
          }
          setSelectedSiftRooms(newSelectedRows);
        }
      }
    }
  };

  const handleTableRowTouchStart = (e, rowIndex) => {
    const tableRowTouchMoveEventHandler = (e) => {
      handleTableRowTouchMove(e, rowIndex);
    };

    const handleTabletRowSelect = (rowIndex) => {
      document.body.style.overflow = 'hidden';
      if (isActiveShift && activeShiftCNA?.length) {
        toggleTableRow(rowIndex, true);

        document.addEventListener('touchmove', tableRowTouchMoveEventHandler, {
          passive: false,
        });
      }
    };

    const timeoutId = setTimeout(() => {
      handleTabletRowSelect(rowIndex);
      document.removeEventListener('touchmove', onTouchMove);
    }, 500);

    const onTouchMove = () => {
      clearTimeout(timeoutId);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', handleTableRowTouchEnd);
    };

    const handleTableRowTouchEnd = (e, rowIndex) => {
      document.body.style.overflow = 'visible';
      document.removeEventListener('touchmove', tableRowTouchMoveEventHandler);
      document.removeEventListener('touchend', handleTableRowTouchEnd);
      clearTimeout(timeoutId);
    };

    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', handleTableRowTouchEnd);
  };

  const handleTableRowClick = (rowIndex) => {
    if (isActiveShift && activeShiftCNA?.length) {
      const newSelectedRows = new Set();
      const currentRowID = getActiveShiftRoomIDByIndex(rowIndex);
      newSelectedRows.add(currentRowID);
      setSelectedSiftRooms(newSelectedRows);
    }
  };

  const handleAssignCNAToRoom = async (cnaID) => {
    if (selectedSiftRooms.size) {
      try {
        setIsLoading(true);

        const cnaData = {
          cna_id: cnaID,
          shift_records_ids: Array.from(selectedSiftRooms),
        };

        const res = await assignCNAToShiftRoomService({ shiftID: activeShiftID, cnaData });
        if (isResponseOk(res)) {
          await handleGetAssignment();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDeleteRoomTask = useCallback(async () => {
    try {
      const res = await deleteCNARoomTaskService(deleteTaskModalData);
      if (isResponseOk(res)) {
        await handleGetAssignment();
      }
    } catch (e) {
      console.error(e);
    }
  }, [handleGetAssignment, deleteTaskModalData]);

  const handleSetTaskCommentsData = (roomID, { id, name }) => {
    const data = { id, name, roomID };
    setTaskCommentsModalData(data);
  };

  const handleDeleteComment = async (taskID, commentID) => {
    try {
      setIsLoading(true);
      const res = await deleteCNATaskCommentService({ taskID, commentID });
      if (isResponseOk(res)) {
        await handleGetAssignment();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const renderHeaderShifts = () => {
    const { time_type, index: activeShiftIndex } = activeShift;

    return isTablet ? (
      <div className={'header__shifts'}>
        <button
          className={`btn btn-outline pagination-btn ${isPreviousButtonDisabled && 'disabled'}`}
          disabled={isPreviousButtonDisabled}
          onClick={() => handleChangeShift(activeShiftIndex - 1)}
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.8334 6.9974H1.16675M1.16675 6.9974L7.00008 12.8307M1.16675 6.9974L7.00008 1.16406"
              stroke="#344054"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className={cx('header__shifts__item', { archive: !isActiveShift })}>
          <span className={'date'}>{dateTitle}:</span>
          <span className={'name'}>{time_type}</span>
          {isActiveShift == false && <span className={'archive-shift-label'}>Archive</span>}
          {isCurrentShiftActive() && (
            <span className="archive-shift-label" style={{ color: 'green' }}>
              Current
            </span>
          )}
        </div>
        <button
          className={`btn btn-outline pagination-btn ${isNextButtonDisabled && 'disabled'}`}
          disabled={isNextButtonDisabled}
          onClick={() => handleChangeShift(activeShiftIndex + 1)}
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.16675 6.9974H12.8334M12.8334 6.9974L7.00008 1.16406M12.8334 6.9974L7.00008 12.8307"
              stroke="#344054"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    ) : (
      <div className={'header__shifts'}>
        <button
          className={'btn btn-outline pagination-btn'}
          disabled={isPreviousButtonDisabled}
          onClick={() => handleChangeShift(activeShiftIndex - 1)}
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.8334 6.9974H1.16675M1.16675 6.9974L7.00008 12.8307M1.16675 6.9974L7.00008 1.16406"
              stroke="#344054"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Previous
        </button>
        <div className={cx('header__shifts__item', { archive: !isActiveShift })}>
          <span className={'date'}>{assignmentDate}:</span>
          <span className={'name'}>{time_type}</span>
          {isActiveShift == false && <span className={'archive-shift-label'}>Archive</span>}
          {isCurrentShiftActive() && (
            <span className="archive-shift-label" style={{ color: 'green' }}>
              Current
            </span>
          )}
        </div>
        <button
          className={'btn btn-outline pagination-btn'}
          disabled={isNextButtonDisabled}
          onClick={() => handleChangeShift(activeShiftIndex + 1)}
        >
          Next
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.16675 6.9974H12.8334M12.8334 6.9974L7.00008 1.16406M12.8334 6.9974L7.00008 12.8307"
              stroke="#344054"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };

  const handleUpdateTemplate = useCallback(async () => {
    try {
      if (!activeShift?.template?.id) {
        return;
      }

      setIsLoading(true);
      const res = await updateTemplateService({ shiftID: activeShift.id, templateID: activeShift.template.id });
      if (isResponseOk(res)) {
        await handleGetAssignment();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [activeShift, handleGetAssignment]);

  const applySort = (field) => {
    if (orderSettings.field == field) {
      setOrderSettings((prevState) => ({ field, order: prevState.order == 'desc' ? 'asc' : 'desc' }));
    } else {
      setOrderSettings({ field, order: 'asc' });
    }
  };

  useEffect(() => {
    handleGetAssignment();
  }, [JSON.stringify(orderSettings), handleGetAssignment]);

  const shiftActionsContextMenu = useMemo(() => {
    let menu = [];

    if (
      (activeShift?.cna?.length || activeShift?.records?.some((record) => record.tasks && record.tasks.length > 0)) &&
      hasTemplateWritePermission
    ) {
      menu.push({
        label: 'Save as a new template',
        icon: <SaveTemplateIcon />,
        action: ({ onCloseMenu }) => {
          setAddNewTemplateModalData({ shiftID: activeShift?.id, action: 'add' });
          onCloseMenu();
        },
      });
    }

    if (activeShift?.template && hasTemplateWritePermission) {
      menu.push({
        label: 'Update the applied template',
        icon: <UpdateTemplateIcon />,
        action: ({ onCloseMenu }) => {
          handleUpdateTemplate();
          onCloseMenu();
          toast('Template updated successfully', {
            icon: () => <img src="/images/full-check-circle.svg" />,
            closeButton: ({ closeToast }) => (
              <button onClick={closeToast} className="btn btn-icon close-alert-btn">
                <img src="/images/x-icon.svg" />
              </button>
            ),
            className: 'template-updated-alert',
            toastClassName: 'template-updated-alert-wrapper',
          });
        },
      });
    }

    menu.push({
      label: 'Templates list',
      icon: <TemplatesListIcon />,
      action: ({ onCloseMenu }) => {
        setIsOpenTemplatesListModal(true);
        onCloseMenu();
      },
    });

    return <ContextMenu menu={menu} styles={{ width: 56, height: 44 }} />;
  }, [activeShift, handleUpdateTemplate, hasTemplateWritePermission]);

  const renderHeader = () => {
    return (
      <header>
        <div className="header__side left">
          <div className={'header__facility'}>
            <span>{assignmentUnit?.facility ? `${assignmentUnit.facility.name}` : '-'}</span>
          </div>
          <a className={'header__back-link'} href={STATIC_ROUTES.CNA_ASSIGNMENTS}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.8333 9.9974H4.16663M4.16663 9.9974L9.99996 15.8307M4.16663 9.9974L9.99996 4.16406"
                stroke="#101828"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {assignmentUnit?.name ?? '-'}
          </a>
        </div>
        <div className="header__side right">
          {renderHeaderShifts()}
          {!isTablet && (
            <div className="d-flex shift-actions-block">
              <button className={'btn'} disabled={!assignment} onClick={() => seIsOpenExportToPdfModal(true)}>
                Export to PDF
              </button>
              {activeShift?.is_active && !is_unit_user && shiftActionsContextMenu}
            </div>
          )}
        </div>
      </header>
    );
  };

  const renderCNAContextMenu = (cnaID) => {
    const menu = [
      {
        label: 'Change CNA',
        icon: <i className="icon-icon-edit" />,
        action: ({ onCloseMenu }) => {
          setChangeCNAID(cnaID);
          onCloseMenu();
        },
      },
      {
        label: 'Delete',
        color: '#B32318',
        icon: <i className="icon-remove" />,
        action: ({ onCloseMenu }) => {
          onCloseMenu();
          handleDeleteCNA(cnaID);
        },
      },
    ];

    return <ContextMenu size={'small'} menu={menu} styles={{ background: 'transparent', border: 'none', boxShadow: 'none' }} />;
  };

  const renderStaff = () => {
    return isTablet ? (
      <div className={'staff'}>
        <div className="staff__flex">
          <div className="left-block">
            <div className="staff__nurses__block">
              <div className="staff__block__title">
                <span className="nurses_title">Nurses</span>
                {isEditableAssignment && !is_unit_user && <StaffEditBtn onClick={() => setIsOpenManageNursesModal(true)} />}
              </div>
              <div className="staff__block__content">
                <div className="staff__nurses">
                  {activeShiftNurses?.length ? (
                    activeShiftNurses?.map(({ id, full_name }) => (
                      <span className="staff__nurses__item" key={id}>
                        {full_name};
                      </span>
                    ))
                  ) : (
                    <div>No selected nurses yet</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="right-block">
            <button className="btn" disabled={!assignment} onClick={() => seIsOpenExportToPdfModal(true)}>
              <ExportPDFIcon />
            </button>
            {activeShift?.is_active && shiftActionsContextMenu}
          </div>
        </div>
        <div className="staff__cna__block">
          <div className="staff__block">
            <div className="staff__block__title">
              {'CNA'}
              {isEditableAssignment && !is_unit_user && <StaffEditBtn onClick={() => setIsOpenManageCNAsModal(true)} />}
            </div>
            <div className="staff__block__content staff__block_cna" style={{ minHeight: 44 }}>
              <div className="staff__cna-list" ref={staffCNAListRef}>
                {activeShiftCNA?.length ? (
                  activeShiftCNA.map(({ id, full_name, colors: { bg: bgColor, border: borderColor } }) => {
                    return (
                      <div
                        className={'staff__cna-item'}
                        key={id}
                        style={{
                          backgroundColor: bgColor,
                          borderColor: borderColor,
                          paddingRight: isEditableAssignment && !is_unit_user ? undefined : 10,
                        }}
                      >
                        <span className={'cna-name'} onClick={() => handleAssignCNAToRoom(id)}>
                          {full_name}
                        </span>
                        {isEditableAssignment && !is_unit_user && renderCNAContextMenu(id)}
                      </div>
                    );
                  })
                ) : (
                  <div>No selected CNA yet</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={'staff'}>
        <div className="staff__block">
          <div className="staff__block__title">
            {'Nurses'}
            {isEditableAssignment && !is_unit_user && <StaffEditBtn onClick={() => setIsOpenManageNursesModal(true)} />}
          </div>
          <div className="staff__block__content">
            <div className="staff__nurses">
              {activeShiftNurses?.length ? (
                activeShiftNurses?.map(({ id, full_name }) => (
                  <span className="staff__nurses__item" key={id}>
                    {full_name}
                  </span>
                ))
              ) : (
                <div>No selected nurses yet</div>
              )}
            </div>
          </div>
        </div>
        <div className="staff__block">
          <div className="staff__block__title">
            {'CNA'}
            {isEditableAssignment && !is_unit_user && <StaffEditBtn onClick={() => setIsOpenManageCNAsModal(true)} />}
          </div>
          <div className="staff__block__content" style={{ minHeight: 44 }}>
            <div className="staff__cna-list" ref={staffCNAListRef}>
              {activeShiftCNA?.length ? (
                activeShiftCNA.map(({ id, full_name, colors: { bg: bgColor, border: borderColor } }) => {
                  return (
                    <div
                      className={'staff__cna-item'}
                      key={id}
                      style={{
                        backgroundColor: bgColor,
                        borderColor: borderColor,
                        paddingRight: isEditableAssignment && !is_unit_user ? undefined : 10,
                      }}
                    >
                      <span className={'cna-name'} onClick={() => handleAssignCNAToRoom(id)}>
                        {full_name}
                      </span>
                      {isEditableAssignment && !is_unit_user && renderCNAContextMenu(id)}
                    </div>
                  );
                })
              ) : (
                <div>No selected CNA yet</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTaskContextMenu = (roomID, task, hasResident) => {
    const menu = [
      ...(canManageTasks
        ? [
            {
              label: 'Edit Task',
              icon: <i className="icon-icon-edit" />,
              action: ({ onCloseMenu }) => {
                onCloseMenu();
                setEditTaskData({ ...task, shiftRecordID: roomID, hasResident });
              },
            },
          ]
        : []),
      {
        label: 'Add Comment',
        icon: (
          <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.5 12.5C17.5 12.942 17.3244 13.366 17.0118 13.6785C16.6993 13.9911 16.2754 14.1667 15.8333 14.1667H5.83333L2.5 17.5V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V12.5Z"
              stroke="#101828"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ),
        action: ({ onCloseMenu }) => {
          onCloseMenu();
          handleSetTaskCommentsData(roomID, task);
        },
      },
      ...(canManageTasks
        ? [
            {
              label: 'Delete Task',
              color: '#B32318',
              icon: <i className="icon-remove" />,
              action: ({ onCloseMenu }) => {
                onCloseMenu();
                setDeleteTaskModalData({ shiftRecordID: roomID, taskID: task.id });
              },
            },
          ]
        : []),
    ];
    return <ContextMenu size={'small'} menu={menu} styles={{ background: 'transparent' }} />;
  };

  const renderTaskComments = (roomID, task = {}) => {
    const { comments = [], id: taskID } = task;
    let layout = null;

    if (comments.length) {
      layout = (
        <div className="task-comments">
          <span className="task-comments__label">{'Comments:'}</span>
          <ol className="task-comments__list">
            {comments.map((comment) => {
              const { id: commentID, text } = comment;
              return (
                <li key={commentID} className={'comment'}>
                  <div className="comment__inner">
                    <p className={'comment__body'}>{text}</p>
                    {isActiveShift && (
                      <div className="comment__actions">
                        <i
                          className="icon-icon-edit"
                          style={{ cursor: 'pointer', fontSize: 16 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditCommentModalData({ ...comment, taskID });
                          }}
                        />
                        <i
                          className="icon-remove"
                          style={{ cursor: 'pointer', color: '#B32318', fontSize: 16 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteComment(taskID, commentID);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      );
    }
    return layout;
  };

  const renderTable = (isPrint) => {
    const tableRows = table.getRowModel().rows;

    return (
      <div className={'table-container'}>
        <table ref={tableRef}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              const { id: headerGroupId } = headerGroup;
              return (
                <tr key={headerGroupId} className="cna-table-header">
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        onClick={() =>
                          ['cna', 'room'].includes(header.column.columnDef.accessorKey)
                            ? applySort(header.column.columnDef.accessorKey)
                            : undefined
                        }
                        key={header.id}
                        className={header.column.columnDef.cellClassName}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.columnDef.accessorKey == orderSettings.field && orderSettings.order == 'desc' && (
                          <>
                            {' '}
                            <svg
                              height={12}
                              width={12}
                              fill="#667085"
                              xmlns="http://www.w3.org/2000/svg"
                              shapeRendering="geometricPrecision"
                              textRendering="geometricPrecision"
                              imageRendering="optimizeQuality"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              viewBox="0 0 404 511.51"
                            >
                              <path
                                fillRule="nonzero"
                                d="M184.29 442.88 184.83 0h34.95l-.54 438.53 158.99-173.49L404 288.61l-204.27 222.9L0 293.11l25.77-23.57z"
                              />
                            </svg>
                          </>
                        )}
                        {header.column.columnDef.accessorKey == orderSettings.field && orderSettings.order == 'asc' && (
                          <>
                            {' '}
                            <svg
                              height={11}
                              width={11}
                              fill="#667085"
                              xmlns="http://www.w3.org/2000/svg"
                              shapeRendering="geometricPrecision"
                              textRendering="geometricPrecision"
                              imageRendering="optimizeQuality"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              viewBox="0 0 404 511.5"
                            >
                              <path
                                fillRule="nonzero"
                                d="m219.24 72.97.54 438.53h-34.95l-.55-442.88L25.77 241.96 0 218.39 199.73 0 404 222.89l-25.77 23.58z"
                              />
                            </svg>
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {table.getRowModel().rows.length === 0 ? (
              <tr>
                <td colSpan={table.getAllColumns().length} style={{ height: 128, textAlign: 'center', verticalAlign: 'middle' }}>
                  No data available
                </td>
              </tr>
            ) : (
              tableRows.map((row, rowIndex) => {
                const { original: rowData } = row;
                const { unit_bed = {}, id: shiftRecordID, tasks = [], cna } = rowData;
                const { resident } = unit_bed;
                const residentFullName = resident ? `${resident.first_name} ${resident.last_name}` : '';
                const isSelected = selectedSiftRooms.has(shiftRecordID);
                const { bg, border } = cna ? activeShiftCNAColors[cna.id] ?? {} : {};
                const isNextSelected = tableRows.length >= rowIndex + 2 && selectedSiftRooms.has(tableRows[rowIndex + 1].original.id);

                const r = (
                  <tr
                    key={row.id}
                    data-index={rowIndex}
                    data-id={shiftRecordID}
                    className={cx('table__row', {
                      selected: isSelected && !isPrint,
                      'has-cna': !!cna,
                      'prev-row-selected': previousRowSelected,
                      'next-row-selected': isNextSelected,
                    })}
                    style={{
                      background: bg,
                      border: border ? `1px dashed ${border}` : undefined,
                    }}
                    onClick={isPrint | isTablet | is_unit_user ? undefined : (event) => handleTableRowClick(rowIndex, event)}
                    onMouseDown={isPrint | isTablet | is_unit_user ? undefined : () => handleTableRowMouseDown(rowIndex)}
                    onTouchStart={isPrint | !isTablet | is_unit_user ? undefined : (e) => handleTableRowTouchStart(e, rowIndex)}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const cellCtx = cell.getContext();
                      const columnID = cellCtx.column.id;
                      let layout = null;

                      if (rowData) {
                        switch (columnID) {
                          case 'cna': {
                            layout = cna?.full_name;
                            break;
                          }
                          case 'room': {
                            layout = (
                              <div className="d-flex justify-content-space-between room-col">
                                <div>
                                  <strong>{unit_bed ? extractBedParts(unit_bed.bed).number : ''} </strong>
                                </div>
                                <div>{unit_bed ? extractBedParts(unit_bed.bed).letter : ''}</div>
                              </div>
                            );
                            break;
                          }
                          case 'resident': {
                            layout = residentFullName;
                            break;
                          }
                          case 'tasks': {
                            layout = (
                              <div className={'tasks'}>
                                {tasks.length ? (
                                  <div className="tasks__list">
                                    {tasks.map((task, taskIndex) => {
                                      const { id, name } = task;
                                      return (
                                        <div key={id} className={'tasks__list__item'}>
                                          <div className={'inner'}>
                                            <div
                                              className={cx('task-name', { 'as-new-comment-btn': isActiveShift })}
                                              onClick={isActiveShift ? () => handleSetTaskCommentsData(shiftRecordID, task) : undefined}
                                            >
                                              <div className="task-name-block">
                                                {`${taskIndex + 1}. `} {name}
                                              </div>
                                              {renderTaskComments(shiftRecordID, task)}
                                            </div>
                                            {!isPrint &&
                                              isActiveShift &&
                                              !is_unit_user &&
                                              renderTaskContextMenu(shiftRecordID, task, !!resident)}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className={'tasks__empty-list'}></div>
                                )}

                                {!isPrint && canManageTasks && (
                                  <div className="tasks__actions">
                                    <button
                                      className="btn btn-outline add-task-btn"
                                      onClick={() => setAddTasksModalData({ shiftRecordID, hasResident: !!resident })}
                                    >
                                      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M10.5 3V17M3.5 10H17.5"
                                          stroke="#101828"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      {!isTablet && 'Add Task'}
                                    </button>
                                  </div>
                                )}
                              </div>
                            );
                            break;
                          }
                        }
                      }

                      return (
                        <td
                          key={cell.id}
                          className={cx({ [`${columnID}-column`]: columnID })}
                          style={
                            is_unit_user && !isPrint
                              ? isTablet
                                ? { paddingTop: '4px', paddingBottom: '4px' }
                                : { paddingTop: '8px', paddingBottom: '8px' }
                              : {}
                          }
                        >
                          {layout}
                        </td>
                      );
                    })}
                  </tr>
                );

                previousRowSelected = isSelected;
                return r;
              })
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Layout title={assignmentUnit?.name ?? 'CNA Unit'} className={'unit-page-layout'}>
      <div className="page-wrapper">
        {renderHeader()}
        {renderStaff()}
        {renderTable()}
        {isOpenManageNursesModal && (
          <ManageNursesModal
            shiftNurses={activeShiftNurses}
            nursesSuggest={nursesSuggest}
            onSave={handleUpdateStaff}
            onClose={() => setIsOpenManageNursesModal(false)}
          />
        )}
        {isOpenManageCNAsModal && (
          <ManageCNAsModal
            shiftCNA={activeShiftCNA}
            cnaSuggest={cnaSuggest}
            onSave={handleUpdateStaff}
            onClose={() => setIsOpenManageCNAsModal(false)}
          />
        )}
        {deleteTaskModalData && (
          <DeleteTaskModal
            closeModal={() => setDeleteTaskModalData(null)}
            handleConfirm={() => {
              handleDeleteRoomTask();
              setDeleteTaskModalData(null);
            }}
          />
        )}
        {changeCNAID && (
          <ChangeCNAModal
            shiftID={activeShiftID}
            cnaID={changeCNAID}
            shiftCNA={activeShiftCNA}
            cnaSuggest={cnaSuggest}
            onRefetchAssignment={handleGetAssignment}
            onClose={() => setChangeCNAID(null)}
          />
        )}
        {isOpenAddTasksModal && (
          <AddTasksModal
            addTasksModalData={addTasksModalData}
            onClose={() => setAddTasksModalData(null)}
            onRefetchAssignment={handleGetAssignment}
          />
        )}
        {isOpenEditTaskModal && (
          <EditTaskModal manageTaskData={editTaskData} onRefetchAssignment={handleGetAssignment} onClose={() => setEditTaskData(null)} />
        )}
        {taskCommentsModalData && (
          <TaskCommentsModal
            data={taskCommentsModalData}
            onClose={() => setTaskCommentsModalData(null)}
            onRefetchAssignment={handleGetAssignment}
          />
        )}
        {addNewTemplateModalData && (
          <AddNewTemplateModal
            data={addNewTemplateModalData}
            onClose={() => setAddNewTemplateModalData(null)}
            onRefetchAssignment={handleGetAssignment}
          />
        )}
        {isOpenTemplatesListModal && (
          <TemplatesListModal
            shiftID={activeShiftID}
            onClose={() => setIsOpenTemplatesListModal(false)}
            setAddNewTemplateModalData={setAddNewTemplateModalData}
            onRefetchAssignment={handleGetAssignment}
            hasTemplateWritePermission={hasTemplateWritePermission}
          />
        )}
        {isOpenExportToPdfModal && (
          <ExportToPdfModal
            shift={activeShift}
            unit={assignmentUnit}
            assignmentDate={assignmentDate}
            facility={assignmentUnit?.facility}
            renderTable={() => renderTable(true)}
            onClose={() => seIsOpenExportToPdfModal(false)}
          />
        )}
        {editCommentModalData && (
          <EditCommentModal
            commentData={editCommentModalData}
            onRefetchAssignment={handleGetAssignment}
            onClose={() => setEditCommentModalData(null)}
          />
        )}
        {isLoading && <PageLoader />}
      </div>
    </Layout>
  );
};

export default CNAUnitPage;
