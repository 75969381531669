import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * Converts a local date-time string to a UTC date-time string in ISO format,
 * representing either the start or end of the day for a date range.
 *
 * @param {string | Date | undefined} localDate - The local date-time string or Date object to convert.
 * @param {string} rangeDateType - The type of range, either "start" for the beginning of the day or "end" for the end of the day.
 * @returns {string | undefined} - Returns the UTC date-time string in ISO format or `undefined` if the input is invalid.
 * @throws {Error} - Throws an error if the input is not a valid date-time string or if the rangeDateType is invalid.
 */
export const localToUTCStringForDateRange = (localDate, rangeDateType) => {
  if (!localDate) {
    return undefined; // Return undefined if localDate is not provided
  }

  const date = dayjs(localDate); // Handles both string and Date object

  if (!date.isValid()) {
    throw new Error(`Invalid local date-time: ${localDate}`);
  }

  let convertedDate;

  if (rangeDateType === 'start') {
    // Set to the start of the day (00:00:00.000)
    convertedDate = date.startOf('day');
  } else if (rangeDateType === 'end') {
    // Set to the end of the day (23:59:59.999)
    convertedDate = date.endOf('day');
  } else {
    throw new Error(`Invalid rangeDateType: ${rangeDateType}. Expected "start" or "end".`);
  }

  // Convert to UTC and return the ISO string
  return convertedDate.utc().toISOString();
};
