import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RenderForm } from './render-form';
import { activateGeneralForm, createFormAdmin, deactivateGeneralForm } from '../../services/form';
import DeactivateModal from '../company/deactivate-modal';
import CopyModal from '../company/copy-modal';
import { usePermission } from '../../container/permissionContext';

export const ViewGeneralForm = function ViewForm({ formData, initData, handelError, handelInitSettings }) {
  const navigation = useNavigate();
  const { id } = useParams();
  const [deactivateIsOpen, setIsOpenDeactivate] = useState(false);
  const [copyIsOpen, setIsOpenCopy] = useState(false);
  const { hasPermissionByCodeName } = usePermission();
  const handleDeactivateIsOpen = () => {
    setIsOpenDeactivate(false);
  };
  const handleCopyIsOpen = () => {
    setIsOpenCopy(false);
  };

  const handelCopy = () => {
    const bodyRequest = JSON.parse(JSON.stringify(formData));
    if (formData?.filling_type === 'PUBLIC') {
      delete bodyRequest['notifications_settings'];
    }
    bodyRequest['name'] = `${formData.name} (Copy)`;
    bodyRequest['is_draft'] = true;

    handleCopyIsOpen(false);

    createFormAdmin(bodyRequest)
      .then((res) => {
        console.log(res);
        navigation(`/general-view-form/${res.id}`);
      })
      .catch((e) => console.log(e));
  };
  const handelDeactivate = () => {
    deactivateGeneralForm(id)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };

  const handelActivated = () => {
    activateGeneralForm(id)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="init-form-tab">
      <div className="init-form-tab-content general-form-tab-content ">
        {hasPermissionByCodeName('corporate_library_management') && (
          <div className="init-form-action d-flex justify-space-between">
            <div className={'d-flex'}>
              <button
                disabled={formData.deleted_at}
                className={'btn btn-white mr-2'}
                onClick={() => {
                  navigation(`/form-builder/${formData.id}/constructor`);
                }}
              >
                <i className={'icon-icon-edit mr-2'}></i>Edit
              </button>
              <button disabled={formData.deleted_at} className="btn btn-white" onClick={() => setIsOpenCopy(true)}>
                <i className="icon-copy mr-2"></i>Copy Form
              </button>
            </div>

            <div>
              {!formData.deleted_at && (
                <button className="btn  btn-danger btn-outline" onClick={() => setIsOpenDeactivate(true)}>
                  <svg className={'mr-2'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_4855_3699)">
                      <g clipPath="url(#clip1_4855_3699)">
                        <path
                          d="M12.4998 7.5013L7.49984 12.5013M7.49984 7.5013L12.4998 12.5013M18.3332 10.0013C18.3332 14.6037 14.6022 18.3346 9.99984 18.3346C5.39746 18.3346 1.6665 14.6037 1.6665 10.0013C1.6665 5.39893 5.39746 1.66797 9.99984 1.66797C14.6022 1.66797 18.3332 5.39893 18.3332 10.0013Z"
                          stroke="#B42318"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_4855_3699">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                      <clipPath id="clip1_4855_3699">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Archive
                </button>
              )}

              {formData.deleted_at && (
                <button className="btn  btn-success btn-outline" onClick={() => handelActivated(formData)}>
                  <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1484_3438)">
                      <path
                        d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                        stroke="#12B76A"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1484_3438">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Activate
                </button>
              )}
            </div>
          </div>
        )}

        {formData.form_fields && (
          <RenderForm
            isPrint={false}
            title={
              formData?.title_options
                ? { ...formData?.title_options, options: { ...formData?.title_options.options, value: formData.name } }
                : formData.name
            }
            formElement={initData.form_fields ? initData.form_fields : []}
            handelInitSettings={handelInitSettings}
          ></RenderForm>
        )}
      </div>

      <div className="form-control-actions">
        <Link to={`/general-library/forms/${formData.folder_id ? formData.folder_id : ''}`} className="btn btn-outline">
          Back to General Library
        </Link>
      </div>

      <DeactivateModal
        isOpen={deactivateIsOpen}
        closeModal={handleDeactivateIsOpen}
        handelSuccess={handelDeactivate}
        title="Archive the Form"
        button={'Archive'}
        subtitle="Are you sure you want to Archive this form? It will be unavailable for initiation then."
      ></DeactivateModal>
      <CopyModal
        isOpen={copyIsOpen}
        closeModal={handleCopyIsOpen}
        handelSuccess={handelCopy}
        title="Copy form"
        subtitle="The copy of this form will appear in Drafts folder"
      ></CopyModal>
    </div>
  );
};
