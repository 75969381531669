import React, { useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';
import { useUserFormRole } from '../../../hook/useUserFormRole';
import { useIsInitFormPage } from '../../../hook/pages/useIsInitFormPage';
import { useIsSubmitFormPage } from '../../../hook/useIsSubmitFormPage';

export const DateElement = function FromElement({ element, handlerChange, error }) {
  const userFormRole = useUserFormRole();
  const isSubmitFormPage = useIsSubmitFormPage();
  const isInitFormPage = useIsInitFormPage();
  const isRequiredElement = useIsRequiredElement(element);
  const isRequiredForAnotherRole = !!element.options.requiredFor && element.options.requiredFor !== userFormRole;
  const isEditable = element.options?.editable && !element.options.readOnly && !isRequiredForAnotherRole;
  let value = null;

  if (element.options.valueInput) {
    value = new Date(element.options.valueInput);
  }

  useEffect(() => {
    if (!isRequiredForAnotherRole && !isEditable && !element.options.valueInput && (isSubmitFormPage || isInitFormPage)) {
      handlerChange(element, new Date());
    }
  }, [element, isEditable, isInitFormPage, isRequiredForAnotherRole, isSubmitFormPage]);

  const handleDatePickerFocus = useCallback(() => {
    if (isEditable && !value) {
      const currentDate = new Date();
      handlerChange(element, currentDate);
    }
  }, [element, handlerChange, isEditable, value]);

  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <DatePicker
        id={element.id}
        readOnly={!isEditable}
        dateFormat={'MM-dd-yyyy'}
        popperPlacement="bottom-start"
        popperClassName={'date-picker-popper'}
        placeholderText={isEditable ? 'Choose Date' : ''}
        className={`form-control form-control-${element.options.size}`}
        selected={element?.options?.valueInput ? new Date(element?.options?.valueInput) : ''}
        onFocus={handleDatePickerFocus}
        onChange={(date) => (isEditable ? handlerChange(element, date) : undefined)}
      />
      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
