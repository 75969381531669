// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.context-menu-btn {
  display: inline-flex;
  padding: 0;
  position: relative;
  align-items: center;
  justify-content: center;
}
.context-menu-btn.small {
  width: 32px;
  height: 32px;
  min-width: 32px;
}
.context-menu-btn.big {
  width: 40px;
  height: 40px;
  min-width: 40px;
}

.context-btn-menu {
  top: calc(100% + 4px);
  width: max-content;
  max-width: 300px;
  position: absolute;
  overflow: hidden;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.context-btn-menu .item {
  display: flex;
  height: 44px;
  gap: 4px;
  font-weight: 500;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  cursor: pointer;
  --svg-color: #101828;
}
.context-btn-menu .item[data-color] {
  --svg-color: attr(data-color);
}
.context-btn-menu .item i::before {
  color: var(--svg-color);
}`, "",{"version":3,"sources":["webpack://./src/components/other/context-menu/styles.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,UAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,eAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,eAAA;AAAJ;;AAKA;EACE,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,2CAAA;EACA,YAAA;AAFF;AAIE;EACE,aAAA;EACA,YAAA;EACA,QAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EAMA,oBAAA;AAPJ;AASI;EACE,6BAAA;AAPN;AAWM;EACE,uBAAA;AATR","sourcesContent":[".context-menu-btn {\n  display: inline-flex;\n  padding: 0;\n  position: relative;\n  align-items: center;\n  justify-content: center;\n\n  &.small {\n    width: 32px;\n    height: 32px;\n    min-width: 32px;\n  }\n\n  &.big {\n    width: 40px;\n    height: 40px;\n    min-width: 40px;\n  }\n}\n\n// Portal\n.context-btn-menu {\n  top: calc(100% + 4px);\n  width: max-content;\n  max-width: 300px;\n  position: absolute;\n  overflow: hidden;\n  border-radius: 8px;\n  background: #FFF;\n  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);\n  z-index: 999;\n\n  .item {\n    display: flex;\n    height: 44px;\n    gap: 4px;\n    font-weight: 500;\n    padding-left: 16px;\n    padding-right: 16px;\n    align-items: center;\n    cursor: pointer;\n\n    &:hover {\n      //opacity: .7;\n    }\n\n    --svg-color: #101828;\n\n    &[data-color] {\n      --svg-color: attr(data-color);\n    }\n\n    i {\n      &::before {\n        color: var(--svg-color);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
