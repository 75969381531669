import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { validateEmail } from '../../utility';
import { createUser, getRoles, updateUser } from '../../services/user';
import { getCompanyPositions, getCompanyUserGroup } from '../../services/company';
import InputMask from 'react-input-mask';
import { usePermission } from '../../container/permissionContext';

function UserModal({ isEditMode, isOpen, closeModal, companyId, companyName, userData, handelCreateUser, facilities, facility }) {
  const { isEmployee, isSuperAdmin, isCompanySystemAdministrator, hasPermission } = usePermission();
  const canUserChangeNotificationType = isSuperAdmin() || isCompanySystemAdministrator() || hasPermission('Users, Positions management');

  const [errorUser, setErrorUser] = useState({});
  const [roleEmployee, setRoleEmployee] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [userGroupOptions, setUserGroupOptions] = useState([]);

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    role_id: '',
    facility_id: facility ? { value: facility.id, label: facility.name } : '',
    position_id: '',
    user_group_ids: [],
    company_id: companyId,
    preferred_notification_type: 'SMS',
  });

  useEffect(() => {
    getRoles()
      .then((res) => {
        setRoleOptions(res.map((item) => ({ value: item.id, label: item.name })));
        if (isEmployee()) {
          setRoleOptions(
            res
              .filter((item) => item.name === 'Employee')
              .map((item) => ({
                value: item.id,
                label: item.name,
              })),
          );
        }

        if (facility) {
          let employeeObject = res.filter((item) => item.name === 'Employee')[0];
          handleChange({ value: employeeObject.id, label: employeeObject.name }, 'role_id');
          setRoleEmployee(employeeObject);
        }

        if (userData) {
          setUser(() => {
            const newState = { ...userData };
            let currentRole = '';
            if (userData?.role) {
              currentRole = res.find((item) => item.name === userData?.role);
            }

            if (userData?.facility) {
              newState.facility_id = { label: userData.facility.name, value: userData.facility.id };
            }
            if (userData?.position) {
              newState.position_id = { label: userData.position.name, value: userData.position.id };
            }
            if (userData?.user_groups?.length) {
              newState.user_group_ids = userData.user_groups.map((item) => ({ value: item.id, label: item.name }));
            }
            if (userData?.position_id) {
              newState.position_id = { label: userData.position.name, value: userData.position.id };
            }

            if (currentRole) {
              newState.role_id = { label: currentRole?.name, value: currentRole?.id };
            }

            newState.preferred_notification_type = userData?.preferred_notification_type ?? 'SMS';

            return newState;
          });
        }
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    getCompanyPositions(companyId, 'getAll', user.facility_id.value)
      .then((res) => {
        setPositionOptions(res.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line
  }, [companyId, isOpen]);

  useEffect(() => {
    getCompanyUserGroup(companyId, 'getAll', user.facility_id.value)
      .then((res) => {
        setUserGroupOptions(res.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line
  }, [companyId, isOpen]);

  useEffect(() => {
    setFacilityOptions(
      facilities
        .filter((item) => !item.deleted_at)
        .map((item) => ({
          value: item.id,
          label: item.name,
        })),
    );
    // eslint-disable-next-line
  }, [facilities, isOpen]);

  useEffect(() => {
    if (user.facility_id) {
      getCompanyPositions(companyId, 'getAll', user.facility_id.value)
        .then((res) => {
          setPositionOptions(res.map((item) => ({ value: item.id, label: item.name })));
        })
        .catch((e) => console.log(e));
      getCompanyUserGroup(companyId, 'getAll', user.facility_id.value)
        .then((res) => {
          setUserGroupOptions(res.map((item) => ({ value: item.id, label: item.name })));
        })
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line
  }, [user, isOpen]);

  useEffect(() => {
    if (userData) {
      setUser(() => {
        const newState = { ...userData };
        if (userData.facility) {
          newState.facility_id = { label: userData.facility.name, value: userData.facility.id };
        }
        if (userData.position) {
          newState.position_id = { label: userData.position.name, value: userData.position.id };
        }
        if (userData.user_groups.length) {
          newState.user_group_ids = userData.user_groups.map((item) => ({ value: item.id, label: item.name }));
        }
        if (userData.position_id) {
          newState.position_id = { label: userData.position.name, value: userData.position.id };
        }
        if (userData.role_id) {
          newState.role_id = { label: userData.role.name, value: userData.role.id };
        }

        newState.preferred_notification_type = userData.preferred_notification_type ?? 'SMS';

        return newState;
      });
    }
  }, [userData]);

  const handleChange = (value, key) => {
    setUser({
      ...user,
      [key]: value,
    });

    if (key === 'role_id' && value.label === 'Corporate System Admin') {
      setUser({
        ...user,
        [key]: value,
        facility_id: '',
        position_id: '',
        user_group_ids: [],
      });
    }

    if (key === 'facility_id' && value !== user.facility_id) {
      setUser({
        ...user,
        [key]: value,
        position_id: '',
        user_group_ids: [],
      });
    }

    if (key === 'email' && value) {
      if (validateEmail(value)) {
        delete errorUser[key];
      }
      return;
    }

    if (errorUser.hasOwnProperty(key)) {
      delete errorUser[key];
    }
  };

  const isDisabled = () => {
    if (isUserAdministrator()) {
      return true;
    }

    return !user.facility_id;
  };

  const isUserAdministrator = () => {
    return user.role_id?.label === 'Corporate System Admin';
  };

  const resetForm = () => {
    setUser({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      role_id: facility ? { value: roleEmployee.id, label: roleEmployee.name } : '',
      facility_id: facility ? { value: facility.id, label: facility.name } : '',
      position_id: facility
        ? roleOptions.map((item) =>
            item.name === 'Employee'
              ? {
                  value: item.id,
                  label: item.name,
                }
              : null,
          )
        : '',
      user_group_ids: [],
      company_id: companyId,
      preferred_notification_type: 'SMS',
    });
    setErrorUser({});
  };

  const handleSave = () => {
    const formDate = { ...user };
    if (formDate.role_id) {
      formDate.role_id = formDate.role_id.value;
    }
    if (isUserAdministrator()) {
      delete formDate.facility_id;
      delete formDate.position_id;
      delete formDate.user_group_ids;
    }

    if (formDate.facility_id) {
      formDate.facility_id = formDate.facility_id.value;
    }
    if (formDate.position_id) {
      formDate.position_id = formDate.position_id.value;
    }
    if (formDate.user_group_ids) {
      formDate.user_group_ids = formDate.user_group_ids.map((item) => item.value);
    }

    if (isEditMode) {
      delete formDate.facility;
      delete formDate.id;
      delete formDate.company;
      delete formDate.position;
      delete formDate.role;
      delete formDate.user_groups;

      updateUser(formDate, userData.id)
        .then((res) => {
          resetForm();
          handelCreateUser(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUser(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      formDate.company_id = companyId;

      createUser(formDate)
        .then((res) => {
          resetForm();
          handelCreateUser(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUser(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };

  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {isEditMode ? 'Edit' : 'Add New'} User{' '}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      {!!companyName && <h3 className="modal-subheader">for {companyName} company</h3>}
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-3.svg" alt="" />
          User information
        </h3>
        <div className={`form-item form-item-half ${errorUser.first_name ? 'form-item-error' : ''}`}>
          <label className="form-label">First Name</label>
          <input
            placeholder="Enter First Name"
            value={user.first_name}
            onChange={(e) => handleChange(e.target.value, 'first_name')}
            type="text"
            className="form-control"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.first_name && errorUser.first_name[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorUser.last_name ? 'form-item-error' : ''}`}>
          <label className="form-label">Last Name</label>
          <input
            placeholder="Enter Last Name"
            value={user.last_name}
            onChange={(e) => handleChange(e.target.value, 'last_name')}
            type="text"
            className="form-control"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.last_name && errorUser.last_name[0]}</span>
        </div>

        <div className={`form-item form-item-select ${errorUser.role_id ? 'form-item-error' : ''}`}>
          <label className="form-label">System Role</label>
          <Select
            isDisabled={facility}
            options={roleOptions}
            className="form-control-select"
            classNamePrefix="react-select"
            value={user.role_id}
            placeholder="Select Role"
            onChange={(e) => handleChange(e, 'role_id')}
          ></Select>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.role_id && errorUser.role_id[0]}</span>
        </div>
        <div className={`form-item form-item-half form-item-select ${errorUser.facility_id ? 'form-item-error' : ''}`}>
          <label className="form-label">Facility</label>
          <Select
            isDisabled={user.role_id?.label === 'Corporate System Admin' || !facilityOptions.length || facility}
            options={facilityOptions}
            className="form-control-select"
            classNamePrefix="react-select"
            value={user.facility_id}
            placeholder="Select Facility"
            onChange={(e) => handleChange(e, 'facility_id')}
          ></Select>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.facility_id && errorUser.facility_id[0]}</span>
        </div>
        <div className={`form-item form-item-half form-item-select ${errorUser.position_id ? 'form-item-error' : ''}`}>
          <label className="form-label">Position</label>
          <Select
            isDisabled={isDisabled() || !positionOptions.length}
            options={positionOptions}
            className="form-control-select"
            classNamePrefix="react-select"
            value={user.position_id}
            placeholder="Select Position"
            onChange={(e) => handleChange(e, 'position_id')}
          ></Select>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.position_id && errorUser.position_id[0]}</span>
        </div>
        <div className="form-item form-item-select">
          <label className="form-label">User Group</label>
          <Select
            isMulti
            isDisabled={isDisabled() || !userGroupOptions.length}
            options={userGroupOptions}
            className="form-control-select"
            classNamePrefix="react-select-multi"
            value={user.user_group_ids}
            placeholder="Select User Group"
            onChange={(e) => handleChange(e, 'user_group_ids')}
          ></Select>
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.user_group_ids && errorUser.user_group_ids[0]}</span>
        </div>
        <div className={`form-item form-item-half ${errorUser.phone ? 'form-item-error' : ''}`}>
          <label className="form-label">Phone Number</label>
          <InputMask
            placeholder="XXX-XXX-XXXX"
            mask="999-999-9999"
            value={user.phone}
            onChange={(e) => handleChange(e.target.value, 'phone')}
            className="form-control"
          ></InputMask>

          {/*<input placeholder="XXX-XXX-XXXX" value={user.phone} onChange={(e) => handleChange(e.target.value, 'phone')} type="text" className="form-control" />*/}
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.phone && errorUser.phone[0]}</span>
        </div>
        <div className={`form-item form-item-half form-item-mail ${errorUser.email ? 'form-item-error' : ''}`}>
          <label className="form-label">Email {!isUserAdministrator() && <span className="optional">optional</span>}</label>
          <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
          <input
            placeholder="mail@example.com"
            id="email"
            value={user.email}
            onChange={(e) => handleChange(e.target.value, 'email')}
            type="text"
            className="form-control"
          />
          <img className="danger-icon" src="/images/red-alert.svg" alt="" />
          <span className="form-error">{errorUser.email && errorUser.email[0]}</span>
        </div>

        <div className="form-item">
          <div className="d-flex" style={{ alignItems: 'center', gap: 32 }}>
            <label className="form-label mb-0">Preferred notification type</label>
            <div className="d-flex" style={{ gap: 16 }}>
              <div className={`form-control-radio mb-0`}>
                <input
                  name={'preferred-notification-type'}
                  value={'SMS'}
                  type="radio"
                  disabled={!canUserChangeNotificationType}
                  checked={user?.preferred_notification_type === 'SMS'}
                  onChange={(e) =>
                    canUserChangeNotificationType ? handleChange(e.target.value, 'preferred_notification_type') : undefined
                  }
                />
                <span>SMS</span>
              </div>
              <div className={`form-control-radio mb-0`}>
                <input
                  name={'preferred-notification-type'}
                  value={'EMAIL'}
                  type="radio"
                  disabled={!canUserChangeNotificationType || !user?.email}
                  checked={user?.preferred_notification_type === 'EMAIL'}
                  onChange={(e) =>
                    canUserChangeNotificationType ? handleChange(e.target.value, 'preferred_notification_type') : undefined
                  }
                />
                <span>EMAIL</span>
              </div>
            </div>
          </div>
          <span className="form-error">{errorUser.preferred_notification_type && errorUser.preferred_notification_type[0]}</span>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn" onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default memo(UserModal);
