import client from '../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancel;
let cancelUserGroup;
export const getCompanyPositionsList = async (id, params) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  const response = await client.get(`api/companies/${id}/positions`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};
export const createPosition = async (data) => {
  const response = await client.post('api/positions/bulk-create/', data);

  return response.data;
};
export const editPosition = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/positions/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deactivatePosition = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/positions/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const activatePosition = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/positions/${id}/restore/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
