import React from 'react';
import { TextElement } from './elements/text-element';
import { SingleLineElement } from './elements/single-line-element';
import { MultilineElement } from './elements/multiline-element';
import { NumberElement } from './elements/number-element';
import { PhoneElement } from './elements/phone-element';
import { EmailElement } from './elements/email-element';
import { FullNameElement } from './elements/full-name-element';
import { AddressElement } from './elements/address-element';
import { DateElement } from './elements/date-element';
import { TimeElement } from './elements/time-element';
import { DropdownElement } from './elements/dropdown-element';
import { CheckboxElement } from './elements/checkbox';
import { RadiobuttonElement } from './elements/radiobutton-element';
import { LinkElement } from './elements/link';
import { RatingElement } from './elements/rating-element';
import { SignatureElement } from './elements/signature-element';
import { TableElement } from './elements/table';
import { SingleLineHeaderElement } from './elements/single-line-header-element';
import { SubmittersFieldElement } from './elements/submitters-field-element';
import { FileUploadElement } from './elements/file-upload-element';
import { FileAttachElement } from './elements/file-attach-element';
import { LoginElement } from './elements/login-element';
import cx from 'classnames';

export const FromBuilderItem = function FromElement(props) {
  const {
    handlerChangeFile,
    element,
    handlerChange,
    addNewRow,
    handlerChangeLabel,
    isPrint,
    isSubmitter,
    addNewLogin,
    lastIndex,
    index,
    error,
    duplicateElement,
    removeElement,
    highlightPrevious,
    formHasOnlyOneSubmitter,
    initiatorID,
  } = props;
  return (
    <div
      className={cx('form-builder-element-preview', {
        'show-button': lastIndex === index,
        [`form-builder-element-preview-${element.type}`]: !!element.type,
        'table-form-builder-element-preview': element.type === 'table',
        [`form-label-alignment-${element.options.alignment}`]: !!element.options.alignment,
        [`form-label-${element.options.size}`]: !!element.options.size,
        'form-readonlyy': element.options.readOnly,
        'with-aside-settings': element.options.initiator_can_edit,
      })}
    >
      <div className="form-builder-element-preview-wrapper">
        {element.type === 'text' && (
          <TextElement
            element={element}
            error={error}
            handlerChange={handlerChange}
            removeElement={removeElement}
            duplicateElement={duplicateElement}
          />
        )}
        {element.type === 'single-line' && (
          <SingleLineElement
            handlerChange={handlerChange}
            element={element}
            error={error}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
        {element.type === 'multiline-line' && (
          <MultilineElement
            isPrint={isPrint}
            handlerChange={handlerChange}
            element={element}
            error={error}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
        {element.type === 'number' && (
          <NumberElement handlerChange={handlerChange} element={element} error={error} formHasOnlyOneSubmitter={formHasOnlyOneSubmitter} />
        )}
        {element.type === 'phone' && (
          <PhoneElement handlerChange={handlerChange} element={element} error={error} formHasOnlyOneSubmitter={formHasOnlyOneSubmitter} />
        )}
        {element.type === 'email' && (
          <EmailElement handlerChange={handlerChange} element={element} error={error} formHasOnlyOneSubmitter={formHasOnlyOneSubmitter} />
        )}
        {element.type === 'name' && (
          <FullNameElement
            handlerChange={handlerChange}
            element={element}
            error={error}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
        {element.type === 'address' && (
          <AddressElement handlerChange={handlerChange} element={element} error={error} formHasOnlyOneSubmitter={formHasOnlyOneSubmitter} />
        )}
        {element.type === 'time' && (
          <TimeElement handlerChange={handlerChange} element={element} error={error} formHasOnlyOneSubmitter={formHasOnlyOneSubmitter} />
        )}
        {element.type === 'date' && (
          <DateElement handlerChange={handlerChange} element={element} error={error} formHasOnlyOneSubmitter={formHasOnlyOneSubmitter} />
        )}
        {element.type === 'dropdown' && (
          <DropdownElement
            handlerChange={handlerChange}
            element={element}
            error={error}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
        {element.type === 'checkbox' && (
          <CheckboxElement
            isPrint={isPrint}
            handlerChange={handlerChange}
            element={element}
            error={error}
            duplicateElement={duplicateElement}
            removeElement={removeElement}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
        {element.type === 'radiobutton' && (
          <RadiobuttonElement
            isPrint={isPrint}
            handlerChange={handlerChange}
            element={element}
            error={error}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
        {element.type === 'link' && (
          <LinkElement element={element} handlerChange={handlerChange} error={error} removeElement={removeElement} />
        )}
        {element.type === 'rating' && (
          <RatingElement handlerChange={handlerChange} element={element} error={error} formHasOnlyOneSubmitter={formHasOnlyOneSubmitter} />
        )}
        {element.type === 'signature' && (
          <SignatureElement
            handlerChange={handlerChange}
            element={element}
            error={error}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
        {element.type === 'table' && (
          <TableElement
            isPrint={isPrint}
            element={element}
            handlerChangeLabel={handlerChangeLabel}
            handlerChange={handlerChange}
            addNewRow={addNewRow}
            error={error}
            highlightPrevious={highlightPrevious}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
            initiatorID={initiatorID}
          />
        )}
        {element.type === 'single-line-header' && (
          <SingleLineHeaderElement
            element={element}
            handlerChange={handlerChange}
            error={error}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
        {element.type === 'submitters-field' && (
          <SubmittersFieldElement isSubmitter={isSubmitter} element={element} handlerChange={handlerChange} error={error} />
        )}
        {element.type === 'file-upload' && (
          <FileUploadElement
            handlerChange={handlerChangeFile}
            element={element}
            error={error}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
        {element.type === 'file-attach' && (
          <FileAttachElement element={element} error={error} handlerChange={handlerChangeFile} removeElement={removeElement} />
        )}
        {element.type === 'login' && (
          <LoginElement
            addNewLogin={addNewLogin}
            isSubmitter={isSubmitter}
            element={element}
            handlerChange={handlerChange}
            error={error}
            formHasOnlyOneSubmitter={formHasOnlyOneSubmitter}
          />
        )}
      </div>
    </div>
  );
};
