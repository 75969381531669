import React, { memo } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import './styles.scss';

const Table = ({ data, columns, styles, className }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={'table-container'} style={styles}>
      <table className={className}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            const { id: headerGroupId } = headerGroup;
            return (
              <tr key={headerGroupId}>
                {headerGroup.headers.map((header) => {
                  return <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>;
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default memo(Table);
