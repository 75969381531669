import React from 'react';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';

export const AddressElement = function FromElement({ element, handlerChange, error, formHasOnlyOneSubmitter }) {
  const isRequiredElement = useIsRequiredElement(element, formHasOnlyOneSubmitter);
  return (
    <>
      <div className={`form-grid-full-name form-grid-full-name-${element.options.size}`}>
        <div className="form-grid-item" style={{ gridColumn: '1 / -1' }}>
          <label htmlFor={element.id + element.inputs[0].name} className="form-label">
            {element.inputs[0].label} <span className="required">{isRequiredElement ? '*' : ''}</span>
          </label>
          <input
            name={element.inputs[0].name}
            id={element.id + element.inputs[0].name}
            type="text"
            className="form-control"
            value={element.inputs[0].valueInput}
            readOnly={element.inputs[0].readOnly}
            onChange={(e) => handlerChange(element, e.target.value, false, 0)}
          />
        </div>
        <div className="form-grid-item">
          <label htmlFor={element.id + element.inputs[1].name} className="form-label">
            {element.inputs[1].label} <span className="required">{isRequiredElement ? '*' : ''}</span>
          </label>
          <input
            name={element.inputs[1].name}
            id={element.id + element.inputs[1].name}
            value={element.inputs[1].valueInput}
            readOnly={element.inputs[1].readOnly}
            onChange={(e) => handlerChange(element, e.target.value, false, 1)}
            type="text"
            className="form-control"
          />
        </div>
        <div className="form-grid-item">
          <label htmlFor={element.id + element.inputs[2].name} className="form-label">
            {element.inputs[2].label} <span className="required">{isRequiredElement ? '*' : ''}</span>
          </label>
          <input
            value={element.inputs[2].valueInput}
            readOnly={element.inputs[2].readOnly}
            onChange={(e) => handlerChange(element, e.target.value, false, 2)}
            name={element.inputs[2].name}
            id={element.id + element.inputs[2].name}
            type="text"
            className="form-control"
          />
        </div>
        {/*<div className="form-grid-item">*/}
        {/*    <label htmlFor={element.id+element.inputs[4].name} className="form-label">{element.inputs[4].label} <span className="required">{element.options.required ? '*' : ''}</span></label>*/}
        {/*    <input*/}
        {/*        value={element.inputs[4].valueInput}*/}
        {/*        onChange={e => handlerChange(element, e.target.value, false, 4)}*/}
        {/*        name={element.inputs[4].name}*/}
        {/*        readOnly={element.inputs[4].readOnly}*/}
        {/*        id={element.id+element.inputs[4].name} type="text" className="form-control" />*/}
        {/*</div>*/}
      </div>
      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
