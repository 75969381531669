import React, { memo } from 'react';

const AddFormToStatisticsSetting = ({ onChange, isAdded = false }) => {
  return (
    <div className="form-control-item-settings">
      <div className="form-settings-label">
        <label className="form-label form-label-big">Add this form to Statistics</label>
      </div>
      <div className="form-settings-inputs">
        <div className="d-flex">
          <div className={`form-control-radio`}>
            <input name="include_in_statistics" type="radio" onChange={() => onChange(true, 'include_in_statistics')} checked={isAdded} />
            <span>Yes</span>
          </div>
          <div className={`form-control-radio`}>
            <input name="include_in_statistics" type="radio" onChange={() => onChange(false, 'include_in_statistics')} checked={!isAdded} />
            <span>No</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AddFormToStatisticsSetting);
