// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cna-task-comments-modal {
  height: max-content;
  max-height: 100vh;
  display: flex;
  padding-bottom: 0 !important;
  flex-direction: column;
}
.cna-task-comments-modal .modal-body {
  height: 100%;
  max-height: calc(100vh - 138px);
  display: flex;
  padding-bottom: 32px;
  flex-direction: column;
}
.cna-task-comments-modal .modal-body .comments__list {
  flex-grow: 1;
  overflow-y: auto;
  overscroll-behavior: contain;
}
.cna-task-comments-modal .modal-body .comments__list__item {
  display: flex;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgb(234, 236, 240);
  justify-content: space-between;
  background-color: #ffffff;
}
.cna-task-comments-modal .modal-body .comments__list__item:nth-child(n+2) {
  margin-top: 16px;
}
.cna-task-comments-modal .modal-body .comments__list__item .content {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: rgb(102, 112, 133);
}
.cna-task-comments-modal .modal-body .new-comment .form-control {
  max-height: 360px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.cna-task-comments-modal .modal-footer {
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-right: -32px;
  position: initial;
}`, "",{"version":3,"sources":["webpack://./src/pages/cna/assignments/unit/components/add-task-comment-modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,4BAAA;EACA,sBAAA;AACF;AACE;EACE,YAAA;EACA,+BAAA;EACA,aAAA;EACA,oBAAA;EACA,sBAAA;AACJ;AAEM;EACE,YAAA;EACA,gBAAA;EACA,4BAAA;AAAR;AAEQ;EACE,aAAA;EACA,SAAA;EACA,aAAA;EACA,kBAAA;EACA,oCAAA;EACA,8BAAA;EACA,yBAAA;AAAV;AAEU;EACE,gBAAA;AAAZ;AAGU;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AADZ;AAQM;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AANR;AAWE;EACE,wBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;AATJ","sourcesContent":[".cna-task-comments-modal {\n  height: max-content;\n  max-height: 100vh;\n  display: flex;\n  padding-bottom: 0 !important;\n  flex-direction: column;\n\n  .modal-body {\n    height: 100%;\n    max-height: calc(100vh - 138px);\n    display: flex;\n    padding-bottom: 32px;\n    flex-direction: column;\n\n    .comments {\n      &__list {\n        flex-grow: 1;\n        overflow-y: auto;\n        overscroll-behavior: contain;\n\n        &__item {\n          display: flex;\n          gap: 12px;\n          padding: 16px;\n          border-radius: 8px;\n          border: 1px solid rgba(234, 236, 240, 1);\n          justify-content: space-between;\n          background-color: #ffffff;\n\n          &:nth-child(n + 2) {\n            margin-top: 16px;\n          }\n\n          .content {\n            font-size: 16px;\n            font-weight: 400;\n            line-height: 22.4px;\n            color: rgba(102, 112, 133, 1);\n          }\n        }\n      }\n    }\n\n    .new-comment {\n      .form-control {\n        max-height: 360px;\n        font-size: 16px;\n        font-weight: 400;\n        line-height: 24px;\n      }\n    }\n  }\n\n  .modal-footer {\n    width: calc(100% + 64px);\n    margin-left: -32px;\n    margin-right: -32px;\n    position: initial;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
