import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { STATIC_ROUTES } from '../../../../constants/routes';
import cx from 'classnames';

const tabs = [
  {
    url: STATIC_ROUTES.REPORTS_REPORT_BUILDER,
    label: 'Report Builder',
  },
  { url: STATIC_ROUTES.REPORTS_STATISTICS, label: 'Statistics' },
];

const PageTabs = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pathnameTabIndex = tabs.findIndex(({ url }) => url === pathname);
  return (
    <>
      <div className={'react-tabs'}>
        <ul className="react-tabs__tab-list" role={'tablist'}>
          {tabs.map(({ url, label }, index) => {
            return (
              <li
                role={'tab'}
                key={index}
                onClick={() => {
                  navigate(url);
                }}
                className={cx('react-tabs__tab', { 'react-tabs__tab--selected': pathnameTabIndex === index })}
              >
                {label}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default memo(PageTabs);
