import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import cx from 'classnames';
import { TEXT_ELEMENT_MODULES, TEXT_ELEMENT_FORMATS } from '../../from-builder/elements/text-element';
import { useIsInitFormPage } from '../../../hook/pages/useIsInitFormPage';

const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'times-new-roman'];
Quill.register(Font, true);

const space = '\u0020';

export const TextElement = function FromElement({ element, handlerChange, removeElement, duplicateElement }) {
  const isInitFormPage = useIsInitFormPage();
  const quillRef = useRef(null);
  const { initiator_can_edit, value: elementOptionsValue, valueInput } = element?.options ?? {};
  const currentUserCanEdit = isInitFormPage && initiator_can_edit;

  const [value, setValue] = useState(isInitFormPage ? elementOptionsValue : valueInput || elementOptionsValue);
  const [showEditor, setShowEditor] = useState(true);

  useLayoutEffect(() => {
    const propsValue = isInitFormPage ? elementOptionsValue : valueInput || elementOptionsValue;
    if (propsValue) {
      const newValue = propsValue.replace(/&nbsp;/g, space);
      setValue(newValue);
    }
  }, [isInitFormPage]);

  useEffect(() => {
    const editor = quillRef.current?.getEditor();

    const handleSpace = (event) => {
      if (editor) {
        if (event.key === ' ') {
          event.preventDefault();
          const range = editor.getSelection();
          if (range) {
            editor.insertText(range.index, space);
            editor.setSelection(range.index + 1);
            const newValue = editor.root.innerHTML;
            setValue(newValue);
          }
        }
      }
    };

    editor?.root?.addEventListener('keydown', handleSpace);
    return () => editor?.root?.removeEventListener('keydown', handleSpace);
  }, []);

  const handelEdit = (e) => {
    setValue(e);
    handlerChange(element, e);
  };

  return (
    <div className={'element-wrapper'}>
      <div className={'element-inner'}>
        {currentUserCanEdit ? (
          <>
            <div>
              <button className="btn-link mb-4 ml-auto" onClick={() => setShowEditor(!showEditor)}>
                {!showEditor && 'Show Editor'}
                {showEditor && 'Hide Editor'}
              </button>
            </div>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={value}
              modules={TEXT_ELEMENT_MODULES}
              formats={TEXT_ELEMENT_FORMATS}
              className={cx({ hidden: !showEditor })}
              onChange={handelEdit}
            />
          </>
        ) : (
          <div className="html-area ql-editor" dangerouslySetInnerHTML={{ __html: value }} />
        )}
      </div>

      {currentUserCanEdit && (
        <div className={'init-form-aside-buttons'}>
          <button onClick={() => duplicateElement(element.id)} className="btn btn-settings btn-icon init-form-copy-element-btn">
            <i className="icon-copy" />
          </button>
          <button onClick={() => removeElement(element.id)} className="btn btn-settings btn-icon btn-remove">
            <i className="icon-remove"></i>
          </button>
        </div>
      )}
    </div>
  );
};
