import React, { useRef, useState } from 'react';
import useOutsideClick from '../../hook/useOutsideClick';

function FacilityItem({ userGroup, handelEdit, handelDeactivate, handelActivated }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef();
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);
  return (
    <div className={`company-profile-facilities-item ${userGroup.deleted_at ? 'company-profile-facilities-item-disabled' : ''}`}>
      <div className="d-flex justify-space-between flex-center">
        <div>
          <h4 className="d-flex flex-center sub-title">
            <img src="/images/company-add-icon-5.svg" className={'mr-2'} alt="" /> {userGroup.name}
          </h4>
        </div>
        <div>
          <div className="dropdown" ref={ref}>
            <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn btn-outline">
              <img src="/images/more-vertical.svg" alt="" />
            </button>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                {!userGroup.deleted_at && (
                  <li>
                    <button className="btn btn-left btn-full btn-text ml-0" onClick={() => handelEdit(userGroup)}>
                      <i className="icon-icon-edit mr-2"></i>Rename
                    </button>
                  </li>
                )}
                {!userGroup.deleted_at && (
                  <li>
                    <button className="btn btn-left btn-full btn-danger btn-text ml-0" onClick={() => handelDeactivate(userGroup)}>
                      <svg className={'mr-2'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="x-circle" clipPath="url(#clip0_3488_29290)">
                          <path
                            id="Icon"
                            d="M12.5003 7.50008L7.50033 12.5001M7.50033 7.50008L12.5003 12.5001M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z"
                            stroke="#D92D20"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3488_29290">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Deactivate
                    </button>
                  </li>
                )}
                {userGroup.deleted_at && (
                  <li>
                    <button className="btn btn-left btn-full btn-success btn-text ml-0" onClick={() => handelActivated(userGroup)}>
                      <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1484_3438)">
                          <path
                            d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                            stroke="#12B76A"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1484_3438">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Activate
                    </button>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FacilityItem;
