import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Layout from '../../layout/default';
import cx from 'classnames';
import { useAuth } from '../../container/authContext';
import Select from 'react-select';
import { ReactComponent as PrinterSVG } from '../../assets/images/printer.svg';
import './styles.scss';
import { getAvailableFacilitiesService, getBedboardDataService, getNotesService } from '../../services/bedboard';
import { isResponseOk } from '../../utility/isResponseOk';
import NotesModal from './components/NotesModal';
import UnitTable from './components/UnitTable';
import ExportToPdfModal from './components/ExportToPDF';
import { isTablet, isMobile } from 'react-device-detect';
import { usePermission } from '../../container/permissionContext';

const Bedboard = () => {
  const todayStr = new Date().toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const initialBedboardData = {
    total_in_house: 0,
    total_with_bed_holds: 0,
    units: [],
  };
  const { user } = useAuth();
  const { hasPermissionByCodeNameAndFacility } = usePermission();

  const [facilityOptions, setFacilityOptions] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [bedboardData, setBedboardData] = useState(initialBedboardData);
  const [notes, setNotes] = useState([]);
  const [isOpenPrintModal, setIsOpenPrintModal] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const hasWritePermission = useMemo(() => {
    return !!hasPermissionByCodeNameAndFacility('contacts_management', selectedFacility?.value);
  }, [user, selectedFacility]);

  const getFacilities = useCallback(async () => {
    try {
      const params = {
        company_id: user.company?.id,
      };
      const response = await getAvailableFacilitiesService(params);

      if (isResponseOk(response)) {
        setFacilityOptions(response.data.map((facility) => ({ label: facility.name, value: facility.id })));
      }
    } catch (e) {
      console.log(e);
    }
  }, [user.company?.id]);

  const getBedboardData = useCallback(async () => {
    if (!selectedFacility?.value) {
      setBedboardData(initialBedboardData);
      return;
    }

    try {
      const params = {
        facility_id: selectedFacility.value,
      };

      const response = await getBedboardDataService(params);

      if (isResponseOk(response)) {
        setBedboardData(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedFacility?.value, JSON.stringify(initialBedboardData)]);

  const getNotes = useCallback(async () => {
    if (!selectedFacility?.value) {
      setNotes([]);
      return;
    }

    try {
      const params = {
        facility_id: selectedFacility?.value,
      };

      const response = await getNotesService(params);

      if (isResponseOk(response)) {
        setNotes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedFacility?.value]);

  useEffect(() => {
    getFacilities();
  }, [getFacilities]);

  useEffect(() => {
    getBedboardData();
  }, [getBedboardData]);

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  return (
    <Layout
      title="Bedboard"
      className={cx('bedboard-page-layout', {
        isTablet,
        isMobile,
      })}
      breadcrumbs={[
        {
          name: 'Bed occupancy',
        },
      ]}
    >
      <div className="page-wrapper">
        <div className="page-header">
          <div className="page-header-left d-flex">
            <div className="form-item form-item-select form-control-item-settings facility-select">
              <label className="form-label">Facility</label>
              <Select
                isSearchable={false}
                options={facilityOptions}
                className="form-control-select"
                classNamePrefix="react-select"
                value={selectedFacility}
                onChange={(e) => setSelectedFacility(e)}
                placeholder="Select Facility"
              />
            </div>
            <div className="total-house-info">
              <div className="total-house-info-wrapper">
                <p>
                  Total in-house: <span className="fw-bolder">{bedboardData.total_in_house}</span>
                </p>
                <p>
                  Total with bed holds: <span className="fw-bolder">{bedboardData.total_with_bed_holds}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="page-header-right">
            <div className="mr-2 today-date-div">
              <span className="today-date">{todayStr}</span>
            </div>
            <div className="d-flex buttons-wrapper">
              <button
                className="btn btn-outline btn-outline-secondary notes-btn"
                disabled={!selectedFacility}
                onClick={() => setIsNotesModalOpen(true)}
              >
                Notes
              </button>
              <button
                className={cx('btn d-flex print-btn', {
                  'btn-outline': !selectedFacility,
                })}
                disabled={!selectedFacility}
                onClick={() => {
                  setIsOpenPrintModal(true);
                }}
              >
                <PrinterSVG className="mr-2" style={{ marginRight: isTablet || isMobile ? '0' : '8px' }} />
                {!isTablet && !isMobile && 'Print'}
              </button>
            </div>
          </div>
        </div>
        <div className="page-content scrollbar">
          {!selectedFacility && (
            <div className="empty">
              <p className="empty-title text-center">To see the information on bed occupancy select the facility</p>
            </div>
          )}

          {!!selectedFacility &&
            (bedboardData.units?.length ? (
              <div className="unit-tables-wrapper">
                {bedboardData.units.map((unit, index) => (
                  <UnitTable
                    facility={{ id: selectedFacility.value, name: selectedFacility.label }}
                    unit={unit}
                    key={index}
                    hasWritePermission={hasWritePermission}
                    getNotes={getNotes}
                    setBedboardData={setBedboardData}
                  />
                ))}
              </div>
            ) : (
              <div className="empty">
                <p className="empty-title text-center">There are no units in this facility yet</p>
              </div>
            ))}
        </div>
      </div>
      {!!isNotesModalOpen && (
        <NotesModal
          facilityID={selectedFacility?.value}
          notes={notes}
          setIsNotesModalOpen={setIsNotesModalOpen}
          getBedboardData={getBedboardData}
          getNotes={getNotes}
          hasWritePermission={hasWritePermission}
          setNotes={setNotes}
        />
      )}
      {isOpenPrintModal && (
        <ExportToPdfModal
          bedboardData={bedboardData}
          facility={{ id: selectedFacility.value, name: selectedFacility.label }}
          onClose={() => setIsOpenPrintModal(false)}
          notes={notes}
        />
      )}
    </Layout>
  );
};

export default Bedboard;
