import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const useIsInitFormPage = () => {
  const location = useLocation();
  const { pathname } = location;
  return useMemo(() => {
    let value = false;
    if (pathname) {
      value = pathname.toLowerCase().includes('initialization-form');
    }
    return value;
  }, [pathname]);
};
