import React, { useState, useEffect } from 'react';
import Layout from '../../layout/default';
import { Breadcrumbs } from '../../components/library/Breadcrumbs';

import { Link, useNavigate } from 'react-router-dom';
import { getFormsInProgress } from '../../services/folder/form-avaliable';
import { useAuth } from '../../container/authContext';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { SubmissionItem } from '../../components/library/submission-item';
import TabsMobile from './mobile-tabs';
function CorporateLibrary() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [breadcrumbs] = useState([
    {
      name: 'Company Library',
      url: '/corporate-library',
    },
    {
      name: 'Forms in progress',
      url: '/forms-in-progress',
    },
  ]);

  const [formsSearch, setFormsSearch] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    changeFolder();
    // eslint-disable-next-line
  }, [user, search]);

  const goToPrev = (id, type) => {
    if (type === 'root') {
      navigate(id, { replace: true });
    }
  };

  const changeFolder = () => {
    const bodyRequest = { get_all: true, company_id: user.company.id, search: search };
    if (!search) {
      delete bodyRequest.search;
    }
    getFormsInProgress(bodyRequest)
      .then((res) => {
        setFormsSearch(res);
      })
      .catch((e) => console.log(e));
  };

  const backToMobile = () => {
    let backToLink = breadcrumbs[breadcrumbs.length - 2]
      ? breadcrumbs[breadcrumbs.length - 2]
      : {
          name: 'Company Library',
          url: '/corporate-library',
        };

    if (backToLink.id) {
      changeFolder(backToLink.id);
    } else {
      if (backToLink.url) {
        navigate(backToLink.url);
      } else {
        navigate('/corporate-library');
      }
    }
  };
  return (
    <Layout title="Company Library">
      <div className="library">
        <DndProvider backend={HTML5Backend}>
          <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
        </DndProvider>
        <TabsMobile />
        <h2 className={'library-title-mobile'}>
          <svg
            onClick={backToMobile}
            className={'mr-2 cursor-pointer'}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
              stroke="#101828"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {breadcrumbs[breadcrumbs.length - 1] ? breadcrumbs[breadcrumbs.length - 1].name : ''}
        </h2>
        <h2 className="library-title">
          <Link to={'/corporate-library/forms/'} className="mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
                stroke="#101828"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          Forms in progress
        </h2>
        <div className="library-filter">
          <div className="library-filter-search form-control-item">
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="Search"
            />
            <i className="icon-search"></i>
          </div>
        </div>

        <>
          {formsSearch.length > 0 && (
            <>
              <h3 className="library-subtitle mt-2">Forms</h3>
              <div className="library-wrapper">
                {formsSearch.map((item) => (
                  <SubmissionItem key={item.id} url={`/form-view/${item.id}`} item={item}></SubmissionItem>
                ))}
              </div>
            </>
          )}
          {!formsSearch.length && !!search && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
                <h4 className="empty-page-subtitle">
                  Try to change the range of keywords. We're here to help you find what you're looking for.
                </h4>
              </div>
            </>
          )}
          {!formsSearch.length && !search && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">This folder is empty</h3>
                <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
              </div>
            </>
          )}
        </>
      </div>
    </Layout>
  );
}

export default CorporateLibrary;
