import React, { memo, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import cx from 'classnames';
import { usePermission } from '../../../../../container/permissionContext';
import FileLoader from '../../../../../components/other/FileLoader';
import { getFileById } from '../../../../../services/upload';
import { isWebVideoByFileExt } from '../../../../../utility/files/isWebVideoByFileExt';
import { getFileExtFromFileName } from '../../../../../utility/files/getFileExtFromFileName';
import { getUserFileService, updateUserFileService } from '../../../../../services/user/files';
import { isResponseOk } from '../../../../../utility/isResponseOk';
import { useParams } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import { openPDF } from '../../../../../utility/openPDF';

const DownloadIcon = ({ styles, onClick }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles} onClick={onClick}>
    <path
      d="M6.6595 13.6706L9.99284 17.0039M9.99284 17.0039L13.3262 13.6706M9.99284 17.0039V9.50391"
      stroke="#101828"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9919 13.3269C17.8047 12.8838 18.4467 12.1826 18.8168 11.334C19.1868 10.4855 19.2637 9.53788 19.0354 8.64077C18.807 7.74365 18.2865 6.94812 17.5558 6.37973C16.8251 5.81134 15.9259 5.50247 15.0002 5.50186H13.9502C13.698 4.52623 13.2278 3.62047 12.5752 2.85269C11.9225 2.0849 11.1042 1.47507 10.182 1.06903C9.25967 0.662995 8.25734 0.471324 7.25031 0.508427C6.24328 0.54553 5.25777 0.810442 4.36786 1.28325C3.47795 1.75605 2.7068 2.42444 2.1124 3.23818C1.51799 4.05191 1.11579 4.98981 0.936028 5.98135C0.756269 6.9729 0.803632 7.9923 1.07456 8.96291C1.34548 9.93352 1.83291 10.8301 2.50021 11.5852"
      stroke="#101828"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const downloadFile = ({ name, downloadUrl }) => {
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = name;
  a.click();
};

const File = (props) => {
  const { item, deleteFile, onFilePreview, isListItem, onMovedFile } = props;
  const { isSuperAdmin, isCompanySystemAdministrator, hasPermission } = usePermission();
  const { user_id: userID } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const userCanManageFile = useMemo(
    () => isSuperAdmin() || isCompanySystemAdministrator() || hasPermission('Users, Positions management'),
    [hasPermission, isCompanySystemAdministrator, isSuperAdmin],
  );

  const [, drag] = useDrag(() => ({
    type: 'file',
    item: { item },
    canDrag: userCanManageFile,
    end: async (item, monitor) => {
      try {
        const dropResult = monitor.getDropResult();
        if (dropResult?.item) {
          if (dropResult?.item.id !== item.item.id) {
            const dragFile = item.item;
            const dropFolder = dropResult?.item;

            const patchFileRes = await updateUserFileService({ userID, fileID: dragFile.id, folderID: dropFolder.id });

            if (isResponseOk(patchFileRes)) {
              onMovedFile(dropFolder.id);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  // preview - undefined | boolean
  const handleFileProcessing = (preview) => {
    setIsLoading(true);

    getUserFileService({ userID, fileID: item.id })
      .then((fileInfoRes) => {
        if (isResponseOk(fileInfoRes) && fileInfoRes.data) {
          const { file } = fileInfoRes.data;
          if (file) {
            getFileById(file.uuid)
              .then((fileByIdRes) => {
                if (fileByIdRes) {
                  if (fileByIdRes?.config === 'success' && fileByIdRes?.message) {
                    const fileData = { ...fileInfoRes, url: fileByIdRes.message, downloadUrl: fileByIdRes.message };
                    const fileExtension = getFileExtFromFileName(file?.name);
                    const isWebVideo = isWebVideoByFileExt(fileExtension);
                    const isPDF = fileExtension === 'pdf';

                    if (preview === true && onFilePreview) {
                      // File preview logic
                      if (isWebVideo) {
                        onFilePreview(fileData);
                      } else if (isPDF) {
                        fetch(fileData.downloadUrl)
                          .then((res) => res.blob())
                          .then(openPDF);
                      } else {
                        downloadFile(fileData);
                      }
                    } else {
                      downloadFile(fileData);
                    }
                  }
                }
                setIsLoading(false);
              })
              .catch((e) => {
                console.log(e);
                setIsLoading(false);
              });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  };

  const handleFilePreview = () => handleFileProcessing(true);

  return (
    <>
      {!isListItem && (
        <Tooltip
          role={'tooltip'}
          opacity={1}
          id={`table-tooltip-${item.id}`}
          place="top"
          content={item.name}
          positionStrategy={'absolute'}
        />
      )}
      <div
        ref={drag}
        data-tooltip-id={`table-tooltip-${item.id}`}
        className={cx('library-item', {
          'list-item': isListItem,
        })}
        style={{ marginRight: isListItem ? 0 : undefined, cursor: 'default' }}
      >
        <div className="library-item-text" style={{ cursor: 'pointer' }} onClick={handleFilePreview}>
          <img src="/images/file-big-icon.png" alt="" /> {item.name}
        </div>
        {isListItem && <DownloadIcon styles={{ cursor: 'pointer' }} onClick={handleFileProcessing} />}
        {userCanManageFile && (
          <button
            className={'btn btn-remove btn-icon'}
            style={{ marginLeft: isListItem ? 16 : 0, marginRight: isListItem ? 6 : 16 }}
            onClick={(e) => {
              e.stopPropagation();
              deleteFile(item.id);
            }}
          >
            <i className={'icon-remove'} style={{ fontSize: isListItem ? 20 : undefined }} />
          </button>
        )}
        {isLoading && <FileLoader />}
      </div>
    </>
  );
};

export default memo(File);
