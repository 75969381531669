import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { RenderForm } from './render-form';
import { FormActionsMenu } from '../other/FormActionsMenu';
import { usePermission } from '../../container/permissionContext';

function PrintModal({ isOpen, closeModal, formData, deleteForm, archiveForm, activateForm, hideActions }) {
  const { hasPermissionByCodeName, isSuperAdmin, isCompanySystemAdministrator } = usePermission();
  const { deleted_at, id: formId } = formData ?? {};

  const [initData, setInitData] = useState({});

  const handleDeleteForm = async () => {
    try {
      if (deleteForm) {
        await deleteForm(formId);
        closeModal();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleActivateForm = async () => {
    try {
      if (activateForm) {
        await activateForm(formId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleArchiveForm = useCallback(async () => {
    try {
      if (archiveForm) {
        await archiveForm(formData);
        closeModal();
      }
    } catch (e) {
      console.log(e);
    }
  }, [formId]);

  const formActionsMenu = useMemo(() => {
    let menu = [];

    if ((isSuperAdmin() || isCompanySystemAdministrator()) && hasPermissionByCodeName('corporate_library_management')) {
      menu = [
        ...(deleted_at
          ? [
              <button style={{ color: '#12b76a' }} className="btn btn-full" onClick={handleActivateForm}>
                <svg className="mr-2" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1484_3438)">
                    <path
                      d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                      stroke="#12B76A"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1484_3438">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Activate
              </button>,
            ]
          : [
              <button className="btn btn-full" onClick={handleArchiveForm}>
                <i className="icon-icon-x mr-2" />
                Archive
              </button>,
            ]),
        <button className="btn btn-full btn-remove" onClick={handleDeleteForm}>
          <i className="icon-remove mr-2" />
          Delete
        </button>,
      ];
    }

    return menu;
  }, [
    deleted_at,
    handleActivateForm,
    handleArchiveForm,
    handleDeleteForm,
    hasPermissionByCodeName,
    isCompanySystemAdministrator,
    isSuperAdmin,
  ]);

  const handelCloseModal = () => closeModal();

  useEffect(() => {
    if (formData) {
      if (formData?.submitted_data?.length) {
        const form_fields = formData?.form_fields.map((form_field) => {
          const submitted_item = formData?.submitted_data?.find((item) => form_field.id === item.id);
          const saved_item = formData?.saved_data?.find((item) => form_field.id === item.id);

          if (submitted_item) {
            return isReadOnly(submitted_item, formData);
          }

          if (saved_item) {
            return saved_item;
          }

          return isReadOnly(form_field, formData);
        });

        setInitData({ ...initData, form_fields: form_fields });
      } else if (formData?.form_fields) {
        if (formData?.saved_data?.length) {
          const form_fields = formData?.form_fields.map((form_field) => {
            const saved_data = formData?.saved_data?.find((item) => form_field.id === item.id);

            if (saved_data) {
              return saved_data;
            }

            return isReadOnly(form_field, formData);
          });

          setInitData({ form_fields: JSON.parse(JSON.stringify(form_fields)), ...initData });
        } else {
          const form_fields = formData?.form_fields.map((item) => {
            return isReadOnly(item, formData);
          });

          setInitData({ form_fields: form_fields, ...initData });
        }
      }
    }

    // eslint-disable-next-line
  }, [formData]);

  const isReadOnly = (element, res) => {
    if (element.type === 'name' || element.type === 'address' || element.type === 'checkbox') {
      element.inputs.map((input) => {
        input['readOnly'] = res.can_edit_previous_submissions ? '' : 'readonly';

        return input;
      });
    }

    if (element.type === 'table') {
      element.rows.map((row) => {
        if (row.cols) {
          row.cols.forEach((col) => {
            col['readOnly'] = res.can_edit_previous_submissions ? null : 'readonly';
          });
        }
        return row;
      });
    }

    element.options['readOnly'] = res.can_edit_previous_submissions ? null : 'readonly';

    return element;
  };

  const handelInitSettings = useCallback(
    (res, key) => {
      try {
        if (initData[key] && key === 'form_fields' && Array.isArray(res)) {
          setInitData((prevState) => {
            return {
              ...prevState,
              [key]: [...res],
            };
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [initData],
  );

  return (
    <Modal
      className="modal-default print-modal general-form-tab-content"
      isOpen={isOpen}
      onRequestClose={handelCloseModal}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        <div style={{ display: 'inline-flex', gap: 16 }}>
          <button onClick={window.print} className={'btn btn-outline  btn-outline-secondary d-flex'}>
            <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clipPath="url(#clip0_4773_10494)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.16634 1.66536C4.16634 1.20513 4.53944 0.832031 4.99967 0.832031H14.9997C15.4599 0.832031 15.833 1.20513 15.833 1.66536V6.66536H16.6663C17.3294 6.66536 17.9653 6.92876 18.4341 7.3976C18.9029 7.86644 19.1663 8.50232 19.1663 9.16536V13.332C19.1663 13.9951 18.9029 14.631 18.4341 15.0998C17.9653 15.5686 17.3294 15.832 16.6663 15.832H15.833V18.332C15.833 18.7923 15.4599 19.1654 14.9997 19.1654H4.99967C4.53944 19.1654 4.16634 18.7923 4.16634 18.332V15.832H3.33301C2.66997 15.832 2.03408 15.5686 1.56524 15.0998C1.0964 14.631 0.833008 13.9951 0.833008 13.332V9.16536C0.833008 8.50232 1.0964 7.86644 1.56524 7.3976C2.03408 6.92876 2.66997 6.66536 3.33301 6.66536H4.16634V1.66536ZM5.83301 17.4987H14.1663V12.4987H5.83301V17.4987ZM15.833 14.1654V11.6654C15.833 11.2051 15.4599 10.832 14.9997 10.832H4.99967C4.53944 10.832 4.16634 11.2051 4.16634 11.6654V14.1654H3.33301C3.11199 14.1654 2.90003 14.0776 2.74375 13.9213C2.58747 13.765 2.49967 13.553 2.49967 13.332V9.16536C2.49967 8.94435 2.58747 8.73239 2.74375 8.57611C2.90003 8.41983 3.11199 8.33203 3.33301 8.33203H16.6663C16.8874 8.33203 17.0993 8.41983 17.2556 8.57611C17.4119 8.73239 17.4997 8.94435 17.4997 9.16536V13.332C17.4997 13.553 17.4119 13.765 17.2556 13.9213C17.0993 14.0776 16.8874 14.1654 16.6663 14.1654H15.833ZM14.1663 2.4987V6.66536H5.83301V2.4987H14.1663Z"
                  fill="#7F56D9"
                />
              </g>
              <defs>
                <clipPath id="clip0_4773_10494">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Print
          </button>
          {!hideActions && <FormActionsMenu menu={formActionsMenu} />}
        </div>
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div className={'print'}>
        {formData && (
          <RenderForm
            isPrint
            isSubmitter
            handelInitSettings={handelInitSettings}
            title={
              formData.title_options
                ? {
                    ...formData?.title_options,
                    options: { ...formData?.title_options.options, value: formData.name },
                  }
                : formData.name
            }
            formElement={initData?.form_fields}
          />
        )}
      </div>
    </Modal>
  );
}

export default memo(PrintModal);
