import React, { memo } from 'react';
import { useAuth } from '../../../container/authContext';
import './styles.scss';

const HeaderLogOutButton = () => {
  const { logout, user } = useAuth();
  let layout = null;
  if (user) {
    layout = (
      <button className="header__log-out-btn" onClick={logout}>
        <span>Log Out</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            stroke="#98a2b3"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9M16 17L21 12M21 12L16 7M21 12H9"
          />
        </svg>
      </button>
    );
  }
  return layout;
};

export default memo(HeaderLogOutButton);
