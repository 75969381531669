import React from 'react';

export const cnaColors = [
  {
    bg: 'rgba(255, 218, 51, 0.5)',
    border: 'rgba(184, 134, 11, 0.5)',
  },
  {
    bg: 'rgba(0, 255, 127, 0.5)',
    border: 'rgba(46, 139, 87, 0.5)',
  },
  {
    bg: 'rgba(255, 105, 180, 0.5)', // Bright Pink
    border: 'rgba(255, 20, 147, 0.5)', // Deep Pink
  },
  {
    bg: 'rgba(135, 206, 235, 0.5)',
    border: 'rgba(70, 130, 180, 0.5)',
  },
  {
    bg: 'rgba(200, 200, 200, 0.5)', // Soft Bright Gray
    border: 'rgba(120, 120, 120, 0.5)',
  },
  {
    bg: 'rgba(255, 50, 14, 0.5)',
    border: 'rgba(139, 0, 0, 0.5)',
  },
  {
    bg: 'rgba(123, 104, 238, 0.5)', // Medium Slate Blue
    border: 'rgba(72, 61, 139, 0.5)',
  },
  {
    bg: 'rgba(70, 130, 255, 0.5)', // Brighter Blue
    border: 'rgba(20, 70, 220, 0.5)',
  },
  {
    bg: 'rgba(118, 158, 38, 0.5)', // Yellow Green
    border: 'rgba(85, 107, 47, 0.5)',
  },
  {
    bg: 'rgba(255, 119, 0, 0.5)', // Peach Puff
    border: 'rgba(139, 69, 19, 0.5)',
  },
];

export const tableColumns = [
  {
    header: 'CNA',
    accessorKey: 'cna',
    cellClassName: 'cna',
  },
  {
    header: 'Room',
    accessorKey: 'room',
    cellClassName: 'room',
  },
  {
    header: 'Resident',
    accessorKey: 'resident',
    cellClassName: 'resident',
  },
  {
    header: 'Tasks',
    accessorKey: 'tasks',
    cellClassName: 'tasks',
  },
];

export const shiftOptions = [
  {
    label: '7-3',
    value: '7_3',
  },
  {
    label: '3-11',
    value: '3_11',
  },
  {
    label: '11-7',
    value: '11_7',
  },
];

export const periodOptions = [
  {
    label: 'Days in a week',
    value: 'week_days',
  },
  {
    label: 'Custom dates',
    value: 'custom_dates',
  },
  {
    label: 'Custom interval',
    value: 'custom_interval',
  },
];

export const weekDaysOptions = [
  {
    label: 'Sunday',
    value: 0,
  },
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
  },
  {
    label: 'Thursday',
    value: 4,
  },
  {
    label: 'Friday',
    value: 5,
  },
  {
    label: 'Saturday',
    value: 6,
  },
];

export const quantityOptions = [
  {
    label: 'every',
    value: 1,
  },
  {
    label: 'every 2nd',
    value: 2,
  },
  {
    label: 'every 3rd',
    value: 3,
  },
  {
    label: 'every 4th',
    value: 4,
  },
  {
    label: 'every 5th',
    value: 5,
  },
];
