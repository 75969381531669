import React from 'react';
import { TextElementSettings } from './text-element-settings';
import { TitleElementSettings } from './title-element-settings';
import { SingleLineElementSettings } from './single-line-element-settings';
import { MultilineElementSettings } from './multiline-element-settings';
import { NumberElementSettings } from './number-element-settings';
import { PhoneElementSettings } from './phone-element-settings';
import { EmailElementSettings } from './email-element-settings';
import { FullNameElementSettings } from './full-name-element-settings';
import { AddressElementSettings } from './address-element-settings';
import { DateElementSettings } from './date-element-settings';
import { TimeElementSettings } from './time-element-settings';
import { DropdownElementSettings } from './dropdown-element-settings';
import { CheckboxElementSettings } from './checkbox-element-settings';
import { LinkElementSettings } from './link-element-settings';
import { RatingElementSettings } from './rating-element-settings';
import { SignatureElementSettings } from './signature-element-settings';
import { TableElementSettings } from './table-element-settings';
import { SingleLineHeaderElementSettings } from './single-line-header-element-settings';
import { SubmittersFieldElementSettings } from './submitters-field-element-settings';
import { FileUploadElementSettings } from './file-upload-element-settings';
import { ElectronicSignatureElementSettings } from './electronic-signature-element-settings';
import { FileAttachElementSettings } from './file-attach-element-settings';

export const FormBuilderElementSettings = ({
  element,
  isOpen,
  onClose,
  handlerChangeOption,
  handlerChangeTitle,
  duplicateElement,
  saveElement,
}) => {
  return (
    <div className={`form-builder-aside-property ${isOpen ? 'form-builder-aside-property-open' : ''}`}>
      {element && (
        <>
          <h2 className="form-builder-aside-property-title">
            {element.name} Properties{' '}
            <button className="btn btn-icon" onClick={onClose}>
              <i className="icon-icon-x"></i>
            </button>
          </h2>
          <div className="tabs">
            <div className="tabs-content">
              {element.type === 'title' && (
                <TitleElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeTitle} />
              )}
              {element.type === 'text' && (
                <TextElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'single-line' && (
                <SingleLineElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'login' && (
                <ElectronicSignatureElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'single-line-header' && (
                <SingleLineHeaderElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'submitters-field' && (
                <SubmittersFieldElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'signature' && (
                <SignatureElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'multiline-line' && (
                <MultilineElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'number' && (
                <NumberElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'phone' && (
                <PhoneElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'email' && (
                <EmailElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'name' && (
                <FullNameElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'address' && (
                <AddressElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'date' && (
                <DateElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'time' && (
                <TimeElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'link' && (
                <LinkElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'rating' && (
                <RatingElementSettings element={element} duplicateElement={duplicateElement} handlerChangeOption={handlerChangeOption} />
              )}
              {element.type === 'file-upload' && (
                <FileUploadElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'file-attach' && (
                <FileAttachElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'dropdown' && (
                <DropdownElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  onClose={onClose}
                  saveElement={saveElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'checkbox' && (
                <CheckboxElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  onClose={onClose}
                  saveElement={saveElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'radiobutton' && (
                <CheckboxElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  onClose={onClose}
                  saveElement={saveElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
              {element.type === 'table' && (
                <TableElementSettings
                  element={element}
                  duplicateElement={duplicateElement}
                  onClose={onClose}
                  saveElement={saveElement}
                  handlerChangeOption={handlerChangeOption}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
