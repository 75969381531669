import React, { useCallback, useEffect, useState } from 'react';
import SettingsModal from './components/settingsModal';
import { useIsInitFormPage } from '../../../../hook/pages/useIsInitFormPage';

export const LinkElement = function FromElement({ element, handlerChange, removeElement }) {
  const isInitFormPage = useIsInitFormPage();
  const { options } = element;
  const { initiator_can_edit } = options ?? {};
  const currentUserCanEdit = isInitFormPage && initiator_can_edit;

  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);
  const [style, setStyle] = useState({
    fontSize: element.options.fontSize,
    textAlign: element.options.alignment,
  });

  useEffect(() => {
    setStyle(() => {
      return {
        fontSize: `${element.options.fontSize}px`,
        textAlign: element.options.alignment,
      };
    });
  }, [element]);

  const handelOpenSettingsModal = () => setIsOpenSettingsModal(true);

  const handelCloseSettingsModal = useCallback(() => setIsOpenSettingsModal(false), []);

  return (
    <div className="element-wrapper">
      <div className="element-inner">
        {element.options.label && <label className={'form-label'}>{element.options.label}</label>}
        {element.options.link ? (
          <p style={style}>
            <a target={'_blank'} href={element.options.link} rel="noreferrer">
              {element.options.value}
            </a>
          </p>
        ) : (
          'Empty Link'
        )}
        {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
      </div>

      {currentUserCanEdit && (
        <div className={'init-form-aside-buttons'}>
          <button onClick={handelOpenSettingsModal} className="btn btn-settings btn-icon settings-modal-btn">
            <i className="icon-settings" />
          </button>
          <button onClick={() => removeElement(element.id)} className="btn btn-settings btn-icon btn-remove">
            <i className="icon-remove"></i>
          </button>
        </div>
      )}
      <SettingsModal element={element} isOpen={isOpenSettingsModal} handlerChange={handlerChange} onClose={handelCloseSettingsModal} />
    </div>
  );
};
