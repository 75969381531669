import React, { memo, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useDebounce } from 'use-debounce';
import { initialFiltersState, statusesOptions, tableColumns } from './constants';
import ReactPaginate from 'react-paginate';
import FormSettingsModal from './components/form-settings-modal';
import StopInitiationModal from './components/stop-initiation-modal';
import './styles.scss';
import {
  deleteCorporateLibraryFormInitiation,
  getCorporateFormManagementForms,
  getCorporateFormManagementInitiators,
  removeCorporateFormManagementFormSubmissions,
  stopCorporateFormManagementFormInitiation,
} from '../../../services/libraries/corporate-library/form-management';
import { isResponseOk } from '../../../utility/isResponseOk';
import cx from 'classnames';
import { localToUTCStringForDateRange } from '../../../utility/dates/localToUTCForDateRange';
import PageLoader from '../../other/PageLoader';
import { utcToLocalDateFormatter } from '../../../utility/dates/utcToLocalDate';
import DeleteInitiationModal from './components/delete-initiation-modal';

const FormManagementModal = ({ onClose, formId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [initiatorsOptions, setInitiatorsOptions] = useState([]);
  const [filters, setFilters] = useState(initialFiltersState);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [forms, setForms] = useState(undefined);
  const [isFiltered, setIsFiltered] = useState(false);
  const [settingsFormId, setSettingsFormId] = useState(null);
  const [stopInitiationFormId, setStopInitiationFormId] = useState(null);
  const [deleteInitiationFormId, setDeleteInitiationFormId] = useState(null);

  const [debouncedSearch] = useDebounce(search, 300);

  const initiationStartDate = filters?.initiationDate?.start;
  const initiationEndDate = filters?.initiationDate?.end;

  const handleInitPage = async () => {
    try {
      setIsLoading(true);
      await getInitiatedForms();

      const initiatorsRes = await getCorporateFormManagementInitiators(formId);

      if (isResponseOk(initiatorsRes)) {
        const { data } = initiatorsRes;
        if (Array.isArray(data)) {
          setInitiatorsOptions(data.map((item) => ({ value: item.initiator_id, label: item.initiator_full_name })));
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formId) {
      handleInitPage();
    } else {
      clearFormsData();
      clearFilters();
    }
  }, [formId]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setIsFiltered(true);
  };

  const handleInitiationDateChange = (date) => {
    handleFilter('initiationDate', { start: date[0], end: date[1] });
  };

  const handleFilter = useCallback((key, value) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
    setIsFiltered(true);
  }, []);

  const clearFilters = () => {
    setSearch('');
    setFilters(initialFiltersState);
    isFiltered && setPage(1);
    setIsFiltered(false);
  };

  const clearFormsData = () => {
    setForms(null);
    setPage(1);
    setTotalPages(0);
    setTotal(0);
  };

  const getInitiatedForms = async () => {
    try {
      const filteredFormStatus = filters.status.value;
      const params = { page };

      if (search.length) {
        params['form_name'] = search;
      }
      if (filters.initiators.length) {
        params['initiator_id'] = filters.initiators.map((item) => item.value);
      }
      if (initiationStartDate) {
        params['initiation_date_after'] = localToUTCStringForDateRange(initiationStartDate, 'start');
      }
      if (initiationEndDate) {
        params['initiation_date_before'] = localToUTCStringForDateRange(initiationEndDate, 'end');
      }
      if (filteredFormStatus) {
        params['status'] = filteredFormStatus;
      }

      const getCorporateFormManagementFormsResponse = await getCorporateFormManagementForms(formId, params);

      if (isResponseOk(getCorporateFormManagementFormsResponse)) {
        const { data: { total_pages = 1, count, results, page = 1 } = {} } = getCorporateFormManagementFormsResponse;
        setForms(results);
        setTotalPages(total_pages);
        setTotal(count);
        setPage(page);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await getInitiatedForms();
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [filters, page, debouncedSearch]);

  const handlePageClick = (event) => setPage(event.selected + 1);

  const closeSettingsModal = useCallback(() => setSettingsFormId(null), []);

  const closeStopInitiationModal = useCallback(() => setStopInitiationFormId(null), []);

  const handleStopFormInitiation = useCallback(
    async (stopInitiationType) => {
      try {
        let response;
        if (stopInitiationType === 'stop') {
          response = await stopCorporateFormManagementFormInitiation(formId, stopInitiationFormId);
        } else if (stopInitiationType === 'remove') {
          response = await removeCorporateFormManagementFormSubmissions(formId, stopInitiationFormId);
        }

        if (isResponseOk(response)) {
          setStopInitiationFormId(null);
          await getInitiatedForms();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [formId, getInitiatedForms, stopInitiationFormId],
  );

  const handleDeleteInitiation = useCallback(async () => {
    if (deleteInitiationFormId) {
      setIsLoading(true);
      const response = await deleteCorporateLibraryFormInitiation(formId, deleteInitiationFormId);
      if (isResponseOk(response)) {
        setDeleteInitiationFormId(null);
        await getInitiatedForms();
      }
      setIsLoading(false);
    }
  }, [deleteInitiationFormId, formId, getInitiatedForms]);

  const closeDeleteInitiationModal = useCallback(() => setDeleteInitiationFormId(null), []);

  const renderSearch = () => {
    return (
      <div className={'search-input-wrapper'}>
        <input
          id="search"
          value={search}
          onChange={handleSearch}
          type="text"
          className="form-control"
          placeholder="Search by Form name + attached text field..."
        />
        <i className={'icon-search'} />
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className={'filters'}>
        <div className={'filters-block'}>
          <span className="label">Initiator</span>
          <Select
            isMulti
            options={initiatorsOptions}
            value={filters.initiators}
            onChange={(e) => handleFilter('initiators', e)}
            className="form-control-select filter"
            classNamePrefix="react-select-multi"
          />
        </div>
        <div className={'filters-block'}>
          <span className="label">Initiation date (period)</span>
          <DatePicker
            isClearable
            selectsRange
            disabledKeyboardNavigation
            className="form-control filter"
            selected={initiationStartDate}
            startDate={initiationStartDate}
            endDate={initiationEndDate}
            placeholderText={'Select date'}
            onChange={handleInitiationDateChange}
          />
        </div>
        <div className={'filters-block'}>
          <span className="label">Status</span>
          <Select
            value={filters.status}
            options={statusesOptions}
            classNamePrefix="react-select"
            className="form-control-select filter"
            onChange={(e) => handleFilter('status', e)}
          />
        </div>
        <div className={'filters-block'}>
          <button className="btn btn-outline clear-filters-btn" onClick={clearFilters}>
            Clear All
          </button>
        </div>
      </div>
    );
  };

  const renderFormsTable = () => {
    return (
      <div className="table-responsive table-default table-full table-forms">
        <div className="table-wrapper">
          {forms?.length ? (
            <div className="table">
              <div className="tr">
                {tableColumns.map((item) => {
                  const { label, key } = item;
                  return (
                    <div className="th" key={`table-th-${key}`} style={{ textAlign: key !== 'name' ? 'center' : undefined }}>
                      {label}
                      {key === 'name' && !!total && <span className="th-total">{`(Total: ${total})`}</span>}
                    </div>
                  );
                })}
              </div>
              {forms.map((item) => {
                const { id, form_name, initiation_date, initiator_full_name, status, stopped_at, stopped_by } = item;
                const isStopped = Boolean(stopped_at || stopped_by);
                let statusValue;

                if (isStopped) {
                  statusValue = `Stopped ${utcToLocalDateFormatter(stopped_at)}`;
                  if (stopped_by) {
                    statusValue += ` By ${stopped_by}`;
                  }
                } else if (status) {
                  statusValue = statusesOptions.find(({ value }) => {
                    return value ? value?.split(',').includes(status) : false;
                  })?.label;
                }
                return (
                  <div key={id} className={cx('tr', { stopped: isStopped })}>
                    <>
                      <div className="td">
                        <p
                          style={{
                            maxWidth: 270,
                            display: 'inline-block',
                            color: 'var(--Secondary-eading, #101828)',
                          }}
                        >
                          {form_name}
                        </p>
                      </div>
                      <div className="td">{initiator_full_name}</div>
                      <div className="td">{initiation_date ? utcToLocalDateFormatter(initiation_date) : '-'}</div>
                      <div className="td">
                        <span
                          style={{
                            maxWidth: 140,
                            display: 'inline-block',
                            color: isStopped ? 'var(--tertiary-600, #B42318)' : undefined,
                          }}
                        >
                          {statusValue}
                        </span>
                      </div>
                      <div className="td">
                        <div className="table-row-actions">
                          <span className={'view-settings-button'} onClick={() => setSettingsFormId(id)}>
                            View Settings
                          </span>
                          {!stopped_at && (
                            <div className={'stop-initiation-button'} onClick={() => setStopInitiationFormId(id)}>
                              <i className="icon-icon-x" />
                              <span>Stop the Initiation</span>
                            </div>
                          )}
                          <button className="btn btn-remove btn-icon delete-initiation-btn" onClick={() => setDeleteInitiationFormId(id)}>
                            <i className="icon-remove" />
                          </button>
                        </div>
                      </div>
                    </>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="empty-page">
              <h3 className="empty-page-title">{isFiltered ? 'Does not match any results!' : !isLoading && 'Data not found'}</h3>
            </div>
          )}

          {!!forms?.length && !!totalPages && (
            <ReactPaginate
              breakLabel="..."
              nextLabel="next ->"
              forcePage={page - 1}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="<- previous"
              className={'react-pagination'}
              onPageChange={handlePageClick}
            />
          )}
          {isLoading && <PageLoader />}
        </div>
      </div>
    );
  };

  const render = () => {
    let layout = null;

    if (formId) {
      layout = (
        <>
          <Modal
            id={'formManagementModal'}
            className="modal-default form-management-modal"
            overlayClassName={'form-management-modal-overlay'}
            isOpen={true}
            onRequestClose={onClose}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <h2 className="modal-header">
              {'Form management'}
              <button className="btn btn-icon" onClick={onClose}>
                <i className="icon-icon-x" />
              </button>
            </h2>
            <div className="modal-body">
              {renderSearch()}
              {renderFilters()}
              {renderFormsTable()}
            </div>
          </Modal>
          <FormSettingsModal formId={formId} settingsFormId={settingsFormId} onClose={closeSettingsModal} setIsLoading={setIsLoading} />
          <StopInitiationModal
            isOpen={!!stopInitiationFormId}
            onClose={closeStopInitiationModal}
            hasFrequencySettings={forms?.find((f) => f.id === stopInitiationFormId)?.has_frequency_settings}
            onStopInitiation={handleStopFormInitiation}
          />

          <DeleteInitiationModal
            isOpen={!!deleteInitiationFormId}
            onDeleteInitiation={handleDeleteInitiation}
            onClose={closeDeleteInitiationModal}
          />
        </>
      );
    }

    return layout;
  };

  return render();
};

export default memo(FormManagementModal);
