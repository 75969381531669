import client from '../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancelList;
let cancelAll;
let cancel;
let cancelPositions;
let cancelUserGroup;
let cancelUserGroupInit;
let cancelUserGroupDefault;

export const getCompanies = async (page) => {
  if (cancelList !== undefined) {
    cancelList();
  }

  let params = {};

  if (page === 'getAll') {
    params['get_all'] = true;
  } else {
    params['page'] = page;
  }

  const response = await client.get('api/companies/', {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancelList = c;
    }),
  });

  return response.data;
};

export const getCompanySubmissionData = async (submission_id) => {
  if (cancelList !== undefined) {
    cancelList();
  }

  let params = {
    submission_id: submission_id,
  };

  const response = await client.get('api/companies/get-company-creation-data-from-submission/', {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancelList = c;
    }),
  });

  return response.data;
};
export const getFacilitySubmissionData = async (submission_id) => {
  if (cancelList !== undefined) {
    cancelList();
  }

  let params = {
    submission_id: submission_id,
  };

  const response = await client.get('api/facilities/get-facility-creation-data-from-submission/', {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancelList = c;
    }),
  });

  return response.data;
};

export const getUserSubmissionData = async (submission_id) => {
  if (cancelList !== undefined) {
    cancelList();
  }

  let params = {
    submission_id: submission_id,
  };

  const response = await client.get('api/users/get-user-creation-data-from-submission/', {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancelList = c;
    }),
  });

  return response.data;
};

export const getCompaniesAll = async () => {
  if (cancelAll !== undefined) {
    cancelAll();
  }

  const response = await client.get('api/companies/', {
    params: { get_all: true },
    cancelToken: new CancelToken(function executor(c) {
      cancelAll = c;
    }),
  });

  return response.data;
};

export const getCompanyById = async (id) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }

    const response = await client.get(`api/companies/${id}`, {
      params: { status: 'all' },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });

    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getCompanyPositions = async (id, page, facilities_ids) => {
  if (cancelPositions !== undefined) {
    cancelPositions();
  }

  let params = {};

  if (page === 'getAll') {
    params['get_all'] = true;
    params['status'] = 'active';
  }

  if (facilities_ids) {
    params['facilities_ids'] = facilities_ids;
  }

  const response = await client.get(`api/companies/${id}/positions`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelPositions = c;
    }),
  });

  return response.data;
};

export const getCompanyUnit = async (id, page, facilities_ids) => {
  if (cancelPositions !== undefined) {
    cancelPositions();
  }

  let params = {};

  if (page === 'getAll') {
    params['get_all'] = true;
    params['status'] = 'active';
  }

  if (facilities_ids) {
    params['facilities_ids'] = facilities_ids;
  }

  const response = await client.get(`api/companies/${id}/units`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelPositions = c;
    }),
  });

  return response.data;
};

export const getCompanyUserGroup = async (id, page, facility_id) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  let params = {};

  if (page === 'getAll') {
    params['get_all'] = true;
    params['status'] = 'active';
    params['action'] = 'users_positions_management';
  } else if (page) {
    params['page'] = page;
  }

  if (facility_id) {
    params['facilities_ids'] = facility_id;
  }

  const response = await client.get(`api/companies/${id}/user-groups`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};

export const getCompanyUserGroupList = async (id, params) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  const response = await client.get(`api/companies/${id}/user-groups`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};
export const getCompanyUserGroupDefaultList = async (id, params) => {
  if (cancelUserGroupDefault !== undefined) {
    cancelUserGroupDefault();
  }

  const response = await client.get(`api/companies/${id}/default/user-groups`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroupDefault = c;
    }),
  });

  return response.data;
};

export const createCompany = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post('api/companies/', data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const updateCompany = async (id, data) => {
  if (!data) {
    return;
  }

  const response = await client.patch(`api/companies/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const disableCompany = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/companies/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deleteCompany = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/companies/${id}/hard-delete/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const activateCompany = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/companies/${id}/restore/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getCompanyUserGroupListSettings = async (params) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  const response = await client.get(`api/corporate-library/forms/settings/user-groups/`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};

export const getCompanyUserGroupListSettingsInit = async (params) => {
  if (cancelUserGroupInit !== undefined) {
    cancelUserGroupInit();
  }

  const response = await client.get(`api/corporate-library/forms/initiate/settings/user-groups/`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroupInit = c;
    }),
  });

  return response.data;
};
export const getCompanyUserGroupListAccessSettings = async (params) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  const response = await client.get(`api/corporate-library/forms/access-settings/user-groups`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};

export const getCompanyUserGroupListFileSettings = async (params) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  const response = await client.get(`api/corporate-library/files/folder/access-settings/user-groups/`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};

export const copyCompanyApi = async (id, data) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  const response = await client.post(`api/companies/${id}/copy_company/`, data, {
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};

export const copyFacilityApi = async (id, data) => {
  if (cancelUserGroup !== undefined) {
    cancelUserGroup();
  }

  const response = await client.post(`api/facilities/${id}/copy_facility/`, data, {
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    cancelToken: new CancelToken(function executor(c) {
      cancelUserGroup = c;
    }),
  });

  return response.data;
};
