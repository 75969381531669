import { StickyTableHeader } from 'vh-sticky-table-header';
import React, { forwardRef, useLayoutEffect, useRef } from 'react';
import mobile from 'is-mobile';

export const TableWithStickyHeader = forwardRef(({ children, isPrint }, ref) => {
  const tableRef = useRef(null);
  const tableCloneRef = useRef(null);
  const isUserAgentMobile = (req) => {
    return mobile({ ua: req });
  };

  const isUserAgentTablet = (req) => {
    return mobile({ ua: req, tablet: true, featureDetect: true });
  };

  useLayoutEffect(() => {
    if (tableRef.current && tableCloneRef.current) {
      // Initialize the sticky header.
      const sticky = new StickyTableHeader(tableRef.current, tableCloneRef.current, {
        max: isPrint ? 0 : isUserAgentMobile() || isUserAgentTablet() ? 150 : 80,
      });
      // Make sure to destory the sticky header once the main table is unmounted.
      return () => sticky.destroy();
    }
    // eslint-disable-next-line
  }, [children]);

  return (
    <div style={{ maxWidth: '100%' }}>
      <div className="table_container" ref={ref}>
        <table className={'form-control-table'} ref={tableRef}>
          {children}
        </table>
      </div>
      <div className="table_container sticky-table-clone">
        <table className={'form-control-table'} ref={tableCloneRef} />
      </div>
    </div>
  );
});
