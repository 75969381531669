import client from '../api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
const CancelTokenFolder = axios.CancelToken;
let cancel;
let cancelFolder;
let cancelFacility;

export const getFacilitiesByCompany = async (idCompany, page, action) => {
  if (cancel !== undefined) {
    cancel();
  }
  let params = {};

  if (page === 'getAll') {
    params['get_all'] = true;
  }

  if (action) {
    params['action'] = action;
  }

  const response = await client.get(`api/companies/${idCompany}/facilities`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getFacilitiesByCompanySettings = async (idCompany, page, action) => {
  if (cancel !== undefined) {
    cancel();
  }
  let params = {};

  if (page === 'getAll') {
    params['get_all'] = true;
  }

  if (action) {
    params['action'] = action;
  }

  if (idCompany) {
    params['company_id'] = idCompany;
  }

  const response = await client.get(`api/corporate-library/forms/settings/facilities`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getFacilitiesByFileSettings = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }
  params['get_all'] = true;

  const response = await client.get(`/api/corporate-library/files/folder/access-settings/facilities/`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getFolderInfo = async (id) => {
  if (cancelFolder !== undefined) {
    cancelFolder();
  }
  const params = {};
  params['get_all'] = true;

  const response = await client.get(`/api/corporate-library/files/folders/${id}/`, {
    params,
    cancelToken: new CancelTokenFolder(function executor(c) {
      cancelFolder = c;
    }),
  });

  return response.data;
};
export const getFileInfo = async (id) => {
  if (cancelFolder !== undefined) {
    cancelFolder();
  }
  const params = {};
  params['get_all'] = true;

  const response = await client.get(`/api/corporate-library/files/${id}/`, {
    params,
    cancelToken: new CancelTokenFolder(function executor(c) {
      cancelFolder = c;
    }),
  });

  return response.data;
};

export const getFileGeneralInfo = async (id) => {
  if (cancelFolder !== undefined) {
    cancelFolder();
  }
  const params = {};
  params['get_all'] = true;

  const response = await client.get(`/api/general-library/file/${id}/`, {
    params,
    cancelToken: new CancelTokenFolder(function executor(c) {
      cancelFolder = c;
    }),
  });

  return response.data;
};

export const getFacilitiesByCompanySettingsInit = async (idCompany, page, form_id, field) => {
  if (cancel !== undefined) {
    cancel();
  }
  let params = {};

  if (page === 'getAll') {
    params['get_all'] = true;
  }

  if (form_id) {
    params['form_id'] = form_id;
  }
  if (field) {
    params['field'] = field;
  }

  if (idCompany) {
    params['company_id'] = idCompany;
  }

  const response = await client.get(`api/corporate-library/forms/initiate/settings/facilities`, {
    params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getFacilitiesById = async (id) => {
  if (cancelFacility !== undefined) {
    cancelFacility();
  }

  const response = await client.get(`api/facilities/${id}/`, {
    params: { status: 'all' },
    cancelToken: new CancelToken(function executor(c) {
      cancelFacility = c;
    }),
  });

  return response.data;
};

export const createFacility = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post('api/facilities/', data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const updateFacility = async (data, id) => {
  if (!data) {
    return;
  }

  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/facilities/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deactivateFacility = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/facilities/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const deleteFacility = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/facilities/${id}/hard-delete/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const activatedFacility = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/facilities/${id}/restore/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
