import React, { useEffect, useState } from 'react';

export const TitleElementSettings = function FromElement({ element, handlerChangeOption }) {
  const [content, setContent] = useState(element.options.value);
  const [alignment, setAlignment] = useState(element.options.alignment);
  const [fontSizeOption, setFontSizeOption] = useState(element.options.fontSize);
  useEffect(() => {
    setContent(element.options.value);
    setAlignment(element.options.alignment);
  }, [element]);

  return (
    <div className="tabs-item">
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Content</h3>
        <div className="form-control-item">
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'value');
            }}
          />
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Text Alignment</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="left"
              checked={alignment === 'left'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment');
                setAlignment(e.target.value);
              }}
            />
            <span>Left</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="center"
              checked={alignment === 'center'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment');
                setAlignment(e.target.value);
              }}
            />
            <span>Center</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="right"
              checked={alignment === 'right'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment');
                setAlignment(e.target.value);
              }}
            />
            <span>Right</span>
          </span>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Font Size</h3>
        <div className="form-control-item">
          <input
            type="number"
            className="form-control"
            placeholder="Enter text"
            value={fontSizeOption}
            onChange={(e) => {
              setFontSizeOption(e.target.value);
            }}
            onBlur={(e) => handlerChangeOption(e.target.value, 'fontSize')}
          />
        </div>
      </div>
    </div>
  );
};
