import React, { useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import { useIsInitFormPage } from '../../../hook/pages/useIsInitFormPage';
import { useUserFormRole } from '../../../hook/useUserFormRole';
import ElementErrorMessage from './components/element-error-message';
import { useIsSubmitFormPage } from '../../../hook/useIsSubmitFormPage';

export const TimeElement = function FromElement({ element, handlerChange, error }) {
  const isRequiredElement = useIsRequiredElement(element);
  const isInitFormPage = useIsInitFormPage();
  const userFormRole = useUserFormRole();
  const isSubmitFormPage = useIsSubmitFormPage();
  const isRequiredForAnotherRole = !!element.options.requiredFor && element.options.requiredFor !== userFormRole;
  const isEditable = element.options?.editable && !element.options.readOnly && !isRequiredForAnotherRole;
  let value = null;

  if (element.options.valueInput) {
    value = new Date(element.options.valueInput);
  }

  useEffect(() => {
    if (!isRequiredForAnotherRole && !isEditable && !element.options.valueInput && (isSubmitFormPage || isInitFormPage)) {
      handlerChange(element, new Date());
    }
  }, [element, isEditable, isInitFormPage, isRequiredForAnotherRole, isSubmitFormPage]);

  const handleDatePickerFocus = useCallback(() => {
    if (isEditable && !value) {
      const currentDate = new Date();
      handlerChange(element, currentDate);
    }
  }, [element, handlerChange, isEditable, value]);

  const handleDateChange = useCallback(
    (date) => {
      if (isEditable) {
        handlerChange(element, date);
      }
    },
    [element, handlerChange, isEditable],
  );

  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <DatePicker
        showTimeSelectOnly
        timeCaption=""
        id={element.id}
        selected={value}
        timeIntervals={60}
        dateFormat="h:mm aa"
        readOnly={!isEditable}
        showTimeSelect={isEditable}
        popperPlacement="bottom-start"
        popperClassName={'time-picker-popper'}
        placeholderText={isEditable ? 'Choose Time' : ''}
        className={`form-control form-control-${element.options.size}`}
        onChange={handleDateChange}
        onFocus={handleDatePickerFocus}
      />
      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
