import React, { memo, useEffect, useState } from 'react';

const AttachToFormNameSetting = ({ element, onChange }) => {
  const [isChecked, setIsChecked] = useState(element.options.isFormName);

  useEffect(() => {
    setIsChecked(element.options.isFormName);
  }, [element]);

  const handleChange = () => {
    setIsChecked((prevState) => {
      const newState = !prevState;
      onChange(newState, 'isFormName', element.id);
      return newState;
    });
  };

  return (
    <>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Attach to the form name</h3>
        <div className="form-switcher">
          <input type="checkbox" checked={Boolean(isChecked)} onChange={handleChange} />
          <span>{isChecked ? 'ON' : 'OFF'}</span>
        </div>
      </div>
    </>
  );
};

export default memo(AttachToFormNameSetting);
