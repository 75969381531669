import Axios from 'axios';

const client = Axios.create({
  timeout: 10_000,
  baseURL: process.env.REACT_APP_API_URL ?? 'http://34.253.3.15/',
  headers: {
    isPublic: false,
    'Content-Type': 'application/json',
    Authorization: window.localStorage.getItem('auth_token') ? `Bearer ${window.localStorage.getItem('auth_token')}` : null,
  },
});

client.interceptors.request.use((request) => {
  if (request.headers) {
    if (request.headers.isPublic) {
      delete request.headers.Authorization;
    }
    delete request.headers.isPublic;
  }

  return request;
});

client.interceptors.response.use(undefined, (error) => {
  if (Axios.isCancel(error)) {
    throw error;
  }

  if (error?.response?.status === 401) {
    removeToken();
    window.location.href = '/login';
  }

  if (error?.response?.status === 403) {
    if (error?.response?.data?.form && error?.response?.data?.form.length > 0) {
      window.location.href = `/form-submitted?text-error=${error?.response?.data?.form ?? error?.response?.data?.form[0]}`;
    } else if (error?.response?.data?.form) {
      window.location.href = '/form-expired';
    } else {
      // window.location.href = '/forbidden';
    }
  }
  // if (error.response.status === 404) {
  //     window.location.href = '/not-found';
  // }

  if (!error.response) {
    throw error;
  }

  throw error;
});

export function refreshToken() {
  client
    .post('/api/token/refresh/', { refresh: getRefresh() })
    .then((res) => {
      setToken(res.access);
    })
    .catch((e) => {
      console.log(e);
    });
}

export function checkToken() {
  client
    .post('/api/token/verify/', { token: hesAuthToken() })
    .then((res) => {
      refreshToken();
    })
    .catch((e) => {
      console.log(e);
    });
}

export function checkAuthToken() {
  if (window.localStorage.getItem('auth_token')) {
    setToken(window.localStorage.getItem('auth_token'));
  }
}

export function setRefresh(token) {
  window.localStorage.setItem('auth_token_refresh', token);
}

export function getRefresh() {
  return window.localStorage.getItem('auth_token_refresh');
}

export function hesAuthToken() {
  return window.localStorage.getItem('auth_token');
}

export function setToken(token) {
  window.localStorage.setItem('auth_token', token);
  Object.assign(client.defaults.headers, {
    Authorization: `Bearer ${token}`,
  });
}

export function removeToken() {
  window.localStorage.removeItem('auth_token');
  window.localStorage.removeItem('auth_token_refresh');
  window.localStorage.removeItem('user');
  delete client.defaults.headers.Authorization;
}

export function getToken() {
  return client.defaults.headers.Authorization;
}

export default client;
