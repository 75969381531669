import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const TitleElement = function FromElement({ element, formData = null }) {
  const [style, setStyle] = useState({
    fontSize: '24px',
    textAlign: 'left',
  });

  const location = useLocation();
  const navigation = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setStyle(() => {
      return {
        fontSize: element?.options?.fontSize ? `${element?.options?.fontSize}px` : '24px',
        textAlign: element?.options?.alignment ? element?.options?.alignment : 'left',
      };
    });
  }, [element]);
  const backTo = () => {
    if (location.pathname.includes('initialization-form')) {
      navigation(`/corporate-library/forms/forms-available-to-me`);
    } else {
      if (formData?.filling_type === 'PUBLIC') {
        if (searchParams.has('isPublic')) {
          searchParams.delete('isPublic');
          setSearchParams(searchParams);
          if (searchParams.has('parent_id')) {
            navigation(`/corporate-library/forms/public-forms?parent_id=${searchParams.get('parent_id')}`);
          } else {
            navigation(`/corporate-library/forms/public-forms`);
          }
        }
      } else {
        navigation(`/corporate-library/forms/forms-assigned-to-me`);
      }
    }
  };
  return (
    <div className="from-builder-title form-builder-element-preview">
      {element && (
        <h1 style={style}>
          <img src="/images/file-big-icon.png" className={'mr-2'} alt="" />
          {element?.options?.value !== null ? element?.options?.value : element}
        </h1>
      )}

      <span onClick={backTo} className="btn btn-icon btn-mobile">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path d="M11 1L1 11M1 1L11 11" stroke="#101828" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </span>
    </div>
  );
};
