import client from '../../api';
import axios from 'axios';
import qs from 'qs';

const CancelToken = axios.CancelToken;
let cancel;

export const getUserFilesService = async ({ userID, folderID, search }) => {
  if (!userID) return;
  if (cancel !== undefined) {
    cancel();
  }
  try {
    const params = {};

    if (folderID) {
      params.parent_id = folderID;
    }
    if (search) {
      params.search = search;
    }

    return await client.get(`/api/users/${userID}/files/`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  } catch (e) {
    console.log(e);
  }
};
export const createUserFolderService = async ({ userID, currentFolderID, folderName, folderType = 'user' }) => {
  if (cancel !== undefined) {
    cancel();
  }

  if (!userID || !folderName) return;

  try {
    const data = {
      name: folderName,
      parent_id: currentFolderID,
      type: folderType,
    };
    return await client.post(`/api/users/${userID}/folders/`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
export const addUserFileService = async ({ userID, folderID, fileUUID }) => {
  if (!userID || !fileUUID) return;
  if (cancel !== undefined) {
    cancel();
  }
  try {
    const data = {
      file_uuid: fileUUID,
      folder_id: folderID,
    };
    return await client.post(`/api/users/${userID}/files/`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  } catch (e) {
    console.log(e);
  }
};
export const getUserFileService = async ({ userID, fileID }) => {
  if (!userID || !fileID) return;
  if (cancel !== undefined) {
    cancel();
  }
  try {
    return await client.get(`/api/users/${userID}/files/${fileID}/`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
export const deleteUserFileService = async ({ userID, fileID }) => {
  if (!userID || !fileID) return;
  if (cancel !== undefined) {
    cancel();
  }
  try {
    return await client.delete(`/api/users/${userID}/files/${fileID}/`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
export const updateUserFolderService = async ({ userID, folderID, ...data }) => {
  if (!userID || !folderID || !data?.name) return;
  if (cancel !== undefined) {
    cancel();
  }
  try {
    return await client.patch(`/api/users/${userID}/folders/${folderID}/`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
export const deleteUserFolderService = async ({ userID, folderID }) => {
  if (!userID || !folderID) return;
  if (cancel !== undefined) {
    cancel();
  }
  try {
    return await client.delete(`/api/users/${userID}/folders/${folderID}/`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
export const updateUserFileService = async ({ userID, fileID, folderID = null }) => {
  if (!userID || !fileID) return;

  if (cancel !== undefined) {
    cancel();
  }

  try {
    const data = { folder_id: folderID };

    return await client.patch(`/api/users/${userID}/files/${fileID}/`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
