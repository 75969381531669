const ResizableGrid = (e, handlerChange) => {
  let t = e.getElementsByTagName('tr')[0],
    n = t ? t.children : void 0;
  if (n) {
    e.style.overflow = 'hidden';
    for (let i = e.offsetHeight, o = 0; o < n.length; o++) {
      let r = s(i);
      if (n[o]) {
        //eslint-disable-next-line
        n[o].appendChild(r), (n[o].style.position = 'relative'), d(r);
      }
    }
  }

  function d(e) {
    // eslint-disable-next-line
    let t, n, i, o, r;
    //eslint-disable-next-line
    e.addEventListener('mousedown', function (e) {
      //eslint-disable-next-line
      (n = e.target.parentElement), (i = n.nextElementSibling), (t = e.pageX);
      let d = (function (e) {
        if ('border-box' === l(e, 'box-sizing')) return 0;
        let t = l(e, 'padding-left'),
          n = l(e, 'padding-right');
        return parseInt(t) + parseInt(n);
      })(n);
      //eslint-disable-next-line
      (o = n.offsetWidth - d), i && (r = i.offsetWidth - d);
    });
    e.addEventListener('mouseover', function (e) {
      if (e.target.parentElement) {
        e.target.style.borderRight = '2px solid #CBD2E0';
      }
    });
    e.addEventListener('mouseout', function (e) {
      e.target.style.borderRight = '';
    });
    document.addEventListener('mousemove', function (e) {
      if (n) {
        let d = e.pageX - t;
        //eslint-disable-next-line
        n.style.width = o + d + 'px';
        handlerChange(o + d + 'px', n, !n.id);
      }
    });
    document.addEventListener('mouseup', function (e) {
      //eslint-disable-next-line
      (n = void 0), (i = void 0), (t = void 0), (r = void 0), (o = void 0);
    });
  }

  function s(e) {
    let t = document.createElement('div');
    //eslint-disable-next-line
    return (
      (t.style.top = 0),
      (t.style.right = 0),
      (t.style.width = '5px'),
      (t.style.position = 'absolute'),
      (t.style.cursor = 'col-resize'),
      (t.style.userSelect = 'none'),
      (t.style.height = e + 'px'),
      t
    );
  }

  function l(e, t) {
    return window.getComputedStyle(e, null).getPropertyValue(t);
  }
};

export default ResizableGrid;
