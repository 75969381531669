// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__log-out-btn {
  justify-self: flex-end;
  min-width: 41px;
  display: inline-flex;
  margin-left: 20px;
  gap: 4px;
  padding: 4px 6px;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  font-family: "Inter", sans-serif;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--secondary-on-surface, #98A2B3);
}
.header__log-out-btn span {
  margin-top: 2px;
}
.header__log-out-btn:hover {
  color: #7F56D9;
}
.header__log-out-btn:hover svg path {
  stroke: #7F56D9;
}

.mobile .header__log-out-btn span {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/header/log-out-button/styles.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,eAAA;EACA,oBAAA;EACA,iBAAA;EACA,QAAA;EACA,gBAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,6BAAA;EACA,2CAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AAEI;EACE,eAAA;AAAN;;AAOI;EACE,aAAA;AAJN","sourcesContent":[".header__log-out-btn {\n  justify-self: flex-end;\n  min-width: 41px;\n  display: inline-flex;\n  margin-left: 20px;\n  gap: 4px;\n  padding: 4px 6px;\n  outline: none;\n  border: none;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 21px;\n  font-family: 'Inter', sans-serif;\n  align-items: center;\n  justify-content: center;\n  text-transform: uppercase;\n  background-color: transparent;\n  color: var(--secondary-on-surface, #98A2B3);\n\n  span {\n    margin-top: 2px;\n  }\n\n  &:hover {\n    color: #7F56D9;\n\n    svg path {\n      stroke: #7F56D9;\n    }\n  }\n}\n\n.mobile {\n  .header__log-out-btn {\n    span {\n      display: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
