import React, { useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { createUserFolderService, updateUserFolderService } from '../../../../../services/user/files';
import { isResponseOk } from '../../../../../utility/isResponseOk';
import Select from 'react-select';

const folderTypeOptions = [
  {
    value: 'system',
    label: 'System folder',
  },
  {
    value: 'user',
    label: 'Employee specific folder ',
  },
];

function ManageFolderModal({ onCloseModal, currentFolderID, onFolderSaved, editFolderData }) {
  const { user_id } = useParams();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [folder, setFolder] = useState({
    name: editFolderData?.name ?? '',
    type: editFolderData?.type,
    id: editFolderData?.id,
  });

  const isRootFolder = !currentFolderID;
  const isEditing = !!editFolderData.id;
  const folderTypeOption = folderTypeOptions.find((o) => o.value === folder.type);
  const isValidFolderData = Boolean(folder.name.trim().length && (isRootFolder ? folder.type : true));

  const handleChange = (value, key) => {
    setFolder((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    setErrors((prevState) => {
      const newState = { ...prevState };
      if (newState.hasOwnProperty(key)) {
        delete newState[key];
      }
      return newState;
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    if (isEditing) {
      updateUserFolderService({ userID: user_id, folderID: folder.id, name: folder.name }).then((res) => {
        if (isResponseOk(res)) {
          onFolderSaved();
        }
      });
    } else {
      createUserFolderService({
        userID: user_id,
        folderName: folder.name,
        currentFolderID,
        folderType: folder.type,
      }).then((res) => {
        if (isResponseOk(res)) {
          onFolderSaved();
        }
      });
    }

    setIsLoading(false);
  };

  return (
    <Modal className="modal-default" isOpen onRequestClose={onCloseModal} ariaHideApp={false}>
      <h2 className="modal-header">
        {isEditing ? 'Rename' : 'Create'} folder
        <button className="btn btn-icon" onClick={onCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/folder-icon-default.png" alt="" />
          Folder information
        </h3>
        <div className={`form-item ${errors.name && errors.name[0] ? 'form-item-error' : ''}`}>
          <label className="form-label">Folder Name</label>
          <input
            placeholder="Enter the folder name"
            value={folder.name}
            onChange={(e) => handleChange(e.target.value, 'name')}
            type="text"
            className="form-control"
          />
          <span className="form-error">{errors.name && errors.name[0]}</span>
        </div>

        {!isEditing && isRootFolder && (
          <div className={`form-item ${errors.type && errors.type[0] ? 'form-item-error' : ''}`}>
            <label className="form-label">Folder Type</label>
            <Select
              options={folderTypeOptions}
              value={folderTypeOption}
              onChange={(option) => handleChange(option.value, 'type')}
              className="form-control-select"
              classNamePrefix="react-select"
            />
            <span className="form-error">{errors.type && errors.type[0]}</span>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onCloseModal}>
          Cancel
        </button>
        <button disabled={isLoading || !isValidFolderData} className="btn" onClick={handleSubmit}>
          {isEditing ? 'Save' : 'Create'}
        </button>
      </div>
    </Modal>
  );
}

export default ManageFolderModal;
