import cx from 'classnames';
import React, { memo, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { ReactComponent as TemplateIcon } from '../../../../../../assets/images/template-icon.svg';
import { editTemplateService, saveAsANewTemplateService } from '../../../../../../services/cna/assignments/cna';
import { isResponseOk } from '../../../../../../utility/isResponseOk';
import './styles.scss';

const AddNewTemplateModal = ({ onClose, data = {}, onRefetchAssignment }) => {
  const { shiftID, templateData = {} } = data;

  const isEditing = !!templateData?.id;

  const [templateName, setTemplateName] = useState(isEditing ? templateData.name : '');

  const handleSave = async () => {
    try {
      let res;

      if (isEditing) {
        res = await editTemplateService({ shiftID, templateID: templateData.id, name: templateName });
      } else {
        res = await saveAsANewTemplateService({ shiftID, name: templateName });
      }

      if (isResponseOk(res)) {
        await onRefetchAssignment();
        onClose();
        setTemplateName('');
        toast(`Template ${isEditing ? 'updated' : 'created'} successfully`, {
          icon: () => <img src="/images/full-check-circle.svg" />,
          closeButton: ({ closeToast }) => (
            <button onClick={closeToast} className="btn btn-icon close-alert-btn">
              <img src="/images/x-icon.svg" />
            </button>
          ),
          className: 'template-updated-alert',
          toastClassName: 'template-updated-alert-wrapper',
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isActiveSaveBtn = useMemo(() => {
    const trimName = templateName.trim();
    return !!trimName.length;
  }, [templateName]);

  const handleChangeTemplateName = (e) => setTemplateName(e.target.value);

  return (
    <Modal
      isOpen
      className={cx('modal-default add-new-template-modal')}
      overlayClassName={'modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Add new template modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        {isEditing ? 'Edit' : 'Save as a'} template
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <div className="group">
          <span className={'group__title'}>
            <TemplateIcon />
            Template information
          </span>

          <div className="group__body">
            <label className="form-label">Template name</label>
            <input placeholder="Enter the name" value={templateName} onChange={handleChangeTemplateName} className="form-control" />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isActiveSaveBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(AddNewTemplateModal);
