import React from 'react';

export const FullNameElement = function FromElement({ element }) {
  return (
    <>
      <div className={`form-grid-full-name form-grid-full-name-${element.options.size}`}>
        <div className="form-grid-item">
          <label htmlFor={element.id + element.inputs[0].name} className="form-label">
            {element.inputs[0].label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
          </label>
          <input name={element.inputs[0].name} id={element.id + element.inputs[0].name} type="text" readOnly className="form-control" />
        </div>
        <div className="form-grid-item">
          <label htmlFor={element.id + element.inputs[1].name} className="form-label">
            {element.inputs[1].label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
          </label>
          <input name={element.inputs[1].name} id={element.id + element.inputs[1].name} type="text" readOnly className="form-control" />
        </div>
      </div>
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
