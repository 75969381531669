import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import _ from 'lodash';
import { useAuth } from '../../../../../container/authContext';
import ResidentModal from '../../../../../components/company/resident-modal';
import { extractBedParts } from '../../../../../utility/index';
import { editContacts } from '../../../../../services/contacts';
import { moveResidentService } from '../../../../../services/bedboard';
import { updateUnitBedService } from '../../../../../services/unit-beds';
import './styles.scss';
import cx from 'classnames';
import { Tooltip } from 'react-tooltip';
import { isResponseOk } from '../../../../../utility/isResponseOk';
import { isTablet } from 'react-device-detect';

const UnitBedRow = ({ unitBed, getNotes, setBedboardData, facility, unit, lastRoomNumber, isPrint, hasWritePermission }) => {
  const { user } = useAuth();

  const { letter, number } = extractBedParts(unitBed.bed);
  const [manageResidentModalData, setManageResidentModalData] = useState(null);

  const handleManageResident = (resident) => {
    setBedboardData((prevState) => {
      const newState = _.cloneDeep(prevState);

      newState.units.forEach((u) => {
        if (resident.unit.id == u.id) {
          u.beds.forEach((b) => {
            if (b.id == resident.unit_bed.id) {
              b.resident = {
                id: resident.id,
                first_name: resident.first_name,
                last_name: resident.last_name,
                email: resident.email,
                phone: resident.phone,
                unit_bed: resident.unit_bed,
                representatives: resident.representatives,
                deleted_at: resident.deleted_at,
                note: resident.note || unitBed.note || '',
              };
            }
            if (!!b.resident?.id && b.resident?.id === resident.id && b.id !== resident.unit_bed.id) {
              b.resident = null;
            }
          });

          u.in_house_count = u.beds.filter((item) => item.resident && item.resident?.note !== 'H').length;
        }
      });

      newState.total_in_house = _.sum(newState.units.map((u) => u.in_house_count));
      newState.total_with_bed_holds = _.sum(newState.units.map((u) => u.beds.filter((item) => !!item.resident).length));

      return newState;
    });

    setManageResidentModalData(null);
  };

  const handleResidentCodeChange = async (bed, note) => {
    try {
      const body = {
        note,
      };

      let response;

      if (unitBed.resident?.id) {
        response = await editContacts(body, bed.resident.id);
      } else {
        response = await updateUnitBedService(unit.id, unitBed.id, body);
      }

      if (response) {
        setBedboardData((prevState) => {
          const newState = _.cloneDeep(prevState);
          newState.units.forEach((u) => {
            if (u.id == unit.id) {
              u.beds.forEach((b) => {
                if (bed.resident?.id && b.resident?.id == bed.resident?.id) {
                  b.resident.note = note;
                }

                if (!bed.resident?.id && b.id == bed.id) {
                  b.note = note;
                }
              });

              u.in_house_count = u.beds.filter((item) => item.resident && item.resident?.note !== 'H').length;
            }
          });

          newState.total_in_house = _.sum(newState.units.map((u) => u.in_house_count));
          return newState;
        });

        getNotes();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeactivateResident = () => {
    setBedboardData((prevState) => {
      const newState = _.cloneDeep(prevState);
      newState.units.forEach((u) => {
        if (u.id == unit.id) {
          u.beds.forEach((b) => {
            if (b.resident?.id == unitBed.resident?.id) {
              b.resident = null;
            }
          });
          u.in_house_count = u.beds.filter((item) => item.resident && item.resident?.note !== 'H').length;
        }
      });
      newState.total_in_house = _.sum(newState.units.map((u) => u.in_house_count));
      newState.total_with_bed_holds = _.sum(newState.units.map((u) => u.beds.filter((item) => !!item.resident).length));

      return newState;
    });
    setManageResidentModalData(null);
  };

  const [collected, drag] = useDrag(
    () => ({
      type: 'unit_bed',
      item: { unitBed: { ...unitBed, unit_id: unit.id } },
      canDrag: !!unitBed.resident && !isPrint && !isTablet && hasWritePermission,
      end: async (item, monitor) => {
        try {
          const dropResult = monitor.getDropResult();

          if (dropResult?.unitBed) {
            if (dropResult.unitBed.id !== item.unitBed?.id) {
              const dragUnitBed = item.unitBed;
              const dropUnitBed = dropResult?.unitBed;

              const residentData = _.clone(dragUnitBed.resident);

              const body = {
                from_bed: dragUnitBed.id,
                to_bed: dropUnitBed.id,
                resident: dragUnitBed.resident?.id,
              };

              const response = await moveResidentService(body);

              if (isResponseOk(response)) {
                setBedboardData((prevState) => {
                  const newState = _.cloneDeep(prevState);

                  newState.units.forEach((u) => {
                    if (u.id == dragUnitBed.unit_id) {
                      u.in_house_count += residentData.note === 'H' ? 0 : -1;
                      u.beds.forEach((b) => {
                        if (b.id == dragUnitBed.id) {
                          b.resident = null;
                          b.note = null;
                        }
                      });
                    }

                    if (u.id == dropUnitBed.unit_id) {
                      u.in_house_count += residentData.note === 'H' ? 0 : 1;
                      u.beds.forEach((b) => {
                        if (b.id == dropUnitBed.id) {
                          b.resident = residentData;
                          b.resident.note = residentData.note || dropUnitBed.note || '';
                        }
                      });
                    }
                    u.in_house_count = u.beds.filter((item) => item.resident && item.resident?.note !== 'H').length;
                  });

                  newState.total_in_house = _.sum(newState.units.map((u) => u.in_house_count));
                  return newState;
                });
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [unitBed.resident, unitBed],
  );

  const [, drop] = useDrop(
    () => ({
      accept: ['unit_bed'],
      canDrop: () => !unitBed.resident && !isPrint && !isTablet && hasWritePermission,
      drop: () => ({ unitBed: { ...unitBed, unit_id: unit.id } }),
    }),
    [unitBed.resident, unitBed],
  );

  return (
    <>
      <div
        ref={unitBed.resident ? drag : drop}
        className={cx('tr cursor-pointer', {
          'is-first-room-bed': lastRoomNumber !== number,
        })}
        style={{ opacity: collected.isDragging ? 0.5 : 1 }}
      >
        <div className="td room-col">
          <Tooltip opacity={1} id={`${unitBed.bed}-room-tooltip`} content={number} />
          <span data-tooltip-id={`${unitBed.bed}-room-tooltip`}>{number}</span>
        </div>
        <div className="td bed-col">{letter}</div>
        <div
          className="td resident-col"
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
          onClick={
            !isPrint && !isTablet && hasWritePermission
              ? () => {
                  const residentData = unitBed?.resident?.id
                    ? { ...unitBed.resident, facility, unit, unit_bed: unitBed }
                    : { facility, unit: unit, unit_bed: unitBed };
                  setManageResidentModalData(residentData);
                }
              : () => {}
          }
        >
          {unitBed.resident?.last_name} {unitBed.resident?.first_name}
        </div>
        <div className="td code-col" style={{ borderLeft: '1px solid #cbd2e0' }}>
          <input
            type="text"
            readOnly={isTablet || !hasWritePermission}
            className="code-input"
            value={(unitBed.resident ? unitBed.resident.note : unitBed.note) || ''}
            onChange={(e) => handleResidentCodeChange(unitBed, e.target.value)}
          />
        </div>
      </div>
      {manageResidentModalData && (
        <ResidentModal
          isOpen
          companyId={user.company?.id}
          closeModal={() => setManageResidentModalData(null)}
          handelCreateUser={handleManageResident}
          handleDeactivateResident={handleDeactivateResident}
          facilities={[facility]}
          userData={manageResidentModalData}
          showDeactivate
        />
      )}
    </>
  );
};

export default UnitBedRow;
