import React from 'react';
import Modal from 'react-modal';

function DeactivateModal({ isOpen, closeModal, title, subtitle, button, handelSuccess, errors }) {
  const handelCloseModal = () => {
    closeModal();
  };
  return (
    <Modal className="modal-alert" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        <img src="/images/alert-icon.svg" alt="" />
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div>
        <h3>{title}</h3>
        <p>{subtitle}</p>
        <ul className={'form-error mt-2 d-flex'}>{Array.isArray(errors) && errors.map((item) => <li>{item}</li>)}</ul>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn btn-danger" onClick={handelSuccess}>
          {!button || button === 'Archive' ? (
            <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clipPath="url(#clip0_1495_6510)">
                <path
                  d="M12.5001 7.50008L7.50008 12.5001M7.50008 7.50008L12.5001 12.5001M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z"
                  stroke="white"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1495_6510">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <i className={'icon-remove mr-2'}></i>
          )}

          {button ? button : 'Deactivate'}
        </button>
      </div>
    </Modal>
  );
}

export default DeactivateModal;
