import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../layout/default';
import CompanyModal from '../../components/company/company-modal';
import { activateCompany, getCompanies, getCompanySubmissionData } from '../../services/company';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { EmptyPage } from '../../components/other/EmptyPage';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';
import SubmissionsModal from '../../components/company/submission-modal/submissions-modal';
import SubmissionModal from '../../components/company/submission-modal/submission-modal';
import useOutsideClick from '../../hook/useOutsideClick';
import { utcToLocalDateFormatter } from '../../utility/dates/utcToLocalDate';

function CompanyList() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [companyWillUpdated, setCompanyWillUpdated] = useState(0);
  const navigate = useNavigate();
  const { isSuperAdmin } = usePermission();

  useEffect(() => {
    if (!isSuperAdmin()) {
      redirectToLibrary(navigate);
    }
  }, [isSuperAdmin, navigate]);

  const [column, setColumn] = useState([
    {
      name: 'Company Name',
    },
    {
      name: 'Nickname',
    },
    {
      name: 'Date Added',
    },
    {
      name: 'Signed Contract by',
    },
  ]);

  useEffect(() => {
    setColumn([
      {
        name: 'Company Name',
        total: total,
      },
      {
        name: 'Nickname',
      },
      {
        name: 'Date Added',
      },
      {
        name: 'Signed Contract by',
      },
    ]);
  }, [total]);
  const [companies, setCompanies] = useState([]);

  const [pageBreadcrumbs] = useState([
    {
      url: '/companies/',
      name: 'Company List',
    },
  ]);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [page]);

  const [companiesList, setCompaniesList] = useState(companies);
  const [search, setSearch] = useState('');
  useEffect(() => {
    let filteredData = companies;
    if (search) {
      filteredData = companies.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
    }
    setCompaniesList(filteredData);
  }, [search, companies]);

  const initPage = () => {
    if (isSuperAdmin()) {
      getCompanies(page)
        .then((res) => {
          setCompanies(res.results);
          setTotal(res.count);
          setTotalPage(res.total_pages);
          setCompanyWillUpdated(companyWillUpdated + 1);
        })
        .catch((e) => {
          console.log('Init page error: ', e);
        });
    }
  };

  const openAddModal = () => {
    setIsOpen(true);
  };
  function closeAddModal() {
    setIsOpen(false);
    setExportData(null);
  }

  const handelCreateCompany = () => {
    getCompanies(page)
      .then((res) => {
        setCompanies(res.results);
        setExportData(null);
      })
      .catch((e) => {
        console.log(e);
      });
    closeAddModal();
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const handleActivateCompany = (id) => {
    activateCompany(id)
      .then((res) => initPage())
      .catch((e) => console.log(e));
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef();
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);

  // create submission code
  const [modalAttachIsOpen, setIsAttachOpen] = useState(false);
  const [modalAttachDataIsOpen, setIsAttachDataOpen] = useState(false);
  const [selectedSubmissionForm, setSelectedSubmissionForm] = useState(null);
  const [exportData, setExportData] = useState(null);

  function closeAttachModal() {
    setIsAttachOpen(false);
  }
  function closeAttachDataModal() {
    setIsAttachDataOpen(false);
  }
  const handelFormSelected = (form) => {
    setSelectedSubmissionForm(form);
    setIsAttachDataOpen(true);
  };

  const handelExportDataSelected = (data) => {
    getCompanySubmissionData(data.submission_id)
      .then((res) => {
        console.log(res);
        setExportData(res);
        openAddModal();
      })
      .catch((e) => console.log(e));
  };

  return (
    <Layout companyUpdated={companyWillUpdated} title={'Company List'} breadcrumbs={pageBreadcrumbs}>
      <div className="page-wrapper">
        <div className="library-filter">
          <div className="library-filter-search form-control-item">
            <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
            <i className="icon-search"></i>
          </div>
          <div>
            <div className="dropdown" ref={ref}>
              <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn">
                <i className="icon-plus-svgrepo-com mr-2"></i> Add company
              </button>
              {isDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <button className="btn ml-0 btn-left btn-full btn-text" onClick={openAddModal}>
                      <i className="icon-plus-svgrepo-com mr-2"></i> Add manually
                    </button>
                  </li>
                  <li>
                    <button className={'btn ml-0 btn-full btn-text'} onClick={() => setIsAttachOpen(true)}>
                      <i className="icon-file-text mr-2"></i> Add from the submission
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div>
          {companiesList.length > 0 && (
            <div className="table-responsive table-default table-full">
              <div className="table-wrapper">
                <div className="table">
                  <div className="tr">
                    {column.map((item, index) => (
                      <div className="th" key={index}>
                        {item.name}
                        {!!item.total && <span className="th-total">(Total: {item.total} companies)</span>}
                      </div>
                    ))}
                    <div className="th"></div>
                  </div>
                  {companiesList.map((item) => (
                    <div className={`tr ${item.deleted_at ? 'tr-deactivated' : ''}`} key={item.id}>
                      <div onClick={() => navigate(`/company/${item.id}`)} className="td cursor-pointer td-big">
                        <strong>
                          {item.name} {item.deleted_at ? '(deactivated)' : ''}
                        </strong>
                      </div>
                      <div onClick={() => navigate(`/company/${item.id}`)} className="td cursor-pointer td-big">
                        {item.nickname}
                      </div>
                      <div onClick={() => navigate(`/company/${item.id}`)} className="td cursor-pointer">
                        {utcToLocalDateFormatter(item.date_added)}
                      </div>
                      <div onClick={() => navigate(`/company/${item.id}`)} className="td cursor-pointer">
                        <div className="d-flex">
                          <span className="d-flex flex-column">
                            <strong>
                              {item.signer_name} {item.signer_lastname}
                            </strong>
                            <span>{item.signer_email}</span>
                          </span>
                        </div>
                      </div>
                      <div className="td td-actions">
                        {item.deleted_at && (
                          <button className="btn btn-outline btn-success mr-2" onClick={() => handleActivateCompany(item.id)}>
                            <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_1484_3438)">
                                <path
                                  d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                                  stroke="#12B76A"
                                  strokeWidth="1.67"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1484_3438">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            Activate
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                {totalPage > 1 && (
                  <ReactPaginate
                    className={'react-pagination'}
                    breakLabel="..."
                    nextLabel="next ->"
                    pageRangeDisplayed={5}
                    pageCount={totalPage}
                    previousLabel="<- previous"
                    onPageChange={handlePageClick}
                  />
                )}
              </div>
            </div>
          )}
          {companiesList.length <= 0 && !!search.length && (
            <EmptyPage
              imageUrl={'/images/no-resoult.png'}
              title={'Unfortunately, nothing was found for your query.'}
              subtitle={"Try to change the range of keywords. We're here to help you find what you're looking for."}
            />
          )}

          {companiesList.length <= 0 && !search.length && (
            <EmptyPage
              imageUrl={'/images/emptypage.png'}
              title={'No companies listed yet'}
              subtitle={'We\'re here to assist you all the way. Click the button "Add company" to get started.'}
            />
          )}

          <CompanyModal
            companyData={exportData}
            isOpen={modalIsOpen}
            openModal={openAddModal}
            closeModal={closeAddModal}
            handelCreateCompany={handelCreateCompany}
          />

          <SubmissionsModal handelFormSelected={handelFormSelected} isOpen={modalAttachIsOpen} closeModal={closeAttachModal} />

          <SubmissionModal
            closeModal={closeAttachDataModal}
            isOpen={modalAttachDataIsOpen}
            handelExportDataSelected={handelExportDataSelected}
            selectedForm={selectedSubmissionForm}
          />
        </div>
      </div>
    </Layout>
  );
}

export default CompanyList;
