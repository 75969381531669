import { useEffect } from 'react';

// Custom hook for context-menu
export default function useOutsideClick(ref, action, isActive) {
  useEffect(() => {
    const refsArray = Array.isArray(ref) ? ref : [ref];
    const allRefsExist = refsArray.every((r) => r.current !== null);

    const handleClickOutside = (event) => {
      const clickedOutside = allRefsExist ? refsArray.every((r) => r.current && !r.current.contains(event.target)) : true;
      if (clickedOutside) {
        action();
      }
    };

    if (isActive && allRefsExist) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, action, isActive]);
}
