import React, { memo, useMemo, useState } from 'react';
import { dateToISOString } from '../../../../../utility/dates/dateToISOString';
import { generateSubmissionFormsPDF } from '../../../../../services/folder/form-avaliable';
import { isResponseOk } from '../../../../../utility/isResponseOk';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import cx from 'classnames';

import './styles.scss';

const pdfNameRegex = /^[\p{L}\p{N}_\- ]+$/u;

const ExportToPDFModal = ({ search, filters = {}, onClose }) => {
  const { corporate_form_id } = useParams();

  const [pdfName, setPDFName] = useState('');
  const [isPDFGeneration, setIsPDFGeneration] = useState(false);
  const [notificationType, setNotificationType] = useState('EMAIL'); // SMS | EMAIL

  const isValidPDFName = useMemo(() => {
    const trimValue = pdfName.trim();
    return pdfNameRegex.test(trimValue) && trimValue.length <= 255;
  }, [pdfName]);

  const handleChangePDFName = (e) => setPDFName(e.target.value);

  const handleChangeNotificationType = (e) => setNotificationType(e.target.value);

  const generatePDF = async () => {
    try {
      const params = { search };

      if (!search) {
        delete params.search;
      }

      params['status'] = 'all';

      if (filters.positions?.length) {
        params['positions'] = filters.positions.map((item) => item.value);
      }

      if (filters.facilities?.length) {
        params['facilities'] = filters.facilities.map((item) => item.value);
      }

      if (filters.initiator) {
        params['initiators'] = filters.initiator.map((item) => item.value);
      }

      if (filters.submitters.length) {
        params['submitters'] = filters.submitters.map((item) => item.value);
      }

      if (filters.submission_status) {
        params['submission_status'] = filters.submission_status.value;
      }

      if (filters.initiation_date_before) {
        params['initiation_date_before'] = dateToISOString(filters.initiation_date_before);
      }

      if (filters.initiation_date_after) {
        params['initiation_date_after'] = dateToISOString(filters.initiation_date_after);
      }

      if (filters.submission_date_after) {
        params['submission_date_after'] = dateToISOString(filters.submission_date_after);
      }

      if (filters.submission_date_before) {
        params['submission_date_before'] = dateToISOString(filters.submission_date_before);
      }

      const requestBody = {
        file_name: pdfName,
        notification_type: notificationType,
      };

      const response = await generateSubmissionFormsPDF({ formID: corporate_form_id, params, requestBody });
      if (isResponseOk(response)) {
        setIsPDFGeneration(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderPDFSettingsModalBody = () => {
    return (
      <div className="modal-body settings">
        <div className={'modal-body__header'}>
          <img src="/images/icon-file-list.svg" alt="" />
          Export information
        </div>
        <div className={'modal-body__main'}>
          <div className="name_field">
            <div className="name_field__label">Name</div>
            <input
              value={pdfName}
              type="text"
              className={'form-control name_field__input'}
              placeholder="Enter the name of the generated PDF"
              onChange={handleChangePDFName}
            />
          </div>
          <div className="radiobutton-row">
            <span className="radiobutton-row__label">Notify the user once the PDF file is generated</span>
            <div className="form-settings-inputs">
              <div className="d-flex" style={{ gap: 20 }}>
                <div className="form-control-radio">
                  <input
                    value={'SMS'}
                    checked={notificationType === 'SMS'}
                    name="notification-type"
                    type="radio"
                    onChange={handleChangeNotificationType}
                  />
                  <span>SMS</span>
                </div>
                <div className="form-control-radio">
                  <input
                    value={'EMAIL'}
                    checked={notificationType === 'EMAIL'}
                    name="notification-type"
                    type="radio"
                    onChange={handleChangeNotificationType}
                  />
                  <span>EMAIL</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const render = () => {
    let contentLayout;

    if (isPDFGeneration) {
      contentLayout = (
        <>
          <h2 className="modal-header">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="5" y="5" width="38" height="38" rx="19" fill="#F4EBFF" />
              <rect x="5" y="5" width="38" height="38" rx="19" stroke="#F9F5FF" strokeWidth="10" />
              <g clipPath="url(#clip0_9911_47605)">
                <path
                  d="M31.3327 18.6651V22.6651M31.3327 22.6651H27.3327M31.3327 22.6651L28.2393 19.7584C27.5228 19.0416 26.6364 18.5179 25.6628 18.2363C24.6892 17.9547 23.6601 17.9243 22.6715 18.1479C21.683 18.3716 20.7672 18.842 20.0097 19.5154C19.2521 20.1887 18.6775 21.0429 18.3393 21.9984M16.666 29.3318V25.3318M16.666 25.3318H20.666M16.666 25.3318L19.7593 28.2384C20.4758 28.9553 21.3623 29.479 22.3359 29.7606C23.3095 30.0422 24.3386 30.0726 25.3272 29.8489C26.3157 29.6252 27.2315 29.1548 27.989 28.4815C28.7466 27.8082 29.3212 26.9539 29.6593 25.9984"
                  stroke="#7F56D9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_9911_47605">
                  <rect width="16" height="16" fill="white" transform="translate(16 16)" />
                </clipPath>
              </defs>
            </svg>
            <button className="btn btn-icon" onClick={onClose}>
              <i className="icon-icon-x" />
            </button>
          </h2>
          <div className="modal-body">
            <div className={'modal-body__main'}>
              <h3 className="pdf-generation-title">It can take a few minutes to generate this PDF</h3>
              <p className="pdf-generation-description">We will notify you once the PDF is completed</p>
            </div>
          </div>
        </>
      );
    } else {
      contentLayout = (
        <>
          <h2 className="modal-header">
            {'Export to PDF'}
            <button className="btn btn-icon" onClick={onClose}>
              <i className="icon-icon-x" />
            </button>
          </h2>
          {renderPDFSettingsModalBody()}
          <div className="modal-footer">
            <button className="btn btn-outline" onClick={onClose}>
              Cancel
            </button>
            <button className={'btn btn'} disabled={!isValidPDFName} onClick={generatePDF}>
              <span>Generate PDF</span>
            </button>
          </div>
        </>
      );
    }

    return (
      <Modal
        isOpen={true}
        id={'exportSubmissionsToPDFModal'}
        className={cx('modal-default export-submissions-to-pdf-modal', { 'pdf-generation': isPDFGeneration })}
        overlayClassName={'modal-overlay export-submissions-to-pdf-modal-overlay'}
        onRequestClose={onClose}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {contentLayout}
      </Modal>
    );
  };

  return render();
};

export default memo(ExportToPDFModal);
