import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useOutsideClick from '../../hook/useOutsideClick';
// import {getState} from "../../utility";

function FacilityItem({ facility, handelEdit, handelDeactivate }) {
  const { id } = useParams();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  console.log(facility);
  useOutsideClick(ref, closeDropdown);
  return (
    <div className={`company-profile-facilities-item ${facility.deleted_at ? 'company-profile-facilities-item-disabled' : ''}`}>
      <div className="d-flex justify-space-between flex-start">
        <div>
          <h4 className="title">
            {facility.last_name} {facility.first_name}
          </h4>
          {facility.deleted_at && <h5 className="subtitle-deactivated">deactivated</h5>}
          {!facility.deleted_at && <h5 className="subtitle">{facility.relation_with_resident}</h5>}
        </div>
        <div>
          <div className="dropdown" ref={ref}>
            <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn btn-outline">
              <img src="/images/more-vertical.svg" alt="" />
            </button>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <button className="btn btn-left btn-full btn-text" onClick={() => handelEdit(facility)}>
                    <i className="icon-icon-edit mr-2"></i>Edit Representative
                  </button>
                </li>
                <li>
                  <button className="btn btn-left btn-full btn-danger btn-text" onClick={() => handelDeactivate(facility)}>
                    <i className="icon-icon-x mr-2"></i>Deactivate
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>

      <table className={'cursor-pointer'} onClick={() => navigate(`/company/${id}/facility/${facility.id}`)}>
        <tbody>
          <tr>
            <td>Email:</td>
            <td>{facility.email}</td>
          </tr>
          <tr>
            <td>Phone:</td>
            <td>{facility.phone}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default FacilityItem;
