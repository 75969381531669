export const filterStatusesOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

export const CNAFilterStatusOptions = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];
