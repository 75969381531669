import React, { useEffect, useState } from 'react';
import { Base64 } from 'js-base64';
import { GOOGLE_OAUTH_CLIENT_ID, GOOGLE_OAUTH_REDIRECT_URI, GOOGLE_OAUTH_SCOPE } from '../../constants/oauth';
import { updateCompany } from '../../services/company';

const ExportPdfTab = ({ company, updated }) => {
  const [daily, setDaily] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [error, setError] = useState(null);
  const [submissions_export_recipients, setSubmissionsExportRecipients] = useState([]);

  useEffect(() => {
    if (company.submissions_export_frequency) {
      setDaily(!!company.submissions_export_frequency.find((item) => item === 'daily'));

      setMonthly(!!company.submissions_export_frequency.find((item) => item === 'monthly'));
    }

    if (company.submissions_export_recipients) {
      setSubmissionsExportRecipients([...company.submissions_export_recipients]);
    } else {
      setSubmissionsExportRecipients(['']);
    }
  }, [company]);

  const handelAddEmail = () => {
    setSubmissionsExportRecipients([...submissions_export_recipients, '']);
  };

  const handelUpdate = () => {
    setError(null);
    const submissions_export_frequency = [];
    const bodyRequest = {};

    if (daily) {
      submissions_export_frequency.push('daily');
    }

    if (monthly) {
      submissions_export_frequency.push('monthly');
    }

    if (submissions_export_frequency) {
      bodyRequest['submissions_export_frequency'] = submissions_export_frequency;
    }

    bodyRequest['submissions_export_recipients'] = submissions_export_recipients;

    updateCompany(company.id, bodyRequest)
      .then((res) => {
        updated();
      })
      .catch((e) => {
        if (e?.response?.data) {
          setError(JSON.parse(JSON.stringify(e.response.data)));
        }
      });
  };

  const handelChange = (value, index) => {
    setSubmissionsExportRecipients((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };

  const handelRemove = (itemIndex) => {
    setSubmissionsExportRecipients(submissions_export_recipients.filter((email, index) => index !== itemIndex));
    if (!submissions_export_recipients.length) {
      setSubmissionsExportRecipients(['']);
    }
  };

  const handleGoogleSignIn = () => {
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    const state = Base64.encode(JSON.stringify({ token: window.localStorage.getItem('auth_token'), company_id: company.id }));
    const params = {
      client_id: GOOGLE_OAUTH_CLIENT_ID,
      redirect_uri: GOOGLE_OAUTH_REDIRECT_URI,
      response_type: 'code',
      scope: GOOGLE_OAUTH_SCOPE,
      include_granted_scopes: 'true',
      access_type: 'offline',
      prompt: 'consent',
      state: state,
    };

    const queryParams = new URLSearchParams(params).toString();
    const authUrl = `${oauth2Endpoint}?${queryParams}`;

    window.location.href = authUrl;
  };

  return (
    <div className={'export-pdf'}>
      <div className={'export-pdf-item'}>
        <div className={'export-pdf-item-label'}>
          <h3>Export to PDF settings</h3>
          <p>Please, specify how often you would like to receive an email with submissions export</p>
        </div>
        <div className={'export-pdf-item-inputs export-pdf-item-inputs-checkbox'}>
          <div className={`form-control-checkbox`}>
            <input name={'daily'} type="checkbox" checked={daily} onChange={() => setDaily(!daily)} />
            <span>Every day</span>
          </div>
          {error && error.hasOwnProperty('submissions_export_frequency') && (
            <div className={'form-error'}>{error.submissions_export_frequency[0]}</div>
          )}
        </div>
      </div>
      <div className="export-pdf-item">
        <div className="export-pdf-item-label">
          <h3>Storage</h3>
          <p>Connect storage for receiving daily reports</p>
        </div>
        <div className="export-pdf-item-inputs">
          <button className="btn btn-outline mr-2" onClick={handleGoogleSignIn}>
            <i className={`icon-circle mr-2 ${company.has_google_drive_connect ? 'icon-green-circle' : 'icon-red-circle'}`}></i>
            {company.has_google_drive_connect ? `Google Drive (${company.google_drive_email})` : 'Connect Google Drive'}
          </button>
        </div>
      </div>

      <div className={'export-pdf-item'}>
        <div className={'export-pdf-item-label'}>
          <h3>Enter email which will receive exported files</h3>
          <p>one or multiple</p>
        </div>
        <div className={'export-pdf-item-inputs'}>
          {submissions_export_recipients.length &&
            submissions_export_recipients.map((item, index) => (
              <div key={index} className={'form-item'}>
                <label className={'form-label'}>Email</label>
                <div className={'d-flex'}>
                  <div className={'form-item-mail'}>
                    <input
                      placeholder={'Enter Email'}
                      value={item}
                      onChange={(e) => handelChange(e.target.value, index)}
                      type="email"
                      className={'form-control'}
                    />
                  </div>
                  {index > 0 && (
                    <button onClick={() => handelRemove(index)} className={'btn btn-icon'}>
                      <i className={'icon-remove'}></i>
                    </button>
                  )}
                </div>
                {error && error.hasOwnProperty('submissions_export_recipients') && error?.submissions_export_recipients[index] && (
                  <div className={'form-error mt-1'}>{error?.submissions_export_recipients[index][0]}</div>
                )}
              </div>
            ))}

          <span className="add-facility cursor-pointer mb-2" onClick={() => handelAddEmail()}>
            <i className="icon-plus-svgrepo-com mr-2"></i>Add Email
          </span>
          <div className={'export-pdf-action'}>
            <button className={'btn'} onClick={handelUpdate}>
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportPdfTab;
