import { useDrop } from 'react-dnd';
import cx from 'classnames';

export const DropArea = ({ ...props }) => {
  const [{ canDrop }, drop] = useDrop(() => ({
    accept: 'fromElement',
    drop: () => ({ name: 'AddNew' }),
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div ref={drop} className={cx('drop-area', { 'drop-area-active': canDrop })} data-testid="DropArea">
      {props.children}
    </div>
  );
};
