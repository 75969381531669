import client from '../api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;
let cancelActions;

export const createUserGroup = async (data) => {
  const response = await client.post('api/user-groups/bulk-create/', data);

  return response.data;
};

export const createUserGroupDefault = async (id, data) => {
  const response = await client.post(`api/companies/${id}/default/user-groups/`, data);

  return response.data;
};

export const editUserGroupDefault = async (id, data, userGroupId) => {
  const response = await client.patch(`api/companies/${id}/default/user-groups/${userGroupId}/`, data);

  return response.data;
};

export const deactivateUserGroupDefault = async (id, userGroupId) => {
  const response = await client.delete(`api/companies/${id}/default/user-groups/${userGroupId}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const activateUserGroupDefault = async (id, userGroupId) => {
  const response = await client.patch(`api/companies/${id}/default/user-groups/${userGroupId}/restore/`);

  return response.data;
};
export const addActions = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`api/user-groups/${id}/actions/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const editUserGroup = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/user-groups/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const editActions = async (data, id, actionId) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/user-groups/${id}/actions/${actionId}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const addUsers = async (data, id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/user-groups/${id}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const deleteActions = async (id, actionId) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/user-groups/${id}/actions/${actionId}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const getUserGroupById = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`api/user-groups/${id}`, {
    params: { status: 'all' },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getUserGroupsActions = async () => {
  if (cancelActions !== undefined) {
    cancelActions();
  }

  const response = await client.get(`api/user-groups-actions?get_all=true`, {
    cancelToken: new CancelToken(function executor(c) {
      cancelActions = c;
    }),
  });

  return response.data;
};

export const deactivateUserGroup = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`api/user-groups/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const activateUserGroup = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`api/user-groups/${id}/restore/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
