import React from 'react';
import cx from 'classnames';

export const RadiobuttonElement = function FromElement({ element }) {
  const { options } = element;
  const { alignment } = options ?? {};
  return (
    <div className={'element-wrapper'}>
      <div className="element-inner">
        <label htmlFor={element.id} className="form-label w-full">
          {element.options.label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
        </label>
        <div className={cx('form-control-radio-wrapper')}>
          {element.inputs.map((option, index) => (
            <div
              key={index}
              className={cx('form-control-radio', {
                [`form-control-radio-${element.options.size}`]: element.options.size,
                [alignment]: alignment,
              })}
            >
              <input name={element.options.label} type="radio" value={option.id} />
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      </div>

      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </div>
  );
};
