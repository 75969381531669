import React from 'react';

export const FileUploadElement = function FromElement({ element }) {
  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
      </label>
      <div className={`form-control-file form-control-file-default mb-1 form-control-${element.options.size}`}>
        <input accept={element.options.fileType?.map((item) => item.value)} name={element.options.name} id={element.id} />
        <div>
          <img className="icon-icon-upload" src="/images/upload-svg.svg" alt="" />
          <span>Click to upload</span>
          <span className="sub-text">{element.options.fileType?.map((item) => item.value)}</span>
        </div>
      </div>
      <p className="text-secondary">No files uploaded yet</p>
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
