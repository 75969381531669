import React from 'react';
import { MultiSelect } from '../../other/MultiSelect';
import { Tooltip } from 'react-tooltip';
import InitiatorCanSetFrequencySetting, { INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY } from './components/initiator-can-set-frequency-setting';
import get from 'lodash/get';
import EditPreviousSubmissionsSetting from './components/edit-previous-submissions-setting';

export const PublicSettings = function SubmissionSettings({
  submission_settings,
  handelSettings,
  handelRemove,
  handelSubmittersSettings,
  handleRemoveAllowedSubmissionsEditor,
  facilityOptions,
  usersGroupsOptions,
  individualUsersOptions,
  errors,
}) {
  return (
    <>
      <div className="form-control-item-settings">
        <div className="form-settings-label">
          <label className="form-label form-label-big">Choose Amount of Submitters</label>
          <span>Select multiple submitters if, after the initiation process, the form needs to be sent to the next submitter.</span>
        </div>
        <div className="form-settings-inputs">
          <div className="d-flex">
            {submission_settings.is_one_submitter}
            <div className={`form-control-radio`}>
              <input
                name="is_one_submitter"
                type="radio"
                onChange={() => handelSettings(true, 'is_one_submitter')}
                checked={submission_settings.is_one_submitter}
              />
              <span>One</span>
              <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-is_one_submitter`}></i>
              <Tooltip opacity={1} id={`table-tooltip-is_one_submitter`} place="bottom">
                Users who are specified in "Access settings" will be submitters. These users always have access to these forms in "Forms
                available to me" folder. The user who initiates the form is a form submitter too.
              </Tooltip>
            </div>
            <div className={`form-control-radio`}>
              <input
                name="is_one_submitter"
                type="radio"
                onChange={() => handelSettings(false, 'is_one_submitter')}
                checked={submission_settings.is_one_submitter === false}
              />
              <span>Multiple</span>
              <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-is_one_submitter-multiple`}></i>
              <Tooltip opacity={1} id={`table-tooltip-is_one_submitter-multiple`} place="bottom">
                After the initiation, the form will be submitted by one or multiple submitters, specified by a form creator or initiator.
              </Tooltip>
            </div>
          </div>

          <span className="form-error mb-2 d-flex">{errors?.is_one_submitter && errors.is_one_submitter[0]}</span>
        </div>
      </div>

      {/* if Can the form initiator choose submitters? = true */}
      {submission_settings.is_one_submitter === false && (
        <>
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Define form submitters</label>
              <span>Specify users (facility and / or user groups / individual Users)</span>
            </div>
            <div className="form-settings-inputs">
              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">Facility</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several facilities</span>
                  <MultiSelect
                    isDisabled={!facilityOptions.length}
                    options={facilityOptions}
                    value={submission_settings.submitters?.facilities}
                    onChange={(e) => handelSubmittersSettings(e, 'facilities')}
                    placeholder=""
                    allName="All facilities"
                  ></MultiSelect>
                  {submission_settings.submitters?.facilities?.length > 0 && (
                    <>
                      <ul>
                        {submission_settings.submitters.facilities.map((item, key) => (
                          <li key={key}>
                            {item.label}
                            <button className="btn btn-icon" onClick={() => handelRemove('facilities', item)}>
                              <img src="/images/x-circle.svg" alt="" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
                <span className="form-error mb-2 d-flex">{errors?.submitters?.facilities && errors.submitters?.facilities[0]}</span>
              </div>
              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">User groups</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several user groups</span>
                  <MultiSelect
                    isDisabled={!usersGroupsOptions.length}
                    options={usersGroupsOptions}
                    value={submission_settings.submitters?.user_groups}
                    onChange={(e) => handelSubmittersSettings(e, 'user_groups')}
                    placeholder=""
                    allName="All user groups"
                  ></MultiSelect>
                  {submission_settings.submitters?.user_groups?.length > 0 && (
                    <>
                      <ul>
                        {submission_settings.submitters.user_groups.map((item, key) => (
                          <li key={key}>
                            {item.label}
                            <button className="btn btn-icon" onClick={() => handelRemove('user_groups', item)}>
                              <img src="/images/x-circle.svg" alt="" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
                <span className="form-error mb-2 d-flex">{errors?.submitters?.user_groups && errors.submitters?.user_groups[0]}</span>
              </div>

              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">Individual Users</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several individual_users</span>
                  <MultiSelect
                    isDisabled={!individualUsersOptions.length}
                    options={individualUsersOptions}
                    value={submission_settings.submitters?.individual_users}
                    onChange={(e) => handelSubmittersSettings(e, 'individual_users')}
                    placeholder=""
                    allName="All individual users"
                  ></MultiSelect>
                  {submission_settings.submitters?.individual_users?.length > 0 && (
                    <>
                      <ul className={'individual-users-list'}>
                        {submission_settings.submitters.individual_users
                          .sort(function (a, b) {
                            if (a.label < b.label) {
                              return -1;
                            }
                            if (a.label > b.label) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item, key) => (
                            <li key={key}>
                              {item.label}
                              <button className="btn btn-icon" onClick={() => handelRemove('individual_users', item)}>
                                <img src="/images/x-circle.svg" alt="" />
                              </button>
                            </li>
                          ))}
                      </ul>
                    </>
                  )}

                  <span className="form-error mb-2 d-flex">
                    {errors?.submitters?.individual_users && errors.submitters?.individual_users[0]}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <EditPreviousSubmissionsSetting
            handelSettings={handelSettings}
            handelSubmittersSettings={handelSubmittersSettings}
            settings={submission_settings}
            errors={errors}
            handleRemoveAllowedSubmissionsEditor={handleRemoveAllowedSubmissionsEditor}
          />
        </>
      )}

      <InitiatorCanSetFrequencySetting
        error={get(errors, `[${INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY}]`)}
        initiatorCanSetFrequency={get(submission_settings, `[${INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY}]`)}
        onChange={handelSettings}
      />
    </>
  );
};
