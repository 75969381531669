import React, { useCallback, useState } from 'react';
import { useIsRequiredElement } from '../../../../hook/useIsRequiredElement';
import ElementErrorMessage from '../components/element-error-message';
import SettingsModal from './components/settingsModal';
import { useIsInitFormPage } from '../../../../hook/pages/useIsInitFormPage';
import cx from 'classnames';

export const CheckboxElement = function FromElement({
  element,
  handlerChange,
  error,
  duplicateElement,
  removeElement,
  isPrint,
  formHasOnlyOneSubmitter,
}) {
  const isRequiredElement = useIsRequiredElement(element, formHasOnlyOneSubmitter);
  const isInitFormPage = useIsInitFormPage();
  const { options, id: elementId } = element;
  const { initiator_can_edit, alignment } = options ?? {};
  const currentUserCanEdit = isInitFormPage && initiator_can_edit;

  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);

  const handelOpenSettingsModal = () => setIsOpenSettingsModal(true);

  const handelCloseSettingsModal = useCallback(() => setIsOpenSettingsModal(false), []);

  return (
    <div className={'element-wrapper checkbox'}>
      <div className={'element-inner'}>
        <label htmlFor={element.id} className="form-label w-full">
          {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
        </label>
        <div className="form-control-checkbox-wrapper">
          {element.inputs.map((input, index) => {
            const { id: inputId } = input;
            const isChecked = !!input.valueInput;
            const inputLabelId = `${elementId}-${inputId}`;
            return (
              <div
                key={index}
                className={cx('form-control-checkbox element', {
                  print: isPrint,
                  [alignment]: alignment,
                  checked: isChecked,
                  [`form-control-checkbox-${element.options.size}`]: element.options.size,
                })}
              >
                <label className={'checkbox-label'} htmlFor={inputLabelId}>
                  {!isPrint && (
                    <input
                      id={inputLabelId}
                      name={element.options.label}
                      type="checkbox"
                      value={inputId}
                      checked={isChecked}
                      readOnly={input.readOnly}
                      onChange={(e) => handlerChange(element, e.target.checked, false, index)}
                    />
                  )}
                  <span className={cx('checkbox-button', { checked: isChecked })} />
                  {input.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>

      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}

      {currentUserCanEdit && (
        <div className={'init-form-aside-buttons'}>
          <button onClick={() => duplicateElement(element.id)} className="btn btn-settings btn-icon init-form-copy-element-btn">
            <i className="icon-copy" />
          </button>
          <button onClick={handelOpenSettingsModal} className="btn btn-settings btn-icon settings-modal-btn">
            <i className="icon-settings" />
          </button>
          <button onClick={() => removeElement(element.id)} className="btn btn-settings btn-icon btn-remove">
            <i className="icon-remove"></i>
          </button>
        </div>
      )}
      {currentUserCanEdit && (
        <SettingsModal element={element} isOpen={isOpenSettingsModal} handlerChange={handlerChange} onClose={handelCloseSettingsModal} />
      )}
    </div>
  );
};
