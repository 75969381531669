// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-initiation-modal {
  width: 100%;
  max-width: 480px !important;
  min-height: 310px;
  background-color: #fff !important;
  z-index: 9;
}
.delete-initiation-modal-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  padding: 48px;
  align-items: center;
  justify-content: center;
}
.delete-initiation-modal .modal-header .title {
  display: flex;
  gap: 16px;
  align-items: center;
}
.delete-initiation-modal .modal-body .content {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #667085;
}
.delete-initiation-modal .modal-footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.delete-initiation-modal .delete-initiation-btn {
  display: inline-flex;
  align-items: center;
  box-shadow: none;
  color: #ffffff;
  border: 1px solid transparent;
  background-color: #D92D20;
}
.delete-initiation-modal .delete-initiation-btn:hover {
  color: #D92D20;
  border-color: #D92D20;
  background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/library/form-management-modal/components/delete-initiation-modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,2BAAA;EACA,iBAAA;EACA,iCAAA;EACA,UAAA;AACF;AACE;EACE,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAGI;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AADN;AAMI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAJN;AAQE;EACE,8BAAA;EACA,+BAAA;AANJ;AASE;EACE,oBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,6BAAA;EACA,yBAAA;AAPJ;AASI;EACE,cAAA;EACA,qBAAA;EACA,yBAAA;AAPN","sourcesContent":[".delete-initiation-modal {\n  width: 100%;\n  max-width: 480px !important;\n  min-height: 310px;\n  background-color: #fff !important;\n  z-index: 9;\n\n  &-overlay {\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: fixed;\n    display: flex;\n    padding: 48px;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .modal-header {\n    .title {\n      display: flex;\n      gap: 16px;\n      align-items: center;\n    }\n  }\n\n  .modal-body {\n    .content {\n      font-size: 16px;\n      font-weight: 400;\n      line-height: 24px;\n      color: #667085;\n    }\n  }\n\n  .modal-footer {\n    border-bottom-left-radius: 8px;\n    border-bottom-right-radius: 8px;\n  }\n\n  .delete-initiation-btn {\n    display: inline-flex;\n    align-items: center;\n    box-shadow: none;\n    color: #ffffff;\n    border: 1px solid transparent;\n    background-color: #D92D20;\n\n    &:hover {\n      color: #D92D20;\n      border-color: #D92D20;\n      background-color: #ffffff;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
