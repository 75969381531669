import React from 'react';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';

export const SingleLineElement = function FromElement({ element, handlerChange, error }) {
  const isRequiredElement = useIsRequiredElement(element);
  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <input
        value={element.options.valueInput.trimStart()}
        onChange={(e) => handlerChange(element, e.target.value)}
        name={element.options.name}
        readOnly={element.options.readOnly}
        id={element.id}
        type="text"
        className={`form-control form-control-${element.options.size}`}
      />
      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
