import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../container/authContext';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { getFacilitiesByCompany } from '../../services/facility';
import { deactivatePosition, getCompanyPositionsList } from '../../services/positions';
import PositionsTab from '../../components/company/positions-tab';
import PositionsModal from '../../components/company/positions-modal';
import DeactivateModal from '../../components/company/deactivate-modal';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';

function CompanyList() {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [totalUserPage, setTotalUserPage] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [errors, setErrors] = useState([]);
  const [filtersUser, setFiltersUser] = useState({
    facilities_ids: [],
    status: 'active',
    search: '',
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalDeactivateIsOpen, setDeactivateIsOpen] = useState(false);

  const [currentItem, setCurrentItem] = useState(null);
  const { user } = useAuth();
  const [facilities, setFacilities] = useState([]);
  const [pageBreadcrumbs] = useState([
    {
      url: `/company/${id}`,
      name: `${user.company.name}`,
    },
    {
      url: `/company/${id}/positions`,
      name: 'Positions List',
    },
  ]);
  const navigation = useNavigate();
  const { isEmployee, hasPermission } = usePermission();
  useEffect(() => {
    if (isEmployee() && !hasPermission('Users, Positions management')) {
      redirectToLibrary(navigation);
    }
  }, [isEmployee, navigation, hasPermission]);
  const [defaultIndex, setDefaultIndex] = useState(1);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [id, userPage, filtersUser]);

  const handlePageUserClick = (event) => {
    setUserPage(event.selected + 1);
  };
  const initPage = () => {
    let params = {
      page: userPage,
      ...filtersUser,
    };

    getCompanyPositionsList(id, params)
      .then((resUsers) => {
        setUsers(resUsers.results);
        setTotalUserPage(resUsers.total_pages);
        setTotalUser(resUsers.count);
      })
      .catch((e) => console.log(e));
    let action = null;

    if (isEmployee()) {
      action = 'users_positions_management';
    }
    getFacilitiesByCompany(id, 'getAll', action)
      .then((resFacilities) => {
        setFacilities(resFacilities);
      })
      .catch((e) => console.log(e));
  };
  function closeAddModal() {
    setIsOpen(false);
    setCurrentItem(null);
  }
  const handelCreateUserGroup = () => {
    initPage();
    closeAddModal();
  };
  const openAddModal = () => {
    setIsOpen(true);
  };

  const openDeactivateModal = (item) => {
    setDeactivateIsOpen(true);
    setCurrentItem(item);
  };

  const openEditModal = (item) => {
    setIsOpen(true);
    setCurrentItem(item);
  };

  function closeDeactivateModal() {
    setDeactivateIsOpen(false);
    setCurrentItem(null);
    setErrors([]);
  }

  const applyFilters = (filters) => {
    setFiltersUser(filters);
  };

  const handelDeactivate = () => {
    deactivatePosition(currentItem.id)
      .then((res) => {
        initPage();
        closeDeactivateModal();
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrors(e.response.data);
        }
      });
  };
  return (
    <Layout title={'Position List'} breadcrumbs={pageBreadcrumbs}>
      <div className={`page-wrapper`}>
        <div className="company-profile">
          <div className="company-profile-tab">
            <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
              <TabList>
                <Tab
                  disabled={isEmployee()}
                  onClick={() => {
                    if (!isEmployee()) {
                      navigation(`/company/${id}/user-groups`);
                    }
                  }}
                >
                  All User Groups
                </Tab>
                <Tab>Positions</Tab>
                <Tab
                  disabled={isEmployee()}
                  onClick={() => {
                    if (!isEmployee()) {
                      navigation(`/company/${id}/units`);
                    }
                  }}
                >
                  Units
                </Tab>
              </TabList>
              <TabPanel>User Groups</TabPanel>
              <TabPanel>
                {/*<h3 className="company-profile-title">Users</h3>*/}
                <PositionsTab
                  onUpdate={initPage}
                  openDeactivateModal={openDeactivateModal}
                  openEditModal={openEditModal}
                  facilities={facilities}
                  handelFilter={applyFilters}
                  filtersUser={filtersUser}
                  totalItem={totalUser}
                  totalPage={totalUserPage}
                  handlePageClick={handlePageUserClick}
                  userGroup={users}
                  openAddModal={openAddModal}
                />

                <PositionsModal
                  userGroupData={currentItem}
                  companyName={user.company.name}
                  facilities={facilities}
                  closeModal={closeAddModal}
                  isOpen={modalIsOpen}
                  openModal={openAddModal}
                  handelCreateUserGroup={handelCreateUserGroup}
                />
              </TabPanel>
              <TabPanel>Units</TabPanel>
            </Tabs>
          </div>
        </div>
      </div>

      <DeactivateModal
        isOpen={modalDeactivateIsOpen}
        closeModal={closeDeactivateModal}
        handelSuccess={handelDeactivate}
        title="Confirm Position deletion"
        subtitle="This action is irreversible and will permanently remove all associated entities. Deleted information cannot be restored."
        button={'Delete'}
        errors={errors}
      ></DeactivateModal>
    </Layout>
  );
}

export default CompanyList;
