import { get } from 'lodash';

// Form initialization or submit
export const getFormFieldsWithInitialValues = (formFields, userFormRole) => {
  let newFormFields;
  if (Array.isArray(formFields)) {
    newFormFields = formFields.map((field) => {
      const { type: fieldType } = field ?? {};
      const newField = field ? { ...field } : undefined;
      if (field) {
        if (fieldType === 'table') {
          const { rows, cols: tableCols } = field;
          if (Array.isArray(rows) && Array.isArray(tableCols)) {
            newField.rows.forEach((row) => {
              row.cols = tableCols.map((tableCol, colIndex) => {
                const { autoSetValueFor, type: tableColType } = tableCol;
                const rowCol = get(row, `cols[${colIndex}]`);
                let colValue = '';

                if (rowCol) {
                  const { value: rowColValue } = rowCol;
                  colValue = rowColValue ?? '';

                  if (tableColType?.value === 'date' || tableColType?.value === 'time') {
                    if (!rowColValue && autoSetValueFor === userFormRole) {
                      colValue = new Date();
                    }
                    return {
                      ...rowCol,
                      value: colValue,
                    };
                  }
                } else {
                  return {
                    ...tableCol,
                    value: colValue,
                  };
                }

                return { ...(Object.keys(rowCol).length ? rowCol : tableCol), value: colValue };
              });
            });
          }
        }
      }
      return newField;
    });
  }
  return newFormFields;
};
