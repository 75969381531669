import _ from 'lodash';
import React from 'react';
import './styles.scss';
import UnitBedRow from './UnitBedRow';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { extractBedParts } from '../../../../utility';

const UnitTable = ({ isPrint, unit, getNotes, setBedboardData, facility, hasWritePermission }) => {
  let lastRoomNumber = null;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="unit-block" style={{ pageBreakAfter: 'always' }}>
        <div className="text-center">
          <p className="unit-in-house-title ">
            Unit in-house: <span className="fw-bolder">{unit.in_house_count}</span>
          </p>
        </div>
        <p className="unit-title text-center">{unit.name}</p>
        <div className="table-responsive table-default table-full mt-2 unit-table scrollbar">
          <div className="table-wrapper">
            <div className="table">
              {unit.beds.length ? (
                unit.beds.map((item, index) => {
                  const row = (
                    <UnitBedRow
                      key={index}
                      index={index}
                      unitBed={item}
                      getNotes={getNotes}
                      setBedboardData={setBedboardData}
                      facility={facility}
                      unit={unit}
                      isPrint={isPrint}
                      lastRoomNumber={lastRoomNumber}
                      hasWritePermission={hasWritePermission}
                    />
                  );
                  lastRoomNumber = extractBedParts(item.bed).number;

                  return row;
                })
              ) : (
                <div className="tr">
                  <div className="td">No beds yet</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default UnitTable;
