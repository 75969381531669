import React, { memo, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import { getCNADefaultTasksService } from '../../../../../../services/cna/default-tasks';
import { isResponseOk } from '../../../../../../utility/isResponseOk';
import { useAuth } from '../../../../../../container/authContext';
import Select from 'react-select';
import { assignCNATasksToShiftRoomService, updateCNARoomTaskService } from '../../../../../../services/cna/assignments/cna';

const ManageTaskModal = ({ manageTaskData = {}, onClose, onRefetchAssignment }) => {
  const { user } = useAuth();
  const companyID = user?.company?.id;
  const { shiftRecordID, name: manageTaskName, default_task_id: manageTaskDefaultTaskID } = manageTaskData;

  const [defaultTasksSuggest, setDefaultTasksSuggest] = useState([]);
  const [taskTitle, setTaskTitle] = useState(manageTaskName ?? '');
  const [selectedDefaultTaskID, setSelectedDefaultTaskID] = useState(manageTaskData?.is_default ? manageTaskDefaultTaskID : null);

  const isEditing = !!manageTaskData?.id;

  const options = useMemo(() => {
    return defaultTasksSuggest?.map(({ id, text }) => ({ value: id, label: text }));
  }, [defaultTasksSuggest]);
  const selectedDefaultTaskOption = useMemo(() => {
    let option = null;

    if (typeof selectedDefaultTaskID === 'number' && defaultTasksSuggest?.length) {
      const defaultTask = defaultTasksSuggest.find((item) => item.id === selectedDefaultTaskID);
      if (defaultTask) {
        const { id, text } = defaultTask;
        option = {
          value: id,
          label: text,
        };
      }
    }

    return option;
  }, [defaultTasksSuggest, selectedDefaultTaskID]);

  const isValidTask = useMemo(() => {
    const trimTitle = taskTitle.trim();
    const trimTitleLength = trimTitle.length;

    if (selectedDefaultTaskID) {
      return true;
    }

    return trimTitleLength >= 2 && trimTitleLength <= 255;
  }, [taskTitle, selectedDefaultTaskID]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCNADefaultTasksService({ companyID });
        if (isResponseOk(res) && res.data) {
          setDefaultTasksSuggest(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [companyID]);

  const onInputChange = (value, action) => {
    if (action.action === 'input-change') {
      setSelectedDefaultTaskID(null);
      setTaskTitle(value);
    }
  };

  const handleSelectDefaultTask = ({ value, label }) => {
    setTaskTitle(label);
    setSelectedDefaultTaskID(value);
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        let newTaskTitle;
        let newDefaultTaskID;
        if (selectedDefaultTaskID) {
          newDefaultTaskID = selectedDefaultTaskID;
        } else {
          if (selectedDefaultTaskOption) {
            newTaskTitle = selectedDefaultTaskOption.label;
          } else {
            newTaskTitle = taskTitle;
          }
        }
        const data = {
          shiftRecordID,
          taskID: manageTaskData?.id,
          taskTitle: newTaskTitle,
          default_task_id: newDefaultTaskID,
        };
        const res = await updateCNARoomTaskService(data);
        if (isResponseOk(res)) {
          onClose();
          onRefetchAssignment();
        }
      } else {
        let newTask = {};

        if (selectedDefaultTaskID) {
          newTask.default_task_id = selectedDefaultTaskID;
        } else {
          newTask.name = taskTitle;
        }

        const response = await assignCNATasksToShiftRoomService({ shiftRecordID, ...newTask });
        if (isResponseOk(response)) {
          onClose();
          onRefetchAssignment();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleTaskSelectBlur = () => {
    const formattedNewTitle = taskTitle.trim();
    if (formattedNewTitle.length) {
      setSelectedDefaultTaskID(null);
      setTaskTitle(formattedNewTitle);
    }
  };

  const handleTaskSelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTaskSelectBlur();
      e.preventDefault();
    }
  };

  const handleFocusToStringEnd = (e) => {
    const valueLength = e.target.value.length;
    e.target.setSelectionRange(valueLength, valueLength);
  };

  return (
    <Modal
      isOpen
      className={cx('modal-default assignment-manage-task-modal')}
      overlayClassName={'modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        {`${isEditing ? 'Edit' : 'Add Default'} Task`}
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <div className="group">
          <span className={'group__title'}>
            <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="2.5" y="2" width="32" height="32" rx="16" fill="#F4EBFF" />
              <rect x="2.5" y="2" width="32" height="32" rx="16" stroke="#F9F5FF" strokeWidth="4" />
              <path
                d="M21.1667 12.6693H22.5001C22.8537 12.6693 23.1928 12.8097 23.4429 13.0598C23.6929 13.3098 23.8334 13.649 23.8334 14.0026V23.3359C23.8334 23.6896 23.6929 24.0287 23.4429 24.2787C23.1928 24.5288 22.8537 24.6693 22.5001 24.6693H14.5001C14.1465 24.6693 13.8073 24.5288 13.5573 24.2787C13.3072 24.0287 13.1667 23.6896 13.1667 23.3359V14.0026C13.1667 13.649 13.3072 13.3098 13.5573 13.0598C13.8073 12.8097 14.1465 12.6693 14.5001 12.6693H15.8334M16.5001 11.3359H20.5001C20.8683 11.3359 21.1667 11.6344 21.1667 12.0026V13.3359C21.1667 13.7041 20.8683 14.0026 20.5001 14.0026H16.5001C16.1319 14.0026 15.8334 13.7041 15.8334 13.3359V12.0026C15.8334 11.6344 16.1319 11.3359 16.5001 11.3359Z"
                stroke="#7F56D9"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {isEditing ? undefined : 'Default'} Task Information
          </span>

          <div className="group__body">
            <label className="form-label">Task</label>
            <Select
              isSearchable
              options={options}
              inputValue={selectedDefaultTaskOption ? selectedDefaultTaskOption.label : taskTitle}
              className="form-control-select"
              classNamePrefix="react-select"
              value={selectedDefaultTaskOption}
              styles={{
                input: (provided) => ({
                  ...provided,
                  input: {
                    opacity: '1 !important',
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  minWidth: '100%',
                }),
                option: (provided) => ({
                  ...provided,
                  cursor: 'pointer',
                }),
              }}
              filterOption={null}
              onFocus={handleFocusToStringEnd}
              onKeyDown={handleTaskSelectKeyDown}
              onInputChange={onInputChange}
              onChange={handleSelectDefaultTask}
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isValidTask} onClick={handleSubmit}>
          {isEditing ? 'Save' : 'Add'}
        </button>
      </div>
    </Modal>
  );
};

export default memo(ManageTaskModal);
