import React, { useEffect, useState } from 'react';
import InitiatorCanEditSetting from './components/initiator-can-edit-setting';
import AddToCopyGroupSetting, { ADD_TO_COPY_GROUP_SETTING_KEY } from './components/add-to-copy-group-setting';

export const FileAttachElementSettings = function FromElement({ element, handlerChangeOption, duplicateElement }) {
  const [label, setLabel] = useState(element.options.label);
  const [instructions, setInstructions] = useState(element.options.instructions);

  useEffect(() => {
    setLabel(element.options.label);
    setInstructions(element.options.instructions);
  }, [element]);

  return (
    <div className="tabs-item">
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Name Label</h3>
        <div className="form-control-item">
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'label', element.id);
              handlerChangeOption(e.target.value.replace(/ /g, '-').toLowerCase(), 'name', element.id);
            }}
          />
        </div>
      </div>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Instructions</h3>
        <div className="form-control-item">
          <textarea
            className="form-control"
            placeholder="Enter Instructions"
            value={instructions}
            onChange={(e) => {
              setInstructions(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'instructions', element.id);
            }}
          ></textarea>
        </div>
      </div>

      <InitiatorCanEditSetting element={element} onChange={handlerChangeOption} />

      <AddToCopyGroupSetting
        elementID={element?.id}
        selected={element?.options?.[ADD_TO_COPY_GROUP_SETTING_KEY]}
        onChange={handlerChangeOption}
      />
    </div>
  );
};
