import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';

dayjs.extend(utc);

/**
 * Converts a UTC date string to a local Date object.
 *
 * @param {string | undefined} utcDateString - The UTC date string in the format "YYYY-MM-DDTHH:mm:ss".
 * @returns {Date | undefined} - Returns a local Date object or `undefined` if the input is invalid.
 */
export const utcToLocalDate = (utcDateString) => {
  try {
    if (!utcDateString) {
      console.warn('utcDateString is empty.');
      return undefined;
    }

    // Parse the UTC date string using Day.js with strict format matching
    const parsedDate = dayjs.utc(utcDateString, 'YYYY-MM-DDTHH:mm:ss', true);

    // Validate the parsed date
    if (!parsedDate.isValid()) {
      console.error(`Invalid UTC date: ${utcDateString}`);
      return undefined;
    }

    // Convert to local time and return as Date object
    return parsedDate.local().toDate();
  } catch (error) {
    console.error('Error converting UTC to local date:', error);
    return undefined;
  }
};

/**
 * Converts a UTC date string to a formatted local date string.
 *
 * @param {string | Date | undefined} utcDate - The UTC date string in the format "YYYY-MM-DDTHH:mm:ss" or a Date object.
 * @param {string} [dateFormat=DATE_FORMAT] - The desired date format string. Defaults to DATE_FORMAT if not provided.
 * @returns {string | undefined} - Returns a formatted date string or `undefined` if the input is invalid.
 */
export const utcToLocalDateFormatter = (utcDate, dateFormat = DATE_FORMAT) => {
  try {
    let localDate;

    if (typeof utcDate === 'string') {
      // Use the existing parse function to convert string to Date
      localDate = utcToLocalDate(utcDate);
      if (!localDate) {
        // If parsing failed, return undefined
        return undefined;
      }
    } else if (utcDate instanceof Date) {
      if (isNaN(utcDate.getTime())) {
        console.error(`Invalid Date object: ${utcDate}`);
        return undefined;
      }
      // Convert the Date object to local time using Day.js
      localDate = dayjs(utcDate).toDate();
    } else {
      console.error(`Unsupported type for utcDate: ${typeof utcDate}`);
      return undefined;
    }

    // Format the local date
    return dayjs(localDate).format(dateFormat);
  } catch (error) {
    console.error('Error formatting UTC to local date string:', error);
    return undefined;
  }
};
