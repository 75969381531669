import React, { useState, useEffect } from 'react';
import Layout from '../../layout/default';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getSubmissionItem, getSubmissionProfileById } from '../../services/folder/form-avaliable';
import { useAuth } from '../../container/authContext';
import DatePicker from 'react-datepicker';
import { getSubmissionInfo } from '../../services/folder/sabmissions';
import ReactPaginate from 'react-paginate';
import { Tooltip } from 'react-tooltip';
import PrintModal from '../../components/init-form/print-modal';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getUserById } from '../../services/user';
import { utcToLocalDateFormatter } from '../../utility/dates/utcToLocalDate';
import { localToUTCStringForDateRange } from '../../utility/dates/localToUTCForDateRange';

function CorporateLibrary() {
  const { user } = useAuth();
  const { corporate_form_id, user_id, id } = useParams();
  const [defaultIndex, setDefaultIndex] = useState(1);
  const [profile, setUser] = useState({});
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([
    {
      url: '/my-profile',
      name: 'My Profile',
    },
  ]);
  const [formsSearch, setFormsSearch] = useState([]);
  const [search, setSearch] = useState('');
  const [isFilterMode, setIsFilterMode] = useState(true);
  const [column, setColumn] = useState([]);
  const [filters, setFilters] = useState({
    submission_status: {
      value: 'Finished',
      label: 'Finished',
    },
    positions: [],
    submitters: [],
    facilities: [],
    initiator: null,
    initiation_date_before: null,
    initiation_date_after: null,
    submission_date_before: null,
    submission_date_after: null,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateSubmission, setStartDateSubmission] = useState(null);
  const [endDateSubmission, setEndDateSubmission] = useState(null);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPages] = useState(0);
  const [formData, setFormData] = useState(null);
  const [name, setName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const navigation = useNavigate();
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    setColumn([
      {
        name: 'Submission',
        total: total,
      },
      {
        name: 'Submitters',
      },
      {
        name: 'Facility',
      },
      {
        name: 'Position',
      },
      {
        name: 'Form Initiator',
      },
      {
        name: 'Status',
      },
      {
        name: 'Initiation date',
      },
      {
        name: 'Date received',
      },
      {
        name: 'Submission date',
      },
    ]);
  }, [formsSearch, total]);

  useEffect(() => {
    initPage();
    if (search || isHasFilter()) {
      changeFolder();
    } else {
      setFormsSearch([]);
    }
    // eslint-disable-next-line
  }, [search, filters, page]);
  useEffect(() => {
    setPage(1);
  }, [search, filters]);
  useEffect(() => {
    initPage();

    getSubmissionInfo(corporate_form_id)
      .then((res) => {
        setName(res.name ? res.name : 'Submission');
      })
      .catch((e) => console.log(e));

    // eslint-disable-next-line
  }, [user, corporate_form_id]);

  const isHasFilter = () => {
    return !!(
      filters.positions.length ||
      filters.submitters.length ||
      filters.facilities.length ||
      filters.initiator !== null ||
      filters.submission_status !== null ||
      filters.initiation_date_before !== null ||
      filters.initiation_date_after !== null ||
      filters.submission_date_before !== null ||
      filters.submission_date_after !== null
    );
  };

  const initPage = () => {
    getUserById(user_id)
      .then((res) => {
        setUser(res);
        let breadcrumbs = [
          {
            url: `/company/${res.company.id}`,
            name: res.company.name,
          },
          {
            url: `/company/${res.company.id}/users`,
            name: 'User List',
          },
          {
            url: `/company/${res.company.id}/user/${res.id}`,
            name: `${res.first_name} ${res.last_name}`,
          },
          {
            url: `/company/${res.company.id}/user/${res.id}/forms`,
            name: `Forms`,
          },
        ];
        setPageBreadcrumbs(breadcrumbs);
      })
      .catch((e) => console.log(e));
  };

  const handleDateChange = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };
  const handleDateChangeSubmission = (date) => {
    setStartDateSubmission(date[0]);
    setEndDateSubmission(date[1]);
  };

  useEffect(() => {
    if (endDate) {
      setFilters({
        ...filters,
        initiation_date_before: endDate,
        initiation_date_after: startDate,
      });
    } else {
      setFilters({
        ...filters,
        initiation_date_before: null,
        initiation_date_after: null,
      });
    }
    // eslint-disable-next-line
  }, [endDate, startDate]);
  useEffect(() => {
    if (endDateSubmission) {
      setFilters({
        ...filters,
        submission_date_before: endDateSubmission,
        submission_date_after: startDateSubmission,
      });
    } else {
      setFilters({
        ...filters,
        submission_date_before: null,
        submission_date_after: null,
      });
    }
    // eslint-disable-next-line
  }, [endDateSubmission, startDateSubmission]);

  const clearFilter = () => {
    setFormsSearch([]);
    setSearch('');
    setFilters({
      positions: [],
      submitters: [],
      facilities: [],
      initiator: null,
      initiation_date_before: null,
      initiation_date_after: null,
      submission_date_before: null,
      submission_date_after: null,
      submission_status: {
        value: 'Finished',
        label: 'Finished',
      },
    });
    setStartDate(null);
    setEndDate(null);
    setStartDateSubmission(null);
    setEndDateSubmission(null);
  };
  const openModal = (id) => {
    if (id > 0) {
      setFormData(null);
      getSubmissionItem(corporate_form_id, id)
        .then((res) => {
          setFormData(res);
          setIsOpen(true);
        })
        .catch((e) => console.log(e));
    }
  };

  // eslint-disable-next-line
  const closeModal = () => {
    setFormData(null);
    setIsOpen(false);
  };

  const changeFolder = () => {
    const bodyRequest = { page: page, search: search };

    if (!search) {
      delete bodyRequest.search;
    }

    bodyRequest['corporate_form_id'] = corporate_form_id;

    if (filters.positions?.length) {
      bodyRequest['positions'] = filters.positions.map((item) => item.value);
    }

    if (filters.facilities?.length) {
      bodyRequest['facilities'] = filters.facilities.map((item) => item.value);
    }

    if (filters.initiator) {
      bodyRequest['initiators'] = filters.initiator.map((item) => item.value);
    }

    if (filters.submitters.length) {
      bodyRequest['submitters'] = filters.submitters.map((item) => item.value);
    }

    if (filters.submission_status) {
      bodyRequest['submission_status'] = filters.submission_status.value;
    }

    if (filters.initiation_date_before) {
      bodyRequest['initiation_date_before'] = localToUTCStringForDateRange(filters.initiation_date_before, 'end');
    }

    if (filters.initiation_date_after) {
      bodyRequest['initiation_date_after'] = localToUTCStringForDateRange(filters.initiation_date_after, 'start');
    }

    if (filters.submission_date_after) {
      bodyRequest['submission_date_after'] = localToUTCStringForDateRange(filters.submission_date_after, 'start');
    }

    if (filters.submission_date_before) {
      bodyRequest['submission_date_before'] = localToUTCStringForDateRange(filters.submission_date_before, 'end');
    }

    getSubmissionProfileById(corporate_form_id, user_id, bodyRequest)
      .then((res) => {
        setFormsSearch(res.results);
        setTotalPages(res.total_pages);
        setTotal(res.count);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Layout breadcrumbs={pageBreadcrumbs} title={'User Profile - Forms'}>
      <div className={`page-wrapper ${profile.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
        <div className="company-profile">
          {profile.first_name && (
            <>
              <div className="company-profile-header">
                <div className="user-profile-header">
                  <div>
                    <span className="date-add">
                      Date Added: {utcToLocalDateFormatter(profile.created_at)}{' '}
                      {profile.deleted_at && (
                        <span className="date-add-deactivated">Deactivated: {utcToLocalDateFormatter(user.deleted_at)}</span>
                      )}
                    </span>
                    <h2 className="company-profile-title">
                      {profile.last_name} {profile.first_name}
                    </h2>
                    <h3 className="company-profile-subtitle">{profile.role}</h3>
                  </div>
                </div>
                <div className="company-profile-header-action"></div>
              </div>
              <div className="company-profile-tab user-profile-tab">
                <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
                  <TabList>
                    <Tab onClick={() => navigation(`/company/${id}/user/${user_id}`)}>Main Information</Tab>
                    <Tab>Forms</Tab>
                  </TabList>
                  <TabPanel>
                    <h3>Main Information</h3>
                  </TabPanel>
                  <TabPanel>
                    <div className="library p-0">
                      <h2 className="library-title">
                        <Link to={`/company/${id}/user/${user_id}/forms`} className="mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                              d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
                              stroke="#101828"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Link>
                        {name ? name : ''}
                      </h2>
                      <div className="library-filter">
                        <div className="library-filter-search form-control-item">
                          <input
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Search"
                          />
                          <i className="icon-search"></i>
                        </div>
                        <div>
                          <button
                            className={`btn-outline btn ${isFilterMode ? 'btn-active' : ''}`}
                            onClick={() => setIsFilterMode(!isFilterMode)}
                          >
                            <i className="icon-icon-sliders"></i>
                          </button>
                          {isFilterMode && (
                            <button className="btn btn-outline" onClick={clearFilter}>
                              Clear All
                            </button>
                          )}
                        </div>
                      </div>
                      {isFilterMode && (
                        <div className="library-filters">
                          <div className="library-filters-wrapper">
                            {/*<div className="form-item">*/}
                            {/*    <label className="form-label">Submission status</label>*/}
                            {/*    <Select*/}
                            {/*        options={[*/}
                            {/*            {*/}
                            {/*                value: '',*/}
                            {/*                label: 'All'*/}
                            {/*            }, {*/}
                            {/*                value: 'Sent',*/}
                            {/*                label: 'Sent'*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                value: 'In Progress',*/}
                            {/*                label: 'In Progress'*/}
                            {/*            },*/}
                            {/*            {*/}
                            {/*                value: 'Finished',*/}
                            {/*                label: 'Finished'*/}
                            {/*            },*/}
                            {/*        ]}*/}
                            {/*        value={filters.submission_status}*/}
                            {/*        onChange={(e) => handleFilter(e, 'submission_status')}*/}
                            {/*        className="form-control-select"*/}
                            {/*        classNamePrefix="react-select"*/}
                            {/*    ></Select>*/}
                            {/*</div>*/}
                            <div className="form-item">
                              <label className="form-label">Initiation date</label>
                              <DatePicker
                                selected={startDate}
                                className="form-control"
                                onChange={handleDateChange}
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                                isClearable
                                disabledKeyboardNavigation
                                placeholderText={'Select...'}
                              />
                            </div>
                            <div className="form-item">
                              <label className="form-label">Submission date</label>
                              <DatePicker
                                selected={startDateSubmission}
                                className="form-control"
                                onChange={handleDateChangeSubmission}
                                selectsRange
                                startDate={startDateSubmission}
                                endDate={endDateSubmission}
                                isClearable
                                disabledKeyboardNavigation
                                placeholderText={'Select...'}
                              ></DatePicker>
                            </div>
                          </div>
                        </div>
                      )}

                      <>
                        {formsSearch?.length > 0 && (
                          <div className="table-responsive table-default table-full">
                            <div className="table-wrapper" key={'formsSearch-key'}>
                              <div className="table">
                                <div className="tr">
                                  {column.map((item, index) => (
                                    <div className="th" key={`table-th-${item.name}`}>
                                      {item.name}
                                      {item.tooltip && (
                                        <>
                                          <i data-tooltip-id={`table-tooltip-${index}`} className="icon-icon-info"></i>
                                          <Tooltip opacity={1} id={`table-tooltip-${index}`} place="bottom" content={item.tooltip} />
                                        </>
                                      )}
                                      {!!item.total && <span className="th-total">(Total: {item.total} submissions)</span>}
                                    </div>
                                  ))}
                                </div>
                                {formsSearch.map((item, index) => (
                                  <div
                                    key={`${item.submitter}-${item.id}-${index}`}
                                    className={`tr ${item.submission_id > 0 ? 'cursor-pointer' : ''}`}
                                    onClick={() => openModal(item.submission_id)}
                                  >
                                    <>
                                      <div className="td">{item.name}</div>
                                      <div className="td">{item.submitter}</div>
                                      <div className="td">{item.facility}</div>
                                      <div className="td">{item.position}</div>
                                      <div className="td">{item.form_initiator}</div>
                                      <div className="td">{item.status}</div>
                                      <div className="td">{item.initiation_date ? utcToLocalDateFormatter(item.initiation_date) : '-'}</div>
                                      <div className="td">{item.date_received ? utcToLocalDateFormatter(item.date_received) : '-'}</div>
                                      <div className="td">{item.submitted_at ? utcToLocalDateFormatter(item.submitted_at) : '-'}</div>
                                    </>
                                  </div>
                                ))}
                              </div>

                              {totalPage > 1 && (
                                <ReactPaginate
                                  className={'react-pagination'}
                                  breakLabel="..."
                                  nextLabel="next ->"
                                  pageRangeDisplayed={5}
                                  forcePage={page - 1}
                                  pageCount={totalPage}
                                  previousLabel="<- previous"
                                  onPageChange={handlePageClick}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {/*{!formsSearch.length && !!search &&*/}
                        {/*    <>*/}
                        {/*        <div className="empty-page">*/}
                        {/*            <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>*/}
                        {/*            <h4 className="empty-page-subtitle">Try to change the range of keywords. We're here to help you find what you're looking for.</h4>*/}
                        {/*        </div>*/}
                        {/*    </>*/}
                        {/*}*/}
                        {!formsSearch?.length && (
                          <>
                            <div className="empty-page">
                              <h3 className="empty-page-title">To view a list of submissions, please use the search by name or filters.</h3>
                            </div>
                          </>
                        )}
                      </>
                    </div>
                    {isOpen && <PrintModal isOpen={isOpen} closeModal={closeModal} formData={formData} hideActions></PrintModal>}
                  </TabPanel>
                </Tabs>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default CorporateLibrary;
