import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import cx from 'classnames';

import 'react-quill/dist/quill.snow.css';

const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'times-new-roman'];
Quill.register(Font, true);

export const TEXT_ELEMENT_MODULES = {
  toolbar: [
    [{ font: ['arial', 'times-new-roman'] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ color: ['black', 'grey', 'silver', 'red', 'maroon', 'lime', 'green', 'aqua', 'blue', 'navy', 'orange', 'yellow', 'fuchsia'] }],
    ['bold', 'italic', 'underline'],
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '+1' }, { indent: '-1' }],
    ['clean'],
  ],
};
export const TEXT_ELEMENT_FORMATS = ['font', 'size', 'color', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'align'];
const NBSP = '\u00A0';

export const TextElement = function FromElement({ element, handlerChangeOption }) {
  const quillRef = useRef(null);

  const [value, setValue] = useState(element.options.value);
  const [showEditor, setShowEditor] = useState(true);

  useEffect(() => {
    setValue(element.options.value);
  }, [element.options.value]);

  useEffect(() => {
    const editor = quillRef.current.getEditor();
    const handleSpace = (event) => {
      if (event.key === ' ') {
        event.preventDefault();
        const range = editor.getSelection();
        if (range) {
          editor.insertText(range.index, NBSP);
          editor.setSelection(range.index + 1);
          const newValue = editor.root.innerHTML;
          setValue(newValue);
          handlerChangeOption(newValue, 'value', element.id);
        }
      }
    };

    editor.root.addEventListener('keydown', handleSpace);
    return () => editor.root.removeEventListener('keydown', handleSpace);
  }, []);

  const handelEdit = (e) => {
    setValue(e);
    handlerChangeOption(e, 'value', element.id);
  };

  return (
    <>
      <div>
        <button className="btn-link mb-4 ml-auto" onClick={() => setShowEditor(!showEditor)}>
          {!showEditor && 'Show Editor'}
          {showEditor && 'Hide Editor'}
        </button>
      </div>
      <ReactQuill
        theme="snow"
        ref={quillRef}
        value={value}
        modules={TEXT_ELEMENT_MODULES}
        formats={TEXT_ELEMENT_FORMATS}
        className={cx({ hidden: !showEditor })}
        onChange={handelEdit}
      />
    </>
  );
};
