import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../container/authContext';
import InputMask from 'react-input-mask';
function Login() {
  const { reset } = useAuth();
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');

  console.log(error);
  const handelSubmit = () => {
    if (!phone) {
      return;
    }

    reset({ phone: phone }, setError);
  };

  return (
    <>
      <header className="header-wrapper header-wrapper-login">
        <div className="header-left">
          <div className="header-logo">
            <img src="/images/logo.svg" alt="" />
          </div>
          <div className="pageBreadcrumbs">
            <span>Forgot Password</span>
          </div>
        </div>
      </header>
      <div className="login-page">
        <div className="login-page-wrapper">
          <div className="form-title">
            <h2>Forgot your password?</h2>

            <p>
              Please enter your phone number, and we'll send you<br></br>a link to reset your password.
            </p>
          </div>
          <div className="form-control-item">
            <label className="form-label">Phone Number</label>
            <div className="form-control-item-login">
              <InputMask
                placeholder="XXX-XXX-XXXX"
                mask="999-999-9999"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-control form-control-default"
              ></InputMask>
            </div>
            <div className={'form-error mt-1'}>{error.phone && error.phone[0]}</div>
          </div>

          <button disabled={!phone} className="btn btn-full" onClick={handelSubmit}>
            Send
          </button>

          <div className="login-bottom login-bottom-center">
            <Link className="back-to" to={'/login'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path
                  d="M16.3332 9.99984H4.6665M4.6665 9.99984L10.4998 15.8332M4.6665 9.99984L10.4998 4.1665"
                  stroke="#7F56D9"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back to log in
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
