import client from '../../api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;

export const createFormsAssignedToMeFolder = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }
  try {
    return await client.post(`/api/corporate-library/forms-assigned-to-me/folders/`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateFormsAssignedToMeFolder = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  const { folderID, ...data } = params;
  try {
    return await client.patch(`/api/corporate-library/forms-assigned-to-me/folders/${folderID}/`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteFormsAssignedToMeFolder = async (folderID) => {
  if (cancel !== undefined) {
    cancel();
  }
  try {
    return await client.delete(`/api/corporate-library/forms-assigned-to-me/folders/${folderID}/`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};

export const getFormsAssignedToMeAllFolders = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }
  try {
    return await client.get(`/api/corporate-library/forms/folders/forms-assigned-to-me-folders/`, {
      params,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
  }
};
