import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { Breadcrumbs } from '../../components/library/Breadcrumbs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams, useNavigate } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { usePermission } from '../../container/permissionContext';
import cx from 'classnames';
import { useAuth } from '../../container/authContext';

function CorporateLibrary() {
  const { user } = useAuth();
  const { form, folder } = useParams();
  const navigate = useNavigate();
  const { hasPermissionByCodeName, isSuperAdmin, isCompanySystemAdministrator } = usePermission();
  const { forms_info: { forms_in_progress_count, forms_assigned_count } = {} } = user ?? {};

  const [defaultIndex, setDefaultIndex] = useState(form === 'files' ? 1 : 0);
  const [breadcrumbs] = useState([
    {
      name: 'Company Library',
      url: '/corporate-library',
    },
    null,
    null,
  ]);
  const [defaultFolder, setDefaultFolder] = useState([
    {
      id: 'forms-available-to-me',
      name: 'Forms available to me',
      url: 'forms-available-to-me',
      isDisable: false,
    },
    {
      id: 'forms-assigned-to-me',
      name: 'Forms assigned to me',
      url: 'forms-assigned-to-me',
      isDisable: false,
    },
    {
      id: 'forms-in-progress',
      name: 'Forms in progress',
      url: 'forms-in-progress',
      isDisable: false,
    },
    {
      id: 'submissions',
      name: 'Submissions',
      url: 'submissions',
      isDisable: false,
    },
    {
      id: 'public-forms',
      name: 'Public forms',
      url: 'public-forms',
      isDisable: false,
    },
  ]);

  useEffect(() => {
    if (hasPermissionByCodeName('corporate_library_management')) {
      setDefaultFolder([
        {
          id: 'forms-available-to-me',
          name: 'Forms available to me',
          url: 'forms-available-to-me',
          isDisable: false,
        },
        {
          id: 'forms-assigned-to-me',
          name: 'Forms assigned to me',
          url: 'forms-assigned-to-me',
          isDisable: false,
        },
        {
          id: 'forms-in-progress',
          name: 'Forms in progress',
          url: 'forms-in-progress',
          isDisable: false,
        },
        {
          id: 'submissions',
          name: 'Submissions',
          url: 'submissions',
          isDisable: false,
        },
        {
          id: 'public-forms',
          name: 'Public forms',
          url: 'public-forms',
          isDisable: false,
        },
        {
          id: 'drafts',
          name: 'Drafts',
          url: 'drafts',
          isDisable: false,
        },
      ]);
    }

    if (isSuperAdmin() || isCompanySystemAdministrator()) {
      setDefaultFolder([
        {
          id: 'forms-available-to-me',
          name: 'Forms available to me',
          url: 'forms-available-to-me',
          isDisable: false,
        },
        {
          id: 'forms-assigned-to-me',
          name: 'Forms assigned to me',
          url: 'forms-assigned-to-me',
          isDisable: false,
        },
        {
          id: 'submissions',
          name: 'Submissions',
          url: 'submissions',
          isDisable: false,
        },
        {
          id: 'public-forms',
          name: 'Public forms',
          url: 'public-forms',
          isDisable: false,
        },
        {
          id: 'drafts',
          name: 'Drafts',
          url: 'drafts',
          isDisable: false,
        },
      ]);
    }
  }, [hasPermissionByCodeName, isCompanySystemAdministrator, isSuperAdmin]);

  const goToPrev = (id) => navigate(id, { replace: true });

  const handlerChangeDefaultFolder = (folder) => navigate(`/corporate-library/forms/${folder.url}`);

  return (
    <Layout title="Company Library">
      <div className="library">
        <DndProvider backend={HTML5Backend}>
          <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
        </DndProvider>
        <h2 className="library-title">Company Library </h2>

        {/*<TabsMobile />*/}
        <h2 className="library-title-index-mobile">Company Library</h2>

        <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
          <TabList>
            <Tab
              onClick={() => {
                navigate('/corporate-library/forms');
              }}
            >
              Forms
            </Tab>
            <Tab
              onClick={() => {
                navigate('/corporate-library/files');
              }}
            >
              Files
            </Tab>
          </TabList>

          <TabPanel>
            {folder && (
              <div className="library-filter">
                <div className="library-filter-search form-control-item">
                  <input type="text" className="form-control" placeholder="Search" />
                  <i className="icon-search"></i>
                </div>
                <div>
                  <button className="btn-outline btn">
                    <i className="icon-icon-sliders"></i>
                  </button>
                </div>
              </div>
            )}
            <h3 className="library-subtitle">Folders</h3>
            <div className="library-wrapper">
              {!folder &&
                defaultFolder.map((item) => {
                  let formsCount;

                  if (item.id === 'forms-assigned-to-me' || item.id === 'forms-in-progress') {
                    if (item.id === 'forms-assigned-to-me') {
                      formsCount = forms_assigned_count;
                    } else {
                      // Hide folder if user has no forms in progress
                      if (!forms_in_progress_count) return null;
                      formsCount = forms_in_progress_count;
                    }
                  }

                  return (
                    <div
                      key={item.id}
                      className={cx('library-item cursor-pointer', item.id, { 'library-item-disabled': item.isDisable })}
                      onClick={() => {
                        if (!item.isDisable) handlerChangeDefaultFolder(item);
                      }}
                    >
                      {!!formsCount && <span className={'forms-count'}>{formsCount}</span>}
                      <div className="library-item-text">
                        <img src="/images/folder-big-icon.png" className="mr-2" alt="" />
                        {item.name}
                      </div>
                    </div>
                  );
                })}
            </div>
          </TabPanel>
          <TabPanel>
            <h2>Files</h2>
          </TabPanel>
        </Tabs>
      </div>
    </Layout>
  );
}

export default CorporateLibrary;
