import React, { useState } from 'react';
import { useAuth } from '../../container/authContext';
import { useNavigate, useParams, Link } from 'react-router-dom';

function Login() {
  const { confirmation } = useAuth();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [error, setError] = useState(null);
  const { key } = useParams();
  const navigate = useNavigate();

  if (!key) {
    navigate('/login');
  }

  const handelSubmit = () => {
    confirmation(key, { password: password, password_confirmation: passwordConfirmation }, setError);
  };

  return (
    <>
      <header className="header-wrapper header-wrapper-login">
        <div className="header-left">
          <div className="header-logo">
            <img src="/images/logo.svg" alt="" />
          </div>
          <div className="pageBreadcrumbs">
            <span>User Registration</span>
          </div>
        </div>
      </header>
      <div className="login-page">
        <div className="login-page-wrapper">
          <div className="form-title">
            <h2>New user registration</h2>

            <p>
              Complete registration for a new system user account.<br></br>
              Please kindly ensure to fill out all of these fields.
            </p>
          </div>
          <div className="form-control-item">
            <label className="form-label">Password</label>
            <div className="form-control-item-login">
              <span className="icon-login">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M5.83333 9.1665V5.83317C5.83333 4.7281 6.27232 3.66829 7.05372 2.88689C7.83512 2.10549 8.89493 1.6665 10 1.6665C11.1051 1.6665 12.1649 2.10549 12.9463 2.88689C13.7277 3.66829 14.1667 4.7281 14.1667 5.83317V9.1665M4.16667 9.1665H15.8333C16.7538 9.1665 17.5 9.9127 17.5 10.8332V16.6665C17.5 17.587 16.7538 18.3332 15.8333 18.3332H4.16667C3.24619 18.3332 2.5 17.587 2.5 16.6665V10.8332C2.5 9.9127 3.24619 9.1665 4.16667 9.1665Z"
                    stroke="#98A2B3"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input
                placeholder={'Enter the password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                className="form-control"
              />
              <span className="show-password cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                {!showPassword && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 5.24951C4.5 5.24951 1.5 12.0002 1.5 12.0002C1.5 12.0002 4.5 18.7495 12 18.7495C19.5 18.7495 22.5 12.0002 22.5 12.0002C22.5 12.0002 19.5 5.24951 12 5.24951Z"
                      stroke="#98A2B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                      stroke="#98A2B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {showPassword && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M10.4955 4.82764C11.1525 4.68702 11.8252 4.61673 12.5 4.61818C19.1818 4.61818 23 11.6 23 11.6C22.4206 12.5911 21.7296 13.5241 20.9382 14.384M14.5236 13.4502C14.2615 13.7074 13.9453 13.9137 13.5941 14.0568C13.2428 14.1999 12.8636 14.2769 12.4791 14.2831C12.0946 14.2893 11.7127 14.2246 11.3561 14.0929C10.9995 13.9613 10.6756 13.7653 10.4037 13.5166C10.1317 13.268 9.91738 12.9719 9.77335 12.6459C9.62932 12.3199 9.55859 11.9707 9.56538 11.6191C9.57216 11.2676 9.65632 10.9209 9.81284 10.5997C9.96935 10.2786 10.195 9.98951 10.4764 9.74982M2 2L23 21.2M18.17 16.784C16.5383 17.9212 14.5514 18.5512 12.5 18.5818C5.81818 18.5818 2 11.6 2 11.6C3.18735 9.57693 4.83417 7.8094 6.83 6.416L18.17 16.784Z"
                      stroke="#98A2B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
            </div>
            <span className={'info'}>Minimum 5 characters</span>
            {error?.password && <p className={'form-error'}>{error?.password && error.password[0]}</p>}
          </div>
          <div className="form-control-item">
            <label className="form-label">Repeat Password</label>
            <div className="form-control-item-login">
              <span className="icon-login">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M5.83333 9.1665V5.83317C5.83333 4.7281 6.27232 3.66829 7.05372 2.88689C7.83512 2.10549 8.89493 1.6665 10 1.6665C11.1051 1.6665 12.1649 2.10549 12.9463 2.88689C13.7277 3.66829 14.1667 4.7281 14.1667 5.83317V9.1665M4.16667 9.1665H15.8333C16.7538 9.1665 17.5 9.9127 17.5 10.8332V16.6665C17.5 17.587 16.7538 18.3332 15.8333 18.3332H4.16667C3.24619 18.3332 2.5 17.587 2.5 16.6665V10.8332C2.5 9.9127 3.24619 9.1665 4.16667 9.1665Z"
                    stroke="#98A2B3"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input
                placeholder={'Repeat the password'}
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                type={showPasswordConfirmation ? 'text' : 'password'}
                className="form-control"
              />
              <span className="show-password cursor-pointer" onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}>
                {!showPasswordConfirmation && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 5.24951C4.5 5.24951 1.5 12.0002 1.5 12.0002C1.5 12.0002 4.5 18.7495 12 18.7495C19.5 18.7495 22.5 12.0002 22.5 12.0002C22.5 12.0002 19.5 5.24951 12 5.24951Z"
                      stroke="#98A2B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                      stroke="#98A2B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {showPasswordConfirmation && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M10.4955 4.82764C11.1525 4.68702 11.8252 4.61673 12.5 4.61818C19.1818 4.61818 23 11.6 23 11.6C22.4206 12.5911 21.7296 13.5241 20.9382 14.384M14.5236 13.4502C14.2615 13.7074 13.9453 13.9137 13.5941 14.0568C13.2428 14.1999 12.8636 14.2769 12.4791 14.2831C12.0946 14.2893 11.7127 14.2246 11.3561 14.0929C10.9995 13.9613 10.6756 13.7653 10.4037 13.5166C10.1317 13.268 9.91738 12.9719 9.77335 12.6459C9.62932 12.3199 9.55859 11.9707 9.56538 11.6191C9.57216 11.2676 9.65632 10.9209 9.81284 10.5997C9.96935 10.2786 10.195 9.98951 10.4764 9.74982M2 2L23 21.2M18.17 16.784C16.5383 17.9212 14.5514 18.5512 12.5 18.5818C5.81818 18.5818 2 11.6 2 11.6C3.18735 9.57693 4.83417 7.8094 6.83 6.416L18.17 16.784Z"
                      stroke="#98A2B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
            </div>
            <span className={'info'}>Minimum 5 characters</span>
            {error?.password_confirmation && (
              <p className={'form-error'}>{error?.password_confirmation && error.password_confirmation[0]}</p>
            )}
          </div>

          <button className="btn btn-full" onClick={handelSubmit}>
            Complete Registration
          </button>

          <div className="login-bottom">
            <p>Do you already have an account?</p>
            <Link to={'/login'}>Log in</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
