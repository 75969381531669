// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-date-picker {
  background-color: #f4ebff;
}
.highlight-single-line {
  background-color: #f4ebff;
}
.highlight-time-picker {
  background-color: #f4ebff;
}
.highlight-dropdown .react-select__control {
  background-color: #f4ebff;
}
.highlight-multiline {
  background-color: #f4ebff;
}
.highlight-number {
  background-color: #f4ebff;
}
.highlight-current-user {
  background-color: #f4ebff;
}
.highlight-signature {
  background-color: #f4ebff;
}
.highlight-text {
  background-color: #f4ebff;
}`, "",{"version":3,"sources":["webpack://./src/components/init-form/elements/table/styles.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,yBAAA;AAFJ;AAMI;EACE,yBAAA;AAJN;AAQE;EACE,yBAAA;AANJ;AASE;EACE,yBAAA;AAPJ;AAUE;EACE,yBAAA;AARJ;AAWE;EACE,yBAAA;AATJ;AAYE;EACE,yBAAA;AAVJ","sourcesContent":[".highlight {\n  &-date-picker {\n    background-color: #f4ebff;\n  }\n\n  &-single-line {\n    background-color: #f4ebff;\n  }\n\n  &-time-picker {\n    background-color: #f4ebff;\n  }\n\n  &-dropdown {\n    .react-select__control {\n      background-color: #f4ebff;\n    }\n  }\n\n  &-multiline {\n    background-color: #f4ebff;\n  }\n\n  &-number {\n    background-color: #f4ebff;\n  }\n\n  &-current-user {\n    background-color: #f4ebff;\n  }\n\n  &-signature {\n    background-color: #f4ebff;\n  }\n\n  &-text {\n    background-color: #f4ebff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
