import React from 'react';
import Select from 'react-select';
export const DropdownElement = function FromElement({ element }) {
  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{element.options.requiredFor ? '*' : ''}</span>
      </label>
      <Select
        classNamePrefix="react-select"
        name={element.options.name}
        id={element.id}
        className={`form-control-select form-control-${element.options.size}`}
        options={element.inputs.map((item) => ({ label: item.label, value: item.id }))}
      ></Select>
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
