import { Navigate } from 'react-router-dom';
import { useAuth } from '../../container/authContext';

export const LoginRoute = ({ children }) => {
  const { user } = useAuth();

  if (user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};
