import React from 'react';
import Modal from 'react-modal';

function DeactivateModal({ isOpen, closeModal, title, subtitle }) {
  const handelCloseModal = () => {
    closeModal();
  };
  return (
    <Modal
      className="modal-alert without-footer"
      isOpen={isOpen}
      onRequestClose={handelCloseModal}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        <img src="/images/alert-icon.svg" alt="" />
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div>
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </Modal>
  );
}

export default DeactivateModal;
