/**
 * Convert time from 12-hour clock format (AM/PM) to 24-hour format.
 * @param {string} timeString - Time string in 12-hour clock format (e.g., "11:00 AM").
 * @returns {string} Time string in 24-hour format (e.g., "11:00").
 */
export const convert12HourTo24HourFormat = (timeString) => {
  const [time, period] = timeString.split(' ');
  const [hours, minutes] = time.split(':');
  let hoursIn24Format = parseInt(hours, 10);

  if (period === 'AM' && hoursIn24Format === 12) {
    hoursIn24Format = 0;
  } else if (period === 'PM' && hoursIn24Format !== 12) {
    hoursIn24Format += 12;
  }

  return `${hoursIn24Format.toString().padStart(2, '0')}:${minutes}`;
};
