import React from 'react';
import Modal from 'react-modal';

function CopyModal({ isOpen, closeModal, title, subtitle, button, handelSuccess, errors }) {
  const handelCloseModal = () => {
    closeModal();
  };
  return (
    <Modal className="modal-alert" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        {button !== 'Confirm' && <img src="/images/file-big-icon.png" alt="" />}
        {button === 'Confirm' && <img src="/images/user-ok.png" alt="" />}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div>
        <h3>{title}</h3>
        <p>{subtitle}</p>
        <ul className={'form-error mt-2 d-flex'}>{Array.isArray(errors) && errors.map((item) => <li>{item}</li>)}</ul>
      </div>
      <div className="modal-footer">
        {button !== 'Ok' ? (
          <button className="btn btn-outline" onClick={handelCloseModal}>
            Cancel
          </button>
        ) : (
          <span></span>
        )}
        <button className="btn d-flex" onClick={handelSuccess}>
          {button !== 'Confirm' && button !== 'Ok' && (
            <svg className={'mr-2'} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M4.5 12.8333H3.66667C3.22464 12.8333 2.80072 12.6577 2.48816 12.3452C2.17559 12.0326 2 11.6087 2 11.1667V3.66667C2 3.22464 2.17559 2.80072 2.48816 2.48816C2.80072 2.17559 3.22464 2 3.66667 2H11.1667C11.6087 2 12.0326 2.17559 12.3452 2.48816C12.6577 2.80072 12.8333 3.22464 12.8333 3.66667V4.5M9.5 7.83333H17C17.9205 7.83333 18.6667 8.57953 18.6667 9.5V17C18.6667 17.9205 17.9205 18.6667 17 18.6667H9.5C8.57953 18.6667 7.83333 17.9205 7.83333 17V9.5C7.83333 8.57953 8.57953 7.83333 9.5 7.83333Z"
                stroke="white"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          {button ? button : 'Copy'}
        </button>
      </div>
    </Modal>
  );
}

export default CopyModal;
