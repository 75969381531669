import { useLocation } from 'react-router-dom';
import { FORM_ROLES } from '../constants/roles';

// creator | initiator | submitter | undefined
// Also refers to require_for (initiator | submitter)
export const useUserFormRole = () => {
  const { pathname } = useLocation();
  let role = undefined;
  if (pathname.includes('/initialization-form/')) {
    role = FORM_ROLES.FORM_INITIATOR;
  } else if (pathname.includes('/form-builder/')) {
    role = FORM_ROLES.FORM_CREATOR;
  } else if (pathname.includes('/form-view/')) {
    role = FORM_ROLES.FORM_SUBMITTER;
  }
  return role;
};
