const videoTypes = ['video/mp4', 'video/webm', 'video/ogg', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv'];

/**
 * @param {Blob} blob
 * @return {boolean}
 */
export function isBlobVideo(blob) {
  let result = false;
  if (blob?.type) {
    result = videoTypes.includes(blob.type);
  }
  return result;
}
