import React, { useState } from 'react';
import './styles.scss';
import { ReactComponent as TogglerIcon } from '../../../assets/images/navbar-toggler.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/navbar-close-toggler.svg';
import SideBarMenu from './sidebar-menu';

const NavbarToggler = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="navbar-toggler">
      <button className="toggler-btn" onClick={toggleSidebar}>
        {isSidebarOpen ? <CloseIcon /> : <TogglerIcon />}
      </button>
      {isSidebarOpen && <SideBarMenu isOpen={isSidebarOpen} handelClose={toggleSidebar} />}
    </div>
  );
};

export default NavbarToggler;
