import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../container/authContext';
import { usePermission } from '../../container/permissionContext';
import SMSModal from '../../components/other/SMSModal';

function HomePage() {
  const { user } = useAuth();
  const navigation = useNavigate();
  const { isSuperAdmin } = usePermission();
  const { is_unit_user = false } = user || {};

  const [isOpen, setIsOpen] = useState(false);

  const handelClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (user) {
      if (isSuperAdmin()) {
        if (user?.company?.id) {
          navigation(`company/${user.company.id}`);
        } else {
          navigation('/companies');
        }
      } else {
        if (is_unit_user) {
          navigation('/cna/assignments');
        } else {
          navigation('/corporate-library/');
        }
      }
    }
  }, [user, isSuperAdmin, navigation, is_unit_user]);

  if (user) {
    return;
  }

  return (
    <main className={'home-page'}>
      <div className={'home-wrapper'}>
        <div className={'home-logo'}>
          <img src="/images/home-logo.png" alt="AllCareForms" />
        </div>
        <div className={'home-contact'}>
          <a href="mailto:info@AllCareForms.com">
            <img src="/images/icon-hone-mail.svg" className={'mr-2'} alt="" />
            info@AllCareForms.com
          </a>
          <a href="tel:+17247052198">
            <img src="/images/icon-hone-phone.svg" className={'mr-2'} alt="" />
            (724) 705-2198
          </a>
          <button className={'btn'} onClick={() => setIsOpen(true)}>
            SMS opt-in consent
          </button>
        </div>
        <div className={'home-content'}>
          <h1>ACHIEVE PAPERLESS SOLUTION</h1>
          <p>FOR</p>
          <h2>senior living operations and compliance</h2>
          <Link to={'/login'} className={'btn mr-auto ml-auto'}>
            Log in
          </Link>
        </div>
      </div>

      <SMSModal isOpen={isOpen} closeModal={handelClose} title={'SMS opt-in consent'}></SMSModal>
    </main>
  );
}

export default HomePage;
