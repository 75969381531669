import client from '../api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;

// File UUID
export const getFileById = async (id) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }

    return await client.get(`/api/files/${id}/`, {
      timeout: 900_000, // 15min
      responseType: 'stream',
      onDownloadProgress: (evt) => {
        const target = evt?.event?.target;
        if (target) {
          const { status, responseURL } = target;
          if (status === 200) {
            if (cancel !== undefined) {
              cancel(responseURL, 'success');
            }
          }
        }
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log('GetFileById error: ', e);
    return e;
  }
};

export const getFilesById = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/corporate-library/files/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
export const getFilesGeneralById = async (id) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.get(`/api/general-library/file/${id}/`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const uploadFile = async (file) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(
    `/api/files/`,
    { file },
    {
      timeout: 900_000, // 15min
      headers: { 'Content-Type': 'multipart/form-data' },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    },
  );

  return response.data;
};
