import React from 'react';

export const EmptyPage = function SelectCustom({ title, subtitle, imageUrl }) {
  return (
    <div className="empty-page">
      <h3 className="empty-page-title">{title}</h3>
      <h4 className="empty-page-subtitle">{subtitle}</h4>
      {/*<img className="empty-page-img" src={imageUrl} alt=""/>*/}
    </div>
  );
};
