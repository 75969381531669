import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../container/authContext';
import { getRoles, getUsersList, importUser } from '../../services/user';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import UserTab from '../../components/company/user-tab';
import UserModal from '../../components/company/user-modal';
import { getFacilitiesByCompany } from '../../services/facility';
import { getCompanyPositions, getCompanyUserGroup, getUserSubmissionData } from '../../services/company';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';
import ImportModal from '../../components/company/import-modal';
import SubmissionsModal from '../../components/company/submission-modal/submissions-modal';
import SubmissionModal from '../../components/company/submission-modal/submission-modal';
import { localToUTCStringForDateRange } from '../../utility/dates/localToUTCForDateRange';

function CompanyList() {
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [totalUserPage, setTotalUserPage] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const navigation = useNavigate();
  const { isSuperAdmin, isCompanySystemAdministrator, isEmployee, hasPermission } = usePermission();
  useEffect(() => {
    if (isEmployee() && !hasPermission('Users, Positions management')) {
      redirectToLibrary(navigation);
    }
  }, [isEmployee, navigation, hasPermission]);

  const [filtersUser, setFiltersUser] = useState({
    facilities_ids: [],
    positions_ids: [],
    user_groups_ids: [],
    date_added_before: '',
    date_added_after: '',
    status: 'active',
    search: '',
    role_ids: [],
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [errors, setErrors] = useState(null);
  const { user } = useAuth();
  const [facilities, setFacilities] = useState([]);
  const [positions, setPositions] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [roles, setRoles] = useState([]);
  const [pageBreadcrumbs] = useState([
    {
      url: `/company/${id}`,
      name: `${user.company.name}`,
    },
    {
      url: `/company/${id}/users`,
      name: 'User List',
    },
  ]);
  const [defaultIndex, setDefaultIndex] = useState(0);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [id, userPage, filtersUser]);

  const handlePageUserClick = (event) => {
    setUserPage(event.selected + 1);
  };
  const initPage = () => {
    const params = {
      page: userPage,
      ...filtersUser,
      date_added_after: localToUTCStringForDateRange(filtersUser.date_added_after, 'start'),
      date_added_before: localToUTCStringForDateRange(filtersUser.date_added_before, 'end'),
    };

    getUsersList(id, params)
      .then((resUsers) => {
        setUsers(resUsers.results);
        setTotalUserPage(resUsers.total_pages);
        setTotalUser(resUsers.count);
      })
      .catch((e) => console.log(e));

    let action = null;

    if (isEmployee()) {
      action = 'users_positions_management';
    }

    getFacilitiesByCompany(id, 'getAll', action)
      .then((resFacilities) => {
        setFacilities(resFacilities);
      })
      .catch((e) => console.log(e));

    getCompanyPositions(id, 'getAll', filtersUser.facilities_ids)
      .then((res) => {
        setPositions(res);
      })
      .catch((e) => console.log(e));

    getCompanyUserGroup(id, 'getAll', filtersUser.facilities_ids)
      .then((res) => {
        setUserGroup(res);
      })
      .catch((e) => console.log(e));
    getRoles()
      .then((res) => {
        setRoles(res);
      })
      .catch((e) => console.log(e));
  };

  function closeAddModal() {
    setIsOpen(false);
    setExportData(null);
  }

  function closeImportModal() {
    setImportModalIsOpen(false);
  }

  const handelCreateUser = () => {
    initPage();
    closeAddModal();
    setExportData(null);
  };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handelImportUser = (file) => {
    if (!file) {
      setIsPending(false);
      setErrors(null);
      return;
    }

    setIsPending(true);
    setErrors(null);
    const formData = new FormData();
    formData.append('file', file.file);

    importUser(formData, id)
      .then((res) => {
        initPage();
        closeImportModal();
        setIsPending(false);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrors(e?.response?.data);
        }
        setIsPending(false);
      });
  };

  const applyFilters = (filters) => {
    setFiltersUser(filters);
  };
  const canSeeContacts = () => {
    if (isSuperAdmin() || isCompanySystemAdministrator()) {
      return true;
    }

    return hasPermission('Contacts management');
  };

  // create submission code
  const [modalAttachIsOpen, setIsAttachOpen] = useState(false);
  const [modalAttachDataIsOpen, setIsAttachDataOpen] = useState(false);
  const [selectedSubmissionForm, setSelectedSubmissionForm] = useState(null);
  const [exportData, setExportData] = useState(null);

  function openAddAttachModal() {
    setIsAttachOpen(true);
  }

  function closeAttachModal() {
    setIsAttachOpen(false);
  }

  function closeAttachDataModal() {
    setIsAttachDataOpen(false);
  }

  const handelFormSelected = (form) => {
    setSelectedSubmissionForm(form);
    setIsAttachDataOpen(true);
  };

  const handelExportUserDataSelected = (data) => {
    getUserSubmissionData(data.submission_id)
      .then((res) => {
        console.log(res);
        setExportData(res);
        openAddModal();
        setSelectedSubmissionForm(null);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Layout title={'User List'} breadcrumbs={pageBreadcrumbs}>
      <div className={`page-wrapper`}>
        <div className="company-profile">
          <div className="company-profile-tab">
            <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
              <TabList>
                <Tab>System Users</Tab>
                <Tab
                  disabled={!canSeeContacts()}
                  onClick={() => {
                    if (canSeeContacts()) {
                      navigation(`/company/${id}/residents`);
                    }
                  }}
                >
                  Residents
                </Tab>
              </TabList>

              <TabPanel>
                {/*<h3 className="company-profile-title">Users</h3>*/}
                <UserTab
                  openAddAttachModal={openAddAttachModal}
                  openImport={setImportModalIsOpen}
                  roles={roles}
                  userGroup={userGroup}
                  positions={positions}
                  facilities={facilities}
                  handelFilter={applyFilters}
                  filtersUser={filtersUser}
                  totalUser={totalUser}
                  onUpdate={handelCreateUser}
                  totalPage={totalUserPage}
                  handlePageClick={handlePageUserClick}
                  users={users}
                  openAddModal={openAddModal}
                ></UserTab>
                <UserModal
                  userData={exportData}
                  companyName={user.company.name}
                  facilities={facilities}
                  closeModal={closeAddModal}
                  companyId={id}
                  isOpen={modalIsOpen}
                  openModal={openAddModal}
                  handelCreateUser={handelCreateUser}
                />
                <ImportModal
                  handelError={setErrors}
                  isPending={isPending}
                  onSubmit={handelImportUser}
                  errors={errors}
                  closeModal={closeImportModal}
                  isOpen={importModalIsOpen}
                  title={'Import Users from File'}
                ></ImportModal>

                <SubmissionsModal
                  handelFormSelected={handelFormSelected}
                  isOpen={modalAttachIsOpen}
                  closeModal={closeAttachModal}
                  company_id={id}
                />

                <SubmissionModal
                  closeModal={closeAttachDataModal}
                  isOpen={modalAttachDataIsOpen}
                  handelExportDataSelected={handelExportUserDataSelected}
                  selectedForm={selectedSubmissionForm}
                  company_id={id}
                />
              </TabPanel>
              {canSeeContacts() && <TabPanel>Residents</TabPanel>}
            </Tabs>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CompanyList;
