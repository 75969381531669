import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

export const NumberModal = ({ element, isOpen, handelCloseModal, handlerChange, selectedIndexRow, selectedIndexCol, currentCol }) => {
  const [prevState, setPrevState] = useState('');
  const [state, setState] = useState('');
  const [title, setTitle] = useState('Number input');

  useEffect(() => {
    if (element?.rows[selectedIndexRow]?.cols[selectedIndexCol]) {
      setState(element.rows[selectedIndexRow].cols[selectedIndexCol].value ?? '');
      setTitle(
        `${!element.options.showQuestions ? element.rows[selectedIndexRow].label + ' - ' : ''}${element.cols[selectedIndexCol].label}`,
      );
    } else {
      setTitle('Number input');
    }
  }, [element, currentCol, selectedIndexCol, selectedIndexRow]);

  const cancel = () => {
    handlerChange(element, prevState);
    handelCloseModal();
  };

  const setNumber = (number) => {
    let resStr = '';

    if (!currentCol) {
      resStr = `${element.options.valueInput ?? ''}`;
    } else {
      resStr = `${state}`;
    }

    if (number === 'delete') {
      resStr = resStr.slice(0, resStr?.length - 1);
    } else if (number === 'clear') {
      resStr = '';
    } else {
      resStr = resStr + number;
    }

    if (selectedIndexRow !== null && selectedIndexCol !== null) {
      handlerChange(element, resStr, true, selectedIndexCol, selectedIndexRow);
    } else {
      handlerChange(element, resStr);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setPrevState(currentCol ? element.rows[selectedIndexRow].cols[selectedIndexCol].value : element.options.valueInput);
    } else {
      setPrevState('');
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    const onKeypress = (e) => {
      if (e.code === 'Enter') {
        handelCloseModal();
      }
      if (e.code === 'Backspace') {
        setNumber('delete');
      }
      if (e.code === 'Delete') {
        setNumber('clear');
      }
    };

    document.addEventListener('keydown', onKeypress);

    return () => {
      document.removeEventListener('keydown', onKeypress);
    };
    // eslint-disable-next-line
  }, [element]);

  return (
    <Modal
      isOpen={isOpen}
      className="modal-default modal-number"
      onRequestClose={handelCloseModal}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        {title}
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div className={'input-clear'}>
        <input
          readOnly
          name={element.options.name}
          id={element.id}
          type="input"
          min={element.options.min}
          max={element.options.max}
          value={currentCol ? state : element.options.valueInput}
          className={`form-control`}
        />
        {element?.options?.valueInput?.length > 0 && (
          <button onClick={() => setNumber('clear')} className={'btn btn-icon'}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="x-circle" clipPath="url(#clip0_1490_14213)">
                <path
                  id="Icon"
                  d="M12.5003 7.50033L7.50033 12.5003M7.50033 7.50033L12.5003 12.5003M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
                  stroke="#FDA29B"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1490_14213">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        )}
        {state?.length > 0 && (
          <button onClick={() => setNumber('clear')} className={'btn btn-icon'}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="x-circle" clipPath="url(#clip0_1490_14213)">
                <path
                  id="Icon"
                  d="M12.5003 7.50033L7.50033 12.5003M7.50033 7.50033L12.5003 12.5003M18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
                  stroke="#FDA29B"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1490_14213">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        )}
      </div>

      <div className={'number-wrapper'}>
        <button className={'btn btn-outline'} onClick={() => setNumber(7)}>
          7
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber(8)}>
          8
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber(9)}>
          9
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber(4)}>
          4
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber(5)}>
          5
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber(6)}>
          6
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber(1)}>
          1
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber(2)}>
          2
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber(3)}>
          3
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber('.')}>
          .
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber(0)}>
          0
        </button>
        <button className={'btn btn-outline'} onClick={() => setNumber('delete')}>
          <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m22 6c0-.552-.448-1-1-1h-12.628c-.437 0-.853.191-1.138.523-1.078 1.256-3.811 4.439-4.993 5.815-.16.187-.241.419-.241.651 0 .231.08.463.24.651 1.181 1.38 3.915 4.575 4.994 5.835.285.333.701.525 1.14.525h12.626c.552 0 1-.448 1-1 0-2.577 0-9.423 0-12zm-13.628.5h12.128v11h-12.126l-4.715-5.51zm5.637 4.427 1.71-1.71c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.384-.219.531l-1.711 1.711 1.728 1.728c.147.147.22.339.22.53 0 .427-.349.751-.75.751-.192 0-.384-.073-.531-.219l-1.728-1.729-1.728 1.729c-.146.146-.339.219-.531.219-.401 0-.75-.324-.75-.751 0-.191.073-.383.22-.53l1.728-1.728-1.788-1.787c-.146-.148-.219-.339-.219-.532 0-.425.346-.749.751-.749.192 0 .384.073.53.219z"
              fillRule="nonzero"
            />
          </svg>
        </button>
      </div>
      <div className={'modal-footer'}>
        <button className={'btn btn-outline'} onClick={cancel}>
          Cancel
        </button>
        <button className={'btn'} onClick={() => handelCloseModal()}>
          Apply
        </button>
      </div>
    </Modal>
  );
};
