import React, { useState } from 'react';
import { NumberModal } from './number-modal';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';

export const NumberElement = function FromElement({ element, handlerChange, error }) {
  const isRequiredElement = useIsRequiredElement(element);
  const [isOpen, setIsOpen] = useState(false);
  const handelCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <input
        name={element.options.name}
        id={element.id}
        type="input"
        min={element.options.min}
        max={element.options.max}
        readOnly
        className={`form-control form-control-${element.options.size}`}
        value={element.options.valueInput}
        onClick={() => setIsOpen(true)}
      />
      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}

      {isOpen && <NumberModal handelCloseModal={handelCloseModal} isOpen={isOpen} element={element} handlerChange={handlerChange} />}
    </>
  );
};
