import React, { memo, useMemo, useState } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import './styles.scss';
import Select from 'react-select';

const ManageCNAsModal = ({ shiftCNA = [], cnaSuggest = [], onClose, onSave }) => {
  // Formatted origin (active) CNA
  const formattedShiftCNAs = useMemo(() => {
    return shiftCNA.map((item) => ({ ...item, active: true }));
  }, [shiftCNA]);

  const shiftCNAsObj = useMemo(() => {
    return formattedShiftCNAs.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [formattedShiftCNAs]);

  const [selectedCNA, setSelectedCNA] = useState(formattedShiftCNAs);
  const [newUserFullName, setNewUserFullName] = useState('');

  const selectedOptions = useMemo(() => {
    return selectedCNA.reduce((acc, { id, full_name, ...rest }) => {
      const cnaOption = {
        value: id,
        label: full_name,
        ...rest,
      };
      acc.push(cnaOption);
      return acc;
    }, []);
  }, [selectedCNA]);
  const options = useMemo(
    () =>
      cnaSuggest.map(({ user_id, full_name }) => {
        const cna = formattedShiftCNAs.find((item) => item.user_id === user_id);
        return {
          value: cna?.id ?? user_id,
          label: full_name,
          active: !!cna?.id,
        };
      }),
    [cnaSuggest, formattedShiftCNAs],
  );

  const handleSave = () => {
    const formattedNewCNA = selectedCNA.map(({ id: value, full_name, custom }) => {
      const { active, id } = shiftCNAsObj[value] ?? {};
      const cna = {};

      if (active) {
        cna.id = id;
      } else if (!active && !custom) {
        cna.user_id = value;
      } else {
        cna.full_name = full_name;
      }

      return cna;
    });

    console.log('selectedCNA: ', selectedCNA);
    console.log('formattedNewCNA: ', formattedNewCNA);

    onClose();
    onSave('cna', formattedNewCNA);
  };

  const handleRemoveCNA = (value) => {
    setSelectedCNA((prevState) => prevState.filter(({ id }) => String(id) !== String(value)));
  };

  const handleChangeCNA = (data) => {
    setSelectedCNA(data.map(({ value, label, ...rest }) => ({ id: value, full_name: label, ...rest })));
  };

  const handleCNASelectBlur = () => {
    const formattedNewUserFullName = newUserFullName.trim();
    if (formattedNewUserFullName.length) {
      const newUser = { id: new Date().getTime(), full_name: formattedNewUserFullName, custom: true };
      setSelectedCNA((prevState) => [...prevState, newUser]);
      setNewUserFullName('');
    }
  };

  const handleCNAInputChange = (value) => setNewUserFullName(value);

  const handleCNASelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleCNASelectBlur();
    }
  };

  return (
    <Modal
      isOpen
      className={cx('modal-default manage-cna-modal')}
      overlayClassName={'modal-overlay manage-cna-modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        Edit CNA List
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <span className={'select-label'}>CNA</span>
        <Select
          isMulti
          hideSelectedOptions
          inputValue={newUserFullName}
          value={selectedOptions}
          options={options}
          closeMenuOnSelect={false}
          className="form-control-select"
          classNamePrefix="react-select-multi"
          onChange={handleChangeCNA}
          onBlur={handleCNASelectBlur}
          onKeyDown={handleCNASelectKeyDown}
          onInputChange={handleCNAInputChange}
        />
        <div className="selected-cna-list">
          {selectedOptions.map(({ value, label: fullName }) => {
            return (
              <div key={value} className={'selected-cna-item'}>
                {fullName}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleRemoveCNA(value)}
                >
                  <path
                    d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                    stroke="#FDA29B"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            );
          })}
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(ManageCNAsModal);
