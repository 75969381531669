import { createContext, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hook/useLocalStorage';
import { loginService, getCurrentUserInfo, loginConfirmation, forgotPassword } from '../services/auth';
import { setToken, removeToken, hesAuthToken, setRefresh } from '../services/api';
import { redirectToLibrary } from '../utility';
import { STATIC_ROUTES } from '../constants/routes';
// const base64 = require('js-base64');

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);
  const navigate = useNavigate();
  useEffect(() => {
    if (hesAuthToken()) {
      setToken(hesAuthToken());
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (hesAuthToken()) {
      updateUser();
    }
    // eslint-disable-next-line
  }, []);

  // call this function when you want to authenticate the user
  const login = async (data, handelError) => {
    loginService(data, handelError)
      .then((res) => {
        if (res?.data) {
          const { access, refresh } = res?.data ?? {};
          setToken(access);
          setRefresh(refresh);
          getCurrentUserInfo().then((res) => {
            setUser(res);
            if (res.role === 'Super Admin') {
              navigate('/companies');
            } else {
              if (res.is_unit_user) {
                navigate(STATIC_ROUTES.CNA_ASSIGNMENTS);
              } else {
                redirectToLibrary(navigate);
              }
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateUser = () => {
    if (user && user.role !== 'Super Admin') {
      getCurrentUserInfo().then((res) => {
        setUser(res);
      });
    }
  };
  const confirmation = (key, data, handelError) => {
    loginConfirmation(key, data)
      .then((res) => {
        navigate('/login');
      })
      .catch((e) => {
        if (e?.response?.data) {
          handelError(e.response.data);
        }
      });
  };
  const reset = (data, handelError) => {
    console.log(data);
    forgotPassword(data)
      .then((res) => {
        navigate('/login');
      })
      .catch((e) => {
        if (e?.response?.data) {
          handelError(e.response.data);
        }
      });
  };
  const resetConfirmation = (data, handelError, key) => {
    loginConfirmation(key, data)
      .then((res) => {
        navigate('/login');
      })
      .catch((e) => {
        if (e?.response?.data) {
          handelError(e.response.data);
        }
      });
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    removeToken();
    navigate('/login', { replace: true });
  };
  const changeCompany = (company) => {
    setUser({ ...user, company: company });
    if (company) {
      navigate(`/company/${company.id}`);
    } else {
      navigate(`/companies`);
    }
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
      changeCompany,
      updateUser,
      confirmation,
      reset,
      resetConfirmation,
    }),
    // eslint-disable-next-line
    [user],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
