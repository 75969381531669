import React, { memo, useMemo, useState } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import './styles.scss';
import Select from 'react-select';

const ManageNursesModal = ({ shiftNurses = [], nursesSuggest = [], onClose, onSave }) => {
  const formattedNursesSuggest = useMemo(() => {
    return nursesSuggest.map(({ id, full_name }) => ({ user_id: id, full_name }));
  }, [nursesSuggest]);

  // Formatted origin (active) nurses
  const formattedShiftNurses = useMemo(() => {
    return shiftNurses.map((item) => ({ ...item, active: true }));
  }, [shiftNurses]);

  const [selectedNurses, setSelectedNurses] = useState(formattedShiftNurses);
  const [newUserFullName, setNewUserFullName] = useState('');

  const shiftNursesObj = useMemo(() => {
    return formattedShiftNurses.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [formattedShiftNurses]);

  const selectedOptions = useMemo(() => {
    return selectedNurses.reduce((acc, { id, full_name, ...rest }) => {
      const option = {
        value: id,
        label: full_name,
        ...rest,
      };
      acc.push(option);
      return acc;
    }, []);
  }, [selectedNurses]);

  const options = useMemo(
    () =>
      formattedNursesSuggest.map(({ user_id, full_name }) => {
        const nurse = formattedShiftNurses.find((item) => item.user_id === user_id);
        return {
          value: nurse?.id ?? user_id,
          label: full_name,
          active: !!nurse?.id,
          custom: Boolean(full_name && !user_id),
        };
      }),
    [formattedNursesSuggest, formattedShiftNurses],
  );

  const handleChangeNurses = (data) => {
    setSelectedNurses(data.map(({ value, label, ...rest }) => ({ id: value, full_name: label, ...rest })));
  };

  const handleRemoveNurse = (value) => {
    setSelectedNurses((prevState) => prevState.filter(({ id }) => String(id) !== String(value)));
  };

  const handleSave = () => {
    const formattedNewNurses = selectedNurses.map(({ id: value, full_name, custom }) => {
      const { active, id } = shiftNursesObj[value] ?? {};
      const nurse = {};

      if (active) {
        nurse.id = id;
      } else if (!active && !custom) {
        nurse.user_id = value;
      } else {
        nurse.full_name = full_name;
      }

      return nurse;
    });
    onSave('nurses', formattedNewNurses);
    onClose();
  };

  const handleNurseSelectBlur = () => {
    const formattedNewUserFullName = newUserFullName.trim();
    if (formattedNewUserFullName.length) {
      const newUser = { id: new Date().getTime(), full_name: formattedNewUserFullName, custom: true };
      setSelectedNurses((prevState) => [...prevState, newUser]);
      setNewUserFullName('');
    }
  };

  const handleNurseSelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleNurseSelectBlur();
    }
  };

  const handleInputChange = (value) => setNewUserFullName(value);

  return (
    <Modal
      isOpen
      className={cx('modal-default manage-nurses-modal')}
      overlayClassName={'modal-overlay manage-nurses-modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        Edit Nurses List
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <span className={'select-label'}>Nurse</span>
        <Select
          isMulti
          options={options}
          hideSelectedOptions
          inputValue={newUserFullName}
          closeMenuOnSelect={false}
          value={selectedOptions}
          className="form-control-select"
          classNamePrefix="react-select-multi"
          formatValue={() => null}
          onChange={handleChangeNurses}
          onBlur={handleNurseSelectBlur}
          onKeyDown={handleNurseSelectKeyDown}
          onInputChange={handleInputChange}
        />

        <div className="selected-nurses-list">
          {selectedOptions.map(({ value: nurseID, label: fullName }) => {
            return (
              <div key={nurseID} className={'selected-nurses-item'}>
                {fullName}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleRemoveNurse(nurseID)}
                >
                  <path
                    d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                    stroke="#FDA29B"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            );
          })}
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(ManageNursesModal);
