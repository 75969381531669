import React, { memo, useMemo, useState } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import './styles.scss';
import { createCNATaskCommentService } from '../../../../../../services/cna/assignments/cna';
import { isResponseOk } from '../../../../../../utility/isResponseOk';

const TaskCommentsModal = ({ onClose, data = {}, onRefetchAssignment }) => {
  const { name: taskName, id: taskID } = data;

  const [comment, setComment] = useState('');

  const isActiveSaveBtn = useMemo(() => {
    const trimComment = comment.trim();
    return !!trimComment.length;
  }, [comment]);

  const handleChangeComment = (e) => setComment(e.target.value);

  const handleSave = async () => {
    try {
      const res = await createCNATaskCommentService({ taskID, comment });
      if (isResponseOk(res)) {
        setComment('');
        await onRefetchAssignment();
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      contentLabel="Example Modal"
      className={cx('modal-default cna-task-comments-modal')}
      overlayClassName={'modal-overlay'}
      onRequestClose={onClose}
    >
      <h2 className="modal-header">
        {taskName}
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <div className="new-comment">
          <div className="group">
            <div className="group__body" style={{ marginTop: 0 }}>
              <label className="form-label">Add Comment</label>
              <textarea value={comment} className="form-control" placeholder="Enter comment" onChange={handleChangeComment} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isActiveSaveBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(TaskCommentsModal);
