import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '../../../layout/default';
import { useParams } from 'react-router-dom';
import { getFilesFoler } from '../../../services/folder/form-avaliable';
import { useAuth } from '../../../container/authContext';
import SubmissionsLayout from './layouts/submissions';
import CommonLayout from './layouts/common';

const getBreadcrumbs = (breadcrumbs) => {
  const list = [];
  if (breadcrumbs) {
    getProp(breadcrumbs);
  }

  function getProp(o) {
    list.push(o);
    if (o.children?.length) {
      getProp(o.children);
    }
  }

  return list;
};

function GeneralLibrary() {
  const { user } = useAuth();
  const { id } = useParams();

  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      name: 'Company Library',
      url: '/corporate-library',
    },
    {
      name: 'Files',
      url: '/corporate-library/files',
    },
    null,
  ]);

  const isSubmissionsFolder = useMemo(() => {
    return breadcrumbs[breadcrumbs.length - 1]?.name === 'Submissions';
  }, [breadcrumbs]);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [id]);

  const handleSetCurrentFolder = useCallback((folder) => setCurrentFolder(folder), []);
  const handleSetFolders = useCallback((newFolders = []) => setFolders(newFolders), []);
  const handleSetFiles = useCallback((newFiles = []) => setFiles(newFiles), []);
  const handleSetBreadcrumbs = useCallback((newBreadcrumbs = []) => setBreadcrumbs(newBreadcrumbs), []);

  const initPage = () => {
    const params = {
      parent_id: id ?? null,
      company_id: user.company ? user.company.id : null,
    };

    getFilesFoler(params)
      .then((res) => {
        const newBreadcrumbs = [
          {
            name: 'Company Library',
            url: '/corporate-library',
          },
          {
            name: 'Files',
            url: '/corporate-library/files',
          },
          ...getBreadcrumbs(res.breadcrumbs),
        ];
        setBreadcrumbs(newBreadcrumbs);
        setFolders(res.data.filter((item) => item.type === 'folder'));
        setFiles(res.data.filter((item) => item.type === 'file'));
      })
      .catch((e) => console.log(e));
  };

  const render = () => {
    return (
      <Layout title="Company Library">
        {isSubmissionsFolder ? (
          <SubmissionsLayout files={files} breadcrumbs={breadcrumbs} onSetFiles={handleSetFiles} />
        ) : (
          <CommonLayout
            folders={folders}
            files={files}
            breadcrumbs={breadcrumbs}
            currentFolder={currentFolder}
            onSetBreadcrumbs={handleSetBreadcrumbs}
            onSetFolders={handleSetFolders}
            onSetFiles={handleSetFiles}
            onSetCurrentFolder={handleSetCurrentFolder}
          />
        )}
      </Layout>
    );
  };

  return render();
}

export default GeneralLibrary;
