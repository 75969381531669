import cx from 'classnames';
import { get } from 'lodash';
import isObject from 'lodash/isObject';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Select, { components } from 'react-select';

const DropdownCol = ({ row, col, element, handlerChange, isPrint }) => {
  const selectRef = useRef();
  const [otherInputValue, setOtherInputValue] = useState('');

  const options = useMemo(() => {
    const colOption = col.options || [];
    const optionsList = colOption.map((item) => ({
      id: item.id,
      label: item.label,
      value: item.id,
    }));

    if (col.addOtherOption) {
      optionsList.push({ id: 'other', value: 'other', label: 'Other' });
    }

    return optionsList;
  }, [col.options]);

  const colIndex = row?.cols?.findIndex((c) => c.id === col.id);
  const rowCol = get(row, `cols[${colIndex}]`);
  const rowIndex = element.rows?.findIndex((r) => r.id === row.id);
  const isActive = !isPrint;

  const selectedOption = useMemo(() => {
    const { value, defaultOptionId } = rowCol;
    let selectedOption;

    if (isObject(value)) {
      selectedOption = value;
    } else if (defaultOptionId) {
      selectedOption = options.find((option) => option.id === defaultOptionId);
    }

    return selectedOption;
  }, [rowCol.value, rowCol.defaultOptionId, rowCol, options]);

  const shouldUseOtherInput = () => {
    return col.addOtherOption && selectedOption?.label === 'Other';
  };

  useEffect(() => {
    if (shouldUseOtherInput()) {
      setOtherInputValue(selectedOption.otherInputValue);
    }
  }, [col.addOtherOption]);

  const handleInputChange = (value) => {
    if (shouldUseOtherInput() && (value || (otherInputValue?.length === 1 && value?.length === 0))) {
      setOtherInputValue(value);
      handlerChange(element, { value: 'other', label: 'Other', otherInputValue: value, id: 'other' }, true, colIndex, rowIndex);
    }
  };

  const resetOtherInputValue = () => {
    setOtherInputValue('');
    selectRef.current.blur();
  };

  const handleChangeValue = (e) => {
    if (e.label !== 'Other') {
      resetOtherInputValue();
    } else {
      setTimeout(() => {
        if (selectRef.current) {
          selectRef.current.focus();
        }
      }, 0);
    }
    handlerChange(element, e, true, colIndex, rowIndex);
  };

  const getInputValue = () => {
    if (shouldUseOtherInput()) {
      return otherInputValue;
    }

    return selectedOption?.label || '';
  };

  if (rowCol) {
    const SingleValue = ({ children, ...props }) => <components.SingleValue {...props} />;

    return (
      <Select
        ref={selectRef}
        menuPortalTarget={document.body}
        menuPlacement={'auto'}
        inputValue={getInputValue()}
        components={{ SingleValue }}
        value={selectedOption}
        options={options}
        readOnly={!isActive}
        classNamePrefix="react-select"
        className={cx('form-control-select qwe', {
          'highlight-dropdown': rowCol?.user_metadata?.can_edit_previous_submissions === false,
        })}
        filterOption={null}
        styles={{
          menuList: (provided) => ({
            ...provided,
            height: '100%',
            minHeight: options?.length > 3 ? 168 : 'unset',
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9 }),
          input: (provided) => ({
            ...provided,
            input: {
              opacity: '1 !important',
            },
          }),
        }}
        onChange={(e) => isActive && handleChangeValue(e)}
        onInputChange={(e) => isActive && handleInputChange(e)}
      />
    );
  }
};

export default DropdownCol;
