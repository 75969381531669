import React, { useEffect, useState } from 'react';
import { MultiSelect } from '../../other/MultiSelect';
import { getContactsListSettings } from '../../../services/contacts';
import { getUnitListSettings } from '../../../services/units';
import { useAuth } from '../../../container/authContext';

export const SubmissionSettingsInitContact = function SubmissionSettingsInitContact({ formData, handelInitSettings, initData, errors }) {
  const [recipient_settings_init, setRecipientSettingsInit] = useState({});
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [individualUsersOptions, setIndividualUsersOptions] = useState([]);
  // eslint-disable-next-line
  const { user } = useAuth();
  const companyId = user.company?.id;

  useEffect(() => {
    getContactsListSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: user.facility ? [user.facility.id] : null,
      units_ids: initData?.submitters_settings?.unit ? [...initData?.submitters_settings?.unit?.map((item) => item.value)] : [],
    })
      .then((resUsers) => {
        setIndividualUsersOptions(resUsers.map((item) => ({ value: item.id, label: item.last_name + ' ' + item.first_name })));
      })
      .catch((e) => console.log(e));

    getUnitListSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: user.facility ? [user.facility.id] : null,
    })
      .then((res) => {
        setFacilityOptions(res.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => console.log(e));

    // eslint-disable-next-line
  }, [formData, companyId, recipient_settings_init]);

  useEffect(() => {
    if (initData?.submitters_settings) {
      setRecipientSettingsInit({ ...initData?.submitters_settings });
    }
  }, [initData]);

  const handelRemove = (key, value) => {
    const prevState = { ...recipient_settings_init };
    prevState[key] = prevState[key].filter((item) => item.value !== value.value);
    setRecipientSettingsInit(() => {
      return prevState;
    });

    handelInitSettings(prevState, 'submitters_settings');
  };

  const handelSettings = (value, key) => {
    const prevState = { ...recipient_settings_init };
    prevState[key] = value;
    setRecipientSettingsInit(() => {
      return prevState;
    });

    handelInitSettings(prevState, 'submitters_settings');
  };

  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-recipient-settings">
        <p className="info-text">
          <i className="icon-icon-info"></i> Choose submitters and submission rules.
        </p>
        <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>

        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Specify Submitters (required)</label>
            <span>Submitters are users to whom you are sending this form completion.</span>
          </div>
          <div className="form-settings-inputs">
            <>
              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">Unit</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several unit</span>
                  <MultiSelect
                    isDisabled={!facilityOptions.length}
                    options={facilityOptions}
                    value={recipient_settings_init.unit}
                    onChange={(e) => handelSettings(e, 'unit')}
                    placeholder=""
                    allName="All Units"
                  ></MultiSelect>
                  {recipient_settings_init.unit?.length > 0 && (
                    <>
                      <ul>
                        {recipient_settings_init.unit.map((item, key) => (
                          <li key={key}>
                            {item.label}
                            <button className="btn btn-icon" onClick={() => handelRemove('unit', item)}>
                              <img src="/images/x-circle.svg" alt="" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
                <span className="form-error">{errors?.submitters_settings?.units && errors.submitters_settings.units[0]}</span>
              </div>

              <div className={`form-item form-item-select mb-4`}>
                <label className="form-label form-label-big">Residents</label>
                <div className="select-multy-wrapper">
                  <span className="select-placeholder">Choose one or several residents</span>
                  <MultiSelect
                    isDisabled={!individualUsersOptions.length}
                    options={individualUsersOptions}
                    value={recipient_settings_init.residents}
                    onChange={(e) => handelSettings(e, 'residents')}
                    placeholder=""
                    allName="All Residents"
                  ></MultiSelect>
                  {recipient_settings_init.residents?.length > 0 && (
                    <>
                      <ul className={'individual-users-list'}>
                        {recipient_settings_init.residents.map((item, key) => (
                          <li key={key}>
                            {item.label}
                            <button className="btn btn-icon" onClick={() => handelRemove('residents', item)}>
                              <img src="/images/x-circle.svg" alt="" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <span className="form-error">{errors?.submitters_settings?.contacts && errors.submitters_settings.contacts[0]}</span>
                </div>
              </div>
            </>
          </div>
        </div>
        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Define form recievers</label>
            <span>
              If both are specified, it means that the form will be sent not only to selected residents, but also to all of their
              representatives
            </span>
          </div>
          <div className="form-settings-inputs">
            <div className="d-flex">
              <div className={`form-control-radio`}>
                <input
                  name="contact_person_submitter_type"
                  type="radio"
                  onChange={() => handelSettings('contact', 'contact_person_submitter_type')}
                  checked={recipient_settings_init.contact_person_submitter_type === 'contact'}
                />
                <span>Residents</span>
              </div>
              <div className={`form-control-radio`}>
                <input
                  name="contact_person_submitter_type"
                  type="radio"
                  onChange={() => handelSettings('representative', 'contact_person_submitter_type')}
                  checked={recipient_settings_init.contact_person_submitter_type === 'representative'}
                />
                <span>Representatives </span>
              </div>
              <div className={`form-control-radio`}>
                <input
                  name="contact_person_submitter_type"
                  type="radio"
                  onChange={() => handelSettings('both', 'contact_person_submitter_type')}
                  checked={recipient_settings_init.contact_person_submitter_type === 'both'}
                />
                <span>Both</span>
              </div>
            </div>
            <span className="form-error mb-2 d-flex">
              {errors?.submitters_settings?.contact_person_submitter_type && errors.submitters_settings?.contact_person_submitter_type[0]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
