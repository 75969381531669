import React, { useEffect, useState } from 'react';
import { EmptyPage } from '../other/EmptyPage';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import dayjs from 'dayjs';
import { activateUnit } from '../../services/units';
import { DATE_FORMAT } from '../../constants';

function UnitsTab({
  userGroup,
  openAddModal,
  totalPage,
  handlePageClick,
  filtersUser,
  facilities,
  handelFilter,
  totalItem,
  openEditModal,
  openDeactivateModal,
  onUpdate,
}) {
  const [userGroupList, setUserGroupList] = useState(userGroup);
  const [search, setSearch] = useState('');

  const [column, setColumn] = useState([
    {
      name: 'Facility',
    },
    {
      name: 'Units',
    },
    {
      name: 'Total (residents)',
    },
    {
      name: '',
    },
  ]);
  useEffect(() => {
    setColumn([
      {
        name: 'Facility',
        total: totalItem,
      },
      {
        name: 'Units',
      },
      {
        name: 'Total (residents)',
      },
      {
        name: '',
      },
    ]);
  }, [totalItem]);
  const [filters, setFilters] = useState((prevState) => {
    const newState = filtersUser;
    if (filtersUser) {
      newState['status'] = { value: 'active', label: 'Active' };
    }

    return newState;
  });
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [facilitiesOption, setFacilitiesOption] = useState([]);
  useEffect(() => {
    let filteredData = userGroup;
    if (search) {
      filteredData = userGroup.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
    }
    setUserGroupList(filteredData);
  }, [search, userGroup]);

  const clearFilter = () => {
    setFilters({
      facilities_ids: [],
      status: { value: 'active', label: 'Active' },
      search: '',
    });
  };
  const onFilter = (value, key) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };
  useEffect(() => {
    if (facilities) {
      setFacilitiesOption(facilities.map((item) => ({ value: item.id, label: item.name })));
    }
  }, [facilities]);

  useEffect(() => {
    if (filtersUser) {
      const newFilter = { ...filters };
      for (let key in newFilter) {
        if (typeof newFilter[key] === 'object' && !Array.isArray(newFilter[key]) && newFilter[key] !== null) {
          if (newFilter[key].value) {
            newFilter[key] = newFilter[key].value;
          } else {
            newFilter[key] = dayjs(newFilter[key]).format(DATE_FORMAT);
          }
        }

        if (Array.isArray(newFilter[key])) {
          newFilter[key] = newFilter[key].map((item) => item.value);
        }
      }

      handelFilter(newFilter);
    }

    // eslint-disable-next-line
  }, [filters]);

  return (
    <>
      <div className="library-filter">
        {!filtersUser && (
          <div className="library-filter-search form-control-item">
            <input type="text" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
            <i className="icon-search"></i>
          </div>
        )}
        {filtersUser && (
          <>
            <div className="library-filter-search form-control-item">
              <input
                type="text"
                className="form-control"
                value={filters.search}
                onChange={(e) => onFilter(e.target.value, 'search')}
                placeholder="Search"
              />
              <i className="icon-search"></i>
            </div>
            <div>
              <button className="btn btn-outline" onClick={() => setFiltersIsOpen(!filtersIsOpen)}>
                <i className="icon-icon-sliders-rotate"></i>
              </button>
            </div>
          </>
        )}
        <div>
          <button className="btn" onClick={openAddModal}>
            <i className="icon-plus-svgrepo-com mr-2"></i> Add Units
          </button>
        </div>
      </div>
      {filtersIsOpen && (
        <div className="library-filters">
          <div className="library-filters-wrapper">
            <div className="form-item">
              <label className="form-label">Facility</label>
              <Select
                isMulti
                hideSelectedOptions={false}
                options={facilitiesOption}
                value={filters.facilities_ids}
                onChange={(e) => onFilter(e, 'facilities_ids')}
                className="form-control-select"
                classNamePrefix="react-select-multi"
              ></Select>
            </div>
            <div className="form-item">
              <label className="form-label">Status</label>
              <Select
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'active',
                    label: 'Active',
                  },
                  {
                    value: 'archived',
                    label: 'Inactive',
                  },
                ]}
                value={filters.status}
                onChange={(e) => onFilter(e, 'status')}
                className="form-control-select"
                classNamePrefix="react-select"
              ></Select>
            </div>
          </div>

          <button className="btn btn-outline" onClick={clearFilter}>
            Clear All
          </button>
        </div>
      )}
      {userGroupList.length > 0 && (
        <div className="table-responsive table-default table-full">
          <div className="table-wrapper">
            <div className="table">
              <div className="tr">
                {column.map((item, index) => (
                  <div className="th" key={index}>
                    {item.name}
                    {!!item.total && <span className="th-total">(Total: {item.total} units)</span>}
                  </div>
                ))}
              </div>
              {userGroupList.map((item) => (
                <div className={`tr ${item.deleted_at ? 'tr-deactivated' : ''}`} key={item.id}>
                  <div className="td">{item.facility.name}</div>
                  <div className="td">{item.name}</div>
                  <div className="td">{item.contacts_count}</div>
                  <div className="td td-actions">
                    {!item.deleted_at && (
                      <div className="d-flex">
                        <button className="btn btn-icon mr-2" onClick={() => openEditModal(item)}>
                          <i className="icon-icon-edit"></i>
                        </button>
                        <button className="btn btn-icon" onClick={() => openDeactivateModal(item)}>
                          <i className="icon-remove"></i>
                        </button>
                      </div>
                    )}
                    {item.deleted_at && (
                      <div className="d-flex">
                        <button
                          className="btn btn-left btn-success btn-text"
                          onClick={() =>
                            activateUnit(item.id)
                              .then(() => onUpdate())
                              .catch((e) => console.log(e))
                          }
                        >
                          <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1484_3438)">
                              <path
                                d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                                stroke="#12B76A"
                                strokeWidth="1.67"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1484_3438">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          Activate
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {totalPage > 1 && (
              <ReactPaginate
                className={'react-pagination'}
                breakLabel="..."
                nextLabel="next ->"
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="<- previous"
                onPageChange={handlePageClick}
              />
            )}
          </div>
        </div>
      )}

      {!userGroupList.length && !search && (
        <EmptyPage
          imageUrl={'/images/empty-user-group.png'}
          title={'At the moment, there are no units'}
          subtitle={'Simply click the button “Add Units”  to start the units creation process'}
        />
      )}
      {!userGroupList.length && search && (
        <EmptyPage
          imageUrl={'/images/no-resoult.png'}
          title={'Unfortunately, nothing was found for your query.'}
          subtitle={"Try to change the range of keywords. We're here to help you find what you're looking for."}
        />
      )}
    </>
  );
}

export default UnitsTab;
