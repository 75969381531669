import React from 'react';
import './styles.scss';

const PageLoader = () => (
  <div className="lds-ring-wrapper">
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default PageLoader;
