import React, { useEffect, useState } from 'react';
import RequiredForSetting from './components/required-for-setting';
import AddToCopyGroupSetting, { ADD_TO_COPY_GROUP_SETTING_KEY } from './components/add-to-copy-group-setting';

export const ElectronicSignatureElementSettings = ({ element, handlerChangeOption, duplicateElement }) => {
  const [label, setLabel] = useState(element.options.label);
  const [instructions, setInstructions] = useState(element.options.instructions);
  const [size, setSize] = useState(element.options.size);
  const [hasTime, setHasTime] = useState(element.options.hasTime);
  const [hasDate, setHasDate] = useState(element.options.hasDate);
  const [canAddNew, setCanAddNew] = useState(element.options.canAddNew);
  const [alignment, setAlignment] = useState(element.options.alignment);

  useEffect(() => {
    setLabel(element.options.label);
    setSize(element.options.size);
    setInstructions(element.options.instructions);
    setHasTime(element.options.hasTime);
    setHasDate(element.options.hasDate);
    setCanAddNew(element.options.canAddNew);
    setAlignment(element.options.alignment);
  }, [element]);

  return (
    <div className="tabs-item">
      {/*<div className="property-item">*/}
      {/*    <h3 className="form-builder-aside-property-sub-title">Field Alignment</h3>*/}
      {/*    <div className="form-group">*/}
      {/*            <span className="form-radio-button">*/}
      {/*                <input type="radio" name="alignment" value="left" checked={alignment === 'left'} onChange={(e) => {handlerChangeOption(e.target.value, 'alignment', element.id);  setAlignment(e.target.value)}}/>*/}
      {/*                <span>Left</span>*/}
      {/*            </span>*/}
      {/*        <span className="form-radio-button">*/}
      {/*                <input type="radio" name="alignment" value="center" checked={alignment === 'center'} onChange={(e) => {handlerChangeOption(e.target.value, 'alignment', element.id);  setAlignment(e.target.value)}}/>*/}
      {/*                <span>Center</span>*/}
      {/*            </span>*/}
      {/*        <span className="form-radio-button">*/}
      {/*                <input type="radio" name="alignment" value="right" checked={alignment === 'right'}  onChange={(e) => {handlerChangeOption(e.target.value, 'alignment', element.id);  setAlignment(e.target.value)}}/>*/}
      {/*                <span>Right</span>*/}
      {/*            </span>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Field Alignment</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="left"
              checked={alignment === 'left'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Left</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="center"
              checked={alignment === 'center'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Center</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="right"
              checked={alignment === 'right'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Right</span>
          </span>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Input Size</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="small"
              checked={size === 'small'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'size', element.id);
                setSize(e.target.value);
              }}
            />
            <span>Small</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="medium"
              checked={size === 'medium'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'size', element.id);
                setSize(e.target.value);
              }}
            />
            <span>Medium</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="large"
              checked={size === 'large'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'size', element.id);
                setSize(e.target.value);
              }}
            />
            <span>Large</span>
          </span>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Name Label</h3>
        <div className="form-control-item">
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'label', element.id);
              handlerChangeOption(e.target.value.replace(/ /g, '-').toLowerCase(), 'name', element.id);
            }}
          />
        </div>
      </div>

      <RequiredForSetting element={element} onChange={handlerChangeOption} />

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Date and Time</h3>
        <div className="form-control-item d-block">
          <div className={`form-control-checkbox w-full`}>
            <input
              name={'addRowsButton'}
              type="checkbox"
              checked={Boolean(hasDate)}
              onChange={(e) => {
                handlerChangeOption(!hasDate, 'hasDate', element.id);
                setHasDate(!hasDate);
              }}
            />
            <span>Show Date</span>
          </div>
          <div className={`form-control-checkbox w-full`}>
            <input
              name={'addRowsButton'}
              type="checkbox"
              checked={Boolean(hasTime)}
              onChange={(e) => {
                handlerChangeOption(!hasTime, 'hasTime', element.id);
                setHasTime(!hasTime);
              }}
            />
            <span>Show Time</span>
          </div>
        </div>

        <div className="property-item">
          <h3 className="form-builder-aside-property-sub-title">Ability to add new Login filed</h3>
          <div className="form-control-item d-block">
            <div className={`form-control-checkbox w-full`}>
              <input
                name={'addRowsButton'}
                type="checkbox"
                checked={Boolean(canAddNew)}
                onChange={(e) => {
                  handlerChangeOption(!canAddNew, 'canAddNew', element.id);
                  setCanAddNew(!canAddNew);
                }}
              />
              <span>Ability to add new Login filed</span>
            </div>
          </div>
        </div>
      </div>

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Instructions</h3>
        <div className="form-control-item">
          <textarea
            type="text"
            className="form-control"
            placeholder="Enter Instructions"
            value={instructions}
            onChange={(e) => {
              setInstructions(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'instructions', element.id);
            }}
          ></textarea>
        </div>
      </div>

      <AddToCopyGroupSetting
        elementID={element?.id}
        selected={element?.options?.[ADD_TO_COPY_GROUP_SETTING_KEY]}
        onChange={handlerChangeOption}
      />
    </div>
  );
};
