// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-template-modal {
  height: max-content;
  max-height: 100vh;
  display: flex;
  padding-bottom: 0 !important;
  flex-direction: column;
}
.add-new-template-modal .modal-body {
  height: 100%;
  max-height: calc(100vh - 138px);
  display: flex;
  padding-bottom: 32px;
  flex-direction: column;
  padding-bottom: 0;
}
.add-new-template-modal .modal-footer {
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-right: -32px;
  position: initial;
}`, "",{"version":3,"sources":["webpack://./src/pages/cna/assignments/unit/components/add-new-template-modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,4BAAA;EACA,sBAAA;AACF;AACE;EACE,YAAA;EACA,+BAAA;EACA,aAAA;EACA,oBAAA;EACA,sBAAA;EACA,iBAAA;AACJ;AAEE;EACE,wBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".add-new-template-modal {\n  height: max-content;\n  max-height: 100vh;\n  display: flex;\n  padding-bottom: 0 !important;\n  flex-direction: column;\n\n  .modal-body {\n    height: 100%;\n    max-height: calc(100vh - 138px);\n    display: flex;\n    padding-bottom: 32px;\n    flex-direction: column;\n    padding-bottom: 0;\n  }\n\n  .modal-footer {\n    width: calc(100% + 64px);\n    margin-left: -32px;\n    margin-right: -32px;\n    position: initial;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
