import client from '../api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;
let cancelUser;

export const getCurrentUserInfo = async (token) => {
  if (cancelUser !== undefined) {
    cancelUser();
  }
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const response = await client.get('api/users/profile/', {
    cancelToken: new CancelToken(function executor(c) {
      cancelUser = c;
    }),
    headers,
  });

  return response.data;
};

// export const getUsers = async() => {
//     if (cancel !== undefined) {
//         cancel();
//     }
//
//     const response = await client.get('api/auth/users/', {
//         cancelToken: new CancelToken(function executor(c) {
//             cancel = c;
//         })
//     })
//
//     return response.data
// }
//
// export const createUsers = async(data) => {
//     if (cancel !== undefined) {
//         cancel();
//     }
//
//     const response = await client.post('api/auth/users/', data,{
//         cancelToken: new CancelToken(function executor(c) {
//             cancel = c;
//         })
//     })
//
//     return response.data
// }

export const loginService = async (data, handleError) => {
  try {
    if (cancel !== undefined) {
      cancel();
    }
    return await client.post('api/token/', data, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    handleError && handleError(e);
  }
};

export const loginConfirmation = async (key, data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`api/users/set-login-info/${key}/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const forgotPassword = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`api/users/forgot-password/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
