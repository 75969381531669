import { useDrag } from 'react-dnd';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

export const FormElement = function FromElement({ element, onAddItem }) {
  const [hasTooltip, setHasTooltip] = useState(false);

  const [, drag] = useDrag(() => ({
    type: 'fromElement',
    item: element,
    options: {
      dropEffect: 'copy',
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const copyItem = { ...item };
        copyItem.id = new Date().getTime();
        onAddItem(copyItem);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  useEffect(() => {
    if (
      element.type === 'link' ||
      element.type === 'file-attach' ||
      element.type === 'file-upload' ||
      element.type === 'single-line-header' ||
      element.type === 'submitters-field' ||
      element.type === 'login'
    ) {
      setHasTooltip(true);
    }
  }, [element]);

  return (
    <div data-tooltip-id={`table-tooltip-${element.type}`} ref={drag} className="form-builder-element">
      {element.name}
      {hasTooltip && <Tooltip opacity={1} id={`table-tooltip-${element.type}`} place="bottom" content={element.name} />}
    </div>
  );
};
