import Layout from '../../../layout/default';
import React, { useCallback, useEffect, useState } from 'react';
import UserProfileHeader from '../components/profile-header';
import { getUserById } from '../../../services/user';
import { useParams } from 'react-router-dom';
import { addUserFileService, deleteUserFileService, deleteUserFolderService, getUserFilesService } from '../../../services/user/files';
import FilesUploadModal from '../../../components/library/files-upload-modal';
import { uploadFile } from '../../../services/upload';
import withLibraryViewMode from '../../../HOCs/withLibraryViewMode';
import { isResponseOk } from '../../../utility/isResponseOk';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import File from './components/file';
import ManageFolderModal from './components/manage-folder-modal';
import { useDebounce } from 'use-debounce';
import { Breadcrumbs } from '../../../components/library/Breadcrumbs';
import FilePreviewModal from '../../../components/library/file-preview-modal';
import { FolderItemWithDnD } from './components/folder-with-dnd';

const getBreadcrumbs = (breadcrumbs) => {
  const list = [];

  function getProp(o) {
    list.push(o);
    if (o.children) {
      getProp(o.children);
    }
  }

  if (breadcrumbs) {
    getProp(breadcrumbs);
  }

  return list;
};

const UserRelatedFilesPage = ({ libraryViewMode, setLibraryViewMode }) => {
  const { user_id, id: companyID } = useParams();

  const [folders, setFolders] = useState(null);
  const [files, setFiles] = useState(null);
  const [currentFolderID, setCurrentFolderID] = useState(null);
  const [search, setSearch] = useState('');
  const [user, setUser] = useState(null);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([
    {
      url: '/companies/',
      name: 'Company List',
    },
  ]);
  const [isOpenAddFileModal, setIsOpenAddFileModal] = useState(false);
  // { uuid: string, url: string, name: string }
  const [previewFile, setPreviewFile] = useState(null);
  const [foldersBreadcrumbs, setFoldersBreadcrumbs] = useState(
    companyID && user_id
      ? [
          {
            url: `/company/${companyID}/users/${user_id}/related-files`,
            name: 'Related Files',
          },
        ]
      : [],
  );
  const [editFolderData, setEditFolderData] = useState(null);

  const [debouncedSearch] = useDebounce(search, 300);
  const isListMode = libraryViewMode === 'LIST';
  const isCurrentFolderEmpty = Boolean(!folders?.length && !files?.length);
  const isOpenManageFolderModal = !!editFolderData;

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [user_id]);

  const handleGetUserFiles = useCallback(() => {
    const requestParams = { userID: user_id, folderID: currentFolderID, search: debouncedSearch };

    getUserFilesService(requestParams).then((res) => {
      if (isResponseOk(res) && res.data) {
        const { breadcrumbs, data = [] } = res.data;

        const { files: newFiles, folders: newFolders } = data.reduce(
          (acc, item) => {
            if (item) {
              if (item.obj_type === 'file') {
                acc.files.push(item);
              } else if (item.obj_type === 'folder') {
                acc.folders.push(item);
              }
            }

            return acc;
          },
          { files: [], folders: [] },
        );

        setFiles(newFiles);
        setFolders(newFolders);

        const newFoldersBreadcrumbs = [
          {
            url: `/company/${companyID}/users/${user_id}/related-files`,
            name: 'Related Files',
          },
          ...getBreadcrumbs(breadcrumbs),
        ];
        setFoldersBreadcrumbs(newFoldersBreadcrumbs);
      }
    });
  }, [companyID, currentFolderID, debouncedSearch, user_id]);

  useEffect(() => {
    handleGetUserFiles();
  }, [currentFolderID, handleGetUserFiles, isListMode, debouncedSearch]);

  const handleSetPreviewFile = (file) => setPreviewFile(file);

  const initPage = () => {
    getUserById(user_id)
      .then((res) => {
        setUser(res);
        let breadcrumbs = [
          {
            url: `/company/${res.company.id}`,
            name: res.company.name,
          },
          {
            url: `/company/${res.company.id}/users`,
            name: 'User List',
          },
          {
            url: `/company/${res.company.id}/user/${res.id}`,
            name: `${res.first_name} ${res.last_name}`,
          },
        ];
        setPageBreadcrumbs(breadcrumbs);

        handleGetUserFiles();
      })
      .catch((e) => console.log(e));
  };

  const handelOpenCreateFolderModal = () => setEditFolderData({});

  const handleCloseManageFolderModal = () => setEditFolderData(null);

  const handelEditFolder = (folder) => {
    const folderType = folder.is_system_folder ? 'system' : 'user';
    setEditFolderData({ id: folder.id, name: folder.name, type: folderType });
  };

  const onFolderSaved = () => {
    handleGetUserFiles();
    handleCloseManageFolderModal();
  };

  const handelAddFile = (file) => {
    uploadFile(file).then((data) => {
      if (data.uuid) {
        addUserFileService({ userID: user_id, fileUUID: data.uuid, folderID: currentFolderID }).then(() => {
          handleGetUserFiles();
          setIsOpenAddFileModal(false);
        });
      }
    });
  };

  const handelDeleteFile = (fileID) => {
    deleteUserFileService({ userID: user_id, fileID }).then((res) => {
      if (isResponseOk(res)) {
        handleGetUserFiles();
      }
    });
  };

  const handelDeleteFolder = (folder) => {
    deleteUserFolderService({ folderID: folder.id, userID: user_id }).then((res) => {
      if (isResponseOk(res)) {
        handleGetUserFiles();
      }
    });
  };

  const changeFolder = (newFolderID) => setCurrentFolderID(newFolderID);

  const goToPrevFolder = (id, type) => {
    if (type === 'root') {
      changeFolder(null);
    } else if (type === 'folder') {
      changeFolder(id);
    }
  };

  const closeFilePreviewModal = useCallback(() => setPreviewFile(null), []);

  const handleMovedFile = useCallback((folderID) => changeFolder(folderID), []);

  const renderFoldersAndFiles = () => {
    let layout;
    if (isCurrentFolderEmpty) {
      if (search.trim().length) {
        layout = (
          <>
            <div className="empty-page">
              <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
              <h4 className="empty-page-subtitle">
                Try to change the range of keywords. We're here to help you find what you're looking for.
              </h4>
            </div>
          </>
        );
      } else {
        layout = (
          <>
            <div className="empty-page">
              <h3 className="empty-page-title">This folder is empty</h3>
              <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
            </div>
          </>
        );
      }
    } else {
      layout = (
        <>
          {!isListMode && !!folders?.length && <h3 className="library-subtitle">Folders</h3>}
          <div className="library-wrapper" style={{ rowGap: isListMode ? 0 : undefined }}>
            {folders?.map((item) => (
              <FolderItemWithDnD
                key={item.id}
                item={item}
                isDraggable={false}
                isListItem={isListMode}
                changeFolder={changeFolder}
                handelEdit={handelEditFolder}
                handelDelete={item.is_empty ? handelDeleteFolder : undefined}
              />
            ))}
          </div>
          {!isListMode && !!files?.length && <h3 className="library-subtitle mt-2">Files</h3>}
          <div className="library-wrapper" style={{ rowGap: isListMode ? 0 : undefined }}>
            {files?.map((item) => (
              <File
                item={item}
                key={item.id}
                isListItem={isListMode}
                deleteFile={handelDeleteFile}
                onMovedFile={handleMovedFile}
                onFilePreview={handleSetPreviewFile}
              />
            ))}
          </div>
        </>
      );
    }
    return layout;
  };

  const renderFilePreviewModal = () => {
    return <FilePreviewModal previewFile={previewFile} onClose={closeFilePreviewModal} />;
  };

  return (
    <Layout breadcrumbs={pageBreadcrumbs} title={'User Related Files'}>
      <div className={`page-wrapper ${user?.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
        <div className="company-profile">
          <UserProfileHeader user={user} onSetUser={setUser} />
          <div className="library" style={{ paddingTop: 0 }}>
            <DndProvider backend={HTML5Backend}>
              <Breadcrumbs breadcrumbs={foldersBreadcrumbs} styles={{ marginTop: 24, marginBottom: 0 }} goToLevel={goToPrevFolder} />
              <div className="library-filter" style={{ marginTop: 24, marginBottom: 24 }}>
                <div className="library-filter-search form-control-item">
                  <input
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <i className="icon-search" />
                </div>
                <button
                  className={`btn btn-outline`}
                  style={{ width: 44, height: 44 }}
                  onClick={() => setLibraryViewMode(isListMode ? 'GRID' : 'LIST')}
                >
                  {!isListMode && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M2.50033 4.16797C2.04009 4.16797 1.66699 4.54106 1.66699 5.0013C1.66699 5.46154 2.04009 5.83464 2.50033 5.83464H2.50866C2.9689 5.83464 3.34199 5.46154 3.34199 5.0013C3.34199 4.54106 2.9689 4.16797 2.50866 4.16797H2.50033Z"
                        fill="#101828"
                      />
                      <path
                        d="M6.66699 4.16797C6.20676 4.16797 5.83366 4.54106 5.83366 5.0013C5.83366 5.46154 6.20676 5.83464 6.66699 5.83464H17.5003C17.9606 5.83464 18.3337 5.46154 18.3337 5.0013C18.3337 4.54106 17.9606 4.16797 17.5003 4.16797H6.66699Z"
                        fill="#101828"
                      />
                      <path
                        d="M6.66699 9.16797C6.20676 9.16797 5.83366 9.54106 5.83366 10.0013C5.83366 10.4615 6.20676 10.8346 6.66699 10.8346H17.5003C17.9606 10.8346 18.3337 10.4615 18.3337 10.0013C18.3337 9.54106 17.9606 9.16797 17.5003 9.16797H6.66699Z"
                        fill="#101828"
                      />
                      <path
                        d="M5.83366 15.0013C5.83366 14.5411 6.20676 14.168 6.66699 14.168H17.5003C17.9606 14.168 18.3337 14.5411 18.3337 15.0013C18.3337 15.4615 17.9606 15.8346 17.5003 15.8346H6.66699C6.20676 15.8346 5.83366 15.4615 5.83366 15.0013Z"
                        fill="#101828"
                      />
                      <path
                        d="M1.66699 10.0013C1.66699 9.54106 2.04009 9.16797 2.50033 9.16797H2.50866C2.9689 9.16797 3.34199 9.54106 3.34199 10.0013C3.34199 10.4615 2.9689 10.8346 2.50866 10.8346H2.50033C2.04009 10.8346 1.66699 10.4615 1.66699 10.0013Z"
                        fill="#101828"
                      />
                      <path
                        d="M2.50033 14.168C2.04009 14.168 1.66699 14.5411 1.66699 15.0013C1.66699 15.4615 2.04009 15.8346 2.50033 15.8346H2.50866C2.9689 15.8346 3.34199 15.4615 3.34199 15.0013C3.34199 14.5411 2.9689 14.168 2.50866 14.168H2.50033Z"
                        fill="#101828"
                      />
                    </svg>
                  )}

                  {isListMode && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M8.44444 3H3V8.44444H8.44444V3Z"
                        stroke="#101828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 3H11.5556V8.44444H17V3Z"
                        stroke="#101828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 11.5556H11.5556V17H17V11.5556Z"
                        stroke="#101828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.44444 11.5556H3V17H8.44444V11.5556Z"
                        stroke="#101828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
                <button className={'btn btn-outline'} style={{ height: 44 }} onClick={handelOpenCreateFolderModal}>
                  <i className="icon-icon-folder mr-2" />
                  Create folder
                </button>
                <button className={'btn btn-file'} onClick={() => setIsOpenAddFileModal(true)}>
                  <i className="icon-plus-svgrepo-com mr-2" />
                  Add File
                </button>
              </div>
              {renderFoldersAndFiles()}
            </DndProvider>
          </div>
        </div>
      </div>

      {isOpenManageFolderModal && (
        <ManageFolderModal
          currentFolderID={currentFolderID}
          editFolderData={editFolderData}
          onFolderSaved={onFolderSaved}
          onCloseModal={handleCloseManageFolderModal}
        />
      )}

      {isOpenAddFileModal && (
        <FilesUploadModal
          isOpen
          parent_id={currentFolderID}
          company_id={user?.company?.id ?? null}
          closeModal={() => setIsOpenAddFileModal(false)}
          onAddFile={handelAddFile}
        />
      )}

      {renderFilePreviewModal()}
    </Layout>
  );
};

export default withLibraryViewMode(UserRelatedFilesPage);
