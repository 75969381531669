export const deadlineRangeOptions = [
  {
    value: 'hours',
    label: 'hours',
  },
  {
    value: 'days',
    label: 'days',
  },
  {
    value: 'weeks',
    label: 'weeks',
  },
  {
    value: 'months',
    label: 'months',
  },
];
export const qualityFrequencyOptions = [
  {
    value: 1,
    label: 'every',
  },
  {
    value: 2,
    label: 'every 2',
  },
  {
    value: 3,
    label: 'every 3',
  },
  {
    value: 4,
    label: 'every 4',
  },
  {
    value: 5,
    label: 'every 5',
  },
  {
    value: 6,
    label: 'every 6',
  },
];
export const rangeFullOptions = [
  {
    value: 'daily',
    label: 'Days',
  },
  {
    value: 'weekly',
    label: 'Weeks',
  },
  {
    value: 'monthly',
    label: 'Months',
  },
  {
    value: 'yearly',
    label: 'Year',
  },
];
export const typeOptions = [
  {
    value: 'NONE',
    label: 'None',
  },
  {
    value: 'SAME_DAY',
    label: 'Specify Same Day Deadline',
  },
  {
    value: 'TIME_FRAME',
    label: 'Time Frame',
  },
];
export const frequencyOptions = [
  {
    value: 'specific_dates',
    label: 'Custom dates',
  },
  {
    value: 'custom_interval',
    label: 'Custom Interval',
  },
];
export const initialQualityOptions = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
];

export const all_months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
