import React, { useCallback, useEffect, useState } from 'react';
import { getFileById, getFilesGeneralById, uploadFile, getFilesById } from '../../../services/upload';
import Modal from 'react-modal';
import { useAuth } from '../../../container/authContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Breadcrumbs } from '../../library/Breadcrumbs';
import { getFilesFoler, getFilesGeneralFolder, getFilesGeneralSearch, getFilesSearch } from '../../../services/folder/form-avaliable';
import File from '../../library/files/file';
import { GeneralFolder } from '../../library/folder-general-item';
import FileLoader from '../../other/FileLoader';
import { useIsInitFormPage } from '../../../hook/pages/useIsInitFormPage';
import { getFileExtFromFileName } from '../../../utility/files/getFileExtFromFileName';
import { isWebVideoByFileExt } from '../../../utility/files/isWebVideoByFileExt';
import FilePreviewModal from '../../library/file-preview-modal';
import { openPDF } from '../../../utility/openPDF';

export const FileAttachElement = function FromElement({ element, handlerChange, removeElement }) {
  const { user } = useAuth();
  const isInitFormPage = useIsInitFormPage();
  const company_id = user?.company?.id ? user.company.id : null;
  const { options } = element;
  const { initiator_can_edit } = options ?? {};
  const currentUserCanEdit = user && isInitFormPage && initiator_can_edit;

  const [isOpen, setIsOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [file, setFile] = useState(null);
  const [tab, setTab] = useState(1);
  const [search, setSearch] = useState('');
  const [isListMode, setIsListMode] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [staticFolders, setStaticFolders] = useState([]);
  const [staticForms, setStaticForms] = useState([]);
  const [formsSearch, setFormsSearch] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      name: company_id ? 'Company Library' : 'General Library',
      url: null,
    },
  ]);
  // { uuid: string, url: string, name: string }
  const [previewFile, setPreviewFile] = useState(null);

  useEffect(() => {
    if (search) {
      applySearch();
    } else {
      initPage();
      setFormsSearch([]);
      setIsListMode(false);
    }
    // eslint-disable-next-line
  }, [isOpen, search]);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [currentId]);

  const initPage = () => {
    if (user) {
      const params = {
        parent_id: currentId ? currentId : null,
        company_id: company_id,
      };

      if (company_id) {
        getFilesFoler(params)
          .then((res) => {
            setBreadcrumbs([
              {
                name: 'Company Library',
                url: null,
              },
              ...getBreadcrumbs(res.breadcrumbs),
            ]);
            setStaticFolders(res.data.filter((item) => item.type === 'folder'));
            setStaticForms(res.data.filter((item) => item.type === 'file'));
            // setFolderId(getBreadcrumbs(res.breadcrumbs)[getBreadcrumbs(res.breadcrumbs).length - 1].id);
          })
          .catch((e) => console.log(e));
      } else {
        getFilesGeneralFolder(params)
          .then((res) => {
            setBreadcrumbs([
              {
                name: 'General Library',
                url: null,
              },
              ...getBreadcrumbs(res.breadcrumbs),
            ]);
            setStaticFolders(res.data.filter((item) => item.type === 'folder'));
            setStaticForms(res.data.filter((item) => item.type === 'file'));
            // setFolderId(getBreadcrumbs(res.breadcrumbs)[getBreadcrumbs(res.breadcrumbs).length - 1].id);
          })
          .catch((e) => console.log(e));
      }
    }
  };

  const getBreadcrumbs = (breadcrumbs) => {
    const list = [];
    if (breadcrumbs) {
      getProp(breadcrumbs);
    }

    function getProp(o) {
      list.push(o);
      if (o.children) {
        getProp(o.children);
      }
    }

    return list;
  };
  const applySearch = () => {
    const params = {
      get_all: true,
      status: 'active',
    };

    if (search) {
      params['search'] = search;
    }

    setIsListMode(true);

    if (company_id) {
      getFilesSearch(params)
        .then((res) => {
          setFormsSearch(res);
        })
        .catch((e) => console.log(e));
    } else {
      getFilesGeneralSearch(params)
        .then((res) => {
          setFormsSearch(res);
        })
        .catch((e) => console.log(e));
    }
  };

  const handelCloseModal = () => {
    setIsOpen(false);
    setIsDisable(false);
    setFile(null);
  };

  useEffect(() => {
    setIsDisable(!file);
  }, [file]);

  const handlerAttach = () => {
    if (file.file) {
      handlerChange(element, file.file);
    } else {
      handlerChange(element, file);
    }
    handelCloseModal();
  };

  const [loader, setLoader] = useState(false);

  const handlerUpload = (e) => {
    setLoader(true);
    uploadFile(e.target.files[0])
      .then((res) => {
        setLoader(false);
        setFile(res);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoader(false);
      });
  };

  const handelAttach = (file) => {
    if (company_id) {
      getFilesById(file.id)
        .then((res) => {
          setFile(res);
        })
        .catch((e) => console.log(e));
    } else {
      getFilesGeneralById(file.id)
        .then((res) => {
          setFile(res);
        })
        .catch((e) => console.log(e));
    }
  };

  const downloadFile = ({ name, downloadUrl }) => {
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = name;
    a.rel = 'noreferrer';
    a.click();
  };

  const openFile = (id, name) => {
    setLoader(true);

    const fileExtension = getFileExtFromFileName(name);

    if (fileExtension) {
      getFileById(id)
        .then((fileByIdRes) => {
          if (fileByIdRes?.config === 'success' && fileByIdRes?.message) {
            const isWebVideo = isWebVideoByFileExt(fileExtension);
            const isPDF = fileExtension === 'pdf';
            const fileData = {
              ...(element.options.file ?? {}),
              url: fileByIdRes.message,
              downloadUrl: fileByIdRes.message,
            };
            if (isWebVideo) {
              setPreviewFile(fileData);
            } else if (isPDF) {
              fetch(fileData.downloadUrl)
                .then((res) => res.blob())
                .then(openPDF);
            } else {
              downloadFile(fileData);
            }
          }
        })
        .catch((e) => console.error(e))
        .finally(() => setLoader(false));
    }
  };

  const clearFilter = () => {
    setSearch('');
    setIsListMode(false);
    setFormsSearch([]);
  };

  const goToPrev = (goToId) => {
    clearFilter();
    setIsListMode(false);
    setCurrentId(goToId);
  };

  const handlerChangeStaticFolder = (id) => {
    setCurrentId(id);
  };

  const closeFilePreviewModal = useCallback(() => setPreviewFile(null), []);

  const renderFilePreviewModal = () => {
    return <FilePreviewModal previewFile={previewFile} onClose={closeFilePreviewModal} />;
  };

  return (
    <div className={'element-wrapper'}>
      <div className="element-inner">
        <label htmlFor={element.id} className="form-label">
          {element.options.label}
        </label>

        {!element.options.file && currentUserCanEdit && (
          <span className={'btn btn-attach'} onClick={() => setIsOpen(true)}>
            <i className="icon-plus-svgrepo-com" />
            Choose a file
          </span>
        )}

        {element.options.file && (
          <ul>
            <li
              onClick={() => openFile(element.options.file.uuid, element.options.file.name)}
              key={element.options.file.uuid}
              className={'align-center cursor-pointer file-upload library-item'}
            >
              <div className="d-flex align-center library-item-text">
                <img src="/images/icon-file-list.svg" className={'mr-2'} alt="" />
                <p
                  style={{
                    display: 'inline-block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {element.options.file.name}
                </p>
              </div>
              {currentUserCanEdit && (
                <button
                  className="btn btn-icon ml-2 mr-4 btn-remove"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlerChange(element, null);
                  }}
                >
                  <i className="icon-remove" />
                </button>
              )}
            </li>
          </ul>
        )}
        {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
      </div>

      {isOpen && (
        <Modal
          className="modal-default modal-with-tabs"
          isOpen={isOpen}
          onRequestClose={handelCloseModal}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="modal-body">
            <div className={'modal-tabs'}>
              <button onClick={() => setTab(0)} className={`btn-tab ${tab === 0 && 'btn-tab-active'}`}>
                {company_id ? 'Corporate' : 'General'} Library Files
              </button>
              <button onClick={() => setTab(1)} className={`btn-tab ${tab === 1 && 'btn-tab-active'}`}>
                Upload from the device
              </button>
            </div>
            {tab === 0 && (
              <>
                <div className="library library-attach">
                  <DndProvider backend={HTML5Backend}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />

                    <div className="library-filter">
                      <div className="library-filter-search form-control-item">
                        <input
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="Search"
                        />
                        <i className="icon-search"></i>
                      </div>
                    </div>

                    {!isListMode && (
                      <>
                        {(!!staticFolders.length || !currentId) && <h3 className="library-subtitle">Folders</h3>}
                        <div className="library-wrapper">
                          {staticFolders.map((item) => (
                            <GeneralFolder
                              key={item.id}
                              item={item}
                              changeFolder={handlerChangeStaticFolder}
                              handelEdit={() => null}
                              handelDelete={() => null}
                            />
                          ))}
                        </div>

                        {staticForms.length > 0 && <h3 className="library-subtitle">Files</h3>}
                        {staticForms.length > 0 && (
                          <div className="library-wrapper">
                            {staticForms.length > 0 &&
                              staticForms.map((item) => (
                                <File
                                  file={file}
                                  isGeneral={!company_id}
                                  handelAttach={handelAttach}
                                  isAttach
                                  deleteFile={() => {}}
                                  item={item}
                                  url={item.id}
                                  key={item.id}
                                />
                              ))}
                          </div>
                        )}

                        {!staticFolders.length && !staticForms.length && !search && (
                          <>
                            <div className="empty-page">
                              <h3 className="empty-page-title">This folder is empty</h3>
                              <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {isListMode && (
                      <>
                        {formsSearch.length > 0 && (
                          <>
                            <h3 className="library-subtitle">
                              Files <span className={'total'}>(Total: {formsSearch.length} files)</span>
                            </h3>
                            <div className="library-wrapper">
                              {formsSearch.map((item) => (
                                <File
                                  file={file}
                                  isGeneral={!company_id}
                                  handelAttach={handelAttach}
                                  isAttach
                                  deleteFile={() => {}}
                                  item={item}
                                  url={`${item.id}`}
                                  key={item.id}
                                />
                              ))}
                            </div>
                          </>
                        )}
                        {!formsSearch.length && !!search && (
                          <>
                            <div className="empty-page">
                              <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
                              <h4 className="empty-page-subtitle">
                                Try to change the range of keywords. We're here to help you find what you're looking for.
                              </h4>
                            </div>
                          </>
                        )}
                        {!formsSearch.length && !search && (
                          <>
                            <div className="empty-page">
                              <h3 className="empty-page-title">This folder is empty</h3>
                              <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </DndProvider>
                </div>
              </>
            )}
            {tab === 1 && (
              <>
                <label className={'form-label'}>Upload from the device</label>
                {!file && (
                  <div className={`form-control-file form-control-file-default mb-1 form-control-${element.options.size}`}>
                    <input type="file" onChange={handlerUpload} />
                    <div>
                      <img className="icon-icon-upload" src="/images/upload-svg.svg" alt="" />
                      <span>Click to upload</span>
                    </div>
                  </div>
                )}

                {file && (
                  <ul>
                    <li className={'align-center cursor-pointer file-upload library-item'}>
                      <div className="d-flex align-center library-item-text">
                        <img src="/images/icon-file-list.svg" className={'mr-2'} alt="" />
                        <p>{file.name}</p>
                      </div>
                      <button
                        className="btn btn-icon mr-4"
                        onClick={(e) => {
                          e.stopPropagation();
                          setFile(null);
                        }}
                      >
                        <i className="icon-remove" />
                      </button>
                    </li>
                  </ul>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline" onClick={handelCloseModal}>
              Cancel
            </button>
            <button className="btn d-flex" onClick={handlerAttach} disabled={isDisable}>
              Attach
            </button>
          </div>
        </Modal>
      )}

      {currentUserCanEdit && (
        <div className={'init-form-aside-buttons'}>
          <button onClick={() => removeElement(element.id)} className="btn btn-settings btn-icon btn-remove">
            <i className="icon-remove" />
          </button>
        </div>
      )}

      {renderFilePreviewModal()}

      {loader && <FileLoader />}
    </div>
  );
};
