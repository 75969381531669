import cx from 'classnames';
import React, { memo, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { useAuth } from '../../../../../../container/authContext';
import { assignCNATasksToShiftRoomService } from '../../../../../../services/cna/assignments/cna';
import { getCNADefaultTasksService } from '../../../../../../services/cna/default-tasks';
import { isResponseOk } from '../../../../../../utility/isResponseOk';

import './styles.scss';

const AddTasksModal = ({ addTasksModalData, onClose, onRefetchAssignment }) => {
  const { user } = useAuth();
  const companyID = user?.company?.id;
  const { shiftRecordID } = addTasksModalData;

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [defaultTasksSuggest, setDefaultTasksSuggest] = useState([]);
  const [taskInputValue, setTaskInputValue] = useState('');

  const options = useMemo(() => {
    return defaultTasksSuggest?.map(({ id, text, ...rest }) => ({ value: id, label: text, ...rest }));
  }, [defaultTasksSuggest]);

  const selectedOptions = useMemo(() => {
    return selectedTasks.reduce((acc, { id, text, ...rest }) => {
      const taskOption = {
        value: id,
        label: text,
        ...rest,
      };
      acc.push(taskOption);
      return acc;
    }, []);
  }, [selectedTasks]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCNADefaultTasksService({ companyID });
        if (isResponseOk(res) && res.data) {
          setDefaultTasksSuggest(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [companyID]);

  const handleRemoveTask = (value) => {
    setSelectedTasks((prevState) => prevState.filter(({ id }) => String(id) !== String(value)));
  };

  const handleChangeTask = (data) => {
    const newSelectedTasks = data.map(({ value, label, ...rest }) => ({ id: value, text: label, ...rest }));
    setSelectedTasks(newSelectedTasks);
  };

  const handleTaskSelectBlur = () => {
    const formattedNewTaskName = taskInputValue.trim();
    if (formattedNewTaskName.length) {
      const newTask = { id: new Date().getTime(), text: formattedNewTaskName, isCustom: true };
      setSelectedTasks((prevState) => [...prevState, newTask]);
      setTaskInputValue('');
    }
  };

  const handleTaskInputChange = (value) => setTaskInputValue(value);

  const handleTaskSelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTaskSelectBlur();
    }
  };

  const handleSave = async () => {
    try {
      let newTasks = selectedTasks.map((task) => {
        let taskData = {};

        if (task.isCustom) {
          taskData.name = task.text;
        } else {
          taskData.default_task_id = task.id;
        }

        return taskData;
      });

      const response = await assignCNATasksToShiftRoomService({ shiftRecordID, body: newTasks });
      if (isResponseOk(response)) {
        onClose();
        onRefetchAssignment();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      isOpen
      className={cx('modal-default add-tasks-modal')}
      overlayClassName={'modal-overlay add-tasks-modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Add Tasks Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        Add Tasks
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <span className={'select-label'}>Tasks</span>
        <Select
          isMulti
          hideSelectedOptions
          inputValue={taskInputValue}
          value={selectedOptions}
          options={options}
          closeMenuOnSelect={false}
          className="form-control-select"
          classNamePrefix="react-select-multi"
          onChange={handleChangeTask}
          onBlur={handleTaskSelectBlur}
          onKeyDown={handleTaskSelectKeyDown}
          onInputChange={handleTaskInputChange}
        />
        <div className="selected-tasks-list">
          {selectedOptions.map(({ value, label: text }) => {
            return (
              <div key={value} className={'selected-tasks-item'}>
                <p>{text}</p>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleRemoveTask(value)}
                >
                  <path
                    d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                    stroke="#FDA29B"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            );
          })}
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(AddTasksModal);
