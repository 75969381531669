import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { createUnit, editUnit } from '../../services/units';
import InputMask from 'react-input-mask';
import UnitChangeCredentialsModal from './unit-change-credentials-modal';
import { isEqual } from 'lodash';

function FacilityModal({ isOpen, companyName, closeModal, userGroupData, handelCreateUserGroup, facilities, facility, onRefetchUnits }) {
  const [errorUserGroup, setErrorUserGroup] = useState({});
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [userGroup, setUserGroup] = useState({
    name: '',
    facility_id: '',
    edition_name: [],
    login: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isOpenChangeCredentialsModal, setIsOpenChangeCredentialsModal] = useState(false);

  const isUnitEditing = !!userGroupData;
  const isChanged = useMemo(() => {
    const originalData = { name: userGroupData?.name, login: userGroupData?.login, password: userGroupData?.password };
    const newData = {
      name: userGroup?.name,
      login: userGroup?.login,
      password: userGroup?.password,
    };
    return userGroupData ? !isEqual(newData, originalData) : false;
  }, [userGroup?.login, userGroup?.name, userGroup?.password, userGroupData]);

  useEffect(() => {
    setFacilityOptions(
      facilities
        .filter((item) => !item.deleted_at)
        .map((item) => ({
          value: item.id,
          label: item.name,
        })),
    );
  }, [facilities]);

  useEffect(() => {
    if (userGroupData) {
      const newState = { ...userGroupData };
      newState['facility_id'] = { value: newState.facility.id, label: newState.facility.name };
      newState['edition_name'] = [];
      setUserGroup(newState);
    } else {
      setUserGroup({
        name: '',
        facility_id: '',
        edition_name: [],
        login: '',
        password: '',
      });
    }
  }, [userGroupData]);

  const handleChange = (value, key) => {
    setUserGroup({
      ...userGroup,
      [key]: value,
    });

    if (errorUserGroup.hasOwnProperty(key)) {
      delete errorUserGroup[key];
    }
  };

  const handleChangeName = (value, item) => {
    const newState = [...userGroup.edition_name];

    newState[newState.findIndex((name) => name.id === item.id)].name = value;

    setUserGroup({
      ...userGroup,
      edition_name: [...newState],
    });
  };

  const resetForm = () => {
    setUserGroup({
      name: '',
      facility_id: facility ? { value: facility.id, label: facility.name } : '',
      edition_name: [],
      login: '',
      password: '',
    });
    setErrorUserGroup({});
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };

  const addMorePositions = () => {
    setUserGroup((prevState) => {
      const newState = { ...prevState };
      newState.edition_name = [...newState.edition_name, { id: new Date().getTime(), name: '' }];
      return newState;
    });
  };
  const handleSubmit = async () => {
    if (userGroupData) {
      const formData = { name: userGroup.name };

      delete formData.id;
      delete formData.deleted_at;
      delete formData.created_at;

      editUnit(formData, userGroupData.id)
        .then(() => {
          resetForm();
          handelCreateUserGroup();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUserGroup(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      const formData = {
        names: [userGroup.name, ...userGroup.edition_name.map((item) => item.name)],
        facility_id: userGroup.facility_id.value,
        login: userGroup.login,
        password: userGroup.password,
      };
      createUnit(formData)
        .then((res) => {
          resetForm();
          handelCreateUserGroup();
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUserGroup(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };

  const handelRemove = (item) => {
    let newState = [...userGroup.edition_name];
    newState = newState.filter((name) => name.id !== item.id);
    setUserGroup({
      ...userGroup,
      edition_name: newState,
    });
  };

  return (
    <Modal
      className="modal-default"
      isOpen={isOpen}
      onRequestClose={handelCloseModal}
      contentLabel="Example Modal"
      ariaHideApp={false}
      style={{
        content: {
          paddingBottom: 0,
        },
      }}
    >
      <h2 className="modal-header">
        {userGroupData ? 'Edit' : 'Add New'} Unit
        <button className="btn btn-icon" onClick={handelCloseModal}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>
      {!!companyName && <h3 className="modal-subheader">for {companyName} company</h3>}
      <div className="modal-body">
        <div className="form-wrapper">
          <h3 className="form-wrapper-title">
            <img src="/images/icon-unit.svg" alt="" />
            Unit information
          </h3>
          <div className={`form-item form-item-select form-control-item-settings ${errorUserGroup.facility_id ? 'form-item-error' : ''}`}>
            <label className="form-label">Facility</label>
            <Select
              isDisabled={userGroupData}
              options={facilityOptions}
              className="form-control-select"
              classNamePrefix="react-select"
              value={userGroup.facility_id}
              onChange={(e) => handleChange(e, 'facility_id')}
              placeholder="Select Facility"
            ></Select>
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUserGroup.facility_id && errorUserGroup.facility_id[0]}</span>
          </div>
          <div
            className={`form-item ${(errorUserGroup.names && errorUserGroup.names[0]) || (errorUserGroup.non_field_errors && errorUserGroup.non_field_errors[0]) ? 'form-item-error' : ''}`}
          >
            <label className="form-label">Unit Name</label>
            <input
              placeholder="Enter name"
              value={userGroup.name}
              onChange={(e) => handleChange(e.target.value, 'name')}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUserGroup.names && errorUserGroup.names[0] && errorUserGroup.names[0]}</span>
            <span className="form-error">
              {errorUserGroup.non_field_errors && errorUserGroup.non_field_errors[0] && errorUserGroup.non_field_errors[0]}
            </span>
          </div>

          {userGroup.edition_name.map((item, index) => (
            <div key={item.id} className={`form-item ${errorUserGroup.names && errorUserGroup.names[index + 1] ? 'form-item-error' : ''}`}>
              <label className="form-label">Unit Name</label>
              <input
                placeholder="Enter name"
                value={item.name}
                onChange={(e) => handleChangeName(e.target.value, item)}
                type="text"
                className="form-control"
              />
              <i onClick={() => handelRemove(item)} className="remove-icon cursor-pointer icon-remove" />
              <span className="form-error">
                {errorUserGroup.names && errorUserGroup.names[index + 1] && errorUserGroup.names[index + 1]}
              </span>
            </div>
          ))}
        </div>

        {isUnitEditing && (
          <div className="form-wrapper">
            <h3 className="form-wrapper-title">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="2" width="32" height="32" rx="16" fill="#F4EBFF" />
                <rect x="2" y="2" width="32" height="32" rx="16" stroke="#F9F5FF" strokeWidth="4" />
                <path
                  d="M15.823 17.6769C15.4376 16.7155 15.3959 15.6505 15.705 14.662C16.0142 13.6734 16.6552 12.8219 17.5197 12.2514C18.3841 11.6808 19.4191 11.4263 20.4496 11.5307C21.4801 11.6352 22.4429 12.0923 23.1753 12.8247C23.9077 13.5571 24.3648 14.5199 24.4693 15.5504C24.5737 16.5809 24.3192 17.6159 23.7486 18.4804C23.1781 19.3448 22.3266 19.9858 21.338 20.295C20.3495 20.6041 19.2845 20.5624 18.3231 20.177L18.3231 20.1769L17.5001 20.9999H16.0001V22.4999H14.5001V23.9999H12.0001V21.4999L15.8231 17.6769L15.823 17.6769Z"
                  stroke="#7F56D9"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.25 15.375C21.5952 15.375 21.875 15.0952 21.875 14.75C21.875 14.4048 21.5952 14.125 21.25 14.125C20.9048 14.125 20.625 14.4048 20.625 14.75C20.625 15.0952 20.9048 15.375 21.25 15.375Z"
                  fill="#7F56D9"
                />
              </svg>
              Credentials
            </h3>
            <div className={`form-item form-item-select form-control-item-settings`}>
              <label className="form-label">Phone number</label>
              <InputMask
                id="phone"
                mask="999-999-9999"
                className="form-control readonly"
                value={userGroup.login}
                placeholder="XXX-XXX-XXXX"
              />
              <span className="form-error">{errorUserGroup.login && errorUserGroup.login[0]}</span>
            </div>

            <div className={`form-item form-item-select form-control-item-settings`}>
              <label className="form-label">Password</label>
              <div className="login-page">
                <div className="form-control-item-login" style={{ width: '100%' }}>
                  <span className="icon-login">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M5.83333 9.1665V5.83317C5.83333 4.7281 6.27232 3.66829 7.05372 2.88689C7.83512 2.10549 8.89493 1.6665 10 1.6665C11.1051 1.6665 12.1649 2.10549 12.9463 2.88689C13.7277 3.66829 14.1667 4.7281 14.1667 5.83317V9.1665M4.16667 9.1665H15.8333C16.7538 9.1665 17.5 9.9127 17.5 10.8332V16.6665C17.5 17.587 16.7538 18.3332 15.8333 18.3332H4.16667C3.24619 18.3332 2.5 17.587 2.5 16.6665V10.8332C2.5 9.9127 3.24619 9.1665 4.16667 9.1665Z"
                        stroke="#98A2B3"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <input
                    value={userGroup.password}
                    className="form-control readonly"
                    placeholder={'Enter the password'}
                    type={showPassword ? 'text' : 'password'}
                  />
                  <span className="show-password cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                    {!showPassword && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M12 5.24951C4.5 5.24951 1.5 12.0002 1.5 12.0002C1.5 12.0002 4.5 18.7495 12 18.7495C19.5 18.7495 22.5 12.0002 22.5 12.0002C22.5 12.0002 19.5 5.24951 12 5.24951Z"
                          stroke="#98A2B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                          stroke="#98A2B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                    {showPassword && (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M10.4955 4.82764C11.1525 4.68702 11.8252 4.61673 12.5 4.61818C19.1818 4.61818 23 11.6 23 11.6C22.4206 12.5911 21.7296 13.5241 20.9382 14.384M14.5236 13.4502C14.2615 13.7074 13.9453 13.9137 13.5941 14.0568C13.2428 14.1999 12.8636 14.2769 12.4791 14.2831C12.0946 14.2893 11.7127 14.2246 11.3561 14.0929C10.9995 13.9613 10.6756 13.7653 10.4037 13.5166C10.1317 13.268 9.91738 12.9719 9.77335 12.6459C9.62932 12.3199 9.55859 11.9707 9.56538 11.6191C9.57216 11.2676 9.65632 10.9209 9.81284 10.5997C9.96935 10.2786 10.195 9.98951 10.4764 9.74982M2 2L23 21.2M18.17 16.784C16.5383 17.9212 14.5514 18.5512 12.5 18.5818C5.81818 18.5818 2 11.6 2 11.6C3.18735 9.57693 4.83417 7.8094 6.83 6.416L18.17 16.784Z"
                          stroke="#98A2B3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </span>
                </div>
              </div>
              <span className="form-error">{errorUserGroup.password && errorUserGroup.password[0]}</span>
            </div>

            <span
              style={{ marginLeft: 10, fontWeight: 600, color: 'rgba(127, 86, 217, 1)', cursor: 'pointer' }}
              onClick={() => setIsOpenChangeCredentialsModal(true)}
            >
              Change credentials
            </span>
          </div>
        )}

        {!isUnitEditing && (
          <span className="add-facility cursor-pointer" onClick={() => addMorePositions()}>
            <i className="icon-plus-svgrepo-com"></i> Add units
          </span>
        )}
      </div>

      <div className="modal-footer" style={{ position: 'initial', marginLeft: -32, marginRight: -32, width: 'calc(100% + 64px)' }}>
        <button className="btn btn-outline" onClick={handelCloseModal}>
          Cancel
        </button>
        <button className="btn" disabled={!isChanged && isUnitEditing} onClick={handleSubmit}>
          Save
        </button>
      </div>

      {isOpenChangeCredentialsModal && (
        <UnitChangeCredentialsModal
          unitData={userGroupData}
          onRefetchUnits={onRefetchUnits}
          onClose={() => setIsOpenChangeCredentialsModal(false)}
        />
      )}
    </Modal>
  );
}

export default FacilityModal;
