import React, { memo } from 'react';

export const INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY = 'can_initiator_specify_period_availability_and_frequency';

const InitiatorCanSetFrequencySetting = (props) => {
  const { onChange, error, initiatorCanSetFrequency } = props;
  return (
    <div className="form-control-item-settings">
      <div className="form-settings-label">
        <label className="form-label form-label-big">Will the form initiator have the ability to set frequency settings</label>
      </div>
      <div className="form-settings-inputs">
        <div className="d-flex">
          <div className={`form-control-radio`}>
            <input
              name={INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY}
              type="radio"
              onChange={() => onChange(true, INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY)}
              checked={initiatorCanSetFrequency}
            />
            <span>Yes</span>
          </div>
          <div className={`form-control-radio`}>
            <input
              name={INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY}
              type="radio"
              onChange={() => onChange(false, INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY)}
              checked={!initiatorCanSetFrequency}
            />
            <span>No</span>
          </div>
        </div>
        <span className="form-error mb-2 d-flex">{error}</span>
      </div>
    </div>
  );
};

export default memo(InitiatorCanSetFrequencySetting);
