import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import './styles.scss';
import Modal from 'react-modal';
import cx from 'classnames';
import { getFacilitiesByCompanySettings } from '../../../../../services/facility';
import { useAuth } from '../../../../../container/authContext';
import { MultiSelect } from '../../../../../components/other/MultiSelect';
import {
  createFormsAssignedToMeFolder,
  updateFormsAssignedToMeFolder,
} from '../../../../../services/corporate-library/forms-assigned-to-me';
import { isResponseOk } from '../../../../../utility/isResponseOk';

const ManageFolderModal = ({ editFolderData = {}, onClose, onSaved, folderID, rootFolderID }) => {
  const { user } = useAuth();
  const { name: editFolderName, id: editFolderID, facilities_ids: editFolderFacilities = [] } = editFolderData ?? {};
  const companyID = user.company?.id;
  const isRootFolder = rootFolderID === folderID;

  const [facilitiesOptions, setFacilitiesOptions] = useState(null);
  const [folderName, setFolderName] = useState(editFolderName || '');
  // IDs
  const [selectedFacilities, setSelectedFacilities] = useState(editFolderFacilities);

  const trimmedFolderName = folderName.trim();
  const trimmedFolderNameLength = trimmedFolderName.length;
  const isValidNewFolderName = Boolean(trimmedFolderNameLength && trimmedFolderNameLength <= 255);
  const isValidNewFolderData = Boolean(isValidNewFolderName && (isRootFolder ? selectedFacilities?.length : true));
  const isEditing = !!editFolderName;
  const selectedFacilitiesOptions = useMemo(() => {
    return selectedFacilities?.map((facilityID) => facilitiesOptions?.find((o) => o.value === facilityID)) ?? null;
  }, [facilitiesOptions, selectedFacilities]);

  const getFacilities = useCallback(() => {
    getFacilitiesByCompanySettings(companyID, 'getAll')
      .then((resFacilities) => {
        setFacilitiesOptions(resFacilities.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => console.log(e));
  }, [companyID]);

  useEffect(() => {
    if (isRootFolder) {
      getFacilities();
    }
  }, [getFacilities, isRootFolder]);

  useEffect(() => {
    if (editFolderFacilities?.length) {
      setSelectedFacilities(editFolderFacilities);
    }
  }, [editFolderFacilities]);

  const handleSave = () => {
    if (isEditing) {
      const params = {
        name: folderName,
        folderID: editFolderID,
      };
      if (isRootFolder) {
        // Optional param
        if (selectedFacilities?.length) {
          params.facilities_ids = selectedFacilities;
        }
      }

      updateFormsAssignedToMeFolder(params).then((res) => {
        if (isResponseOk(res) && res.data) {
          onSaved();
          onClose();
        }
      });
    } else {
      // Create new folder
      const data = {
        company_id: companyID,
        name: folderName,
      };

      // Optional params
      if (selectedFacilities?.length) {
        data.facilities_ids = selectedFacilities;
      }
      if (folderID) {
        data.parent_id = folderID;
      }

      createFormsAssignedToMeFolder(data).then((res) => {
        if (isResponseOk(res) && res.data) {
          onSaved(res.data.id);
          onClose();
        }
      });
    }
  };

  const handleChangeFolderName = (e) => setFolderName(e.target.value);

  const handleSelectFacility = (newFacilitiesOptions) => {
    const newFacilitiesIDs = newFacilitiesOptions?.map(({ value }) => value);
    setSelectedFacilities(newFacilitiesIDs);
  };

  const render = () => {
    const contentLayout = (
      <>
        <div className="modal-body">
          <div className={'modal-body__main'}>
            <div className="setting">
              <div className="setting-header">
                <svg width="36" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="2.5" y="2" width="32" height="32" rx="16" fill="#F4EBFF" />
                  <rect x="2.5" y="2" width="32" height="32" rx="16" stroke="#F9F5FF" strokeWidth="4" />
                  <path
                    d="M25.1673 22.6667C25.1673 23.0203 25.0268 23.3594 24.7768 23.6095C24.5267 23.8595 24.1876 24 23.834 24H13.1673C12.8137 24 12.4746 23.8595 12.2245 23.6095C11.9745 23.3594 11.834 23.0203 11.834 22.6667V13.3333C11.834 12.9797 11.9745 12.6406 12.2245 12.3905C12.4746 12.1405 12.8137 12 13.1673 12H16.5007L17.834 14H23.834C24.1876 14 24.5267 14.1405 24.7768 14.3905C25.0268 14.6406 25.1673 14.9797 25.1673 15.3333V22.6667Z"
                    stroke="#7F56D9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Folder information
              </div>
              <div className="setting-body">
                <div className="field-title">Folder Name</div>
                <input
                  value={folderName}
                  type="text"
                  className={'form-control'}
                  placeholder="Enter the folder name"
                  onChange={handleChangeFolderName}
                />
              </div>
            </div>

            {isRootFolder && (
              <div className="setting">
                <div className="setting-header">
                  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="2" y="2" width="32" height="32" rx="16" fill="#F4EBFF" />
                    <rect x="2" y="2" width="32" height="32" rx="16" stroke="#F9F5FF" strokeWidth="4" />
                    <path
                      d="M13.5996 21.7318H12.9329C12.5793 21.7318 12.2402 21.5913 11.9901 21.3412C11.7401 21.0912 11.5996 20.7521 11.5996 20.3984V13.7318C11.5996 13.3781 11.7401 13.039 11.9901 12.789C12.2402 12.5389 12.5793 12.3984 12.9329 12.3984H23.5996C23.9532 12.3984 24.2924 12.5389 24.5424 12.789C24.7925 13.039 24.9329 13.3781 24.9329 13.7318V20.3984C24.9329 20.7521 24.7925 21.0912 24.5424 21.3412C24.2924 21.5913 23.9532 21.7318 23.5996 21.7318H22.9329M18.2663 20.3984L21.5996 24.3984H14.9329L18.2663 20.3984Z"
                      stroke="#7F56D9"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {'Folder Access Limited to Specific Facility Initiators'}
                </div>
                <div className="setting-body">
                  <div className="field-title">Facility</div>
                  <MultiSelect
                    options={facilitiesOptions}
                    value={selectedFacilitiesOptions}
                    isDisabled={!facilitiesOptions?.length}
                    placeholder={'Choose one or several facilities'}
                    allName="All facilities"
                    onChange={(e) => handleSelectFacility(e, 'facility_ids')}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-outline" onClick={onClose}>
            Cancel
          </button>
          <button className={'btn save-btn'} disabled={!isValidNewFolderData} onClick={handleSave}>
            <span>Save</span>
          </button>
        </div>
      </>
    );

    return (
      <Modal
        isOpen
        className={cx('modal-default manage-folder-modal')}
        overlayClassName={'modal-overlay manage-folder-modal-overlay'}
        onRequestClose={onClose}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <h2 className="modal-header">
          {`${isEditing ? 'Edit' : 'Create'} folder`}
          <button className="btn btn-icon" onClick={onClose}>
            <i className="icon-icon-x" />
          </button>
        </h2>
        {contentLayout}
      </Modal>
    );
  };

  return render();
};

export default memo(ManageFolderModal);
