import React, { useMemo } from 'react';
import Select from 'react-select';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';
import cx from 'classnames';
import { useIsMobile } from '../../../hook/useIsMobile';

export const DropdownElement = function FromElement({ element, handlerChange, error }) {
  const isRequiredElement = useIsRequiredElement(element);
  const isMobile = useIsMobile();

  const options = useMemo(() => {
    return element.inputs.map((item) => ({ label: item.label, value: item.id }));
  }, [element.inputs]);

  const optionsLength = options.length;

  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      <Select
        id={element.id}
        options={options}
        menuPosition={'fixed'}
        isSearchable={!isMobile}
        name={element.options.name}
        classNamePrefix="react-select"
        menuPortalTarget={document.body}
        readOnly={element.options.readOnly}
        value={element.options.valueInput}
        styles={{
          menu: (provided) => ({
            ...provided,
          }),
          menuList: (provided) => ({
            ...provided,
            minHeight: optionsLength >= 3 ? 168 : undefined,
            maxHeight: 248,
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9 }),
        }}
        className={cx('form-control-select', { [`form-control-${element.options.size}`]: element.options.size })}
        onChange={(e) => handlerChange(element, e)}
      />
      <ElementErrorMessage message={error} />
      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
    </>
  );
};
