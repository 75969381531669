import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { addUsers } from '../../services/user-group';
import { getUsersByCompany } from '../../services/user';
import { getCompanyPositions } from '../../services/company';
import { MultiSelect } from '../other/MultiSelect';

function UserGroupUserModal({ isOpen, dataAction, closeModal, userGroupId, handelAddUsers, companyId, facilityId }) {
  const [errorForm, setErrorForm] = useState({});
  const [usersOptions, setUsersOptions] = useState([]);
  const [positionsOptions, setPositionsOptions] = useState([]);
  // const [errorUser, setErrorUser] = useState({})

  const [form, setForm] = useState({
    users_ids: [],
    position_ids: [],
  });

  useEffect(() => {
    initModal();
    // eslint-disable-next-line
  }, [companyId, facilityId, dataAction]);

  useEffect(() => {
    initModal();
    // eslint-disable-next-line
  }, [form]);

  const initModal = () => {
    let positions_id = [];
    if (form.position_ids) {
      form.position_ids.forEach((item) => {
        positions_id.push(item.value);
      });
    }

    getUsersByCompany(companyId, 'getAll', facilityId, positions_id, 'active')
      .then((res) => {
        const newState = [...res];
        for (let user in dataAction) {
          if (newState.findIndex((item) => item.id === dataAction[user].id) !== -1) {
            newState.splice(
              newState.findIndex((item) => item.id === dataAction[user].id),
              1,
            );
          }
        }
        setUsersOptions(newState.map((item) => ({ value: item.id, label: `${item.first_name} ${item.last_name}` })));
      })
      .catch((e) => console.log(e));

    getCompanyPositions(companyId, 'getAll', facilityId)
      .then((res) => {
        setPositionsOptions(res.map((item) => ({ value: item.id, label: item.name })));
      })
      .catch((e) => console.log(e));
  };

  // useEffect(() => {
  //     if(dataAction) {
  //         setForm({
  //             users_ids: dataAction.map(item => ({value: item.id, label: `${item.first_name} ${item.last_name}`})),
  //             position_ids: []
  //         })
  //     }
  // }, [dataAction])

  const handleChange = (value, key) => {
    if (errorForm.hasOwnProperty(key)) {
      delete errorForm[key];
    }

    setForm({
      ...form,
      [key]: value,
    });
  };

  const resetForm = () => {
    setForm({
      users_ids: [],
      position_ids: [],
    });
  };

  const handelRemove = (item, key) => {
    let newState = [...form[key]];
    newState = newState.filter((newStateItem) => newStateItem.value !== item.value);
    setForm({
      ...form,
      [key]: newState,
    });
  };

  const handleSubmit = () => {
    const formDate = {
      user_ids: [...form.users_ids.map((item) => item.value), ...dataAction.map((item) => item.id)],
    };

    addUsers(formDate, userGroupId)
      .then((res) => {
        resetForm();
        handelAddUsers(res);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrorForm(JSON.parse(JSON.stringify(e.response.data)));
        }
      });
  };
  const handelClose = () => {
    resetForm();
    closeModal();
  };
  return (
    <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelClose} contentLabel="Example Modal" ariaHideApp={false}>
      <h2 className="modal-header">
        Add user to group{' '}
        <button className="btn btn-icon" onClick={handelClose}>
          <i className="icon-icon-x"></i>
        </button>
      </h2>

      <div className="form-wrapper">
        <h3 className="form-wrapper-title">
          <img src="/images/company-add-icon-3.svg" alt="" />
          User information
        </h3>
        <div className="form-control-item-settings form-item">
          <label className="form-label">Position</label>
          <div className="select-multy-wrapper">
            <span className="select-placeholder">Select Position</span>

            <MultiSelect
              isDisabled={!positionsOptions.length}
              options={positionsOptions}
              value={form.position_ids}
              onChange={(e) => handleChange(e, 'position_ids')}
              placeholder=""
              allName="All positions"
            ></MultiSelect>
            {form.position_ids.length > 0 && (
              <>
                <ul>
                  {form.position_ids.map((item, key) => (
                    <li key={key}>
                      {item.label}
                      <button className="btn btn-icon" onClick={() => handelRemove(item, 'position_ids')}>
                        <img src="/images/x-circle.svg" alt="" />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>

          <span className="form-error">{errorForm.position_ids && errorForm.position_ids[0]}</span>
        </div>
        <div className="form-control-item-settings form-item">
          <label className="form-label">User</label>
          <div className="select-multy-wrapper">
            <span className="select-placeholder">Select User</span>
            <MultiSelect
              isDisabled={!usersOptions.length}
              options={usersOptions}
              value={form.users_ids}
              onChange={(e) => handleChange(e, 'users_ids')}
              placeholder=""
              allName="All users"
            ></MultiSelect>
            {form.users_ids.length > 0 && (
              <>
                <ul>
                  {form.users_ids.map((item, key) => (
                    <li key={key}>
                      {item.label}
                      <button className="btn btn-icon" onClick={() => handelRemove(item, 'users_ids')}>
                        <img src="/images/x-circle.svg" alt="" />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>

          <span className="form-error">{errorForm.users_ids && errorForm.users_ids[0]}</span>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={handelClose}>
          Cancel
        </button>
        <button className="btn" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
}

export default UserGroupUserModal;
