// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-nurses-modal {
  width: 100%;
  padding: 32px 32px 0 !important;
}
.manage-nurses-modal-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: center;
}
.manage-nurses-modal .modal-body {
  padding: 18px;
  border-radius: 8px;
  border-left: 4px solid #f4ebff;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1019607843);
}
.manage-nurses-modal .modal-body .select-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.manage-nurses-modal .modal-body .form-control-select {
  margin-top: 8px;
}
.manage-nurses-modal .modal-body .form-control-select .react-select-multi__multi-value {
  display: none;
}
.manage-nurses-modal .modal-body .selected-nurses-list {
  display: grid;
  margin-top: 16px;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.manage-nurses-modal .modal-body .selected-nurses-item {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}
.manage-nurses-modal .modal-body .selected-nurses-item svg {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/cna/assignments/unit/components/manage-nurses-modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,+BAAA;AACF;AACE;EACE,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAEE;EACE,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,yBAAA;EACA,0DAAA;AAAJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAAN;AAGI;EACE,eAAA;AADN;AAGM;EACE,aAAA;AADR;AAMM;EACE,aAAA;EACA,gBAAA;EACA,SAAA;EACA,qCAAA;AAJR;AAOM;EACE,oBAAA;EACA,SAAA;EACA,mBAAA;AALR;AAOQ;EACE,eAAA;AALV","sourcesContent":[".manage-nurses-modal {\n  width: 100%;\n  padding: 32px 32px 0 !important;\n\n  &-overlay {\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: fixed;\n    display: flex;\n    padding-top: 24px;\n    padding-bottom: 24px;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .modal-body {\n    padding: 18px;\n    border-radius: 8px;\n    border-left: 4px solid #f4ebff;\n    background-color: #ffffff;\n    box-shadow: 0px 1px 3px 0px #1018281a;\n\n    .select-label {\n      font-size: 14px;\n      font-weight: 500;\n      line-height: 16px;\n    }\n\n    .form-control-select {\n      margin-top: 8px;\n\n      .react-select-multi__multi-value {\n        display: none;\n      }\n    }\n\n    .selected-nurses {\n      &-list {\n        display: grid;\n        margin-top: 16px;\n        gap: 10px;\n        grid-template-columns: repeat(3, 1fr);\n      }\n\n      &-item {\n        display: inline-flex;\n        gap: 10px;\n        align-items: center;\n\n        svg {\n          cursor: pointer;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
