// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-modal {
  width: 100%;
  max-width: 460px !important;
  min-height: 300px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff !important;
  z-index: 9;
}
.confirmation-modal-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  padding: 48px;
  align-items: center;
  justify-content: center;
}
.confirmation-modal .modal-header .title {
  display: flex;
  gap: 16px;
  align-items: center;
}
.confirmation-modal .modal-body .description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}`, "",{"version":3,"sources":["webpack://./src/components/init-form/components/delete-form-confirmation-modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,2BAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iCAAA;EACA,UAAA;AACF;AACE;EACE,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAGI;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AADN;AAMI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAJN","sourcesContent":[".confirmation-modal {\n  width: 100%;\n  max-width: 460px !important;\n  min-height: 300px;\n  border-radius: 8px;\n  overflow: hidden;\n  background-color: #fff !important;\n  z-index: 9;\n\n  &-overlay {\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: fixed;\n    display: flex;\n    padding: 48px;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .modal-header {\n    .title {\n      display: flex;\n      gap: 16px;\n      align-items: center;\n    }\n  }\n\n  .modal-body {\n    .description {\n      font-weight: 400;\n      font-size: 16px;\n      line-height: 24px;\n      color: #667085;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
