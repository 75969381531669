// dayjs formats
export const US_DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_FORMAT = US_DATE_FORMAT;
export const TIME_FORMAT = 'hh:mm A';
// react-datepicker format (exceptions)
export const DATE_PICKER_DATE_FORMAT = 'MM-dd-yyyy';
export const DATE_PICKER_TIME_FORMAT = 'hh:mm a';

export const SUPPORTED_WEB_VIDEO_FORMATS = ['mp4', 'webm', 'ogg'];
export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
