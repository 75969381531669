import React, { memo, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../constants';
import { usePermission } from '../../../../container/permissionContext';
import { activateUser, deactivateUser, getUserById } from '../../../../services/user';
import { useParams } from 'react-router-dom';
import UserModal from '../../../../components/company/user-modal';
import DeactivateModal from '../../../../components/company/deactivate-modal';
import { getFacilitiesByCompany } from '../../../../services/facility';
import PageTabs from '../../../../components/other/page-tabs';

const UserProfileHeader = ({ onSetUser, user }) => {
  const { isEmployee } = usePermission();
  const { user_id, id } = useParams();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [deactivateIsOpen, setIsOpenDeactivate] = useState(false);
  const [facilities, setFacilities] = useState([]);

  const tabs = useMemo(() => {
    let data = [];
    if (id && user_id) {
      data = [
        { url: `/company/${id}/user/${user_id}`, label: 'Main Information' },
        { url: `/company/${id}/user/${user_id}/forms`, label: 'Forms' },
        { url: `/company/${id}/users/${user_id}/related-files`, label: 'Related Files' },
      ];
    }
    return data;
  }, [id, user_id]);

  useEffect(() => {
    handleGetFacilitiesByCompany();
    // eslint-disable-next-line
  }, [user_id]);

  const handleGetFacilitiesByCompany = () => {
    let action = null;

    if (isEmployee()) {
      action = 'users_positions_management';
    }

    getFacilitiesByCompany(user?.company.id, 'getAll', action)
      .then((resFacilities) => {
        setFacilities(resFacilities);
      })
      .catch((e) => console.log(e));
  };

  const canEdit = () => {
    if (!isEmployee()) {
      return true;
    }
    return user?.role === 'Employee';
  };

  const openAddModal = () => {
    setIsOpen(true);
  };

  function closeAddModal() {
    setIsOpen(false);
  }

  const handelActivate = () => {
    activateUser(user_id)
      .then(handleGetFacilitiesByCompany)
      .catch((e) => console.log(e));
  };

  const handelEditUser = () => {
    getUserById(user_id)
      .then((res) => onSetUser(res))
      .catch((e) => console.log(e));
    setIsOpen(false);
  };

  const handleDeactivateIsOpen = () => {
    setIsOpenDeactivate(false);
  };

  const handelDeactivate = () => {
    deactivateUser(user_id)
      .then((res) => handleGetFacilitiesByCompany())
      .catch((e) => console.log(e));
    handleDeactivateIsOpen();
  };

  return (
    <>
      <div className="company-profile-header">
        <div className="user-profile-header">
          <div>
            <span className="date-add">
              Date Added: {dayjs(user?.created_at).format(DATE_FORMAT)}{' '}
              {user?.deleted_at && <span className="date-add-deactivated">Deactivated: {dayjs(user?.deleted_at).format(DATE_FORMAT)}</span>}
            </span>
            <h2 className="company-profile-title">
              {user?.last_name} {user?.first_name}
            </h2>
            <h3 className="company-profile-subtitle">{user?.role}</h3>
          </div>
        </div>
        <div className="company-profile-header-action">
          {!user?.deleted_at && canEdit() && (
            <>
              <button className="btn btn-outline" onClick={openAddModal}>
                <i className="icon-icon-edit mr-2"></i> Edit
              </button>
              <button className="btn btn-outline btn-danger" onClick={() => setIsOpenDeactivate(true)}>
                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_1495_6510)">
                    <path
                      d="M12.5001 7.50008L7.50008 12.5001M7.50008 7.50008L12.5001 12.5001M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z"
                      stroke="white"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1495_6510">
                      <rect width="20" height="20" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
                Deactivate
              </button>
            </>
          )}
          {user?.deleted_at && canEdit() && (
            <button className="btn btn-outline btn-success" onClick={handelActivate}>
              <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1484_3438)">
                  <path
                    d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                    stroke="#12B76A"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_1484_3438">
                    <rect width="20" height="20" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              Activate
            </button>
          )}
        </div>
      </div>

      <PageTabs tabs={tabs} />

      {modalIsOpen && (
        <UserModal
          isEditMode
          companyName={user?.company.name}
          userData={user}
          facilities={facilities}
          closeModal={closeAddModal}
          companyId={user?.company.id}
          isOpen={modalIsOpen}
          openModal={openAddModal}
          handelCreateUser={handelEditUser}
        />
      )}
      <DeactivateModal
        isOpen={deactivateIsOpen}
        closeModal={handleDeactivateIsOpen}
        handelSuccess={handelDeactivate}
        title="User Profile Deactivation"
        subtitle="Deactivation will temporarily hide this user from the system. You can reactivate it anytime in this user profile."
      />
    </>
  );
};

export default memo(UserProfileHeader);
