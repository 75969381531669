import cx from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import ContextMenu from '../../../../../../components/other/context-menu';
import { applyTemplateService, deleteTemplateService, getTemplatesService } from '../../../../../../services/cna/assignments/cna';
import { isResponseOk } from '../../../../../../utility/isResponseOk';

import './styles.scss';

const TemplatesListModal = ({ shiftID, onClose, setAddNewTemplateModalData, onRefetchAssignment, hasTemplateWritePermission }) => {
  const [templatesList, setTemplatesList] = useState([]);

  const fetchTemplates = useCallback(async () => {
    try {
      const res = await getTemplatesService({ shiftID });
      if (isResponseOk(res) && res.data) {
        setTemplatesList(res.data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [shiftID]);

  useEffect(() => {
    fetchTemplates();
  }, [shiftID]);

  const handleApplyTemplate = useCallback(
    async (templateID) => {
      try {
        const res = await applyTemplateService({ shiftID, templateID });
        if (isResponseOk(res)) {
          await onRefetchAssignment();
          onClose();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [shiftID, onRefetchAssignment, onClose],
  );

  const handleDeleteTemplateService = useCallback(
    async (templateID) => {
      try {
        const res = await deleteTemplateService({ shiftID, templateID });
        if (isResponseOk(res)) {
          fetchTemplates();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [shiftID, fetchTemplates],
  );

  const renderTemplateActionsMenu = (shiftID, template) => {
    const menu = [
      {
        label: 'Edit',
        icon: <i className="icon-icon-edit" />,
        action: ({ onCloseMenu }) => {
          onCloseMenu();
          setAddNewTemplateModalData({ shiftID, templateData: template });
          onClose();
        },
      },
      {
        label: 'Delete',
        color: '#B32318',
        icon: <i className="icon-remove" />,
        action: ({ onCloseMenu }) => {
          onCloseMenu();
          handleDeleteTemplateService(template.id);
        },
      },
    ];

    return <ContextMenu size={'small'} menu={menu} styles={{ background: 'transparent', marginTop: 4 }} btnClassName="action-button" />;
  };

  return (
    <Modal
      isOpen
      className={cx('modal-default templates-list-modal')}
      overlayClassName={'modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        Templates List
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <span className={'group__title'}>Clicking a template will overwrite all existing table data</span>

        <div className="templates-group">
          <div className="templates-group__header">
            <span className="templates-group__header__title">Templates</span>
          </div>
          <ul className="templates-group__list">
            {templatesList?.map((template) => (
              <li className="templates-group__item">
                <div className="templates-group__item__title">
                  <span>{template.name}</span>
                </div>
                <div className="templates-group__item__actions">
                  <button
                    className="btn apply-button"
                    onClick={() => {
                      handleApplyTemplate(template.id);
                    }}
                  >
                    Apply
                  </button>
                  {hasTemplateWritePermission && renderTemplateActionsMenu(shiftID, template)}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default memo(TemplatesListModal);
