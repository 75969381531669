export const formTypeOptions = [
  { value: 'SPECIAL', label: 'Special' },
  { value: 'PUBLIC', label: 'Public' },
  { value: 'MIXED', label: 'Mixed' },
];

export const formStatusOptions = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

export const notificationOptions = [
  {
    value: 'SMS',
    label: 'SMS',
  },
  {
    value: 'EMAIL',
    label: 'Email',
  },
  {
    value: 'USER_DEFAULT',
    label: 'By default (user`s original preference)',
  },
];
