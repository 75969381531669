// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table--new--row--button {
  top: 50%;
  left: 100%;
  width: 28px;
  height: 28px;
  display: inline-flex;
  padding: 0;
  color: #fff;
  border: none;
  font-size: 28px;
  font-weight: 400;
  position: absolute;
  align-items: center;
  justify-content: center;
  line-height: 1;
  transform: translate(0, -50%);
  border-radius: 50%;
  background-color: #7F56D9;
  cursor: pointer;
  z-index: 1;
}
.table--new--row--button.mobile {
  font-size: 26px;
}
.table--new--row--button.mobile span {
  margin-top: -5px;
}
.table--new--row--button .drop-button {
  fill: #007bff;
  transition: fill 0.3s ease;
}
.table--new--row--button .drop-button:hover {
  fill: #0056b3;
}
.table--new--row--button .plus-symbol {
  fill: #fff;
  font-size: 24px;
  font-family: Arial, sans-serif;
  text-anchor: middle;
  alignment-baseline: middle;
}
.table--new--row--button::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 12px solid #7F56D9;
}`, "",{"version":3,"sources":["webpack://./src/components/init-form/elements/table/components/NewRowButton/styles.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,6BAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,UAAA;AACF;AACE;EACE,eAAA;AACJ;AAAI;EACE,gBAAA;AAEN;AAEE;EACE,aAAA;EACA,0BAAA;AAAJ;AAEI;EACE,aAAA;AAAN;AAIE;EACE,UAAA;EACA,eAAA;EACA,8BAAA;EACA,mBAAA;EACA,0BAAA;AAFJ;AAKE;EACE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;EACA,2BAAA;EACA,QAAA;EACA,SAAA;EACA,kCAAA;EACA,qCAAA;EACA,gCAAA;AAHJ","sourcesContent":[".table--new--row--button {\n  top: 50%;\n  left: 100%;\n  width: 28px;\n  height: 28px;\n  display: inline-flex;\n  padding: 0;\n  color: #fff;\n  border: none;\n  font-size: 28px;\n  font-weight: 400;\n  position: absolute;\n  align-items: center;\n  justify-content: center;\n  line-height: 1;\n  transform: translate(0, -50%);\n  border-radius: 50%;\n  background-color: #7F56D9;\n  cursor: pointer;\n  z-index: 1;\n\n  &.mobile {\n    font-size: 26px;\n    span {\n      margin-top: -5px;\n    }\n  }\n\n  .drop-button {\n    fill: #007bff;\n    transition: fill 0.3s ease;\n\n    &:hover {\n      fill: #0056b3;\n    }\n  }\n\n  .plus-symbol {\n    fill: #fff;\n    font-size: 24px;\n    font-family: Arial, sans-serif;\n    text-anchor: middle;\n    alignment-baseline: middle;\n  }\n\n  &::before {\n    content: '';\n    position: absolute;\n    left: -8px;\n    top: 50%;\n    transform: translateY(-50%);\n    width: 0;\n    height: 0;\n    border-top: 10px solid transparent;\n    border-bottom: 10px solid transparent;\n    border-right: 12px solid #7F56D9;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
