import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useOutsideClick from '../../hook/useOutsideClick';
import { getState } from '../../utility';
import { usePermission } from '../../container/permissionContext';

function FacilityItem({ facility, handelEdit, handelCopy, handelDeactivate, handelDelete, handelActivated }) {
  const { id } = useParams();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();
  const { isSuperAdmin } = usePermission();
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);
  return (
    <div className={`company-profile-facilities-item ${facility.deleted_at ? 'company-profile-facilities-item-disabled' : ''}`}>
      <div className="d-flex justify-space-between flex-start">
        <div className={'cursor-pointer'} onClick={() => navigate(`/company/${id}/facility/${facility.id}`)}>
          <h4 className="title">{facility.name}</h4>
          {facility.deleted_at && <h5 className="subtitle-deactivated">deactivated</h5>}
          {!facility.deleted_at && <h5 className="subtitle">{facility.nickname}</h5>}
        </div>
        <div>
          <div className="dropdown" ref={ref}>
            <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn btn-outline">
              <img src="/images/more-vertical.svg" alt="" />
            </button>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                {!facility.deleted_at && (
                  <li>
                    <button className="btn btn-left btn-full btn-text" onClick={() => handelEdit(facility)}>
                      <i className="icon-icon-edit mr-2"></i>Edit Facility
                    </button>
                  </li>
                )}
                <li>
                  <button className="btn btn-left btn-full btn-text" onClick={() => handelCopy(facility)}>
                    <i className="icon-copy mr-2"></i>Copy Facility
                  </button>
                </li>
                {!facility.deleted_at && (
                  <li>
                    <button className="btn btn-left btn-full btn-danger btn-text" onClick={() => handelDeactivate(facility)}>
                      <i className="icon-icon-x mr-2"></i>Deactivate
                    </button>
                  </li>
                )}
                {facility.deleted_at && (
                  <li>
                    <button className="btn btn-left btn-full btn-success btn-text" onClick={() => handelActivated(facility)}>
                      <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1484_3438)">
                          <path
                            d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                            stroke="#12B76A"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1484_3438">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Activate
                    </button>
                  </li>
                )}
                {isSuperAdmin && (
                  <li>
                    <button className="btn btn-left btn-full btn-danger btn-text" onClick={() => handelDelete(facility)}>
                      <i className="icon-remove mr-2"></i>Delete
                    </button>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>

      <table className={'cursor-pointer'} onClick={() => navigate(`/company/${id}/facility/${facility.id}`)}>
        <tbody>
          <tr>
            <td>License Number:</td>
            <td>{facility.license_number}</td>
          </tr>
          <tr>
            <td>Monthly fee:</td>
            <td>{facility.monthly_fee}</td>
          </tr>
          <tr>
            <td>State:</td>
            <td>{getState(facility.state_of_location)?.label}</td>
          </tr>
          <tr>
            <td>Address:</td>
            <td>{facility.address}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default FacilityItem;
