import React, { useCallback, useState } from 'react';
import { getFileById, uploadFile } from '../../../services/upload';
import FileLoader from '../../other/FileLoader';
import { useIsRequiredElement } from '../../../hook/useIsRequiredElement';
import ElementErrorMessage from './components/element-error-message';
import { getFileExtFromFileName } from '../../../utility/files/getFileExtFromFileName';
import { isWebVideoByFileExt } from '../../../utility/files/isWebVideoByFileExt';
import FilePreviewModal from '../../library/file-preview-modal';
import { openPDF } from '../../../utility/files/openPDF';

export const FileUploadElement = function FromElement({ element, handlerChange, error, formHasOnlyOneSubmitter }) {
  const isRequiredElement = useIsRequiredElement(element, formHasOnlyOneSubmitter);

  const [loader, setLoader] = useState(false);
  // { uuid: string, url: string, name: string }
  const [previewFile, setPreviewFile] = useState(null);

  const handelFile = (event) => {
    setLoader(true);
    uploadFile(event.target.files[0])
      .then((res) => {
        handlerChange(element, res);
        setLoader(false);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoader(false);
      });
  };

  const downloadFile = ({ name, downloadUrl }) => {
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = name;
    a.rel = 'noreferrer';
    a.click();
  };

  const openFile = (id, name) => {
    setLoader(true);

    const fileExtension = getFileExtFromFileName(name);

    if (fileExtension) {
      getFileById(id)
        .then((fileByIdRes) => {
          if (fileByIdRes?.config === 'success' && fileByIdRes?.message) {
            const isWebVideo = isWebVideoByFileExt(fileExtension);
            const isPDF = fileExtension === 'pdf';
            const fileData = {
              ...(element.options.file ?? {}),
              url: fileByIdRes.message,
              downloadUrl: fileByIdRes.message,
            };

            if (isWebVideo) {
              setPreviewFile(fileData);
            } else if (isPDF) {
              fetch(fileData.downloadUrl)
                .then((res) => res.blob())
                .then(openPDF);
            } else {
              downloadFile(fileData);
            }
          }

          setLoader(false);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoader(false));
    }
  };

  const closeFilePreviewModal = useCallback(() => setPreviewFile(null), []);

  const renderFilePreviewModal = () => {
    return <FilePreviewModal previewFile={previewFile} onClose={closeFilePreviewModal} />;
  };

  return (
    <>
      <label htmlFor={element.id} className="form-label">
        {element.options.label} <span className="required">{isRequiredElement ? '*' : ''}</span>
      </label>
      {!element.options.file && (
        <div className={`form-control-file form-control-file-default mb-1 form-control-${element.options.size}`}>
          <input
            type="file"
            onChange={handelFile}
            accept={element.options.fileType?.map((item) => item.value)}
            name={element.options.name}
            id={element.id}
          />
          <div>
            <img className="icon-icon-upload" src="/images/upload-svg.svg" alt="" />
            <span>Click to upload</span>
            <span className="sub-text">{element.options.fileType?.map((item) => item.value)}</span>
          </div>
        </div>
      )}
      {!element.options.file && <p className="text-secondary">No files uploaded yet</p>}
      {element.options.file && (
        <ul>
          <li
            onClick={() => openFile(element.options.file.uuid, element.options.file.name)}
            key={element.options.file.uuid}
            className={'align-center cursor-pointer file-upload library-item'}
          >
            <div className="d-flex align-center library-item-text">
              <img src="/images/icon-file-list.svg" className={'mr-2'} alt="" />
              <p>{element.options.file.name}</p>
            </div>
            <button
              className="btn btn-icon mr-2"
              onClick={(e) => {
                e.stopPropagation();
                handlerChange(element, null);
              }}
            >
              <i className="icon-remove" />
            </button>
          </li>
        </ul>
      )}
      <ElementErrorMessage message={error} />

      {element.options.instructions && <p className="instructions">{element.options.instructions}</p>}
      {loader && <FileLoader />}

      {renderFilePreviewModal()}
    </>
  );
};
