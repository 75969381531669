// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.library-view-mode-switcher {
  height: 44px;
  display: inline-flex;
  align-items: stretch;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--Secondary-Input-Outline, #DADEE4);
}
.library-view-mode-switcher .button {
  height: 100%;
  min-width: 77px;
  display: inline-flex;
  gap: 6px;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #344054;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
.library-view-mode-switcher .button:nth-child(n+2) {
  border-left: 1px solid var(--Secondary-Input-Outline, #DADEE4);
}
.library-view-mode-switcher .button.active {
  color: var(--Primary-600, #7F56D9);
  background-color: var(--Primary-50, #F9F5FF);
}

.library-forms-available-to-me-list-header {
  padding: 13px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #555F72;
  border-bottom: 1px solid var(--Secondary-Input-Outline, #DADEE4);
}

.library-forms-available-to-me-item {
  width: 100%;
  display: flex;
  padding: 6px 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--Secondary-Input-Outline, #DADEE4);
}
.library-forms-available-to-me-item .main {
  width: calc(100% - 40px);
  display: flex;
  gap: 8px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/HOCs/withLibraryViewMode/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,oBAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,yDAAA;AACF;AACE;EACE,YAAA;EACA,eAAA;EACA,oBAAA;EACA,QAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;AACJ;AACI;EACE,8DAAA;AACN;AAEI;EACE,kCAAA;EACA,4CAAA;AAAN;;AAKA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,gEAAA;AAFF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,8BAAA;EACA,gEAAA;AADF;AAGE;EACE,wBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;AADJ","sourcesContent":[".library-view-mode-switcher {\n  height: 44px;\n  display: inline-flex;\n  align-items: stretch;\n  border-radius: 6px;\n  overflow: hidden;\n  border: 1px solid var(--Secondary-Input-Outline, #DADEE4);\n\n  .button {\n    height: 100%;\n    min-width: 77px;\n    display: inline-flex;\n    gap: 6px;\n    padding-left: 12px;\n    padding-right: 12px;\n    align-items: center;\n    justify-content: center;\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 20px;\n    color: #344054;\n    user-select: none;\n    cursor: pointer;\n\n    &:nth-child(n + 2) {\n      border-left: 1px solid var(--Secondary-Input-Outline, #DADEE4);\n    }\n\n    &.active {\n      color: var(--Primary-600, #7F56D9);\n      background-color: var(--Primary-50, #F9F5FF);\n    }\n  }\n}\n\n.library-forms-available-to-me-list-header {\n  padding: 13px 20px;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 18px;\n  color: #555F72;\n  border-bottom: 1px solid var(--Secondary-Input-Outline, #DADEE4);\n}\n.library-forms-available-to-me-item {\n  width: 100%;\n  display: flex;\n  padding: 6px 20px;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid var(--Secondary-Input-Outline, #DADEE4);\n\n  .main {\n    width: calc(100% - 40px);\n    display: flex;\n    gap: 8px;\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
