import React, { useLayoutEffect, useState } from 'react';
import Router from './router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './components/other/error-boundary';
import { isMobile, isTablet } from 'react-device-detect';

function App() {
  const [size, setSize] = useState('desktop');

  useLayoutEffect(() => {
    function updateSize() {
      if (isTablet) {
        setSize('tablet');
      } else if (isMobile) {
        setSize('mobile');
      }
    }

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <ErrorBoundary>
      <div className={size === 'tablet' ? 'mobile tablet' : size === 'mobile' ? 'mobile' : ''}>
        <Router />
        <ToastContainer hideProgressBar closeOnClick autoClose={3_000} position="top-right" theme="light" />
      </div>
    </ErrorBoundary>
  );
}

export default App;
