/**
 * Updates the date and/or time of a given Date object.
 *
 * @param {Date} sourceDateTime - The original Date object to be updated.
 * @param {Date} [date] - The new date to set. If provided, it must be a valid Date object.
 * @param {Date} [time] - The new time to set. If provided, it must be a valid Date object.
 * @returns {Date} - The updated Date object with the new date and/or time.
 * @throws {Error} - Throws an error if the provided date or time is invalid.
 */
export const updateDateTimeSetting = (sourceDateTime, date, time) => {
  let value;
  if (sourceDateTime) {
    value = new Date(sourceDateTime);
  } else {
    value = new Date();
  }

  if (date) {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      throw new Error(`updateDateTimeSetting error. Invalid date: ${date}`);
    }

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    value.setFullYear(year);
    value.setMonth(month);
    value.setDate(day);
  }
  if (time) {
    if (!(time instanceof Date) || isNaN(time.getTime())) {
      throw new Error(`updateDateTimeSetting error. Invalid time: ${time}`);
    }

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    const milliseconds = time.getMilliseconds();

    value.setHours(hours);
    value.setMinutes(minutes);
    value.setSeconds(seconds);
    value.setMilliseconds(milliseconds);
  }

  return value;
};
