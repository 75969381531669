import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getFacilitiesByCompany } from '../../services/facility';
import DeactivateModal from '../../components/company/deactivate-modal';
import { activateContacts, deactivateContacts, editContacts, getContactById } from '../../services/contacts';
import RepresentativyItem from '../../components/company/representativy-item';
import ResidentModal from '../../components/company/resident-modal';
import ResidentShortModal from '../../components/company/resident-short-modal';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';
import { utcToLocalDateFormatter } from '../../utility/dates/utcToLocalDate';

function ResidentsProfile() {
  const { resident_id, id } = useParams();
  const [user, setUser] = useState({});
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [facilities, setFacilities] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [deactivateIsOpen, setIsOpenDeactivate] = useState(false);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([
    {
      url: `/company/${id}`,
      name: 'Company',
    },
  ]);
  const navigation = useNavigate();
  const { isEmployee, hasPermission } = usePermission();
  useEffect(() => {
    if (isEmployee() && !hasPermission('Contacts management')) {
      redirectToLibrary(navigation);
    }
  }, [isEmployee, navigation, hasPermission]);

  useEffect(() => {
    initPage();
    // eslint-disable-next-line
  }, [resident_id]);
  const initPage = () => {
    getContactById(resident_id)
      .then((res) => {
        setUser(res);
        let breadcrumbs = [
          {
            url: `/company/${id}`,
            name: res.company?.name ? res.company.name : 'Company',
          },
          {
            url: `/company/${id}/residents`,
            name: `Residents list`,
          },
          {
            url: `/company/${id}/residents/${res.id}`,
            name: `${res.first_name} ${res.last_name}`,
          },
        ];
        setPageBreadcrumbs(breadcrumbs);
        let action = null;

        if (isEmployee()) {
          action = 'contacts_management';
        }
        getFacilitiesByCompany(id, 'getAll', action)
          .then((resFacilities) => {
            setFacilities(resFacilities);
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };
  const openAddModal = () => {
    setIsOpen(true);
  };

  function closeAddModal() {
    setIsOpen(false);
  }
  function closeEditModal() {
    setEditIsOpen(false);
    setCurrentItem(null);
  }
  function openEditModal(item) {
    setEditIsOpen(true);
    setCurrentItem(item);
  }

  const handelEditUser = () => {
    initPage();
    setIsOpen(false);
    closeEditModal();
  };

  const handelDeactivate = () => {
    deactivateContacts(resident_id)
      .then((res) => initPage())
      .catch((e) => console.log(e));
    handleDeactivateIsOpen();
  };

  const handelActivate = () => {
    activateContacts(resident_id)
      .then((res) => initPage())
      .catch((e) => console.log(e));
  };

  const handleDeactivateIsOpen = () => {
    setIsOpenDeactivate(false);
  };
  const handelEdit = (item) => {
    openEditModal(item);
  };
  const handelDelete = (item) => {
    const formDate = {
      representatives: user.representatives.filter((user) => user.id !== item.id),
    };
    editContacts(formDate, user.id)
      .then((res) => {
        initPage();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Layout breadcrumbs={pageBreadcrumbs} title={'Resident Profile'}>
      <div className={`page-wrapper ${user.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
        <div className="company-profile">
          {user.first_name && (
            <>
              <div className="company-profile-header">
                <div className="user-profile-header">
                  <div>
                    <span className="date-add">
                      Date Added: {utcToLocalDateFormatter(user.created_at)}{' '}
                      {user.deleted_at && (
                        <span className="date-add-deactivated">Deactivated: {utcToLocalDateFormatter(user.deleted_at)}</span>
                      )}
                    </span>
                    <h2 className="company-profile-title">
                      {user.last_name} {user.first_name}
                    </h2>
                    <h3 className="company-profile-subtitle">{user.facility?.name}</h3>
                  </div>
                </div>
                <div className="company-profile-header-action">
                  {!user.deleted_at && (
                    <>
                      <button className="btn btn-outline" onClick={openAddModal}>
                        <i className="icon-icon-edit mr-2"></i> Edit
                      </button>
                      <button className="btn btn-outline btn-danger" onClick={() => setIsOpenDeactivate(true)}>
                        <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <g clipPath="url(#clip0_1495_6510)">
                            <path
                              d="M12.5001 7.50008L7.50008 12.5001M7.50008 7.50008L12.5001 12.5001M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z"
                              stroke="white"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_1495_6510">
                              <rect width="20" height="20" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                        Deactivate
                      </button>
                    </>
                  )}
                  {user.deleted_at && (
                    <button className="btn btn-outline btn-success" onClick={handelActivate}>
                      <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1484_3438)">
                          <path
                            d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                            stroke="#12B76A"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_1484_3438">
                            <rect width="20" height="20" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      Activate
                    </button>
                  )}
                </div>
              </div>
              <div className="company-profile-tab user-profile-tab">
                <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
                  <TabList>
                    <Tab>Main Information</Tab>
                    <Tab disabled>Forms</Tab>
                  </TabList>

                  <TabPanel>
                    <h3 className="company-profile-title">
                      <img src="/images/company-add-icon-3.svg" className="mr-2" alt="" />
                      Personal Information
                    </h3>
                    <div className="user-profile-tab-content user-profile-tab-content-auto">
                      <div className="user-profile-tab-item user-profile-tab-item-auto">
                        <h4 className="title">Unit:</h4>
                        <p>
                          <strong>{user.unit?.name}</strong>
                        </p>
                      </div>
                      <div className="user-profile-tab-item user-profile-tab-item-auto">
                        <h4 className="title">Room Number:</h4>
                        <p>
                          <strong>{user.room_number}</strong>
                        </p>
                      </div>
                      <div className="user-profile-tab-item user-profile-tab-item-auto">
                        <h4 className="title">Email:</h4>
                        <p>
                          <strong>{user.email}</strong>
                        </p>
                      </div>
                      <div className="user-profile-tab-item user-profile-tab-item-auto">
                        <h4 className="title">Number:</h4>
                        <p>
                          <strong>{user.phone}</strong>
                        </p>
                      </div>
                    </div>

                    <h3 className="company-profile-title">
                      <img src="/images/company-add-icon-5.svg" className="mr-2" alt="" />
                      Representative Information
                    </h3>
                    <div className="company-profile-facilities-grid">
                      {user.representatives.map((item) => (
                        <RepresentativyItem key={item.id} facility={item} handelEdit={handelEdit} handelDeactivate={handelDelete} />
                      ))}
                      {!user.representatives.length && (
                        <div className="company-profile-empty">
                          <h3>No Representative Information listed yet</h3>
                          {!user.deleted_at && <p>Click the 'Edit' button in the top-right corner of the screen to add Representatives.</p>}
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <h3 className="company-profile-title">Forms</h3>
                  </TabPanel>
                </Tabs>
              </div>

              {modalIsOpen && (
                <ResidentModal
                  userData={user}
                  companyName={user.company}
                  facilities={facilities}
                  closeModal={closeAddModal}
                  companyId={id}
                  isOpen={modalIsOpen}
                  openModal={openAddModal}
                  handelCreateUser={handelEditUser}
                />
              )}
              {editIsOpen && (
                <ResidentShortModal
                  userData={currentItem}
                  companyName={`${user.first_name} ${user.last_name}`}
                  representatives={user.representatives}
                  closeModal={closeEditModal}
                  companyId={id}
                  isOpen={editIsOpen}
                  openModal={openEditModal}
                  handelCreateUser={handelEditUser}
                />
              )}
              <DeactivateModal
                isOpen={deactivateIsOpen}
                closeModal={handleDeactivateIsOpen}
                handelSuccess={handelDeactivate}
                title="Resident Profile Deactivation"
                subtitle="Deactivation will temporarily hide this resident from the system. You can reactivate it anytime in this resident profile."
              ></DeactivateModal>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default ResidentsProfile;
