import React from 'react';
import Layout from '../../layout/default';
import { Link } from 'react-router-dom';
function NotFound() {
  return (
    <Layout title="403 Forbidden">
      <div className={'forbidden-page'}>
        <div>
          <h1>404 Not Found</h1>
          <p>Oops, it seems the page you're looking for doesn't exist. Please check the URL or navigate back to our homepage.</p>
          <div className={'mt-4 text-center'}>
            <Link to={'/corporate-library'} className={'btn mr-auto ml-auto'}>
              Homepage
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotFound;
