import React, { useEffect, useState } from 'react';
import { CAN_INITIATOR_SELECT_ASSIGNED_FORMS_SUBFOLDER_KEY, SpecialSettings } from './special-settings';
import { getFacilitiesByCompanySettings } from '../../../services/facility';
import { getUsersListSettings } from '../../../services/user';
import { getCompanyUserGroupListSettings } from '../../../services/company';
import { useAuth } from '../../../container/authContext';
import { getUnitListSettings } from '../../../services/units';
import { getContactsListSettings } from '../../../services/contacts';
import { PublicSettings } from './public-settings';
import { MixedSettings } from './mixed-settings';
import { useNavigate, useParams } from 'react-router-dom';
import { updateForm } from '../../../services/form';
import { Tooltip } from 'react-tooltip';
import { INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY } from './components/initiator-can-set-frequency-setting';

export const SubmissionSettings = function SubmissionSettings(props) {
  const { formData, handelError, errors: componentsErrors } = props;
  const errors = componentsErrors?.submission_settings;
  const [filling_type, setFillingType] = useState(
    formData?.submission_settings?.filling_type ? formData.submission_settings.filling_type : null,
  );
  const [submission_settings, setSubmissionSettings] = useState(formData?.submission_settings ? formData.submission_settings : {});
  const [public_folder_id, setPublicFolderId] = useState(formData.hasOwnProperty('public_folder_id') ? formData.public_folder_id : null);
  const { user } = useAuth();
  const companyId = user.company?.id;
  const { id } = useParams();
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [usersGroupsOptions, setUsersGroupsOptions] = useState([]);
  const [individualUsersOptions, setIndividualUsersOptions] = useState([]);
  const [contactsOptions, setContactsOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    // set Submission Settings from server
    setFillingType(formData?.submission_settings?.filling_type ? formData.submission_settings.filling_type : null);
    setPublicFolderId(formData?.hasOwnProperty('public_folder_id') ? formData.public_folder_id : null);
    setSubmissionSettings(() => {
      const prevState = formData?.submission_settings ? JSON.parse(JSON.stringify(formData.submission_settings)) : {};
      if (!prevState?.hasOwnProperty('filling_type')) {
        return {};
      }

      if (formData?.public_folder_id) {
        prevState['public_folder_id'] = formData?.public_folder_id;
      }

      if (prevState.submitters) {
        if (prevState.submitters?.facilities?.length && !submission_settings?.submitters?.is_users_from_initiator_facility) {
          prevState.submitters.facilities = prevState.submitters.facilities.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }

        if (formData.submission_settings?.submitters?.individual_users?.length) {
          prevState.submitters.individual_users = formData.submission_settings.submitters.individual_users.map((item) => ({
            value: item.id,
            label: item.first_name + ' ' + item.last_name,
            entity_type: 'user',
          }));
        }

        if (formData.submission_settings?.submitters?.user_groups?.length) {
          prevState.submitters.user_groups = formData.submission_settings.submitters.user_groups.map((item) => ({
            value: item.id,
            label: item.name,
            entity_type: 'user_group',
          }));
        }

        if (formData.submission_settings?.submitters?.contacts?.length) {
          prevState.submitters.contacts = formData.submission_settings.submitters.contacts.map((item) => ({
            value: item.id,
            label: item.first_name + ' ' + item.last_name,
          }));
        }

        if (formData.submission_settings?.submitters?.units?.length) {
          prevState.submitters.units = formData.submission_settings.submitters.units.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        }
      }

      if (formData.submission_settings?.allowed_submissions_editors) {
        let allowedSubmitters = [];
        if (formData.submission_settings.allowed_submissions_editors.individual_users?.length) {
          allowedSubmitters = allowedSubmitters.concat(
            formData.submission_settings.allowed_submissions_editors.individual_users.map((user) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
              type: 'user',
            })),
          );
        }

        if (formData.submission_settings.allowed_submissions_editors.user_groups?.length) {
          allowedSubmitters = allowedSubmitters.concat(
            formData.submission_settings.allowed_submissions_editors.user_groups.map((group) => ({
              value: group.id,
              label: group.name,
              type: 'group',
            })),
          );
        }

        if (
          formData.submission_settings.allowed_submissions_editors.facilities?.length &&
          !formData.submission_settings.allowed_submissions_editors.individual_users?.length &&
          !formData.submission_settings.allowed_submissions_editors.user_groups?.length
        ) {
          allowedSubmitters = allowedSubmitters.concat(
            formData.submission_settings.allowed_submissions_editors.facilities.map((facility) => ({
              value: facility.id,
              label: facility.name,
              type: 'facility',
            })),
          );
        }

        prevState.allowed_submissions_editors = allowedSubmitters;
      }

      if (formData.submission_settings.submitters_order?.length) {
        prevState.submitters_order = formData.submission_settings.submitters_order.map((item) => ({
          value: item.submitter,
          label: getName(item.submitter, item.entity_type),
          entity_type: item.entity_type,
          public: item.public ? item.public : false,
          step: item.step,
          submitter: item.submitter,
          should_receive_notification: item.should_receive_notification,
          notification_type: item.notification_type,
          notification_text: item.notification_text,
        }));
      }
      return prevState;
    });
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    // setSubmissionSettings({})
    // setFacilityOptions([]);
    setUsersGroupsOptions([]);
    setIndividualUsersOptions([]);
    setContactsOptions([]);
    setUnitOptions([]);
    setSubmissionSettings(() => {
      const prevState = { ...submission_settings };
      prevState['submitters'] = { ...prevState['submitters'] };
      if (!prevState['submitters'].is_users_from_initiator_facility) {
        prevState['submitters']['facilities'] = [];
      }

      prevState['submitters']['user_groups'] = [];
      prevState['submitters']['individual_users'] = [];
      prevState['submitters']['contacts'] = [];
      prevState['submitters']['units'] = [];
      return prevState;
    });

    if (submission_settings?.submitters?.submitters_type === 'user') {
      if (!submission_settings?.submitters?.is_users_from_initiator_facility) {
        getFacilities();
      } else {
        getUsers(user.facility ? [user.facility.id] : [], true);
        getCompanyUserGroup(user.facility ? [user.facility.id] : [], true);
      }
    } else if (submission_settings?.submitters?.submitters_type === 'contact') {
      getFacilities();
    }

    if (filling_type === 'PUBLIC' || filling_type === 'MIXED') {
      getFacilities();
    }

    // eslint-disable-next-line
  }, [
    filling_type,
    submission_settings?.submitters?.submitters_type,
    submission_settings?.submitters?.is_users_from_initiator_facility,
    submission_settings.can_initiator_select_submitters,
    submission_settings.is_one_submitter,
  ]);

  useEffect(() => {
    if (submission_settings.submitters?.facilities?.length) {
      getCompanyUserGroup(
        submission_settings.submitters?.facilities ? [...submission_settings.submitters.facilities.map((item) => item.value)] : [],
      );
      if (submission_settings?.submitters?.submitters_type === 'contact') {
        getUnit();
      }
    } else {
      setIndividualUsersOptions([]);
      setUsersGroupsOptions([]);
      setContactsOptions([]);
      setUnitOptions([]);
      setSubmissionSettings(() => {
        const prevState = { ...submission_settings };
        prevState['submitters'] = { ...prevState['submitters'] };
        prevState['submitters']['user_groups'] = [];
        prevState['submitters']['individual_users'] = [];
        prevState['submitters']['units'] = [];
        prevState['submitters']['contacts'] = [];
        return prevState;
      });
    }

    // eslint-disable-next-line
  }, [submission_settings?.submitters?.facilities]);
  useEffect(() => {
    if (submission_settings.submitters?.facilities?.length) {
      getUsers(submission_settings.submitters?.facilities ? [...submission_settings.submitters.facilities.map((item) => item.value)] : []);
    } else if (submission_settings?.submitters?.is_users_from_initiator_facility) {
      getUsers(user.facility ? [user.facility.id] : []);
    }
    // eslint-disable-next-line
  }, [submission_settings?.submitters?.facilities, submission_settings?.submitters?.user_groups]);
  useEffect(() => {
    if (submission_settings?.submitters?.submitters_type === 'contact') {
      if (submission_settings.submitters?.facilities?.length) {
        getContacts();
      }
    }
    // eslint-disable-next-line
  }, [submission_settings?.submitters?.facilities, submission_settings?.submitters?.units]);

  // useEffect(() => {
  //     if(submission_settings?.submitters?.submitters_type === 'user') {
  //         initPage();
  //     }
  //
  //     if(filling_type === 'PUBLIC' || filling_type === 'MIXED') {
  //         getFacilities();
  //     }
  //
  //     if(submission_settings?.submitters?.submitters_type === 'contact') {
  //         initContactPage();
  //     }
  //
  //     console.log(submission_settings)
  //
  //     // eslint-disable-next-line
  // }, [filling_type]);

  const getName = (id, type) => {
    let currentUser = '';
    if (type === 'user') {
      // eslint-disable-next-line
      currentUser = formData.submission_settings?.submitters?.individual_users.find((item) => item.id == id);
      if (currentUser?.first_name && currentUser?.last_name) {
        return currentUser.first_name + ' ' + currentUser.last_name;
      } else if (currentUser?.name) {
        return currentUser.name;
      }

      return '';
    }
    if (type === 'user_group') {
      // eslint-disable-next-line
      currentUser = formData.submission_settings?.submitters?.user_groups.find((item) => item.id == id);
      if (currentUser?.name) {
        return currentUser.name;
      }
      return currentUser;
    }

    return currentUser;
  };

  // const initPublicPage = () => {
  //     getFacilitiesByCompanySettings(companyId, 'getAll').then(resFacilities => {
  //         setFacilityOptions(resFacilities.map(item => ({value: item.id, label: item.name })));
  //         if(submission_settings.submitters?.facilities?.length) {
  //             getUsersListSettings({
  //                 company_id: companyId,
  //                 status: 'active',
  //                 facilities_ids: submission_settings.submitters?.facilities ? [...submission_settings.submitters.facilities.map(item => item.value)] : [],
  //                 user_groups_ids: submission_settings.submitters?.user_groups ? [...submission_settings.submitters.user_groups.map(item => item.value)] : []
  //             }).then(resUsers => {
  //                 setIndividualUsersOptions(resUsers.map(item => ({value: item.id, label: item.last_name + ' ' + item.first_name, entity_type: 'user'})));
  //             }).catch(e => console.log(e));
  //             getCompanyUserGroupListSettings( {
  //                 company_id: companyId,
  //                 status: 'active',
  //                 facilities_ids: submission_settings.submitters?.facilities ? [...submission_settings.submitters.facilities.map(item => item.value)] : []
  //             }).then(res => {
  //                 setUsersGroupsOptions(res.map(item => ({value: item.id, label: item.name, entity_type: 'user_group' })));
  //             }).catch(e => console.log(e));
  //         }
  //     }).catch(e => console.log(e));
  // }

  const getFacilities = () => {
    if (!facilityOptions?.length) {
      getFacilitiesByCompanySettings(companyId, 'getAll')
        .then((resFacilities) => {
          setFacilityOptions(resFacilities.map((item) => ({ value: item.id, label: item.name })));
        })
        .catch((e) => console.log(e));
    }
  };

  const getUsers = (facilities_ids = [], preventUpdate = false) => {
    getUsersListSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: facilities_ids,
      user_groups_ids: submission_settings.submitters?.user_groups
        ? [...submission_settings.submitters.user_groups.map((item) => item.value)]
        : [],
    })
      .then((resUsers) => {
        const options = resUsers.map((item) => ({
          value: item.id,
          label: item.last_name + ' ' + item.first_name,
          entity_type: 'user',
        }));
        setIndividualUsersOptions(options);
        setSubmissionSettings((state) => {
          const prevState = { ...state };
          const newState = [];

          prevState['submitters'] = { ...prevState['submitters'] };
          prevState['submitters']['individual_users'].forEach((item) => {
            if (options.find((option) => option.value === item.value)) {
              newState.push(item);
            }
          });
          prevState['submitters']['individual_users'] = newState;
          return prevState;
        });
      })
      .catch((e) => console.log(e));
  };

  const getCompanyUserGroup = (facilities_ids = [], preventUpdate = false) => {
    getCompanyUserGroupListSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: facilities_ids,
    })
      .then((res) => {
        const options = res.map((item) => ({ value: item.id, label: item.name, entity_type: 'user_group' }));
        setUsersGroupsOptions(options);
        setSubmissionSettings(() => {
          const prevState = { ...submission_settings };
          const newState = [];

          prevState['submitters'] = { ...prevState['submitters'] };
          prevState['submitters']['user_groups'].forEach((item) => {
            if (options.find((option) => option.value === item.value)) {
              newState.push(item);
            }
          });
          prevState['submitters']['user_groups'] = newState;
          return prevState;
        });
      })
      .catch((e) => console.log(e));
  };

  // const initPage = () => {
  //     if(submission_settings.submitters?.is_users_from_initiator_facility === false || submission_settings.can_initiator_select_submitters === false) {
  //         getFacilities();
  //     } else {
  //         getUsers( user.facility ? [user.facility.id] : [])
  //         getCompanyUserGroup( user.facility ? [user.facility.id] : [])
  //     }
  //
  // }

  // const initContactPage = () => {
  //     getFacilitiesByCompanySettings(companyId, 'getAll').then(resFacilities => {
  //         setFacilityOptions(resFacilities.map(item => ({value: item.id, label: item.name })));
  //         if(submission_settings.submitters?.facilities?.length) {
  //             getContacts();
  //             getUnit();
  //         }
  //     }).catch(e => console.log(e));
  //
  // }

  const getContacts = () => {
    getContactsListSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: submission_settings.submitters?.facilities
        ? [...submission_settings.submitters?.facilities?.map((item) => item.value)]
        : [],
      units_ids: submission_settings.submitters?.units ? [...submission_settings.submitters?.units?.map((item) => item.value)] : [],
    })
      .then((resUsers) => {
        const options = resUsers.map((item) => ({ value: item.id, label: item.first_name + ' ' + item.last_name }));
        if (options) {
          setSubmissionSettings(() => {
            const prevState = { ...submission_settings };
            const newState = [];
            prevState['submitters'] = { ...prevState['submitters'] };
            prevState['submitters']['contacts'].forEach((item) => {
              if (options.find((option) => option.value === item.value)) {
                newState.push(item);
              }
            });
            prevState['submitters']['contacts'] = newState;
            return prevState;
          });
        }
        setContactsOptions(options);
      })
      .catch((e) => console.log(e));
  };

  const getUnit = () => {
    getUnitListSettings({
      company_id: companyId,
      status: 'active',
      facilities_ids: submission_settings.submitters?.facilities
        ? [...submission_settings.submitters?.facilities?.map((item) => item.value)]
        : [],
    })
      .then((res) => {
        const options = res.map((item) => ({ value: item.id, label: item.name }));
        setUnitOptions(options);
        if (options) {
          setSubmissionSettings(() => {
            const prevState = { ...submission_settings };
            const newState = [];
            prevState['submitters'] = { ...prevState['submitters'] };
            prevState['submitters']['units'].forEach((item) => {
              if (options.find((option) => option.value === item.value)) {
                newState.push(item);
              }
            });
            prevState['submitters']['units'] = newState;
            return prevState;
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const handelSettings = (value, key) => {
    setSubmissionSettings((prevState) => {
      const newState = { ...prevState };
      newState[key] = value;

      // Clear submitters
      if (key === 'can_initiator_select_submitters') {
        newState.submitters = {
          ...newState.submitters,
          facilities: [],
          user_groups: [],
          individual_users: [],
          is_users_from_initiator_facility: false,
        };
      }

      return newState;
    });
  };

  const addNewOrder = () => {
    setSubmissionSettings(() => {
      const prevState = { ...submission_settings };

      if (!prevState['submitters_order']) {
        prevState['submitters_order'] = [
          {
            submitter: null,
            step: 1,
            value: null,
            label: null,
            public: false,
          },
          {
            submitter: null,
            step: 2,
            value: null,
            label: null,
            public: false,
          },
        ];
      }

      prevState['submitters_order'] = [
        ...prevState['submitters_order'],
        {
          submitter: null,
          step: prevState['submitters_order'].length,
          value: null,
          label: null,
          public: false,
        },
      ];

      return prevState;
    });
  };

  const addNewUser = () => {
    const prevState = { ...submission_settings };
    if (!prevState.submitters['unregistered_submitters']?.length) {
      prevState.submitters['unregistered_submitters'] = [
        {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
        },
      ];
    }

    prevState.submitters['unregistered_submitters'].push({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    });

    setSubmissionSettings(prevState);
  };

  const removeUser = (element) => {
    const prevState = { ...submission_settings };

    prevState.submitters['unregistered_submitters'] = prevState.submitters['unregistered_submitters'].filter((item) => item !== element);

    setSubmissionSettings(prevState);
  };

  const handelUserSettings = (value, key, index) => {
    setSubmissionSettings(() => {
      const prevState = { ...submission_settings };

      if (!prevState.submitters['unregistered_submitters']) {
        prevState.submitters['unregistered_submitters'] = [
          {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
          },
        ];
      }

      prevState.submitters['unregistered_submitters'][index][key] = value;

      return prevState;
    });
  };

  const orderRemove = (element, index) => {
    setSubmissionSettings(() => {
      const prevState = { ...submission_settings };
      prevState['submitters_order'] = prevState['submitters_order'].filter((item) => item !== element);
      prevState['submitters_order'] = prevState['submitters_order'].map((item, index) => {
        item.step = index + 1;
        return item;
      });

      return prevState;
    });
  };

  const handelOrderSettings = (value, key, index) => {
    setSubmissionSettings(() => {
      const prevState = { ...submission_settings };
      if (!prevState[key]) {
        prevState[key] = [
          {
            submitter: null,
            step: 1,
            value: null,
            entity_type: null,
            public: false,
          },
          {
            submitter: null,
            step: 2,
            value: null,
            label: null,
            entity_type: null,
            public: false,
          },
        ];
      }

      prevState[key][index] = {
        ...(prevState[key][index] ?? {}),
        ...value,
      };
      return prevState;
    });
  };

  const handelSubmittersSettings = (value, key) => {
    setSubmissionSettings((prevState) => {
      const newState = { ...prevState };
      newState['submitters'][key] = value;
      return newState;
    });
  };

  const handelRemove = (key, value) => {
    setSubmissionSettings(() => {
      const prevState = { ...submission_settings };
      prevState['submitters'][key] = prevState['submitters'][key].filter((item) => item.value !== value.value);
      return prevState;
    });
  };

  const handleRemoveAllowedSubmissionsEditor = (value) => {
    setSubmissionSettings((state) => {
      const prevState = { ...state };

      prevState['allowed_submissions_editors'] = prevState['allowed_submissions_editors'].filter((item) => item.value !== value.value);

      return prevState;
    });
  };

  const getFormData = () => {
    const completeFormData = {};
    if (filling_type) {
      completeFormData['filling_type'] = filling_type;
    }

    if (filling_type === 'SPECIAL') {
      completeFormData['is_one_submitter'] = submission_settings.is_one_submitter;

      if (submission_settings.is_one_submitter === true) {
        completeFormData['has_save_button'] = submission_settings.has_save_button;
      }

      if (submission_settings.is_one_submitter === false) {
        completeFormData['can_initiator_select_submitters'] = submission_settings.can_initiator_select_submitters;
        completeFormData['can_initiator_specify_period_availability_and_frequency'] =
          submission_settings.can_initiator_specify_period_availability_and_frequency;

        if (submission_settings.can_initiator_select_submitters === true) {
          if (submission_settings.hasOwnProperty('submitters')) {
            completeFormData['submitters'] = {};
            if (submission_settings.submitters.hasOwnProperty('submitters_type')) {
              completeFormData['submitters']['submitters_type'] = submission_settings.submitters.submitters_type;

              if (submission_settings.submitters.submitters_type === 'user') {
                if (submission_settings.submitters.hasOwnProperty('is_users_from_initiator_facility')) {
                  completeFormData['submitters']['is_users_from_initiator_facility'] =
                    submission_settings.submitters.is_users_from_initiator_facility;

                  if (submission_settings.submitters.is_users_from_initiator_facility === false) {
                    if (submission_settings.submitters?.facilities?.length) {
                      completeFormData['submitters']['facilities'] = submission_settings.submitters.facilities.map((item) => item.value);
                    }

                    if (submission_settings.submitters?.individual_users?.length) {
                      completeFormData['submitters']['individual_users'] = submission_settings.submitters.individual_users.map(
                        (item) => item.value,
                      );
                    }

                    if (submission_settings.submitters?.user_groups?.length) {
                      completeFormData['submitters']['user_groups'] = submission_settings.submitters.user_groups.map((item) => item.value);
                    }
                  } else if (submission_settings.submitters.is_users_from_initiator_facility === true) {
                    if (submission_settings.submitters?.individual_users?.length) {
                      completeFormData['submitters']['individual_users'] = submission_settings.submitters.individual_users.map(
                        (item) => item.value,
                      );
                    }

                    if (submission_settings.submitters?.user_groups?.length) {
                      completeFormData['submitters']['user_groups'] = submission_settings.submitters.user_groups.map((item) => item.value);
                    }
                  }

                  if (submission_settings.hasOwnProperty('has_strict_order_of_submissions')) {
                    if (submission_settings.has_strict_order_of_submissions === true) {
                      completeFormData['has_strict_order_of_submissions'] = submission_settings.has_strict_order_of_submissions;
                      completeFormData['can_edit_previous_submission'] = submission_settings.can_edit_previous_submission;
                      completeFormData['has_save_button'] = submission_settings.has_save_button;
                    } else if (submission_settings.has_strict_order_of_submissions === false) {
                      completeFormData['has_save_button'] = submission_settings.has_save_button;
                      completeFormData['specify_submissions_number'] = submission_settings.specify_submissions_number;
                      completeFormData['only_one_submission'] = submission_settings.only_one_submission;
                      completeFormData['has_strict_order_of_submissions'] = submission_settings.has_strict_order_of_submissions;

                      if (submission_settings.only_one_submission === true) {
                        completeFormData['count_submission_to_complete'] = submission_settings.count_submission_to_complete;
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (submission_settings.can_initiator_select_submitters === false) {
          if (submission_settings.hasOwnProperty('submitters')) {
            completeFormData['submitters'] = {};
            if (submission_settings.submitters.hasOwnProperty('submitters_type')) {
              completeFormData['submitters']['submitters_type'] = submission_settings.submitters.submitters_type;

              if (submission_settings.submitters.submitters_type === 'user') {
                if (submission_settings.submitters?.facilities?.length) {
                  completeFormData['submitters']['facilities'] = submission_settings.submitters.facilities.map((item) => item.value);
                }

                if (submission_settings.submitters?.individual_users?.length) {
                  completeFormData['submitters']['individual_users'] = submission_settings.submitters.individual_users.map(
                    (item) => item.value,
                  );
                }

                if (submission_settings.submitters?.user_groups?.length) {
                  completeFormData['submitters']['user_groups'] = submission_settings.submitters.user_groups.map((item) => item.value);
                }

                if (submission_settings.hasOwnProperty('has_strict_order_of_submissions')) {
                  completeFormData['has_strict_order_of_submissions'] = submission_settings.has_strict_order_of_submissions;
                  if (submission_settings.has_strict_order_of_submissions === true) {
                    completeFormData['can_edit_previous_submission'] = submission_settings.can_edit_previous_submission;
                    completeFormData['has_save_button'] = submission_settings.has_save_button;

                    if (submission_settings.submitters_order?.length) {
                      completeFormData['submitters_order'] = submission_settings.submitters_order.map((item, index) => ({
                        ...item,
                        step: index,
                      }));
                    }
                  } else if (submission_settings.has_strict_order_of_submissions === false) {
                    completeFormData['has_save_button'] = submission_settings.has_save_button;
                    completeFormData['only_one_submission'] = submission_settings.only_one_submission;

                    if (submission_settings.only_one_submission === true) {
                      completeFormData['count_submission_to_complete'] = submission_settings.count_submission_to_complete;
                    }
                  }
                }
              }

              if (submission_settings.submitters?.submitters_type === 'contact') {
                if (submission_settings.submitters?.facilities?.length) {
                  completeFormData['submitters']['facilities'] = submission_settings.submitters.facilities.map((item) => item.value);
                }

                if (submission_settings.submitters?.contacts?.length) {
                  completeFormData['submitters']['contacts'] = submission_settings.submitters.contacts.map((item) => item.value);
                }

                if (submission_settings.submitters?.units?.length) {
                  completeFormData['submitters']['units'] = submission_settings.submitters.units.map((item) => item.value);
                }

                if (submission_settings.submitters?.contact_person_submitter_type) {
                  completeFormData['submitters']['contact_person_submitter_type'] =
                    submission_settings.submitters.contact_person_submitter_type;
                }
              }

              if (submission_settings.submitters?.submitters_type === 'unregistered') {
                completeFormData['submitters']['unregistered_submitters'] = submission_settings.submitters.unregistered_submitters;
              }
            }
          }
        }
      }

      if (submission_settings.include_in_statistics) {
        completeFormData['include_in_statistics'] = submission_settings.include_in_statistics;
      }

      if (submission_settings?.submitters?.submitters_type === 'user') {
        if (submission_settings?.[CAN_INITIATOR_SELECT_ASSIGNED_FORMS_SUBFOLDER_KEY]) {
          completeFormData[CAN_INITIATOR_SELECT_ASSIGNED_FORMS_SUBFOLDER_KEY] = true;
        }
      }
    }

    if (filling_type === 'PUBLIC') {
      completeFormData['is_one_submitter'] = submission_settings.is_one_submitter;

      if (submission_settings.is_one_submitter === false) {
        completeFormData['submitters'] = {};

        ['facilities', 'individual_users', 'user_groups'].forEach((type) => {
          if (submission_settings.submitters?.[type]?.length) {
            completeFormData['submitters'][type] = submission_settings.submitters[type].map((item) => item.value);
          }
        });

        completeFormData['can_edit_previous_submission'] = submission_settings.can_edit_previous_submission;

        if (submission_settings.can_edit_previous_submission) {
          const allowedEditors = {
            facilities: [],
            user_groups: [],
            individual_users: [],
          };

          submission_settings.allowed_submissions_editors?.forEach(({ type, value }) => {
            allowedEditors[type === 'user' ? 'individual_users' : type === 'group' ? 'user_groups' : 'facilities'].push(value);
          });

          completeFormData['allowed_submissions_editors'] = allowedEditors;
        }
      }

      if (submission_settings[INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY]) {
        completeFormData[INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY] = submission_settings[INITIATOR_CAN_SET_FREQUENCY_SETTING_KEY];
      }
    }

    if (filling_type === 'MIXED') {
      completeFormData['submitters'] = {};

      if (submission_settings.submitters?.facilities?.length) {
        completeFormData['submitters']['facilities'] = submission_settings.submitters.facilities.map((item) => item.value);
      }

      if (submission_settings.public_folder_id) {
        completeFormData['public_folder_id'] = submission_settings.public_folder_id;
      }

      if (submission_settings.submitters?.individual_users?.length) {
        completeFormData['submitters']['individual_users'] = submission_settings.submitters.individual_users.map((item) => item.value);
      }

      if (submission_settings.submitters?.user_groups?.length) {
        completeFormData['submitters']['user_groups'] = submission_settings.submitters.user_groups.map((item) => item.value);
      }

      if (submission_settings.submitters_order?.length) {
        completeFormData['submitters_order'] = submission_settings.submitters_order.map((item, index) => ({
          ...item,
          step: index,
        }));
      }

      completeFormData['can_edit_previous_submission'] = submission_settings.can_edit_previous_submission;
    }

    return completeFormData;
  };

  const onUpdate = () => {
    const formData = {
      submission_settings: getFormData(),
    };

    if (formData.submission_settings.public_folder_id) {
      formData['public_folder_id'] = formData.submission_settings.public_folder_id;
    }

    updateForm(id, formData)
      .then(() => {
        if (filling_type === 'SPECIAL' || filling_type === 'MIXED') {
          navigation(`/form-builder/${id}/settings/recipient`);
        } else {
          navigation(`/form-builder/${id}/place-in-form`);
        }
        handelError(null);
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.data?.submission_settings) {
          handelError(e.response.data);
        }
      });
  };

  return (
    <div className="form-builder-setting-tab-content">
      <div className="form-builder-recipient-settings">
        {formData?.name && (
          <div className="from-builder-title from-builder-title-setting">
            <h1>
              <img src="/images/file-big-icon.png" className="mr-2" alt="" />
              {formData.name}
            </h1>
          </div>
        )}
        <p className="info-text">
          <i className="icon-icon-info"></i> Submission Settings: Define users who will be submitting this Form; Define Form type and rules
          of submission.
        </p>

        <span className="form-error mb-2 d-flex">{errors?.non_field_errors && errors.non_field_errors[0]}</span>
        <div className="form-control-item-settings">
          <div className="form-settings-label">
            <label className="form-label form-label-big">Choose Form Type</label>
            {/*<span>Special forms have advanced settings. Public forms require saving in  the 'Public Forms' folder. Mixed forms don't have recipients' settings.</span>*/}
          </div>
          <div className="form-settings-inputs">
            <div className="d-flex">
              <div className={`form-control-radio`}>
                <input
                  name="choose-access-type"
                  type="radio"
                  onChange={() => setFillingType('SPECIAL')}
                  checked={filling_type === 'SPECIAL'}
                />
                <span>General </span>
                <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-special`}></i>
                <Tooltip opacity={1} id={`table-tooltip-special`} place="bottom">
                  All Forms that circulate between: managers, employees, different depanmenis, residents and families.<br></br>
                  Examples: Education records, request for maintenance or vacation requests, audits, records of meeting attendance...
                </Tooltip>
              </div>
              <div className={`form-control-radio`}>
                <input
                  name="choose-access-type"
                  type="radio"
                  onChange={() => setFillingType('PUBLIC')}
                  checked={filling_type === 'PUBLIC'}
                />
                <span>Public</span>
                <i className="icon-icon-info ml-2" data-tooltip-id={`table-tooltip-public`}></i>
                <Tooltip opacity={1} id={`table-tooltip-public`} place="bottom">
                  These Forms are always accessible for completion to specified groups of users.<br></br>
                  Examples: Temperature logs, Narcotic counts, Glucometer testing logs, cleaning logs...
                </Tooltip>
              </div>
              <div className={`form-control-radio`}>
                <input name="choose-access-type" type="radio" onChange={() => setFillingType('MIXED')} checked={filling_type === 'MIXED'} />
                <span>Mixed</span>
              </div>
            </div>
            <span className="form-error mb-2 d-flex">{errors?.filling_type && errors.filling_type[0]}</span>
          </div>
        </div>
        <div className="form-submission-type">
          {filling_type === 'SPECIAL' && (
            <SpecialSettings
              handelSettings={handelSettings}
              unitOptions={unitOptions}
              facilityOptions={facilityOptions}
              contactsOptions={contactsOptions}
              usersGroupsOptions={usersGroupsOptions}
              submission_settings={submission_settings}
              individualUsersOptions={individualUsersOptions}
              handelSubmittersSettings={handelSubmittersSettings}
              handelOrderSettings={handelOrderSettings}
              handelRemove={handelRemove}
              addNewOrder={addNewOrder}
              orderRemove={orderRemove}
              addNewUser={addNewUser}
              removeUser={removeUser}
              handelUserSettings={handelUserSettings}
              errors={errors}
            />
          )}
          {filling_type === 'PUBLIC' && (
            <PublicSettings
              handelSettings={handelSettings}
              facilityOptions={facilityOptions}
              usersGroupsOptions={usersGroupsOptions}
              submission_settings={submission_settings}
              individualUsersOptions={individualUsersOptions}
              handelSubmittersSettings={handelSubmittersSettings}
              handelRemove={handelRemove}
              handleRemoveAllowedSubmissionsEditor={handleRemoveAllowedSubmissionsEditor}
              errors={errors}
            />
          )}
          {filling_type === 'MIXED' && (
            <MixedSettings
              handelSettings={handelSettings}
              facilityOptions={facilityOptions}
              usersGroupsOptions={usersGroupsOptions}
              submission_settings={submission_settings}
              individualUsersOptions={individualUsersOptions}
              handelSubmittersSettings={handelSubmittersSettings}
              handelOrderSettings={handelOrderSettings}
              handelRemove={handelRemove}
              addNewOrder={addNewOrder}
              orderRemove={orderRemove}
              errors={errors}
              public_folder_id={public_folder_id}
            />
          )}
        </div>
      </div>

      <div className="form-control-actions">
        <button className="btn btn-outline" onClick={() => navigation(`/form-builder/${id}/settings`)}>
          Back to Access Settings
        </button>
        <button className="btn" onClick={onUpdate}>
          Save Changes
        </button>
      </div>
    </div>
  );
};
