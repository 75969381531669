// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-management-settings-modal {
  width: 100%;
  max-width: 1170px !important;
  height: calc(100vh - 48px);
  padding: 48px;
  background-color: #fff !important;
  z-index: 9;
}
.form-management-settings-modal-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  padding-top: 24px;
  padding-bottom: 24px;
}
.form-management-settings-modal .modal-body {
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  overflow-y: auto;
}
.form-management-settings-modal .modal-body .settings-block:nth-child(n+2) {
  margin-top: 16px;
}
.form-management-settings-modal .modal-body .settings-block .settings-block-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 38px;
}
.form-management-settings-modal .modal-body .settings-block .settings-block-title + .option,
.form-management-settings-modal .modal-body .settings-block .settings-block-title + .table-container {
  margin-top: 16px;
}
.form-management-settings-modal .modal-body .settings-block .option {
  display: flex;
  margin-top: 8px;
  gap: 3px;
  align-items: center;
  color: var(--secondary-text, #667085);
}
.form-management-settings-modal .modal-body .settings-block .option-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.form-management-settings-modal .modal-body .settings-block .option-value {
  max-width: 500px;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/library/form-management-modal/components/form-settings-modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,4BAAA;EACA,0BAAA;EACA,aAAA;EACA,iCAAA;EACA,UAAA;AACF;AACE;EACE,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;EACA,iBAAA;EACA,oBAAA;AACJ;AAEE;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAGM;EACE,gBAAA;AADR;AAIM;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAFR;AAKM;;EAEE,gBAAA;AAHR;AAMM;EACE,aAAA;EACA,eAAA;EACA,QAAA;EACA,mBAAA;EACA,qCAAA;AAJR;AAMQ;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAJV;AAOQ;EACE,gBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AALV","sourcesContent":[".form-management-settings-modal {\n  width: 100%;\n  max-width: 1170px !important;\n  height: calc(100vh - 48px);\n  padding: 48px;\n  background-color: #fff !important;\n  z-index: 9;\n\n  &-overlay {\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: fixed;\n    padding-top: 24px;\n    padding-bottom: 24px;\n  }\n\n  .modal-body {\n    height: 100%;\n    padding-left: 24px;\n    padding-right: 24px;\n    overflow-y: auto;\n\n    .settings-block {\n      &:nth-child(n + 2) {\n        margin-top: 16px;\n      }\n\n      .settings-block-title {\n        font-size: 25px;\n        font-weight: 700;\n        line-height: 38px;\n      }\n\n      .settings-block-title + .option,\n      .settings-block-title + .table-container {\n        margin-top: 16px;\n      }\n\n      .option {\n        display: flex;\n        margin-top: 8px;\n        gap: 3px;\n        align-items: center;\n        color: var(--secondary-text, #667085);\n\n        &-label {\n          font-size: 16px;\n          font-weight: 700;\n          line-height: 24px;\n        }\n\n        &-value {\n          max-width: 500px;\n          display: inline-block;\n          font-size: 16px;\n          font-weight: 400;\n          line-height: 24px;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
