// One time initiation settings
export const ONE_TIME_INITIATION_OR_ARCHIVING_KEY = 'one_time_initiation_or_archiving';
export const INITIATE_FORM_IMMEDIATELY_KEY = 'immediately_initiation';
export const AUTOMATICALLY_ARCHIVE_FORM_KEY = 'automatically_archive_form';
export const ARCHIVE_DATE_KEY = 'archive_date';
export const ARCHIVE_TIME_KEY = 'archive_time';
export const ARCHIVE_DATE_TIME_KEY = 'archive_datetime';
export const ONE_TIME_INITIATION_START_DATE_KEY = 'one_time_initiation_start_date';
export const ONE_TIME_INITIATION_START_TIME_KEY = 'one_time_initiation_start_time';
// Recurrent initiation settings
export const RECURRENT_INITIATION_OR_ARCHIVING_KEY = 'recurrent_initiation_or_archiving';
export const RECURRENT_INITIATION_START_DAY_KEY = 'first_initiation_day';
export const RECURRENT_INITIATION_END_DAY_KEY = 'last_initiation_day';
export const RECURRENT_INITIATION_TIME_KEY = 'recurrent_initiation_start_time';
export const RECURRENT_INITIATION_REPEAT_EVERY_KEY = 'recurrent_initiation_repeat_every';
export const RECURRENT_INITIATION_REPEAT_PERIOD_KEY = 'recurrent_initiation_repeat_period';
export const RECURRENT_INITIATION_REPEAT_DAYS_KEY = 'recurrent_initiation_repeat_days';
export const RECURRENT_INITIATION_REPEAT_MONTH_DAYS_KEY = 'recurrent_initiation_repeat_month_days';
export const RECURRENT_INITIATION_REPEAT_YEAR_MONTHS_KEY = 'recurrent_initiation_repeat_year_months';
export const ARCHIVE_WITH_NEXT_INITIATION_KEY = 'archive_with_next_initiation';
// Number of hours/days
export const ARCHIVE_TIME_OFFSET_KEY = 'archive_time_offset';
// Hours/Days
export const ARCHIVE_TIME_OFFSET_UNIT_KEY = 'archive_time_offset_unit';
export const ARCHIVE_RELATIVE_TO_INITIATION_KEY = 'archive_relative_to_initiation';

const generateRecurrentRepeatEveryOptions = () => {
  const options = [];
  for (let i = 1; i <= 12; i++) {
    options.push({ value: i, label: i === 1 ? 'every' : `every ${i}` });
  }
  return options;
};

export const recurrentRepeatEveryOptions = generateRecurrentRepeatEveryOptions();

export const recurrentRepeatPeriodsOptions = [
  { value: 'daily', label: 'Days' },
  { value: 'weekly', label: 'Weeks' },
  { value: 'monthly', label: 'Months' },
  { value: 'yearly', label: 'Years' },
];

export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const archiveTimeOffsetUnitsOptions = [
  { value: 'HOURS', label: 'Hours' },
  { value: 'DAYS', label: 'Days' },
];

export const archiveRelativeToInitiationOptions = [
  { value: 'BEFORE', label: 'Before' },
  { value: 'AFTER', label: 'After' },
];
