import React, { useState, useEffect } from 'react';
import Layout from '../../../layout/default';
import { Breadcrumbs } from '../../../components/library/Breadcrumbs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getSubmissions } from '../../../services/folder/form-avaliable';
import { useAuth } from '../../../container/authContext';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Select from 'react-select';
import { SubmissionItem } from '../../../components/library/submission-item';
import { useDebounce } from 'use-debounce';
import { isResponseOk } from '../../../utility/isResponseOk';
import { FolderItem } from '../../../components/library/folders/folder';
import { submitterTypesOptions } from '../../../constants/from-builder/options';
import { formTypeOptions } from '../../../constants/forms/options';
import { get } from 'lodash';
import withLibraryViewMode from '../../../HOCs/withLibraryViewMode';
import PageLoader from '../../../components/other/PageLoader';
import cx from 'classnames';
import { useIsMobile } from '../../../hook/useIsMobile';

const initialFiltersState = {
  status: {
    value: 'active',
    label: 'Active',
  },
  search: '',
  form_type: null,
  submitter_type: null,
};

const getBreadcrumbs = (breadcrumbs) => {
  const list = [];
  getProp(breadcrumbs);

  function getProp(o) {
    list.push(o);
    if (o.children) {
      getProp(o.children);
    }
  }

  // Fix submissions page breadcrumb
  if (list[0]?.name) {
    list[0].name = 'Submissions';
    list[0].url = '/corporate-library/forms/submissions';
  }

  return list;
};

function CorporateLibrarySubmissionsPage({ ViewModeSwitcher, libraryViewMode }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useIsMobile();

  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      name: 'Company Library',
      url: '/corporate-library',
    },
    {
      name: 'Submissions',
      url: '/corporate-library/forms/submissions',
    },
  ]);
  const [isFilterMode, setIsFilterMode] = useState(false);
  const [filters, setFilters] = useState(initialFiltersState);
  const [folders, setFolders] = useState([]);
  const [forms, setForms] = useState([]);
  const [formsWithoutFolders, setFormsWithoutFolders] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isExistForms = !!forms?.length;
  const isExistLibraryItems = Boolean(isExistForms || folders?.length);
  const isListMode = libraryViewMode === 'LIST';
  const { submitter_type, status, form_type } = filters;

  const [debouncedFilters] = useDebounce(filters, 300);

  useEffect(() => {
    handleGetSubmissions();
    // There should be all no-debounced filters for instant filtering & debounced search
    // eslint-disable-next-line
  }, [user, submitter_type, status, form_type, debouncedFilters.search, isListMode, formsWithoutFolders]);

  useEffect(() => {
    let folder_id = null;
    if (searchParams.has('folder_id')) {
      folder_id = searchParams.get('folder_id');

      searchParams.delete('folder_id');

      setSearchParams(searchParams);

      changeFolder(folder_id);
    }
    // eslint-disable-next-line
  }, [user]);

  const goToPrev = (id, type) => {
    if (type === 'root') {
      navigate(id, { replace: true });
    }
    if (type === 'folder') {
      changeFolder(id);
    }
  };

  const clearFilters = () => setFilters(initialFiltersState);

  const handleFilter = (value, key) => {
    setFilters(() => {
      const prevState = { ...filters };
      prevState[key] = value;
      return prevState;
    });
  };

  const changeFolder = (folderId) => handleGetSubmissions(folderId);

  const handleGetSubmissions = (folderId) => {
    setIsLoading(true);

    const bodyRequest = { get_all: true, company_id: user.company.id, folder_id: folderId };
    let searchParam = debouncedFilters.search;

    if (formsWithoutFolders && !debouncedFilters?.search?.length) {
      searchParam = ' ';
    }

    bodyRequest.search = searchParam;

    if (filters.status) {
      bodyRequest['status'] = filters.status.value;
    }
    if (filters.form_type) {
      bodyRequest['form_type'] = filters.form_type.value;
    }
    if (filters.submitter_type) {
      bodyRequest['submitter_type'] = filters.submitter_type.value;
    }

    getSubmissions(bodyRequest)
      .then((res) => {
        if (isResponseOk(res)) {
          if (debouncedFilters.search || formsWithoutFolders) {
            setFolders([]);
            setForms(res.data);
          } else {
            const { breadcrumbs, data } = res.data;

            setBreadcrumbs([
              {
                name: 'Company Library',
                url: '/corporate-library',
              },
              ...getBreadcrumbs(breadcrumbs),
            ]);

            setFolders(data.filter((item) => item.type === 'folder'));
            setForms(data.filter((item) => item.type === 'form'));
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  };

  const renderPageTitle = () => {
    const prevBreadcrumb = get(breadcrumbs, `[${breadcrumbs.length - 2}]`);
    let layout;

    if (prevBreadcrumb?.id) {
      layout = (
        <svg
          className={'mr-2 cursor-pointer'}
          onClick={() => goToPrev(prevBreadcrumb.id, 'folder')}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
            stroke="#101828"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    } else {
      layout = (
        <Link to={'/corporate-library/forms/'} className="mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
              stroke="#101828"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      );
    }

    return (
      <>
        <h2 className="library-title">
          {layout}
          Submissions
        </h2>
        <h2 className={'library-title-mobile'} style={{ marginTop: 24 }}>
          <svg
            className={'mr-2 cursor-pointer'}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            onClick={() => navigate('/corporate-library/forms/')}
          >
            <path
              d="M15.8337 10.0013H4.16699M4.16699 10.0013L10.0003 15.8346M4.16699 10.0013L10.0003 4.16797"
              stroke="#101828"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {breadcrumbs[breadcrumbs.length - 1] ? breadcrumbs[breadcrumbs.length - 1].name : ''}
        </h2>
      </>
    );
  };

  const renderFilters = () => {
    let layout = null;
    if (isFilterMode) {
      layout = (
        <div className="library-filters">
          <div className="library-filters-wrapper">
            <div className="form-item">
              <label className="form-label">Form type</label>
              <Select
                options={formTypeOptions}
                value={filters.form_type}
                className="form-control-select"
                classNamePrefix="react-select"
                onChange={(e) => handleFilter(e, 'form_type')}
              />
            </div>
            <div className="form-item">
              <label className="form-label">Status</label>
              <Select
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'active',
                    label: 'Active',
                  },
                  {
                    value: 'archived',
                    label: 'Archived',
                  },
                ]}
                value={filters.status}
                className="form-control-select"
                classNamePrefix="react-select"
                onChange={(e) => handleFilter(e, 'status')}
              />
            </div>
            <div className="form-item">
              <label className="form-label">Submitter Type</label>
              <Select
                options={submitterTypesOptions}
                value={filters.submitter_type}
                className="form-control-select"
                classNamePrefix="react-select"
                onChange={(e) => handleFilter(e, 'submitter_type')}
              />
            </div>
          </div>
        </div>
      );
    }
    return layout;
  };

  const renderFormsAndFolders = () => {
    let layout;
    if (isLoading) {
      layout = <PageLoader />;
    } else {
      layout = (
        <>
          {isExistLibraryItems && isListMode && !formsWithoutFolders && (
            <div className="library-forms-available-to-me-list-header">Name</div>
          )}
          {isExistForms && formsWithoutFolders && (
            <h3 className="library-subtitle mt-2">
              Forms <span className={'total'}>(Total: {forms.length} forms)</span>
            </h3>
          )}

          {!!folders?.length && (
            <>
              {!formsWithoutFolders && !isListMode && <h3 className="library-subtitle">Folders</h3>}
              <div className="library-wrapper" style={{ rowGap: isListMode ? 0 : undefined }}>
                {folders.map((item) => (
                  <FolderItem isListItem={isListMode} key={item.id} item={item} changeFolder={changeFolder} />
                ))}
              </div>
            </>
          )}

          {isExistForms && (
            <>
              {!formsWithoutFolders && !isListMode && <h3 className="library-subtitle mt-2">Forms</h3>}
              <div className="library-wrapper forms" style={{ rowGap: isListMode ? 0 : undefined }}>
                {forms.map((item) => (
                  <SubmissionItem
                    isListItem={isListMode}
                    key={item.id}
                    url={`/corporate-library/forms/submissions/${item.id}`}
                    item={item}
                    wrapperClassnames={cx({ 'list-item': isListMode })}
                  />
                ))}
              </div>
            </>
          )}

          {!forms?.length && !!debouncedFilters.search && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">Unfortunately, nothing was found for your query.</h3>
                <h4 className="empty-page-subtitle">
                  Try to change the range of keywords. We're here to help you find what you're looking for.
                </h4>
              </div>
            </>
          )}
          {!forms?.length && !debouncedFilters.search && (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">This folder is empty</h3>
                <h4 className="empty-page-subtitle">This folder currently does not contain any forms. </h4>
              </div>
            </>
          )}
        </>
      );
    }

    return layout;
  };

  return (
    <Layout title="Company Library">
      <div className="library">
        <DndProvider backend={HTML5Backend}>
          <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
        </DndProvider>
        {renderPageTitle()}

        <div className="library-filter">
          <div className="library-filter-search form-control-item">
            <input
              value={filters.search}
              onChange={(e) => {
                handleFilter(e.target.value, 'search');
              }}
              type="text"
              className="form-control"
              placeholder="Search"
            />
            <i className="icon-search"></i>
          </div>

          {!isMobile && (
            <>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <button
                  className={`btn-outline btn ${isFilterMode ? 'btn-active' : ''}`}
                  style={{ height: 44, width: 44 }}
                  onClick={() => setIsFilterMode(!isFilterMode)}
                >
                  <i className="icon-icon-sliders"></i>
                </button>

                <button
                  className={`btn-outline btn`}
                  style={{ height: 44, width: 44 }}
                  onClick={() => setFormsWithoutFolders((prevState) => !prevState)}
                >
                  {!formsWithoutFolders ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M2.50033 4.16797C2.04009 4.16797 1.66699 4.54106 1.66699 5.0013C1.66699 5.46154 2.04009 5.83464 2.50033 5.83464H2.50866C2.9689 5.83464 3.34199 5.46154 3.34199 5.0013C3.34199 4.54106 2.9689 4.16797 2.50866 4.16797H2.50033Z"
                        fill="#101828"
                      />
                      <path
                        d="M6.66699 4.16797C6.20676 4.16797 5.83366 4.54106 5.83366 5.0013C5.83366 5.46154 6.20676 5.83464 6.66699 5.83464H17.5003C17.9606 5.83464 18.3337 5.46154 18.3337 5.0013C18.3337 4.54106 17.9606 4.16797 17.5003 4.16797H6.66699Z"
                        fill="#101828"
                      />
                      <path
                        d="M6.66699 9.16797C6.20676 9.16797 5.83366 9.54106 5.83366 10.0013C5.83366 10.4615 6.20676 10.8346 6.66699 10.8346H17.5003C17.9606 10.8346 18.3337 10.4615 18.3337 10.0013C18.3337 9.54106 17.9606 9.16797 17.5003 9.16797H6.66699Z"
                        fill="#101828"
                      />
                      <path
                        d="M5.83366 15.0013C5.83366 14.5411 6.20676 14.168 6.66699 14.168H17.5003C17.9606 14.168 18.3337 14.5411 18.3337 15.0013C18.3337 15.4615 17.9606 15.8346 17.5003 15.8346H6.66699C6.20676 15.8346 5.83366 15.4615 5.83366 15.0013Z"
                        fill="#101828"
                      />
                      <path
                        d="M1.66699 10.0013C1.66699 9.54106 2.04009 9.16797 2.50033 9.16797H2.50866C2.9689 9.16797 3.34199 9.54106 3.34199 10.0013C3.34199 10.4615 2.9689 10.8346 2.50866 10.8346H2.50033C2.04009 10.8346 1.66699 10.4615 1.66699 10.0013Z"
                        fill="#101828"
                      />
                      <path
                        d="M2.50033 14.168C2.04009 14.168 1.66699 14.5411 1.66699 15.0013C1.66699 15.4615 2.04009 15.8346 2.50033 15.8346H2.50866C2.9689 15.8346 3.34199 15.4615 3.34199 15.0013C3.34199 14.5411 2.9689 14.168 2.50866 14.168H2.50033Z"
                        fill="#101828"
                      />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M8.44444 3H3V8.44444H8.44444V3Z"
                        stroke="#101828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 3H11.5556V8.44444H17V3Z"
                        stroke="#101828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 11.5556H11.5556V17H17V11.5556Z"
                        stroke="#101828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.44444 11.5556H3V17H8.44444V11.5556Z"
                        stroke="#101828"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>

                <ViewModeSwitcher styles={{ marginLeft: 16 }} />

                <button className="btn btn-outline" style={{ height: 44 }} onClick={clearFilters}>
                  Clear All
                </button>
              </div>
            </>
          )}
        </div>

        {renderFilters()}

        {renderFormsAndFolders()}
      </div>

      {isLoading && <PageLoader />}
    </Layout>
  );
}

export default withLibraryViewMode(CorporateLibrarySubmissionsPage);
