import React, { useEffect, useState } from 'react';

const InitiatorCanEditSetting = ({ element, onChange }) => {
  const [initiatorCanEdit, setInitiatorCanEdit] = useState(element.options.initiator_can_edit);

  useEffect(() => {
    setInitiatorCanEdit(element.options.initiator_can_edit);
  }, [element]);

  const handleChange = () => {
    setInitiatorCanEdit((prevState) => !prevState);
    onChange(!initiatorCanEdit, 'initiator_can_edit', element.id);
  };

  return (
    <div className="property-item">
      <h3 className="form-builder-aside-property-sub-title">Initiator Can Edit</h3>
      <div className="form-switcher">
        <input type="checkbox" checked={Boolean(initiatorCanEdit)} onChange={handleChange} />
        <span>{initiatorCanEdit ? 'ON' : 'OFF'}</span>
      </div>
    </div>
  );
};

export default InitiatorCanEditSetting;
