import React, { memo } from 'react';
import { getFileExtFromFileName } from '../../../utility/files/getFileExtFromFileName';
import { isVideoByFileExt } from '../../../utility/files/isVideoByFileExt';
import Modal from 'react-modal';

const FilePreviewModal = ({ previewFile, onClose }) => {
  let fileLayout = null;
  if (previewFile) {
    const { name, url } = previewFile;
    const fileExt = getFileExtFromFileName(name);
    const isVideo = isVideoByFileExt(fileExt);
    if (isVideo) {
      fileLayout = (
        <>
          <div className="modal-body">
            <video width={'100%'} height="300" controls autoPlay={false} preload={'auto'} loop={false}>
              <source src={url} type="video/mp4" />
              Your browser does not support the video.
            </video>
          </div>
          <div className="modal-footer">
            <button className="btn btn-outline" onClick={onClose}>
              Close
            </button>
            <a href={url} download={name} className={'btn'} rel="noreferrer">
              Download
            </a>
          </div>
        </>
      );
    }
  }
  return (
    <Modal className="modal-default" isOpen={!!previewFile} onRequestClose={onClose} ariaHideApp={false}>
      {previewFile?.name && (
        <h2 className="modal-header">
          {previewFile.name}
          <button className="btn btn-icon" onClick={onClose}>
            <i className="icon-icon-x" />
          </button>
        </h2>
      )}
      {fileLayout}
    </Modal>
  );
};

export default memo(FilePreviewModal);
