import React, { useEffect, useState } from 'react';
import RequiredForSetting from './components/required-for-setting';
import InitiatorCanEditSetting from './components/initiator-can-edit-setting';
import AddToCopyGroupSetting, { ADD_TO_COPY_GROUP_SETTING_KEY } from './components/add-to-copy-group-setting';

export const CheckboxElementSettings = function FromElement({ element, handlerChangeOption }) {
  const [labels, setLabels] = useState(element.inputs);
  const [alignment, setAlignment] = useState(element.options.alignment);
  const [label, setLabel] = useState(element.options.label);
  const [instructions, setInstructions] = useState(element.options.instructions);
  const [size, setSize] = useState(element.options.size);

  useEffect(() => {
    setLabels(element.inputs);
    setSize(element.options.size);
    setInstructions(element.options.instructions);
    setLabel(element.options.label);
    setAlignment(element.options.alignment);
  }, [element]);

  const removeItem = (item) => {
    setLabels((prevState) => {
      const newState = prevState.filter((label) => label.id !== item.id);
      handlerChangeOption(newState, 'inputs', element.id);
      return newState;
    });
  };

  const addItem = () => {
    setLabels((prevState) => {
      const newState = [...prevState];
      newState.push({
        id: 'new' + new Date().getTime(),
        label: 'New Option',
      });

      handlerChangeOption([...newState], 'inputs', element.id);
      return newState;
    });
  };

  return (
    <div className="tabs-item">
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Field Alignment</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="left"
              checked={alignment === 'left'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Left</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="center"
              checked={alignment === 'center'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Center</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="alignment"
              value="right"
              checked={alignment === 'right'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'alignment', element.id);
                setAlignment(e.target.value);
              }}
            />
            <span>Right</span>
          </span>
        </div>
      </div>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Input Size</h3>
        <div className="form-group">
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="small"
              checked={size === 'small'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'size', element.id);
                setSize(e.target.value);
              }}
            />
            <span>Small</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="medium"
              checked={size === 'medium'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'size', element.id);
                setSize(e.target.value);
              }}
            />
            <span>Medium</span>
          </span>
          <span className="form-radio-button">
            <input
              type="radio"
              name="size"
              value="large"
              checked={size === 'large'}
              onChange={(e) => {
                handlerChangeOption(e.target.value, 'size', element.id);
                setSize(e.target.value);
              }}
            />
            <span>Large</span>
          </span>
        </div>
      </div>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Name Label</h3>
        <div className="form-control-item">
          <input
            type="text"
            className="form-control"
            placeholder="Enter text"
            value={label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'label', element.id);
              handlerChangeOption(e.target.value.replace(/ /g, '-').toLowerCase(), 'name', element.id);
            }}
          />
        </div>
      </div>
      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Name Inputs</h3>
        {labels.map((item, index) => (
          <div className="form-control-item" key={index}>
            <input
              type="text"
              className="form-control"
              placeholder="Enter text"
              value={item.label}
              onChange={(e) => {
                setLabels((prevState) => {
                  return prevState.map((label, prevIndex) => {
                    if (index === prevIndex) {
                      label.label = e.target.value;
                      label.id = e.target.value.replace(/ /g, '-').toLowerCase() + '-' + new Date().getTime();
                    }

                    return label;
                  });
                });
              }}
              onBlur={(e) => {
                handlerChangeOption(e.target.value, 'label', element.id, index);
                handlerChangeOption(e.target.value.replace(/ /g, '-').toLowerCase(), 'name', element.id, index);
              }}
            />
            {labels.length > 1 && (
              <div className="settings-actions">
                <button className="btn btn-remove btn-icon" onClick={() => removeItem(item)}>
                  <i className="icon-remove"></i>
                </button>
              </div>
            )}
          </div>
        ))}
        <button className="btn-link" onClick={() => addItem()}>
          <i className="icon-plus-svgrepo-com"></i>
          Add Choice
        </button>
      </div>

      <RequiredForSetting element={element} onChange={handlerChangeOption} />

      <div className="property-item">
        <h3 className="form-builder-aside-property-sub-title">Instructions</h3>
        <div className="form-control-item">
          <textarea
            className="form-control"
            placeholder="Enter Instructions"
            value={instructions}
            onChange={(e) => {
              setInstructions(e.target.value);
            }}
            onBlur={(e) => {
              handlerChangeOption(e.target.value, 'instructions', element.id);
            }}
          ></textarea>
        </div>
      </div>

      <InitiatorCanEditSetting element={element} onChange={handlerChangeOption} />

      <AddToCopyGroupSetting
        elementID={element?.id}
        selected={element?.options?.[ADD_TO_COPY_GROUP_SETTING_KEY]}
        onChange={handlerChangeOption}
      />
    </div>
  );
};
