import React, { memo, useRef } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import './styles.scss';
import { jsPDF } from 'jspdf';

const ExportToPdfModal = ({ onClose, renderTable, facility = {}, shift = {}, unit = {}, assignmentDate }) => {
  const { id: facilityID, name: facilityName } = facility;
  const { name: unitName } = unit;
  const { time_type } = shift;
  const fileName = `CNA_Facility #${facilityID}_${facilityName} Unit_${unitName}_${time_type}.pdf`;

  const modalBodyRef = useRef(null);

  const handleDownloadPDF = async () => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    // Get the HTML content from the modal body
    const htmlElement = modalBodyRef.current?.innerHTML;

    // Use jsPDF's html method to convert the content to PDF
    if (htmlElement) {
      try {
        await doc.html(htmlElement, {
          callback: (doc) => {
            // const pdfOutput = doc.output('bloburl');
            // window.open(pdfOutput);
            doc.save(fileName);
          },
          autoPaging: true,
          margin: [10, 10, 10, 10], // Set margins to avoid overflow
          html2canvas: {
            scale: 0.21,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      className={cx('modal-default export-to-pdf-modal')}
      overlayClassName={'modal-overlay export-to-pdf-modal-overlay'}
      onRequestClose={onClose}
    >
      <h2 className="modal-header">
        <div className="left">{fileName}</div>
        <div className="right">
          <button className={'btn btn-outline assignment-action'} onClick={handlePrint}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_11117_29593)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.16683 1.66927C4.16683 1.20903 4.53993 0.835938 5.00016 0.835938H15.0002C15.4604 0.835938 15.8335 1.20903 15.8335 1.66927V6.66927H16.6668C17.3299 6.66927 17.9658 6.93266 18.4346 7.4015C18.9034 7.87034 19.1668 8.50623 19.1668 9.16927V13.3359C19.1668 13.999 18.9034 14.6349 18.4346 15.1037C17.9658 15.5725 17.3299 15.8359 16.6668 15.8359H15.8335V18.3359C15.8335 18.7962 15.4604 19.1693 15.0002 19.1693H5.00016C4.53993 19.1693 4.16683 18.7962 4.16683 18.3359V15.8359H3.3335C2.67045 15.8359 2.03457 15.5725 1.56573 15.1037C1.09689 14.6349 0.833496 13.999 0.833496 13.3359V9.16927C0.833496 8.50623 1.09689 7.87034 1.56573 7.4015C2.03457 6.93266 2.67045 6.66927 3.3335 6.66927H4.16683V1.66927ZM5.8335 17.5026H14.1668V12.5026H5.8335V17.5026ZM15.8335 14.1693V11.6693C15.8335 11.209 15.4604 10.8359 15.0002 10.8359H5.00016C4.53993 10.8359 4.16683 11.209 4.16683 11.6693V14.1693H3.3335C3.11248 14.1693 2.90052 14.0815 2.74424 13.9252C2.58796 13.7689 2.50016 13.557 2.50016 13.3359V9.16927C2.50016 8.94826 2.58796 8.73629 2.74424 8.58001C2.90052 8.42373 3.11248 8.33594 3.3335 8.33594H16.6668C16.8878 8.33594 17.0998 8.42373 17.2561 8.58001C17.4124 8.7363 17.5002 8.94826 17.5002 9.16927V13.3359C17.5002 13.5569 17.4124 13.7689 17.2561 13.9252C17.0998 14.0815 16.8878 14.1693 16.6668 14.1693H15.8335ZM14.1668 2.5026V6.66927H5.8335V2.5026H14.1668Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_11117_29593">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button className={'btn btn-outline assignment-action'} onClick={handleDownloadPDF}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.6595 15.6667L9.99284 19M9.99284 19L13.3262 15.6667M9.99284 19V11.5"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.9919 15.323C17.8047 14.8798 18.4467 14.1787 18.8168 13.3301C19.1868 12.4816 19.2637 11.534 19.0354 10.6369C18.807 9.73974 18.2865 8.94421 17.5558 8.37582C16.8251 7.80744 15.9259 7.49856 15.0002 7.49795H13.9502C13.698 6.52232 13.2278 5.61656 12.5752 4.84878C11.9225 4.08099 11.1042 3.47116 10.182 3.06512C9.25967 2.65909 8.25734 2.46742 7.25031 2.50452C6.24328 2.54162 5.25777 2.80654 4.36786 3.27934C3.47795 3.75214 2.7068 4.42054 2.1124 5.23427C1.51799 6.048 1.11579 6.9859 0.936028 7.97745C0.756269 8.969 0.803632 9.98839 1.07456 10.959C1.34548 11.9296 1.83291 12.8262 2.50021 13.5813"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button className="btn btn-icon close-btn" onClick={onClose}>
            <i className="icon-icon-x" />
          </button>
        </div>
      </h2>
      <div className={cx('modal-body')} ref={modalBodyRef}>
        <div className={cx('unit-page-layout')} style={{ width: '100vw' }}>
          <div className="table-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <span>{`Facility #${facilityID}: `}</span>
              <b style={{ fontWeight: 700 }}>{facilityName}</b>
            </div>
            <div>
              <span>{`${assignmentDate}: `}</span>
              <b style={{ fontWeight: 700 }}>{time_type}</b>
            </div>
          </div>
          {renderTable()}
        </div>
      </div>
    </Modal>
  );
};

export default memo(ExportToPdfModal);
