import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams } from 'react-router-dom';
import {
  activateCompany,
  disableCompany,
  getCompanyById,
  getCompanyUserGroupDefaultList,
  getCompanyUserGroupList,
  updateCompany,
  deleteCompany,
  copyCompanyApi,
  getFacilitySubmissionData,
  getUserSubmissionData,
} from '../../services/company';
import dayjs from 'dayjs';
import { getFacilitiesByCompany } from '../../services/facility';
import { getUsersList, importUser } from '../../services/user';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FacilityModal from '../../components/company/facility-modal';
import FacilityTab from '../../components/company/facility-tab';
import UserTab from '../../components/company/user-tab';
import UserModal from '../../components/company/user-modal';
import UserGroupTab from '../../components/company/user-group-tab';
import UserGroupModal from '../../components/company/user-group-modal';
import FileTab from '../../components/company/file-tab';
import useOutsideClick from '../../hook/useOutsideClick';
import CompanyModal from '../../components/company/company-modal';
import DeactivateModal from '../../components/company/deactivate-modal';
import { usePermission } from '../../container/permissionContext';
import { redirectToLibrary } from '../../utility';
import UserGroupDefaultTab from '../../components/company/user-group-default-tab';
import UserGroupDefaultModal from '../../components/company/user-group-default-modal';
import ImportModal from '../../components/company/import-modal';
import CopyModal from '../../components/company/copy-modal';
import SubmissionsModal from '../../components/company/submission-modal/submissions-modal';
import SubmissionModal from '../../components/company/submission-modal/submission-modal';
import ExportPdfTab from '../../components/company/settions-tab';
import { DATE_FORMAT } from '../../constants';
import { utcToLocalDateFormatter } from '../../utility/dates/utcToLocalDate';

function CompanyProfile() {
  const { id } = useParams();
  const [company, setCompany] = useState({});
  const [errorFile, setErrorFile] = useState({});
  const [copyError, setCopyError] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [users, setUsers] = useState([]);
  const [modalEditIsOpen, setIsOpenEdit] = useState(false);
  const [deactivateIsOpen, setIsOpenDeactivate] = useState(false);
  const [deleteIsOpen, setIsOpenDelete] = useState(false);
  const [copyCompanyOpen, setCopyCompanyOpen] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [userGroupsDefault, setUserGroupsDefault] = useState([]);
  const [userGroupPage, setUserGroupPage] = useState(1);
  const [totalGroupPage, setTotalGroupPage] = useState(1);
  const [userPage, setUserPage] = useState(1);
  const [totalUserPage, setTotalUserPage] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [totalUserGroup, setTotalUserGroup] = useState(0);
  const [facility, setFacility] = useState(null);
  const [userGroup, setUserGroup] = useState(null);
  const [userSearch, setUserSearch] = useState('');
  const [userGroupSearch, setUserGroupSearch] = useState('');
  const [userGroupSearchDefault, setUserGroupSearchDefault] = useState('');
  const ref = useRef();
  const navigate = useNavigate();
  const { isSuperAdmin } = usePermission();
  const [companyWillUpdated, setCompanyWillUpdated] = useState(0);
  useEffect(() => {
    if (!isSuperAdmin()) {
      redirectToLibrary(navigate);
    }
  }, [isSuperAdmin, navigate]);

  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([
    {
      url: '/companies/',
      name: 'Company List',
    },
    {
      url: `/company/${id}`,
      name: 'Company',
    },
  ]);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);

  const [defaultIndex, setDefaultIndex] = useState(0);

  useEffect(() => {
    handelGetCompany();
    // eslint-disable-next-line
  }, [id, userSearch, userGroupSearch, userGroupSearchDefault]);
  useEffect(() => {
    handelCreateUserGroup();
    // eslint-disable-next-line
  }, [userGroupPage]);
  useEffect(() => {
    handelCreateUser();
    // eslint-disable-next-line
  }, [userPage]);

  const openAddModal = () => {
    setIsOpen(true);
  };

  const closeCopyCompany = () => {
    setCopyCompanyOpen(false);
    setCopyError({});
  };

  function closeAddModal() {
    setIsOpen(false);
    setFacility(null);
    setUserGroup(null);
    setExportData(null);
  }

  const openEditModal = () => {
    setIsOpenEdit(true);
  };

  function closeEditModal() {
    setIsOpenEdit(false);
    setExportData(null);
  }

  const handelCreateFacility = (res) => {
    handelGetCompany();
    closeAddModal();
    setExportData(null);
  };
  const handelCreateUser = () => {
    handelGetCompany();
    closeAddModal();
    setExportData(null);
  };

  const handelCreateUserGroup = () => {
    handelGetCompany();
    closeAddModal();
  };

  const handelGetCompany = () => {
    closeEditModal();
    getCompanyById(id)
      .then((res) => {
        if (res) {
          setCompany(res);
          setPageBreadcrumbs([
            {
              url: '/companies/',
              name: 'Company List',
            },
            {
              url: `/company/${id}`,
              name: res.name,
            },
          ]);

          setCompanyWillUpdated(companyWillUpdated + 1);

          getFacilitiesByCompany(id, 'getAll')
            .then((resFacilities) => {
              setFacilities(resFacilities);
              getUsersList(id, { page: userPage, search: userSearch, status: 'active' })
                .then((resUsers) => {
                  setUsers(resUsers.results);
                  setTotalUser(resUsers.count);
                  setTotalUserPage(resUsers.total_pages);
                })
                .catch((e) => console.log(e));
              getCompanyUserGroupList(id, { page: userGroupPage, search: userGroupSearch, status: 'active' })
                .then((res) => {
                  setUserGroups(res.results);
                  setTotalUserGroup(res.count);
                  setTotalGroupPage(res.total_pages);
                })
                .catch((e) => console.log(e));

              getCompanyUserGroupDefaultList(id, { get_all: true, search: userGroupSearchDefault, status: 'all' })
                .then((res) => {
                  setUserGroupsDefault(res);
                })
                .catch((e) => console.log(e));
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const handlePageUserGroupClick = (event) => {
    setUserGroupPage(event.selected + 1);
  };
  const handlePageUserClick = (event) => {
    setUserPage(event.selected + 1);
  };

  const handelEditFacility = (facility) => {
    setFacility(facility);
    openAddModal();
  };
  const handelEditUserGroup = (UserGroup) => {
    setUserGroup(UserGroup);
    openAddModal();
  };

  const handelDisableCompany = () => {
    disableCompany(company.id)
      .then((res) => handelGetCompany())
      .catch((e) => console.log(e));
    handleDeactivateIsOpen();
  };
  const handelDeleteCompany = () => {
    deleteCompany(company.id)
      .then((res) => navigate('/companies/'))
      .catch((e) => console.log(e));
    handleDeactivateIsOpen();
  };

  const handleActivateCompany = () => {
    activateCompany(company.id)
      .then((res) => handelGetCompany())
      .catch((e) => console.log(e));
  };

  const handleDeactivateIsOpen = () => {
    setIsOpenDeactivate(false);
  };
  const handleDeleteIsOpen = () => {
    setIsOpenDelete(false);
  };

  const removeFile = (id, key) => {
    if (!id) {
      return;
    }

    setErrorFile(null);
    const formDate = {};
    if (key === 'contract_file') {
      formDate['contract_file'] = null;
    } else if ('other_files') {
      formDate['other_files'] = [...company.other_files.filter((item) => item.uuid !== id).map((item) => item.uuid)];
    }

    updateCompany(company.id, formDate)
      .then((res) => {
        handelGetCompany(res);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrorFile(JSON.parse(JSON.stringify(e.response.data)));
        }
      });
  };

  const addOther = (res) => {
    const formDate = {};
    formDate['other_files'] = [...company.other_files.map((item) => item.uuid), res.uuid];
    updateCompany(company.id, formDate)
      .then((res) => {
        handelGetCompany(res);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrorFile(JSON.parse(JSON.stringify(e.response.data)));
        }
      });
  };

  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [errors, setErrors] = useState(null);

  const handelImportUser = (file) => {
    if (!file) {
      setIsPending(false);
      setErrors(null);
      return;
    }

    setIsPending(true);
    setErrors(null);
    const formData = new FormData();
    formData.append('file', file.file);

    importUser(formData, id)
      .then((res) => {
        handelGetCompany();
        closeImportModal();
        setIsPending(false);
      })
      .catch((e) => {
        if (e?.response?.data) {
          setErrors(e?.response?.data);
        }
        setIsPending(false);
      });
  };

  function closeImportModal() {
    setImportModalIsOpen(false);
  }

  const copyCompany = () => {
    const bodyRequest = {
      should_copy_facilities: true,
      should_copy_positions: true,
      should_copy_user_groups: true,
      should_copy_folders_and_forms: true,
    };

    copyCompanyApi(company.id, bodyRequest)
      .then((res) => {
        if (res.id) {
          closeCopyCompany();
          navigate(`/company/${res.id}`);
        }
      })
      .catch((e) => {
        if (e?.response?.data) {
          setCopyError(e?.response?.data);
        }
      });
  };

  // create submission code
  const [modalAttachIsOpen, setIsAttachOpen] = useState(false);
  const [modalAttachDataIsOpen, setIsAttachDataOpen] = useState(false);
  const [selectedSubmissionForm, setSelectedSubmissionForm] = useState(null);
  const [exportData, setExportData] = useState(null);

  function openAddAttachModal() {
    setIsAttachOpen(true);
  }

  function closeAttachModal() {
    setIsAttachOpen(false);
  }

  function closeAttachDataModal() {
    setIsAttachDataOpen(false);
  }

  const handelFormSelected = (form) => {
    setSelectedSubmissionForm(form);
    setIsAttachDataOpen(true);
  };

  const handelExportDataSelected = (data) => {
    getFacilitySubmissionData(data.submission_id)
      .then((res) => {
        console.log(res);
        setExportData(res);
        setSelectedSubmissionForm(null);
        openAddModal();
      })
      .catch((e) => console.log(e));
  };

  const handelExportUserDataSelected = (data) => {
    getUserSubmissionData(data.submission_id)
      .then((res) => {
        console.log(res);
        setExportData(res);
        openAddModal();
      })
      .catch((e) => console.log(e));
  };

  return (
    <Layout companyUpdated={companyWillUpdated} breadcrumbs={pageBreadcrumbs} title={'Company Profile'}>
      <div className={`page-wrapper ${company.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
        <div className="company-profile">
          {company.name && (
            <>
              <div className="company-profile-header">
                <div>
                  <span className="date-add">
                    Date Added: {utcToLocalDateFormatter(company.date_added)}{' '}
                    {company.deleted_at && (
                      <span className="date-add-deactivated">Deactivated: {utcToLocalDateFormatter(company.deleted_at)}</span>
                    )}
                  </span>
                  <h2 className="company-profile-title">{company.name} </h2>
                  <h3 className="company-profile-subtitle">{company.nickname}</h3>
                </div>
                <div className="d-flex">
                  {company.deleted_at && (
                    <>
                      <div className="dropdown">
                        <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn btn-outline">
                          <img src="/images/more-vertical.svg" alt="" />
                        </button>
                        {isDropdownOpen && (
                          <ul className="dropdown-menu" ref={ref}>
                            <li>
                              <button className="btn btn-full btn-outline btn-success mr-2" onClick={handleActivateCompany}>
                                <svg
                                  className="mr-2"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_1484_3438)">
                                    <path
                                      d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                                      stroke="#12B76A"
                                      strokeWidth="1.67"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1484_3438">
                                      <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Activate
                              </button>
                            </li>
                            {isSuperAdmin && (
                              <li>
                                <button className="btn btn-left btn-full btn-danger btn-outline" onClick={() => setIsOpenDelete(true)}>
                                  <i className={'icon-remove mr-2'}></i>
                                  Delete
                                </button>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </>
                  )}
                  {!company.deleted_at && (
                    <>
                      <button className="btn btn-outline mr-2" onClick={openEditModal}>
                        <i className="icon-icon-edit mr-2"></i>Edit
                      </button>
                      <div className="dropdown">
                        <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn btn-outline">
                          <img src="/images/more-vertical.svg" alt="" />
                        </button>
                        {isDropdownOpen && (
                          <ul className="dropdown-menu" ref={ref}>
                            <li>
                              <button onClick={() => setCopyCompanyOpen(true)} className="btn btn-left btn-full btn-outline">
                                <i className="icon-copy mr-2"></i>Copy
                              </button>
                            </li>
                            <li>
                              <button className="btn btn-left btn-full btn-outline" onClick={() => setIsOpenDeactivate(true)}>
                                <svg
                                  className="mr-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_1495_6510)">
                                    <path
                                      d="M12.5001 7.50008L7.50008 12.5001M7.50008 7.50008L12.5001 12.5001M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z"
                                      stroke="#101828"
                                      strokeWidth="1.67"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1495_6510">
                                      <rect width="20" height="20" fill="#101828 " />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Deactivate
                              </button>
                            </li>
                            {isSuperAdmin && (
                              <li>
                                <button className="btn btn-left btn-full btn-danger btn-outline" onClick={() => setIsOpenDelete(true)}>
                                  <i className={'icon-remove mr-2'}></i>
                                  Delete
                                </button>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="company-profile-content">
                <div className="company-profile-person">
                  <h3 className="company-profile-secondary-text">Contract Signed by:</h3>
                  <ul>
                    <li>
                      <strong>
                        {company.signer_name} {company.signer_lastname}
                      </strong>
                    </li>
                    <li className="text-secondary">{company.signer_phone}</li>
                    <li>
                      <a href={`mailto:${company.contact_person_email}`}>{company.signer_email}</a>
                    </li>
                  </ul>
                </div>
                <div className="company-profile-person">
                  <h3 className="company-profile-secondary-text">Contact Person:</h3>
                  <ul>
                    <li>
                      <strong>
                        {company.contact_person_name} {company.contact_person_lastname}
                      </strong>
                    </li>
                    <li className="text-secondary">{company.contact_person_phone}</li>
                    <li>
                      <a href={`mailto:${company.contact_person_email}`}>{company.contact_person_email}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="company-profile-tab">
                <Tabs selectedIndex={defaultIndex} onSelect={(index) => setDefaultIndex(index)}>
                  <TabList>
                    <Tab>Facility</Tab>
                    <Tab>Users</Tab>
                    <Tab>Default User Groups</Tab>
                    <Tab>All User Groups</Tab>
                    <Tab>Related Files</Tab>
                    <Tab>Settings</Tab>
                  </TabList>

                  <TabPanel>
                    {/*<h3 className="company-profile-title">Facility</h3>*/}
                    <FacilityTab
                      openAddAttachModal={openAddAttachModal}
                      handelCreateFacility={handelCreateFacility}
                      handelEdit={handelEditFacility}
                      facilities={facilities}
                      openAddModal={openAddModal}
                    />
                    <FacilityModal
                      editMode={facility}
                      isShortEdit={facility}
                      facilityData={facility ? facility : exportData}
                      companyName={company.name}
                      companyId={id}
                      isOpen={modalIsOpen}
                      openModal={openAddModal}
                      closeModal={closeAddModal}
                      handelCreateFacility={handelCreateFacility}
                    />

                    <SubmissionsModal
                      handelFormSelected={handelFormSelected}
                      isOpen={modalAttachIsOpen}
                      closeModal={closeAttachModal}
                      company_id={id}
                    />

                    <SubmissionModal
                      closeModal={closeAttachDataModal}
                      isOpen={modalAttachDataIsOpen}
                      handelExportDataSelected={handelExportDataSelected}
                      selectedForm={selectedSubmissionForm}
                      company_id={id}
                    />
                  </TabPanel>
                  <TabPanel>
                    {/*<h3 className="company-profile-title">Users</h3>*/}
                    <ImportModal
                      handelError={setErrors}
                      isPending={isPending}
                      onSubmit={handelImportUser}
                      errors={errors}
                      closeModal={closeImportModal}
                      isOpen={importModalIsOpen}
                      title={'Import Users from File'}
                    ></ImportModal>
                    <UserTab
                      openAddAttachModal={openAddAttachModal}
                      openImport={setImportModalIsOpen}
                      onSearch={setUserSearch}
                      totalUser={totalUser}
                      onUpdate={handelCreateUser}
                      totalPage={totalUserPage}
                      handlePageClick={handlePageUserClick}
                      users={users}
                      openAddModal={openAddModal}
                    ></UserTab>
                    <UserModal
                      userData={exportData}
                      companyName={company.name}
                      facilities={facilities}
                      closeModal={closeAddModal}
                      companyId={id}
                      isOpen={modalIsOpen}
                      openModal={openAddModal}
                      handelCreateUser={handelCreateUser}
                    />

                    <SubmissionsModal
                      handelFormSelected={handelFormSelected}
                      isOpen={modalAttachIsOpen}
                      closeModal={closeAttachModal}
                      company_id={id}
                    />

                    <SubmissionModal
                      closeModal={closeAttachDataModal}
                      isOpen={modalAttachDataIsOpen}
                      handelExportDataSelected={handelExportUserDataSelected}
                      selectedForm={selectedSubmissionForm}
                      company_id={id}
                    />
                  </TabPanel>
                  <TabPanel>
                    {/*<h3 className="company-profile-title">Users Groups</h3>*/}
                    <UserGroupDefaultTab
                      handelCreateUserGroup={handelCreateUserGroup}
                      handelEdit={handelEditUserGroup}
                      onSearch={setUserGroupSearchDefault}
                      userGroup={userGroupsDefault}
                      openAddModal={openAddModal}
                    />
                    <UserGroupDefaultModal
                      userGroupData={userGroup}
                      companyId={company.id}
                      companyName={company.name}
                      closeModal={closeAddModal}
                      isOpen={modalIsOpen}
                      openModal={openAddModal}
                      handelCreateUserGroup={handelCreateUserGroup}
                    />
                  </TabPanel>
                  <TabPanel>
                    {/*<h3 className="company-profile-title">Users Groups</h3>*/}
                    <UserGroupTab
                      onSearch={setUserGroupSearch}
                      totalItem={totalUserGroup}
                      totalPage={totalGroupPage}
                      handlePageClick={handlePageUserGroupClick}
                      userGroup={userGroups}
                      openAddModal={openAddModal}
                    />
                    <UserGroupModal
                      companyName={company.name}
                      facilities={facilities}
                      closeModal={closeAddModal}
                      isOpen={modalIsOpen}
                      openModal={openAddModal}
                      handelCreateUserGroup={handelCreateUserGroup}
                    />
                  </TabPanel>
                  <TabPanel>
                    <FileTab updated={handelGetCompany} error={errorFile} addOther={addOther} removeFile={removeFile} company={company} />
                  </TabPanel>
                  <TabPanel>
                    <ExportPdfTab updated={handelGetCompany} company={company}></ExportPdfTab>
                  </TabPanel>
                </Tabs>
              </div>
            </>
          )}
        </div>
        {modalEditIsOpen && (
          <CompanyModal
            editMode={true}
            companyData={company}
            isOpen={modalEditIsOpen}
            openModal={openEditModal}
            closeModal={closeEditModal}
            handelCreateCompany={handelGetCompany}
          />
        )}

        <DeactivateModal
          isOpen={deactivateIsOpen}
          closeModal={handleDeactivateIsOpen}
          handelSuccess={handelDisableCompany}
          title="Company Profile Deactivation"
          subtitle="Deactivation will temporarily hide this company from the system. You can reactivate it anytime in this company profile."
        ></DeactivateModal>
        <DeactivateModal
          isOpen={deleteIsOpen}
          closeModal={handleDeleteIsOpen}
          handelSuccess={handelDeleteCompany}
          title="Confirm Company deletion"
          button={'Delete'}
          subtitle="This action is irreversible and will permanently remove all associated entities. Deleted information cannot be restored."
        ></DeactivateModal>
        <CopyModal
          isOpen={copyCompanyOpen}
          closeModal={closeCopyCompany}
          title={'Copy Company'}
          subtitle={'Are you sure you want to copy this company?'}
          button={'Copy'}
          handelSuccess={copyCompany}
          errors={copyError}
        />
      </div>
    </Layout>
  );
}

export default CompanyProfile;
