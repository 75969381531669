import { deleteFiles, getFilesFoler } from '../../../../../services/folder/form-avaliable';
import React, { memo, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Breadcrumbs } from '../../../../../components/library/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../../constants';
import './styles.scss';
import { useAuth } from '../../../../../container/authContext';
import { getFileInfo } from '../../../../../services/facility';
import { getFileById } from '../../../../../services/upload';
import PageLoader from '../../../../../components/other/PageLoader';
import { utcToLocalDateFormatter } from '../../../../../utility/dates/utcToLocalDate';

const DownloadIcon = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.6595 13.6706L9.99284 17.0039M9.99284 17.0039L13.3262 13.6706M9.99284 17.0039V9.50391"
      stroke="#101828"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9919 13.3269C17.8047 12.8838 18.4467 12.1826 18.8168 11.334C19.1868 10.4855 19.2637 9.53788 19.0354 8.64077C18.807 7.74365 18.2865 6.94812 17.5558 6.37973C16.8251 5.81134 15.9259 5.50247 15.0002 5.50186H13.9502C13.698 4.52623 13.2278 3.62047 12.5752 2.85269C11.9225 2.0849 11.1042 1.47507 10.182 1.06903C9.25967 0.662995 8.25734 0.471324 7.25031 0.508427C6.24328 0.54553 5.25777 0.810442 4.36786 1.28325C3.47795 1.75605 2.7068 2.42444 2.1124 3.23818C1.51799 4.05191 1.11579 4.98981 0.936028 5.98135C0.756269 6.9729 0.803632 7.9923 1.07456 8.96291C1.34548 9.93352 1.83291 10.8301 2.50021 11.5852"
      stroke="#101828"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const DeleteIcon = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 5.0013H3.16667M3.16667 5.0013H16.5M3.16667 5.0013V16.668C3.16667 17.11 3.34226 17.5339 3.65482 17.8465C3.96738 18.159 4.39131 18.3346 4.83333 18.3346H13.1667C13.6087 18.3346 14.0326 18.159 14.3452 17.8465C14.6577 17.5339 14.8333 17.11 14.8333 16.668V5.0013H3.16667ZM5.66667 5.0013V3.33464C5.66667 2.89261 5.84226 2.46868 6.15482 2.15612C6.46738 1.84356 6.89131 1.66797 7.33333 1.66797H10.6667C11.1087 1.66797 11.5326 1.84356 11.8452 2.15612C12.1577 2.46868 12.3333 2.89261 12.3333 3.33464V5.0013M7.33333 9.16797V14.168M10.6667 9.16797V14.168"
      stroke="#B42318"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const openPDFInBrowser = async (pdfUrl) => {
  try {
    const response = await fetch(pdfUrl);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error when opening PDF:', error);
  }
};

const SubmissionsLayout = ({ files = [], breadcrumbs, onSetFiles }) => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const goToPrev = (goToId) => {
    let url = goToId;
    if (typeof goToId === 'number') {
      url = `/corporate-library/files/${goToId}`;
    }
    navigate(url, { replace: true });
  };

  const handleGetFiles = () => {
    const params = {
      parent_id: id ?? null,
      company_id: user.company ? user.company.id : null,
    };
    getFilesFoler(params)
      .then((res) => {
        onSetFiles(res.data.filter((item) => item.type === 'file'));
      })
      .catch((e) => console.log(e));
  };

  const handleDeleteFile = (fileID) => {
    deleteFiles(fileID).then(handleGetFiles).catch(console.log);
  };

  const handleDownloadFileByLink = ({ name, downloadUrl }) => {
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = name;
    a.rel = 'noreferrer';
    a.click();
  };

  const handleGetFileData = (fileID) => {
    setIsLoading(true);
    return getFileInfo(fileID)
      .then((fileInfoRes) => {
        if (fileInfoRes.file) {
          return getFileById(fileInfoRes.file.uuid).then((fileByIdRes) => {
            if (fileByIdRes?.config === 'success' && fileByIdRes?.message) {
              return { ...fileInfoRes, downloadUrl: fileByIdRes.message };
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDownloadFile = (fileID) => {
    handleGetFileData(fileID).then(handleDownloadFileByLink);
  };

  const handleOpenFile = (fileID) => {
    setIsLoading(true);
    handleGetFileData(fileID)
      .then(async (fileData) => {
        try {
          await openPDFInBrowser(fileData.downloadUrl);
        } catch (e) {
          console.log(e);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const renderFiles = () => {
    let layout = null;
    if (files.length) {
      return (
        <div className={'submissions-files'}>
          {files.length ? (
            <div className={'submissions-files__list'}>
              {files.map(({ id, name, created_at }) => {
                return (
                  <div key={id} className={'file'}>
                    <div className="file__name" onClick={() => handleOpenFile(id)}>
                      <img src="/images/file-big-icon.png" alt="" />
                      {name}
                    </div>
                    <span className="file__date">{utcToLocalDateFormatter(created_at)}</span>
                    <div className="file__actions">
                      <button onClick={() => handleDownloadFile(id)}>
                        <DownloadIcon />
                      </button>
                      <button onClick={() => handleDeleteFile(id)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div className="empty-page">
                <h3 className="empty-page-title">No Files Uploaded Yet</h3>
                <h4 className="empty-page-subtitle">This library currently does not contain any uploaded files.</h4>
              </div>
            </>
          )}
        </div>
      );
    }
    return layout;
  };

  return (
    <div className="library">
      <DndProvider backend={HTML5Backend}>
        <Breadcrumbs breadcrumbs={breadcrumbs} goToLevel={goToPrev} />
      </DndProvider>
      <h2 className="library-title">Company Library</h2>
      {renderFiles()}
      {isLoading && <PageLoader />}
    </div>
  );
};

export default memo(SubmissionsLayout);
