import client from '../../api';
import { CancelToken } from 'axios';
import qs from 'qs';

let cancel;

export const getCNAAssignmentsFacilitiesService = async (companyID) => {
  if (cancel !== undefined) {
    cancel();
  }
  if (!companyID) return;
  try {
    return await client.get(`/api/cna-assignments/facilities/`, {
      params: { company_id: companyID },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
export const getCNAAssignmentsUnitsService = async (facilityID) => {
  if (cancel !== undefined) {
    cancel();
  }

  if (typeof facilityID !== 'number') return;

  try {
    return await client.get(`/api/cna-assignments/units/`, {
      params: { facility_id: facilityID },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
export const getCNAAssignmentsService = async (params) => {
  if (cancel !== undefined) {
    cancel();
  }

  try {
    return await client.get(`/api/cna-assignments/`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  } catch (e) {
    console.log(e);
    return e;
  }
};
