import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import { useIsMobile } from '../../../../../../hook/useIsMobile';
import cx from 'classnames';

const NewRowButton = ({ top, left, onClick, onMouseEnter, onMouseLeave }) => {
  const isMobile = useIsMobile();
  return ReactDOM.createPortal(
    <button
      className={cx('table--new--row--button', { mobile: isMobile })}
      style={{
        top: `${top}px`,
        left: `${left}px`,
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span>+</span>
    </button>,
    document.body,
  );
};

export default memo(NewRowButton);
