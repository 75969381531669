import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EmptyPage } from '../other/EmptyPage';
import { Tooltip } from 'react-tooltip';
import ReactPaginate from 'react-paginate';
// import {activateUser} from "../../services/user";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { usePermission } from '../../container/permissionContext';
import useOutsideClick from '../../hook/useOutsideClick';
import { DATE_FORMAT } from '../../constants';
import { dateToISOString } from '../../utility/dates/dateToISOString';
import { utcToLocalDateFormatter } from '../../utility/dates/utcToLocalDate';

function UserTab({
  users,
  openImport,
  openAddModal,
  totalPage,
  handlePageClick,
  openAddAttachModal,
  onUpdate,
  isShortTable,
  totalUser,
  handelFilter,
  filtersUser,
  facilities,
  positions,
  userGroup,
  roles,
  onSearch,
}) {
  const { id } = useParams();
  const [usersList, setUsersList] = useState(users);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(() => {
    const newState = { ...filtersUser };
    if (filtersUser) {
      newState['status'] = { value: 'active', label: 'Active' };
      newState['registration_status'] = { value: 'all', label: 'All' };
      newState['role_ids'] = { value: [], label: 'All' };
    }

    return newState;
  });
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);
  const [facilitiesOption, setFacilitiesOption] = useState([]);
  const [positionsOption, setPositionsOption] = useState([]);
  const [userGroupOption, setUserGroupOption] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const navigate = useNavigate();
  const [column, setColumn] = useState([]);
  const location = useLocation();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { isEmployee } = usePermission();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef();
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useOutsideClick(ref, closeDropdown);

  const handleDateChange = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  useEffect(() => {
    if (endDate) {
      setFilters({
        ...filters,
        date_added_before: endDate,
        date_added_after: startDate,
      });
    } else {
      setFilters({
        ...filters,
        date_added_before: null,
        date_added_after: null,
      });
    }
    // eslint-disable-next-line
  }, [endDate, startDate]);

  useEffect(() => {
    if (isShortTable) {
      setColumn([
        {
          name: 'User Name',
          total: totalUser,
        },
        {
          name: 'Position',
        },
        {
          name: 'User Group',
        },
        {
          name: 'Date Added',
        },
      ]);
    } else if (isEmployee()) {
      setColumn([
        {
          name: 'User Name',
          total: totalUser,
        },
        {
          name: 'Facility',
        },
        {
          name: 'Position',
        },
        {
          name: 'User Group',
        },
        {
          name: 'Date Added',
        },
      ]);
    } else {
      setColumn([
        {
          name: 'User Name',
          total: totalUser,
        },
        {
          name: 'Facility',
        },
        {
          name: 'Position',
        },
        {
          name: 'User Group',
        },
        {
          name: 'Date Added',
        },
        {
          name: 'Role',
          tooltip: "Depending on the user's role, the system determines the ability to perform certain actions",
        },
      ]);
    }
    // eslint-disable-next-line
  }, [search, users]);

  useEffect(() => {
    setUsersList(users);
  }, [users]);

  useEffect(() => {
    if (roles) {
      setRoleOption(roles.map((item) => ({ value: item.id, label: item.name })));
    }
  }, [roles]);
  useEffect(() => {
    if (facilities) {
      setFacilitiesOption(facilities.map((item) => ({ value: item.id, label: item.name })));
    }
  }, [facilities]);

  useEffect(() => {
    if (positions) {
      setPositionsOption(positions.map((item) => ({ value: item.id, label: item.name })));
    }
  }, [positions]);
  useEffect(() => {
    if (userGroup) {
      setUserGroupOption(userGroup.map((item) => ({ value: item.id, label: `${item.name} (${item.facility.name})` })));
    }
  }, [userGroup]);

  useEffect(() => {
    if (filtersUser) {
      const newFilter = { ...filters };
      for (let key in newFilter) {
        if (typeof newFilter[key] === 'object' && !Array.isArray(newFilter[key]) && newFilter[key] !== null) {
          if (newFilter[key].value) {
            newFilter[key] = newFilter[key].value;
          } else {
            newFilter[key] = dateToISOString(newFilter[key]);
          }
        }

        if (Array.isArray(newFilter[key])) {
          newFilter[key] = newFilter[key].map((item) => item.value);
        }
      }

      handelFilter(newFilter);
    }

    // eslint-disable-next-line
  }, [filters]);

  const onFilter = (value, key) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };
  const clearFilter = () => {
    setFilters({
      facilities_ids: [],
      positions_ids: [],
      user_groups_ids: [],
      date_added_before: '',
      date_added_after: '',
      status: { value: 'active', label: 'Active' },
      search: '',
      role_ids: { value: [], label: 'All' },
      registration_status: { value: 'all', label: 'All' },
    });

    setStartDate(null);
    setEndDate(null);
  };
  // const handleActivateUser = (id) => {
  //     activateUser(id).then(res => onUpdate()).catch(e => console.log(e))
  // }
  //
  // const handleDeactivateUser = (id) => {
  //     deactivateUser(id).then(res => onUpdate()).catch(e => console.log(e))
  // }

  return (
    <>
      <div className="library-filter">
        {!filtersUser && (
          <div className="library-filter-search form-control-item">
            <input
              type="text"
              className="form-control"
              value={search}
              onChange={(e) => {
                onSearch(e.target.value);
                setSearch(e.target.value);
              }}
              placeholder="Search"
            />
            <i className="icon-search"></i>
          </div>
        )}
        {filtersUser && (
          <>
            <div className="library-filter-search form-control-item">
              <input
                type="text"
                className="form-control"
                value={filters.search}
                onChange={(e) => onFilter(e.target.value, 'search')}
                placeholder="Search"
              />
              <i className="icon-search"></i>
            </div>
            <div>
              <button className="btn btn-outline" onClick={() => setFiltersIsOpen(!filtersIsOpen)}>
                <i className="icon-icon-sliders-rotate"></i>
              </button>
            </div>
          </>
        )}

        <div>
          <div className="dropdown" ref={ref}>
            <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="btn">
              <i className="icon-plus-svgrepo-com mr-2"></i> Add User
            </button>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <button className="btn ml-0 btn-left btn-full btn-text" onClick={openAddModal}>
                    <i className="icon-plus-svgrepo-com mr-2"></i> Add manually
                  </button>
                </li>
                <li>
                  <button className={'btn ml-0 btn-full btn-text'} onClick={() => openImport(true)}>
                    <i className="icon-file-text mr-2"></i> Excel File import
                  </button>
                </li>
                <li>
                  <button className={'btn ml-0 btn-full btn-text'} onClick={openAddAttachModal}>
                    <i className="icon-file-text mr-2"></i> Add from the submission
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      {filtersIsOpen && (
        <div className="library-filters">
          <div className="library-filters-wrapper">
            <div className="form-item">
              <label className="form-label">Facility</label>
              <Select
                isMulti
                hideSelectedOptions={false}
                options={facilitiesOption}
                value={filters.facilities_ids}
                onChange={(e) => onFilter(e, 'facilities_ids')}
                className="form-control-select"
                classNamePrefix="react-select-multi"
              ></Select>
            </div>
            <div className="form-item">
              <label className="form-label">Position</label>
              <Select
                isMulti
                options={positionsOption}
                hideSelectedOptions={false}
                value={filters.positions_ids}
                onChange={(e) => onFilter(e, 'positions_ids')}
                className="form-control-select"
                classNamePrefix="react-select-multi"
              ></Select>
            </div>
            <div className="form-item">
              <label className="form-label">User Group</label>
              <Select
                isMulti
                options={userGroupOption}
                hideSelectedOptions={false}
                value={filters.user_groups_ids}
                onChange={(e) => onFilter(e, 'user_groups_ids')}
                className="form-control-select"
                classNamePrefix="react-select-multi"
              ></Select>
            </div>
            <div className="form-item">
              <label className="form-label">Date added</label>
              <DatePicker
                selected={startDate}
                className="form-control"
                onChange={handleDateChange}
                selectsRange
                startDate={startDate}
                endDate={endDate}
                isClearable
                disabledKeyboardNavigation
                placeholderText={'Select...'}
              ></DatePicker>
            </div>
            <div className="form-item">
              <label className="form-label">Status</label>
              <Select
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'active',
                    label: 'Active',
                  },
                  {
                    value: 'archived',
                    label: 'Inactive',
                  },
                ]}
                value={filters.status}
                onChange={(e) => onFilter(e, 'status')}
                className="form-control-select"
                classNamePrefix="react-select"
              ></Select>
            </div>
            {!isEmployee() && (
              <div className="form-item">
                <label className="form-label">System Role</label>
                <Select
                  hideSelectedOptions={false}
                  options={[{ value: [], label: 'All' }, ...roleOption]}
                  value={filters.role_ids}
                  onChange={(e) => onFilter(e, 'role_ids')}
                  className="form-control-select"
                  classNamePrefix="react-select"
                ></Select>
              </div>
            )}
            <div className="form-item">
              <label className="form-label">Registration status</label>
              <Select
                hideSelectedOptions={false}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'completed',
                    label: 'Completed',
                  },
                  {
                    value: 'incomplete',
                    label: 'Incomplete',
                  },
                ]}
                value={filters.registration_status}
                onChange={(e) => onFilter(e, 'registration_status')}
                className="form-control-select"
                classNamePrefix="react-select"
              ></Select>
            </div>
          </div>

          <button className="btn btn-outline" onClick={clearFilter}>
            Clear All
          </button>
        </div>
      )}

      {usersList?.length > 0 && (
        <div className="table-responsive table-default table-full">
          <div className="table-wrapper">
            <div className="table">
              <div className="tr">
                {column.map((item, index) => (
                  <div className="th" key={index}>
                    {item.name}
                    {item.tooltip && (
                      <>
                        <i data-tooltip-id={`table-tooltip-${index}`} className="icon-icon-info"></i>
                        <Tooltip opacity={1} id={`table-tooltip-${index}`} place="bottom" content={item.tooltip} />
                      </>
                    )}
                    {!!item.total && <span className="th-total">(Total: {item.total} users)</span>}
                  </div>
                ))}
              </div>
              {usersList.map((item) => (
                <div
                  className={`tr cursor-pointer ${item.deleted_at ? 'tr-deactivated' : ''}`}
                  key={item.id}
                  onClick={() => navigate(`/company/${id}/${location.pathname.includes('users') ? 'users' : 'user'}/${item.id}`)}
                >
                  {!isShortTable && (
                    <>
                      <div className="td">
                        <div className="d-flex">
                          {item.last_name} {item.first_name}
                        </div>
                      </div>
                      <div className="td">{item.facility?.name}</div>
                      <div className="td">{item.position?.name}</div>
                      <div className="td">{item.user_groups.map((item) => item.name).join(', ')}</div>
                      <div className="td">{utcToLocalDateFormatter(item.created_at)}</div>
                      {!isEmployee() && <div className="td">{item.role}</div>}
                    </>
                  )}
                  {isShortTable && (
                    <>
                      <div className="td">
                        <div className="d-flex">
                          {item.last_name} {item.first_name}
                        </div>
                      </div>
                      <div className="td">{item.position?.name}</div>
                      <div className="td">{item.user_groups.map((item) => item.name).join(', ')}</div>
                      <div className="td">{utcToLocalDateFormatter(item.date_added)}</div>
                    </>
                  )}
                </div>
              ))}
            </div>
            {totalPage > 1 && (
              <ReactPaginate
                className={'react-pagination'}
                breakLabel="..."
                nextLabel="next ->"
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="<- previous"
                onPageChange={handlePageClick}
              />
            )}
          </div>
        </div>
      )}

      {!usersList?.length && !search && (
        <EmptyPage
          imageUrl={'/images/empty-user.png'}
          title={'Currently, there are no registered users'}
          subtitle={'Simply click the button “Add User”  to initiate the user addition process'}
        />
      )}
      {!usersList?.length && search && (
        <EmptyPage
          imageUrl={'/images/no-resoult.png'}
          title={'Unfortunately, nothing was found for your query.'}
          subtitle={"Try to change the range of keywords. We're here to help you find what you're looking for."}
        />
      )}
    </>
  );
}

export default UserTab;
