import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from './authContext';

const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  const { user } = useAuth();
  const [userFacility, setUserFacility] = useState([]);

  const isSuperAdmin = () => {
    return user?.role === 'Super Admin';
  };

  const isCompanySystemAdministrator = () => {
    return user?.role === 'Corporate System Admin';
  };
  const isEmployee = () => {
    return user?.role === 'Employee';
  };

  useEffect(() => {
    let facilityList = [];
    if (user) {
      user.actions.forEach((item) => {
        facilityList.push({ name: item.action.name, facilities: item.influenced_facilities });
      });
    }

    setUserFacility([...new Set(facilityList)]);
  }, [user]);

  const hasPermission = (action) => {
    if (isSuperAdmin()) {
      return true;
    }

    if (isCompanySystemAdministrator()) {
      return true;
    }

    if (isEmployee()) {
      return user.actions.find((item) => {
        return item.action.name === action;
      });
    }
  };

  const hasPermissionByCodeName = (action) => {
    if (isSuperAdmin()) {
      return true;
    }

    if (isCompanySystemAdministrator()) {
      return true;
    }

    if (isEmployee()) {
      return user.actions.find((item) => {
        return item.action.codename === action;
      });
    }
  };

  const value = useMemo(
    () => ({
      hasPermission,
      isSuperAdmin,
      isCompanySystemAdministrator,
      isEmployee,
      userFacility,
      hasPermissionByCodeName,
    }),
    // eslint-disable-next-line
    [user, userFacility],
  );
  return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>;
};

export const usePermission = () => {
  return useContext(PermissionContext);
};
