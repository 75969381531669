import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { get } from 'lodash';
import cx from 'classnames';

export const SubmissionSettingsInitAvailability = ({ formData, handelInitSettings, initData, errors }) => {
  const isDisabledSettings = initData?.will_have_frequency;
  let layout = null;

  const [recipient_settings_init, setRecipientSettingsInit] = useState({});
  const [typeOptions] = useState([
    {
      value: 'NONE',
      label: 'None',
    },
    {
      value: 'SAME_DAY',
      label: 'Select a period',
    },
    {
      value: 'TIME_FRAME',
      label: 'Specify end time',
    },
  ]);
  const [rangeOptions] = useState([
    {
      value: 'hours',
      label: 'hours',
    },
    {
      value: 'days',
      label: 'days',
    },
    {
      value: 'weeks',
      label: 'weeks',
    },
    {
      value: 'months',
      label: 'months',
    },
  ]);
  const [qualityOptions, setQualityOptions] = useState([
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
  ]);

  useEffect(() => {
    if (
      initData?.will_have_period_availability &&
      typeof initData?.immediately_initiation === 'boolean' &&
      !initData?.immediately_initiation &&
      !initData.hasOwnProperty('start_date') &&
      !initData.hasOwnProperty('start_time')
    ) {
      const date = new Date();
      handelSettings(date, 'start_date_time');
    }
  }, [initData]);

  useEffect(() => {
    if (!initData?.submission_avaliable_settings) {
      handelSettings(
        {
          value: 'NONE',
          label: 'None',
        },
        'submission_avaliable_settings',
      );
    }
    // eslint-disable-next-line
  }, [initData]);

  useEffect(() => {
    setQualityOptions(() => {
      const newOptions = [];
      let max = 4;
      if (recipient_settings_init.rage?.value === 'hours') {
        max = 23;
      }
      if (recipient_settings_init.rage?.value === 'days') {
        max = 12;
      }
      if (recipient_settings_init.rage?.value === 'weeks') {
        max = 4;
      }
      if (recipient_settings_init.rage?.value === 'months') {
        max = 12;
      }
      for (let i = 1; i <= max; i++) {
        newOptions.push({
          value: i,
          label: i,
        });
      }

      return newOptions;
    });
  }, [recipient_settings_init.rage]);

  useEffect(() => {
    if (initData) {
      setRecipientSettingsInit({ ...initData });
    }
  }, [initData]);

  const handelSettings = (value, key) => {
    const prevState = { ...recipient_settings_init };
    prevState[key] = value;
    setRecipientSettingsInit(() => {
      return prevState;
    });

    handelInitSettings(prevState[key], key);
  };

  // Set "No" if selected frequency
  useEffect(() => {
    if (initData?.will_have_frequency === true && initData?.will_have_period_availability) {
      handelSettings(false, 'will_have_period_availability');
    }
  }, [initData?.will_have_period_availability, initData?.will_have_frequency]);

  if (get(formData, 'submission_settings.can_initiator_specify_period_availability_and_frequency')) {
    layout = (
      <div className="form-builder-setting-tab-content">
        <div className="form-builder-recipient-settings">
          <div className="form-control-item-settings">
            <div className="form-settings-label">
              <label className="form-label form-label-big">Does the form have period of availability?</label>
              {/*<span>Define whether there is a specific time frame during which the form will be accessible or valid.</span>*/}
            </div>
            <div className={cx('form-settings-inputs', { disabled: isDisabledSettings })}>
              <div className="d-flex">
                <div className={`form-control-radio`}>
                  <input
                    name="will_have_period_availability"
                    type="radio"
                    disabled={isDisabledSettings}
                    checked={recipient_settings_init.will_have_period_availability === true}
                    onChange={() => handelSettings(true, 'will_have_period_availability')}
                  />
                  <span>Yes</span>
                </div>
                <div className={`form-control-radio`}>
                  <input
                    name="will_have_period_availability"
                    type="radio"
                    disabled={isDisabledSettings}
                    checked={recipient_settings_init.will_have_period_availability === false}
                    onChange={() => handelSettings(false, 'will_have_period_availability')}
                  />
                  <span>No</span>
                </div>
              </div>
              <span className="form-error mb-2">{errors?.will_have_period_availability && errors.will_have_period_availability[0]}</span>
              {recipient_settings_init.will_have_period_availability && (
                <>
                  <div className={'form-item form-item-select mb-4'}>
                    <label className="form-label" style={{ marginBottom: 10 }}>
                      Will the form be initiated immediately?
                    </label>
                    <div className="d-flex mb-0">
                      <div className={`form-control-radio`}>
                        <input
                          name="immediately_initiation"
                          type="radio"
                          checked={initData.immediately_initiation === true}
                          onChange={() => handelSettings(true, 'immediately_initiation')}
                        />
                        <span>Yes</span>
                      </div>
                      <div className={`form-control-radio`}>
                        <input
                          name="immediately_initiation"
                          type="radio"
                          checked={initData.immediately_initiation === false}
                          onChange={() => handelSettings(false, 'immediately_initiation')}
                        />
                        <span>No</span>
                      </div>
                    </div>
                    <span className="form-error" style={{ marginTop: 6, display: 'block' }}>
                      {errors?.immediately_initiation}
                    </span>
                  </div>

                  {typeof initData?.immediately_initiation === 'boolean' && !initData?.immediately_initiation && (
                    <>
                      <div className="d-flex time-select mb-2" style={{ alignItems: 'flex-start' }}>
                        <div className={'form-item form-item-select mb-0'}>
                          <label className="form-label">Start Day</label>
                          <DatePicker
                            isClearable
                            minDate={new Date()}
                            className={'form-control'}
                            selected={initData?.start_date}
                            placeholderText={'Choose Date'}
                            onChange={(date) => handelSettings(date, 'start_date')}
                          />

                          <span className="form-error mb-2">{errors?.start_date_time && errors.start_date_time[0]}</span>
                        </div>
                        <div className={'form-item form-item-select form-item-time mb-0'}>
                          <label className="form-label">Start Time</label>
                          <DatePicker
                            selected={initData?.start_time}
                            className={'form-control'}
                            isClearable
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption=""
                            placeholderText={'Choose Time'}
                            dateFormat="h:mm aa"
                            onChange={(date) => handelSettings(date, 'start_time')}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    <div className={`form-item form-item-select mb-4`}>
                      <label className="form-label">Availability period</label>
                      <Select
                        options={typeOptions}
                        className="form-control-select"
                        classNamePrefix="react-select"
                        value={recipient_settings_init?.submission_avaliable_settings}
                        placeholder="Choose period"
                        onChange={(e) => handelSettings(e, 'submission_avaliable_settings')}
                      />
                      {/*<img className="danger-icon" src="/images/red-alert.svg" alt=""/>*/}
                      {/*<span className="form-error">{errorUser.role_id && errorUser.role_id[0]}</span>*/}
                    </div>
                  </div>
                  {recipient_settings_init?.submission_avaliable_settings?.value === 'TIME_FRAME' && (
                    <>
                      <div className={'d-flex time-select mb-0'}>
                        <div className={'form-item form-item-select'}>
                          <label className="form-label">End Day</label>
                          <DatePicker
                            isClearable
                            selected={initData.end_date}
                            className={'form-control'}
                            placeholderText={'Choose Date'}
                            minDate={initData?.start_date ?? null}
                            onChange={(date) => handelSettings(date, 'end_date')}
                          />
                        </div>
                        <div className={'form-item form-item-select form-item-time-short form-item-time'}>
                          <label className="form-label">End Time</label>
                          <DatePicker
                            className={'form-control'}
                            showTimeSelect
                            showTimeSelectOnly
                            isClearable
                            timeIntervals={60}
                            timeCaption=""
                            placeholderText={'Choose Time'}
                            dateFormat="h:mm aa"
                            selected={initData.end_time ?? null}
                            onChange={(date) => handelSettings(date, 'end_time')}
                          />
                        </div>
                      </div>
                      <span className="form-error mb-2">{errors?.end_date_time && errors.end_date_time[0]}</span>
                    </>
                  )}
                  {recipient_settings_init?.submission_avaliable_settings?.value === 'SAME_DAY' && (
                    <>
                      <div className={'d-flex time-select mb-0'}>
                        <div className={'form-item form-item-select'}>
                          <label className="form-label">Choose period</label>
                          <Select
                            options={rangeOptions}
                            className="form-control-select"
                            classNamePrefix="react-select"
                            value={recipient_settings_init.rage}
                            placeholder="Choose period"
                            onChange={(e) => handelSettings(e, 'rage')}
                          />
                        </div>
                        <div className={'form-item form-item-select'}>
                          <label className="form-label opacity-0">End Time</label>
                          <Select
                            options={qualityOptions}
                            className="form-control-select"
                            classNamePrefix="react-select"
                            value={recipient_settings_init.rageQuality}
                            placeholder="Choose quality"
                            onChange={(e) => handelSettings(e, 'rageQuality')}
                          />
                        </div>
                      </div>
                      <span className="form-error mb-2">{errors?.end_date_time && errors.end_date_time[0]}</span>
                    </>
                  )}
                </>
              )}
              {/*<span className="form-error mb-2 d-flex">{errors?.submitters?.contact_person_submitter_type && errors.submitters?.contact_person_submitter_type[0]}</span>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return layout;
};
