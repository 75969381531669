import React, { memo, useEffect, useRef } from 'react';
import cx from 'classnames';

const Multiline = ({ value, onChange, styles, readOnly = false, classnames, placeholder }) => {
  const textareaRef = useRef(null);
  const isUserInputRef = useRef(false);

  useEffect(() => {
    if (!isUserInputRef.current && value) {
      // Value was updated externally
      if (textareaRef.current) {
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 4}px`;
      }
    }
    // Reset the flag after handling
    isUserInputRef.current = false;
  }, [value]);

  const handleChange = (e) => {
    isUserInputRef.current = true;
    onChange(e);
  };

  return (
    <textarea
      ref={textareaRef}
      style={styles}
      value={value}
      readOnly={readOnly}
      className={cx('form-control multiline', classnames)}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};

export default memo(Multiline);
