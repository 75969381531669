import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { validateEmail } from '../../utility';
import InputMask from 'react-input-mask';
import DeactivateModal from '../../components/company/deactivate-modal';
import { createContacts, editContacts, deactivateContacts } from '../../services/contacts';
import { getUnitBedsService } from '../../services/unit-beds';
import { getCompanyUnit } from '../../services/company';
import { isResponseOk } from '../../utility/isResponseOk';

function UserModal({
  isOpen,
  companyId,
  closeModal,
  companyName,
  userData,
  handelCreateUser,
  facilities,
  handleDeactivateResident,
  showDeactivate = false,
}) {
  const [errorUser, setErrorUser] = useState({});

  const [facilityOptions, setFacilityOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [bedOptions, setBedOptions] = useState(null);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    unit_bed: '',
    facility_id: '',
    unit_id: '',
    representatives: [],
    deleted_at: null,
  });

  useEffect(() => {
    if (userData) {
      const newState = userData.representatives ? [...userData.representatives] : [];
      setUser({
        ...userData,
        facility_id: userData.facility ? { value: userData.facility.id, label: userData.facility.name } : '',
        unit_id: userData.unit ? { value: userData.unit.id, label: userData.unit.name } : '',
        unit_bed: userData.unit_bed ? { value: userData.unit_bed.id, label: userData.unit_bed.bed } : null,
        representatives: [...newState],
      });
    }
  }, [userData, isOpen]);

  const getUnitBeds = useCallback(async () => {
    if (!user.unit_id) {
      setBedOptions(null);
    } else {
      try {
        const params = {
          get_all: true,
          is_free: true,
          for_resident: userData?.id,
        };
        const response = await getUnitBedsService(user.unit_id?.value, params);

        if (isResponseOk(response)) {
          setBedOptions(response.data.map((item) => ({ value: item.id, label: item.bed })));
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [user.unit_id]);

  useEffect(() => {
    getUnitBeds();
  }, [getUnitBeds]);

  useEffect(() => {
    if (user.facility_id && user.facility_id.value) {
      getCompanyUnit(companyId, 'getAll', user.facility_id.value)
        .then((res) => {
          setUnitOptions(res.map((item) => ({ value: item.id, label: item.name })));
        })
        .catch((e) => console.log(e));
    } else {
      setUnitOptions([]);
    }
    // eslint-disable-next-line
  }, [companyId, isOpen, user]);

  useEffect(() => {
    setFacilityOptions(facilities.filter((item) => !item.deleted_at).map((item) => ({ value: item.id, label: item.name })));
    // eslint-disable-next-line
  }, [facilities, isOpen]);

  useEffect(() => {
    if (bedOptions?.map((item) => item.value).indexOf(user.unit_bed?.value) == -1) {
      setUser((state) => ({ ...state, unit_bed: null }));
    }
  }, [bedOptions]);

  const handleChange = (value, key) => {
    setUser({
      ...user,
      [key]: value,
    });

    if (key === 'facility_id' && value !== user.facility_id) {
      setUser({
        ...user,
        [key]: value,
        unit_id: '',
      });
    }

    if (key === 'email' && value) {
      if (validateEmail(value)) {
        delete errorUser[key];
      }
      return;
    }

    if (errorUser.hasOwnProperty(key)) {
      delete errorUser[key];
    }
  };

  const handleChangeRepresentative = (value, key, index) => {
    setUser((prevState) => {
      let newState = [...user.representatives];
      newState[index][key] = value;
      return {
        ...user,
        representatives: [...newState],
      };
    });
  };

  const resetForm = () => {
    setUser({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      unit_bed: null,
      facility_id: '',
      unit_id: '',
      representatives: [],
    });
    setErrorUser({});
  };

  const handleSubmit = () => {
    const formDate = { ...user };

    if (user.facility_id?.value) {
      formDate.facility_id = user.facility_id.value;
    }
    if (user.unit_id && user.unit_id.value) {
      formDate.unit_id = user.unit_id.value;
    }
    if (user.unit_bed && user.unit_bed.value) {
      formDate.unit_bed = user.unit_bed.value;
    }

    if (userData?.id) {
      delete formDate.id;
      delete formDate.company;
      delete formDate.facility;
      delete formDate.facility_id;
      delete formDate.unit;
      delete formDate.deleted_at;
      editContacts(formDate, userData.id)
        .then((res) => {
          resetForm();
          handelCreateUser(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUser(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    } else {
      createContacts(formDate)
        .then((res) => {
          resetForm();
          handelCreateUser(res);
        })
        .catch((e) => {
          if (e?.response?.data) {
            setErrorUser(JSON.parse(JSON.stringify(e.response.data)));
          }
        });
    }
  };
  const handelCloseModal = () => {
    closeModal();
    resetForm();
  };

  const addMoreRepresentative = () => {
    setUser({
      ...user,
      representatives: [
        ...user.representatives,
        {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          relation_with_resident: null,
        },
      ],
    });
  };
  const removeRepresentative = (index) => {
    let newState = [...user.representatives];
    newState.splice(index, 1);
    setUser({
      ...user,
      representatives: [...newState],
    });
  };

  const handleDeactivate = async () => {
    try {
      await deactivateContacts(userData.id);
      handleDeactivateResident();
      setIsDeactivateModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal className="modal-default" isOpen={isOpen} onRequestClose={handelCloseModal} contentLabel="Example Modal" ariaHideApp={false}>
        <h2 className="modal-header">
          {userData?.id ? 'Edit' : 'Add New'} Resident{' '}
          <button className="btn btn-icon" onClick={handelCloseModal}>
            <i className="icon-icon-x"></i>
          </button>
        </h2>
        {!!companyName && <h3 className="modal-subheader">for {companyName} company</h3>}
        <div className="form-wrapper">
          <div className="d-flex justify-content-space-between" style={{ width: '100%', marginBottom: '20px' }}>
            <h3 className="form-wrapper-title" style={userData?.id ? { flex: 'unset', marginBottom: 0 } : {}}>
              <img src="/images/company-add-icon-3.svg" alt="" />
              Resident information
            </h3>
            {!!userData?.id && showDeactivate && !userData.deleted_at && (
              <button className="btn btn-outline btn-danger" onClick={() => setIsDeactivateModalOpen(true)}>
                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clipPath="url(#clip0_1495_6510)">
                    <path
                      d="M12.5001 7.50008L7.50008 12.5001M7.50008 7.50008L12.5001 12.5001M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z"
                      stroke="white"
                      strokeWidth="1.67"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1495_6510">
                      <rect width="20" height="20" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
                Deactivate
              </button>
            )}
          </div>

          <div className={`form-item form-item-half ${errorUser.first_name ? 'form-item-error' : ''}`}>
            <label className="form-label">Resident Name</label>
            <input
              placeholder="Enter First Name"
              value={user.first_name}
              onChange={(e) => handleChange(e.target.value, 'first_name')}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.first_name && errorUser.first_name[0]}</span>
          </div>
          <div className={`form-item form-item-half ${errorUser.last_name ? 'form-item-error' : ''}`}>
            <label className="form-label">Resident Last Name</label>
            <input
              placeholder="Enter Last Name"
              value={user.last_name}
              onChange={(e) => handleChange(e.target.value, 'last_name')}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.last_name && errorUser.last_name[0]}</span>
          </div>
          <div className={`form-item form-item-half form-item-mail ${errorUser.email ? 'form-item-error' : ''}`}>
            <label className="form-label">
              Email <span className="optional">optional</span>
            </label>
            <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
            <input
              placeholder="mail@example.com"
              id="email"
              value={user.email}
              onChange={(e) => handleChange(e.target.value, 'email')}
              type="text"
              className="form-control"
            />
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.email && errorUser.email[0]}</span>
          </div>
          <div className={`form-item form-item-half ${errorUser.phone ? 'form-item-error' : ''}`}>
            <label className="form-label">
              Phone Number <span className="optional">optional</span>
            </label>
            <InputMask
              placeholder="XXX-XXX-XXXX"
              mask="999-999-9999"
              value={user.phone}
              onChange={(e) => handleChange(e.target.value, 'phone')}
              className="form-control"
            ></InputMask>

            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.phone && errorUser.phone[0]}</span>
          </div>
          <div className={`form-item form-item-select ${errorUser.facility_id ? 'form-item-error' : ''}`}>
            <label className="form-label">Facility</label>
            <Select
              isDisabled={!facilityOptions.length}
              options={facilityOptions}
              className="form-control-select"
              classNamePrefix="react-select"
              value={user.facility_id}
              placeholder="Select Facility"
              onChange={(e) => handleChange(e, 'facility_id')}
            ></Select>
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.facility_id && errorUser.facility_id[0]}</span>
          </div>
          <div className={`form-item form-item-half form-item-select ${errorUser.unit_id ? 'form-item-error' : ''}`}>
            <label className="form-label">Unit</label>
            <Select
              isDisabled={!unitOptions.length}
              options={unitOptions}
              className="form-control-select"
              classNamePrefix="react-select"
              value={user.unit_id}
              placeholder="Select Unit"
              onChange={(e) => handleChange(e, 'unit_id')}
            ></Select>
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.unit_id && errorUser.unit_id[0]}</span>
          </div>
          <div className={`form-item form-item-half form-item-select ${errorUser.unit_bed ? 'form-item-error' : ''}`}>
            <label className="form-label">Room number + Bed</label>
            <Select
              isDisabled={!bedOptions?.length}
              options={bedOptions}
              className="form-control-select"
              classNamePrefix="react-select"
              value={user.unit_bed}
              placeholder="Select Bed"
              onChange={(e) => handleChange(e, 'unit_bed')}
            ></Select>
            <img className="danger-icon" src="/images/red-alert.svg" alt="" />
            <span className="form-error">{errorUser.unit_bed && errorUser.unit_bed[0]}</span>
          </div>
        </div>

        {errorUser.non_field_errors && (
          <div className="form-error mb-4">
            <img className="mr-2" src="/images/red-alert.svg" alt="" /> {errorUser.non_field_errors[0]}
          </div>
        )}

        {user.representatives.map((item, index) => (
          <div key={index} className="form-wrapper">
            <h3 className="form-wrapper-title">
              <img src="/images/company-add-icon-3.svg" alt="" />
              Representative information{' '}
              <button className="btn btn-icon mr-a" onClick={() => removeRepresentative(index)}>
                <i className="icon-remove"></i>
              </button>
            </h3>
            <div
              className={`form-item form-item-half ${errorUser.representatives && errorUser.representatives[index].first_name ? 'form-item-error' : ''}`}
            >
              <label className="form-label">Representative Name</label>
              <input
                placeholder="Enter First Name"
                value={item.first_name}
                onChange={(e) => handleChangeRepresentative(e.target.value, 'first_name', index)}
                type="text"
                className="form-control"
              />
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">
                {errorUser.representatives &&
                  errorUser.representatives[index]?.first_name &&
                  errorUser.representatives[index].first_name[0]}
              </span>
            </div>
            <div
              className={`form-item form-item-half ${errorUser.representatives && errorUser.representatives[index]?.last_name ? 'form-item-error' : ''}`}
            >
              <label className="form-label">Representative Last Name</label>
              <input
                placeholder="Enter Last Name"
                value={item.last_name}
                onChange={(e) => handleChangeRepresentative(e.target.value, 'last_name', index)}
                type="text"
                className="form-control"
              />
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">
                {errorUser.representatives && errorUser.representatives[index]?.last_name && errorUser.representatives[index].last_name[0]}
              </span>
            </div>
            <div
              className={`form-item form-item-half form-item-mail ${errorUser.representatives && errorUser.representatives[index]?.email ? 'form-item-error' : ''}`}
            >
              <label className="form-label">
                Email <span className="optional">optional</span>
              </label>
              <img className="mail-icon" src="/images/mail-icon.svg" alt="" />
              <input
                placeholder="mail@example.com"
                id="email"
                value={item.email}
                onChange={(e) => handleChangeRepresentative(e.target.value, 'email', index)}
                type="text"
                className="form-control"
              />
              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">
                {errorUser.representatives && errorUser.representatives[index]?.email && errorUser.representatives[index].email[0]}
              </span>
            </div>
            <div
              className={`form-item form-item-half ${errorUser.representatives && errorUser.representatives[index]?.phone ? 'form-item-error' : ''}`}
            >
              <label className="form-label">Phone Number</label>
              <InputMask
                placeholder="XXX-XXX-XXXX"
                mask="999-999-9999"
                value={item.phone}
                onChange={(e) => handleChangeRepresentative(e.target.value, 'phone', index)}
                className="form-control"
              ></InputMask>

              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">
                {errorUser.representatives && errorUser.representatives[index]?.phone && errorUser.representatives[index].phone[0]}
              </span>
            </div>

            <div
              className={`form-item form-item-select ${errorUser.representatives && errorUser.representatives[index]?.relation_with_resident ? 'form-item-error' : ''}`}
            >
              <label className="form-label">Relation with Resident</label>
              <input
                placeholder="Enter Relation"
                value={item.relation_with_resident}
                onChange={(e) => handleChangeRepresentative(e.target.value, 'relation_with_resident', index)}
                type="text"
                className="form-control"
              />

              <img className="danger-icon" src="/images/red-alert.svg" alt="" />
              <span className="form-error">
                {errorUser.representatives &&
                  errorUser.representatives[index]?.relation_with_resident &&
                  errorUser.representatives[index].relation_with_resident[0]}
              </span>
            </div>
          </div>
        ))}

        {user.representatives.length < 2 && (
          <span className="add-facility cursor-pointer" onClick={() => addMoreRepresentative()}>
            <i className="icon-plus-svgrepo-com"></i> Add Representative
          </span>
        )}

        <div className="modal-footer">
          <button className="btn btn-outline" onClick={handelCloseModal}>
            Cancel
          </button>
          <button className="btn" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </Modal>
      {isDeactivateModalOpen && (
        <DeactivateModal
          isOpen
          closeModal={() => setIsDeactivateModalOpen(false)}
          handelSuccess={handleDeactivate}
          title="Resident Profile Deactivation"
          subtitle="Deactivation will temporarily hide this resident from the system. You can reactivate it anytime in this resident profile."
        />
      )}
    </>
  );
}

export default UserModal;
