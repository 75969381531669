import React from 'react';

export const ADD_TO_COPY_GROUP_SETTING_KEY = 'isInCopyGroup';

const AddToCopyGroupSetting = ({ selected = false, onChange, elementID }) => {
  const handleChange = (e) => {
    if (!elementID || !onChange) return;
    onChange(e.target.checked, ADD_TO_COPY_GROUP_SETTING_KEY, elementID);
  };

  return (
    <div className="property-item">
      <h3 className="form-builder-aside-property-sub-title">Add to the copy group</h3>
      <div className="form-switcher">
        <input type="checkbox" checked={selected} onChange={handleChange} />
        <span>{selected ? 'ON' : 'OFF'}</span>
      </div>
    </div>
  );
};

export default AddToCopyGroupSetting;
