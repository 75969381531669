import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../../layout/default';
import { utcToLocalDateFormatter } from '../../../utility/dates/utcToLocalDate';
import PageLoader from '../../../components/other/PageLoader';
import { getUnitService, deactivateUnit, activateUnit } from '../../../services/units';
import { isResponseOk } from '../../../utility/isResponseOk';
import { extractBedParts } from '../../../utility';
import { getUnitBedsService } from '../../../services/unit-beds';
import ContextMenu from '../../../components/other/context-menu';
import './styles.scss';
import ReactPaginate from 'react-paginate';
import UnitModal from '../../../components/company/units-modal';
import DeactivateModal from '../../../components/company/deactivate-modal';
import ManageUnitBedModal from '../components/ManageUnitBedModal';
import DeleteUnitBedModal from '../components/DeleteUnitBedModal';

const UnitDetailPage = () => {
  let lastRoomNumber;
  const columns = [
    {
      name: 'Room Number',
    },
    {
      name: 'Bed',
    },
    {
      name: 'Residents',
    },
    {
      name: '',
      isAction: true,
    },
  ];
  const { unit_id, id } = useParams();
  const [unit, setUnit] = useState(null);
  const [bedsData, setBedsData] = useState({ total_pages: 0, results: [], count: 0 });
  const [page, setPage] = useState(0);
  const [isLoadingBeds, setIsLoadingBeds] = useState(true);
  const [isEditUnitModalOpen, setIsEditUnitModalOpen] = useState(false);
  const [isDeleteUnitModalOpen, setIsDeleteUnitModalOpen] = useState(false);
  const [deleteUnitBed, setDeleteUnitBed] = useState(null);
  const [manageUnitBedModalData, setManageUnitBedModalData] = useState(null);
  const [search, setSearch] = useState('');

  const getUnit = useCallback(async () => {
    try {
      const response = await getUnitService(unit_id);

      if (isResponseOk(response)) {
        setUnit(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [unit_id]);

  const getUnitBeds = useCallback(async () => {
    try {
      setIsLoadingBeds(true);
      const params = {
        page: page + 1,
        search: search,
      };
      const response = await getUnitBedsService(unit_id, params);

      if (isResponseOk(response)) {
        setBedsData(response.data);
      }
      setIsLoadingBeds(false);
    } catch (e) {
      console.log(e);
      setIsLoadingBeds(false);
    }
  }, [unit_id, page, search]);

  const handleDeactivate = async () => {
    try {
      const response = await deactivateUnit(unit_id);

      setIsDeleteUnitModalOpen(false);
      getUnit();
    } catch (e) {
      console.log(e);
    }
  };

  const handleActivate = async () => {
    try {
      const response = await activateUnit(unit_id);
      getUnit();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUnit();
  }, [getUnit]);

  useEffect(() => {
    getUnitBeds();
  }, [getUnitBeds]);

  return (
    <Layout
      breadcrumbs={[
        {
          url: `/company/${id}/units/`,
          name: 'Units',
        },
        {
          name: 'Unit Profile',
        },
      ]}
      title={unit?.name || 'Unit Profile'}
    >
      {unit ? (
        <div className={`page-wrapper ${unit.deleted_at ? 'page-wrapper-deactivated' : ''}`}>
          <div className="company-profile">
            <div className="company-profile-header">
              <div>
                <span className="date-add">
                  Date Creation: {utcToLocalDateFormatter(unit.created_at)}
                  {unit.deleted_at && <span className="date-add-deactivated">Deactivated: {utcToLocalDateFormatter(unit.deleted_at)}</span>}
                </span>
                <h2 className="company-profile-title">{unit.name}</h2>
                <h3 className="company-profile-subtitle">
                  <span className="text-secondary">Facility:</span> {unit.facility.name} #{unit.facility.id}
                </h3>
              </div>
              <div className="d-flex">
                {unit.deleted_at ? (
                  <button className="btn btn-outline btn-success" onClick={handleActivate}>
                    <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1484_3438)">
                        <path
                          d="M18.3332 9.2333V9.99997C18.3321 11.797 17.7503 13.5455 16.6743 14.9848C15.5983 16.4241 14.0859 17.477 12.3626 17.9866C10.6394 18.4961 8.79755 18.4349 7.1119 17.8121C5.42624 17.1894 3.98705 16.0384 3.00897 14.5309C2.03089 13.0233 1.56633 11.24 1.68457 9.4469C1.80281 7.65377 2.49751 5.94691 3.66507 4.58086C4.83263 3.21482 6.41049 2.26279 8.16333 1.86676C9.91617 1.47073 11.7501 1.65192 13.3915 2.3833M18.3332 3.3333L9.99984 11.675L7.49984 9.17497"
                          stroke="#12B76A"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_1484_3438">
                          <rect width="20" height="20" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                    Activate
                  </button>
                ) : (
                  <>
                    <button className="btn btn-outline mr-2" onClick={() => setIsEditUnitModalOpen(true)}>
                      <i className="icon-icon-edit mr-2"></i>Edit
                    </button>
                    <button className="btn btn-outline btn-danger" onClick={() => setIsDeleteUnitModalOpen(true)}>
                      <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clipPath="url(#clip0_1495_6510)">
                          <path
                            d="M12.5001 7.50008L7.50008 12.5001M7.50008 7.50008L12.5001 12.5001M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z"
                            stroke="white"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_1495_6510">
                            <rect width="20" height="20" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      Deactivate
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="unit-profile-data">
              <div className="library-filter">
                <div className="library-filter-search form-control-item">
                  <input
                    type="text"
                    className="form-control"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search room"
                  />
                  <i className="icon-search"></i>
                </div>
                <div>
                  <button className="btn" onClick={() => setManageUnitBedModalData({})}>
                    <i className="icon-plus-svgrepo-com mr-2"></i> Add Bed
                  </button>
                </div>
              </div>
              <div className="table-responsive table-default table-full unit-beds-table">
                <div className="table-wrapper">
                  <div className="table">
                    <div className="thead">
                      <div className="tr">
                        {columns.map((item, index) => (
                          <div className={`th ${item.name === 'Members' ? 'th-center' : ''}`} key={index}>
                            {item.name}
                            {!!item.total && <span className="th-total">(Total: {item.total} user groups)</span>}
                          </div>
                        ))}
                      </div>
                    </div>
                    {isLoadingBeds && <PageLoader />}
                    {bedsData.results.map((item, index) => {
                      let number = extractBedParts(item.bed).number;
                      let letter = extractBedParts(item.bed).letter;
                      let roomStart = lastRoomNumber != number;
                      lastRoomNumber = number;

                      return (
                        <div className={`tr ${roomStart && index != 0 ? 'room-start' : ''}`} key={item.id}>
                          <div className="td">{number}</div>
                          <div className="td">{letter}</div>
                          <div className="td">
                            <p className="resident-name">
                              {item.resident?.last_name} {item.resident?.first_name}
                            </p>
                            <p>{item.resident?.email}</p>
                          </div>
                          <div className="td td-action">
                            <ContextMenu
                              onToggle={undefined}
                              size="small"
                              btnClassName="unit-bed-actions-btn"
                              menu={[
                                {
                                  label: 'Edit',
                                  icon: <i className="icon-icon-edit" />,
                                  action: ({ onCloseMenu }) => {
                                    onCloseMenu();
                                    setManageUnitBedModalData(item);
                                  },
                                },
                                {
                                  label: 'Delete',
                                  color: '#B32318',
                                  icon: <i className="icon-remove" />,
                                  action: ({ onCloseMenu }) => {
                                    onCloseMenu();
                                    setDeleteUnitBed(item);
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {bedsData.count > 10 && (
                    <ReactPaginate
                      className="react-pagination"
                      breakLabel="..."
                      nextLabel="Next ->"
                      pageRangeDisplayed={5}
                      pageCount={bedsData.total_pages}
                      forcePage={page}
                      previousLabel="<- Previous"
                      onPageChange={(page) => setPage(page.selected)}
                      containerClassName="pagination"
                      activeClassName="active"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
      {!!isEditUnitModalOpen && (
        <UnitModal
          isOpen
          closeModal={() => setIsEditUnitModalOpen(false)}
          userGroupData={unit}
          handelCreateUserGroup={() => {
            setIsEditUnitModalOpen(false);
            getUnit();
          }}
          facilities={[]}
          facility={unit.facility}
          onRefetchUnits={() => {}}
        />
      )}
      {!!manageUnitBedModalData && (
        <ManageUnitBedModal
          data={manageUnitBedModalData}
          unit={unit}
          onClose={() => {
            setManageUnitBedModalData(null);
          }}
          onSuccess={getUnitBeds}
        />
      )}
      {!!isDeleteUnitModalOpen && (
        <DeactivateModal
          isOpen
          closeModal={() => setIsDeleteUnitModalOpen(false)}
          handelSuccess={handleDeactivate}
          title="Unit Deactivation"
          subtitle="Deactivation will temporarily hide this unit from the system. You can reactivate it anytime in this unit."
        />
      )}
      {!!deleteUnitBed && (
        <DeleteUnitBedModal
          unitBed={deleteUnitBed}
          onSuccess={() => {
            setDeleteUnitBed(null);
            getUnitBeds();
          }}
          closeModal={() => setDeleteUnitBed(null)}
        />
      )}
    </Layout>
  );
};

export default UnitDetailPage;
