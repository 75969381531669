import React, { memo, useMemo, useState } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import './styles.scss';
import Select from 'react-select';
import { isResponseOk } from '../../../../../../utility/isResponseOk';
import { changeShiftActiveCNAService } from '../../../../../../services/cna/assignments/cna';

const ChangeCNAModal = ({ shiftID, cnaID, onClose, cnaSuggest = [], onRefetchAssignment, shiftCNA = [] }) => {
  const [newCNAID, setNewCNAID] = useState(cnaID);
  const [customFullName, setCustomFullName] = useState('');

  const formattedShiftCNAs = useMemo(() => {
    return shiftCNA.map((item) => ({ ...item, active: true }));
  }, [shiftCNA]);

  // From props
  const currentCNA = useMemo(() => {
    return formattedShiftCNAs.find(({ id }) => id === cnaID);
  }, [cnaID, formattedShiftCNAs]);

  const shiftCNAByUserID = useMemo(() => {
    return formattedShiftCNAs.reduce((acc, item) => {
      acc[item.user_id] = item;
      return acc;
    }, {});
  }, [formattedShiftCNAs]);

  // Exclude active cna
  const filteredSuggest = useMemo(() => {
    return cnaSuggest.filter(({ user_id }) => !shiftCNAByUserID[user_id]);
  }, [cnaSuggest, shiftCNAByUserID]);

  const options = useMemo(
    () =>
      filteredSuggest.map(({ user_id, full_name }) => {
        return {
          value: user_id,
          label: full_name,
        };
      }),
    [filteredSuggest],
  );

  const selectedOption = useMemo(() => {
    return newCNAID === cnaID
      ? {
          value: currentCNA.id,
          label: currentCNA.full_name,
        }
      : options.find(({ value }) => value === newCNAID) ?? null;
  }, [cnaID, currentCNA.full_name, currentCNA.id, newCNAID, options]);

  const isActiveSaveBtn = useMemo(() => {
    let isActive;
    if (typeof newCNAID === 'number') {
      isActive = newCNAID !== cnaID;
    } else {
      const trimCustomFullName = customFullName.trim();
      isActive = trimCustomFullName.length > 1 && trimCustomFullName.length < 255;
    }
    return isActive;
  }, [cnaID, customFullName, newCNAID]);

  const handleCNAChange = ({ value }) => {
    setNewCNAID(value);
    setCustomFullName('');
  };

  const handleSave = async () => {
    try {
      const newCNAData = {};
      if (typeof newCNAID === 'number') {
        newCNAData.user_id = newCNAID;
      } else {
        newCNAData.full_name = customFullName;
      }
      const res = await changeShiftActiveCNAService({ shiftID, currentCnaId: cnaID, ...newCNAData });
      if (isResponseOk(res)) {
        onClose();
        await onRefetchAssignment();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onInputChange = (value, action) => {
    if (action.action === 'input-change') {
      setNewCNAID(null);
      setCustomFullName(value);
    }
  };

  const handleFocusToStringEnd = (e) => {
    const valueLength = e.target.value.length;
    e.target.setSelectionRange(valueLength, valueLength);
  };

  return (
    <Modal
      isOpen
      className={cx('modal-default manage-cna-modal')}
      overlayClassName={'modal-overlay manage-cna-modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        Change CNA
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="modal-body">
        <span className={'select-label'}>CNA</span>
        <Select
          hideSelectedOptions
          inputValue={selectedOption ? selectedOption.label : customFullName}
          options={options}
          value={selectedOption}
          filterOption={null}
          className="form-control-select"
          classNamePrefix="react-select"
          onInputChange={onInputChange}
          onChange={handleCNAChange}
          onFocus={handleFocusToStringEnd}
          styles={{
            input: (provided) => ({
              ...provided,
              input: {
                opacity: '1 !important',
              },
            }),
          }}
        />
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isActiveSaveBtn} onClick={handleSave}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(ChangeCNAModal);
