import React from 'react';

export const cnaColors = [
  {
    bg: '#FFF8E1',
    border: '#6F6517',
  },
  {
    bg: '#E9FEF6',
    border: '#258057',
  },
  {
    bg: '#FAFFCE',
    border: '#6F6517',
  },
  {
    bg: '#E9FBFE',
    border: '#049ACC',
  },
  {
    bg: '#FFE4F3',
    border: '#AB005E',
  },
  {
    bg: '#FFDFDC',
    border: '#9E0000',
  },
  {
    bg: '#E8EFFD',
    border: '#253C80',
  },
  {
    bg: '#F9F5F5',
    border: '#101828',
  },
  {
    bg: '#E9F0E0',
    border: '#4E5D23',
  },
  {
    bg: '#F6EBDD',
    border: '#6F4317',
  },
];

export const tableColumns = [
  {
    header: 'CNA',
    accessorKey: 'cna',
    cellClassName: 'cna',
  },
  {
    header: 'Room',
    accessorKey: 'room',
    cellClassName: 'room',
  },
  {
    header: 'Resident',
    accessorKey: 'resident',
    cellClassName: 'resident',
  },
  {
    header: 'Tasks',
    accessorKey: 'tasks',
    cellClassName: 'tasks',
  },
];
