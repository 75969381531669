import React from 'react';
import Layout from '../../layout/default';
import { useAuth } from '../../container/authContext';
import { Link } from 'react-router-dom';
function NotFound() {
  const { user } = useAuth();
  return (
    <Layout title="Form has expired" className={`${!user ? 'forbidden-page-wrapper' : ''}`}>
      <div className={'forbidden-page'}>
        <div>
          <h1>Form has expired</h1>
          <div className={'mt-4 text-center'}>
            {user && (
              <Link to={'/corporate-library'} className={'btn mr-auto ml-auto'}>
                Homepage
              </Link>
            )}

            {!user && (
              <Link to={'/login'} className={'btn mr-auto ml-auto'}>
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotFound;
