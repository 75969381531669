import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import get from 'lodash/get';

const optionKey = 'requiredFor';
const options = [
  { value: 'any', label: '----' },
  { value: 'submitter', label: 'Submitter' },
  { value: 'initiator', label: 'Initiator' },
];

const getOptionByValue = (value) => {
  return options.find((opt) => opt.value === value);
};

const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9,
  }),
};

const RequiredForSetting = ({ element, onChange }) => {
  const [requiredFor, setRequiredFor] = useState(get(element, `options[${optionKey}]`));

  useEffect(() => {
    setRequiredFor(get(element, `options[${optionKey}]`));
  }, [element]);

  const activeOption = getOptionByValue(requiredFor);

  const handleChange = (value) => {
    const newValue = value.value;
    setRequiredFor(newValue);
    onChange(newValue === 'any' ? undefined : newValue, optionKey, element.id);
  };

  return (
    <div className="property-item">
      <h3 className="form-builder-aside-property-sub-title">Required for</h3>
      <div className="form-control-item">
        <Select className="form-control-select" value={activeOption} options={options} styles={selectStyles} onChange={handleChange} />
      </div>
    </div>
  );
};

export default RequiredForSetting;
