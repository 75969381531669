// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-folder-modal {
  width: 100%;
}
.manage-folder-modal-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  align-items: center;
  justify-content: center;
}
.manage-folder-modal .modal-body {
  margin-top: 16px;
}
.manage-folder-modal .modal-body .setting {
  padding: 18px;
  border-radius: 8px;
  border-left: 4px solid #F4EBFF;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1019607843);
}
.manage-folder-modal .modal-body .setting:nth-child(n+2) {
  margin-top: 16px;
}
.manage-folder-modal .modal-body .setting-header {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--Secondary-Text, #667085);
}
.manage-folder-modal .modal-body .setting-body .field-title {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.manage-folder-modal .modal-body .setting-body .form-control,
.manage-folder-modal .modal-body .setting-body .form-control-select {
  margin-top: 8px;
}
.manage-folder-modal .save-btn {
  width: 137px;
}`, "",{"version":3,"sources":["webpack://./src/pages/corporate-library/forms-assigned-to-me/components/manage-folder-modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAGE;EACE,gBAAA;AADJ;AAGI;EACE,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,yBAAA;EACA,0DAAA;AADN;AAGM;EACE,gBAAA;AADR;AAIM;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qCAAA;AAFR;AAMQ;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAJV;AAOQ;;EAEE,eAAA;AALV;AAWE;EACE,YAAA;AATJ","sourcesContent":[".manage-folder-modal {\n  width: 100%;\n\n  &-overlay {\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: fixed;\n    display: flex;\n    padding-top: 24px;\n    padding-bottom: 24px;\n    align-items: center;\n    justify-content: center;\n  }\n\n\n  .modal-body {\n    margin-top: 16px;\n\n    .setting {\n      padding: 18px;\n      border-radius: 8px;\n      border-left: 4px solid #F4EBFF;\n      background-color: #ffffff;\n      box-shadow: 0px 1px 3px 0px #1018281A;\n\n      &:nth-child(n + 2) {\n        margin-top: 16px;\n      }\n\n      &-header {\n        display: flex;\n        gap: 8px;\n        align-items: center;\n        font-size: 16px;\n        font-weight: 400;\n        line-height: 24px;\n        color: var(--Secondary-Text, #667085);\n      }\n\n      &-body {\n        .field-title {\n          margin-top: 16px;\n          font-size: 14px;\n          font-weight: 500;\n          line-height: 16px;\n        }\n\n        .form-control,\n        .form-control-select {\n          margin-top: 8px;\n        }\n      }\n    }\n  }\n\n  .save-btn {\n    width: 137px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
