import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../../constants';

export const getQuestionAnswerByValue = (booleanValue) => (booleanValue === true ? 'Yes' : 'No');

export const formatDateValue = (dateString) => {
  let value = '-';
  if (dateString) {
    const date = new Date(dateString);
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      throw new Error(`Invalid date: ${date}`);
    }
    value = dayjs(date).format(DATE_FORMAT);
  }
  return value;
};

export const formatTimeValueByDateString = (dateString) => {
  let value = '-';
  if (dateString) {
    const date = new Date(dateString);
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      throw new Error(`Invalid date: ${date}`);
    }
    value = dayjs(date).format(TIME_FORMAT);
  }
  return value;
};
