import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjs from 'dayjs';
import { all_months } from './constants';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const getMonthsInInitiationPeriod = (startDate, endDate) => {
  let months = [];

  if (startDate && endDate) {
    const start = dayjs(startDate).startOf('month');
    const end = dayjs(endDate).endOf('month');

    let current = start;

    while (current.isSameOrBefore(end)) {
      const monthName = all_months[current.month()];
      if (!months.includes(monthName)) {
        months.push(monthName);
      }
      current = current.add(1, 'month');
    }

    months = months.sort((a, b) => all_months.indexOf(a) - all_months.indexOf(b));
  } else {
    months = all_months;
  }

  return months;
};

export const getDaysInInitiationPeriod = (startDate, endDate) => {
  const daysSet = new Set();
  if (startDate && endDate) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    let current = start;

    while (current.isSameOrBefore(end) && daysSet.size <= 31) {
      daysSet.add(current.date());
      current = current.add(1, 'day');
    }
  }

  return Array.from(daysSet).sort((a, b) => a - b);
};

export const getMonthIndexByName = (monthName) => {
  const index = all_months.indexOf(monthName);
  if (index === -1) {
    throw new Error('Invalid month name');
  }
  return index;
};
