import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { usePermission } from '../../../../container/permissionContext';
import { getFileGeneralInfo, getFileInfo } from '../../../../services/facility';
import { getFileById } from '../../../../services/upload';
import FileLoader from '../../../other/FileLoader';
import { useDrag } from 'react-dnd';
import cx from 'classnames';
import { patchFile } from '../../../../services/libraries/corporate-library';
import { isResponseOk } from '../../../../utility/isResponseOk';
import { getFileExtFromFileName } from '../../../../utility/files/getFileExtFromFileName';
import { isWebVideoByFileExt } from '../../../../utility/files/isWebVideoByFileExt';

const DownloadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.6595 13.6706L9.99284 17.0039M9.99284 17.0039L13.3262 13.6706M9.99284 17.0039V9.50391"
      stroke="#101828"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9919 13.3269C17.8047 12.8838 18.4467 12.1826 18.8168 11.334C19.1868 10.4855 19.2637 9.53788 19.0354 8.64077C18.807 7.74365 18.2865 6.94812 17.5558 6.37973C16.8251 5.81134 15.9259 5.50247 15.0002 5.50186H13.9502C13.698 4.52623 13.2278 3.62047 12.5752 2.85269C11.9225 2.0849 11.1042 1.47507 10.182 1.06903C9.25967 0.662995 8.25734 0.471324 7.25031 0.508427C6.24328 0.54553 5.25777 0.810442 4.36786 1.28325C3.47795 1.75605 2.7068 2.42444 2.1124 3.23818C1.51799 4.05191 1.11579 4.98981 0.936028 5.98135C0.756269 6.9729 0.803632 7.9923 1.07456 8.96291C1.34548 9.93352 1.83291 10.8301 2.50021 11.5852"
      stroke="#101828"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FileWithDnd = (props) => {
  const { item, url, deleteFile, isGeneral, isAttach, handelAttach, file, onFilePreview, moveFile, isListItem, wrapperClassnames } = props;
  const { hasPermissionByCodeName, isSuperAdmin, isCompanySystemAdministrator } = usePermission();

  const [loader, setLoader] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const [, drag] = useDrag(() => ({
    type: 'file',
    item: { item },
    canDrag: (isSuperAdmin() || isCompanySystemAdministrator()) && !!hasPermissionByCodeName('corporate_library_management'),
    end: async (item, monitor) => {
      try {
        const dropResult = monitor.getDropResult();
        if (dropResult?.item) {
          if (dropResult?.item.id !== item.item.id) {
            const dragFile = item.item;
            const dropFolder = dropResult?.item;

            const patchFileRes = await patchFile(dragFile.id, { ...dragFile, folder_id: dropFolder.id });

            if (isResponseOk(patchFileRes)) {
              moveFile(dropFolder.id);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const downloadFile = ({ name, downloadUrl }) => {
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = name;
    a.rel = 'noreferrer';
    a.target = '_blank';
    a.click();
  };

  const handleClickFile = () => {
    setIsOpenTooltip(false);
    if (url) {
      if (isGeneral) {
        if (!isAttach) {
          setLoader(true);
          getFileGeneralInfo(item.id)
            .then((fileInfoRes) => {
              getFileById(fileInfoRes.file.uuid)
                .then((res) => {
                  const fileData = { ...fileInfoRes, url: res.message, downloadUrl: res.message };
                  downloadFile(fileData);
                })
                .catch((e) => {
                  console.log(e);
                  setLoader(false);
                });
            })
            .catch((e) => {
              console.log(e);
              setLoader(false);
            });
        } else {
          handelAttach(item);
        }
      } else {
        if (!isAttach) {
          setLoader(true);
          getFileInfo(item.id)
            .then((fileInfoRes) => {
              if (fileInfoRes.file) {
                const fileExtension = getFileExtFromFileName(fileInfoRes?.file?.name);
                const isWebVideo = isWebVideoByFileExt(fileExtension);
                getFileById(fileInfoRes.file.uuid)
                  .then((fileByIdRes) => {
                    if (fileByIdRes?.config === 'success' && fileByIdRes?.message) {
                      const fileData = { ...fileInfoRes, url: fileByIdRes.message, downloadUrl: fileByIdRes.message };
                      if (onFilePreview && isWebVideo) {
                        onFilePreview(fileData);
                      } else {
                        downloadFile(fileData);
                      }
                    }

                    setLoader(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setLoader(false);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
              setLoader(false);
            });
        } else {
          handelAttach(item);
        }
      }
    }
  };

  return (
    <div style={{ display: 'contents' }}>
      {!isListItem && (
        <Tooltip
          isOpen={isOpenTooltip}
          role={'tooltip'}
          opacity={1}
          id={`table-tooltip-${item.id}`}
          place="top"
          content={item.name}
          positionStrategy={'absolute'}
        />
      )}
      <div
        ref={drag}
        data-tooltip-id={`table-tooltip-${item.id}`}
        className={cx('library-item cursor-pointer', {
          'library-item-active': file?.id === item.id,
          'list-item': isListItem,
          [wrapperClassnames]: wrapperClassnames,
        })}
        style={{ marginRight: isListItem ? 0 : undefined }}
        onClick={handleClickFile}
        onMouseEnter={() => setIsOpenTooltip(true)}
        onMouseLeave={() => setIsOpenTooltip(false)}
      >
        <div className="library-item-text">
          <img src="/images/file-big-icon.png" alt="" /> {item.name}
        </div>
        {isListItem && <DownloadIcon />}
        {(hasPermissionByCodeName('corporate_library_management') || hasPermissionByCodeName('files_management')) && !isAttach && (
          <button
            className={'btn btn-remove btn-icon'}
            style={{ marginLeft: isListItem ? 16 : 0, marginRight: isListItem ? 6 : 16 }}
            onClick={(e) => {
              e.stopPropagation();
              deleteFile(item.id);
            }}
          >
            <i className={'icon-remove'} style={{ fontSize: isListItem ? 20 : undefined }} />
          </button>
        )}
        {loader && <FileLoader />}
      </div>
    </div>
  );
};
