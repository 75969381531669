import React, { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import cx from 'classnames';
import useOutsideClick from '../../../../../hook/useOutsideClick';

export const FolderItemWithDnD = function FormItem({ item, changeFolder, handelEdit, handelDelete, isListItem, wrapperClassnames }) {
  const menuButtonWrapperRef = useRef(null);
  const menuRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ left: 0, right: 'initial' });

  const closeMenu = () => {
    setIsOpen(false);
  };

  const [, drop] = useDrop(() => ({
    accept: ['file'],
    drop: () => ({ item }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  useOutsideClick(menuButtonWrapperRef, closeMenu);

  useEffect(() => {
    const menuButton = menuButtonWrapperRef.current;
    if (menuButton && isOpen && menuRef.current) {
      const menuButtonRect = menuButton.getBoundingClientRect();
      const menuButtonRight = menuButtonRect.right;
      const screenRight = window.innerWidth;

      // 300 - menu width
      if (menuButtonRight + 300 > screenRight) {
        setMenuPosition({ left: 'initial', right: 0 });
      } else {
        setMenuPosition({ left: 0, right: 'initial' });
      }
    }
  }, [isOpen]);

  const render = () => {
    return (
      <div
        className={cx('library-item cursor-pointer library-folder', {
          'list-item': isListItem,
          [wrapperClassnames]: wrapperClassnames,
        })}
        onClick={() => {
          changeFolder(item.id);
        }}
      >
        <div ref={drop} className="library-item-text">
          <img src="/images/folder-big-icon.png" alt="" /> {item.name}
        </div>
        <div ref={menuButtonWrapperRef} className={cx('library-item-button', { 'library-item-button-open': isOpen })}>
          <button
            className="btn btn-outline btn-small library-item-menu-button"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
                fill="#667085"
              />
              <path
                d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
                fill="#667085"
              />
              <path
                d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
                fill="#667085"
              />
              <path
                d="M9.99984 10.832C10.4601 10.832 10.8332 10.4589 10.8332 9.9987C10.8332 9.53846 10.4601 9.16536 9.99984 9.16536C9.5396 9.16536 9.1665 9.53846 9.1665 9.9987C9.1665 10.4589 9.5396 10.832 9.99984 10.832Z"
                stroke="#667085"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.99984 4.9987C10.4601 4.9987 10.8332 4.6256 10.8332 4.16536C10.8332 3.70513 10.4601 3.33203 9.99984 3.33203C9.5396 3.33203 9.1665 3.70513 9.1665 4.16536C9.1665 4.6256 9.5396 4.9987 9.99984 4.9987Z"
                stroke="#667085"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.99984 16.6654C10.4601 16.6654 10.8332 16.2923 10.8332 15.832C10.8332 15.3718 10.4601 14.9987 9.99984 14.9987C9.5396 14.9987 9.1665 15.3718 9.1665 15.832C9.1665 16.2923 9.5396 16.6654 9.99984 16.6654Z"
                stroke="#667085"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {isOpen && (
            <div className="library-item-menu" ref={menuRef} style={{ left: menuPosition.left, right: menuPosition.right }}>
              {handelEdit && (
                <button
                  className="btn btn-full btn-dark"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                    handelEdit(item);
                  }}
                >
                  <i className="icon-icon-edit mr-2"></i>Rename
                </button>
              )}
              {handelDelete && (
                <button
                  className="btn btn-full btn-remove"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                    handelDelete(item);
                  }}
                >
                  <i className="icon-remove mr-2"></i>Delete
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return render();
};
