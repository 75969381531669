import client from '../api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;

export const getNotificationsList = async (params) => {
  const response = await client.get(`api/notifications/`, {
    params,
  });

  return response.data;
};

export const markAsRead = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.patch(`/api/notifications/mark-as-read/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const notificationDelete = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.delete(`/api/notifications/delete/`, {
    data,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};

export const cancelSMS = async (data) => {
  if (cancel !== undefined) {
    cancel();
  }

  const response = await client.post(`/api/unsubscribe/`, data, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response.data;
};
