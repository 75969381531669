import React, { useEffect, useState } from 'react';
import Layout from '../../layout/default';
import { useAuth } from '../../container/authContext';
import { Link, useSearchParams } from 'react-router-dom';
function NotFound() {
  const { user } = useAuth();
  const [message, setMessage] = useState('You have already submitted this form');
  const [searchParams, setSearchParams] = useSearchParams();
  const getMessage = () => {
    let text = 'You have already submitted this form';
    if (searchParams.has('text-error')) {
      text = searchParams.get('text-error');
      text = text.replace(/'%20'/g, ' ');
      searchParams.delete('text-error');
      setSearchParams(searchParams);
    }
    return text;
  };
  useEffect(() => {
    setMessage(getMessage());
    // eslint-disable-next-line
  }, []);

  return (
    <Layout title="You have already submitted this form" className={`${!user ? 'forbidden-page-wrapper' : ''}`}>
      <div className={'forbidden-page'}>
        <div>
          <h1>{message}</h1>
          <div className={'mt-4 text-center'}>
            {user && (
              <Link to={'/corporate-library'} className={'btn mr-auto ml-auto'}>
                Homepage
              </Link>
            )}

            {!user && (
              <Link to={'/login'} className={'btn mr-auto ml-auto'}>
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotFound;
