import cx from 'classnames';
import React, { memo, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import { MultiSelect } from '../../../../../../components/other/MultiSelect';
import { Tooltip } from 'react-tooltip';
import { useAuth } from '../../../../../../container/authContext';
import { updateCNARoomTaskService } from '../../../../../../services/cna/assignments/cna';
import { getCNADefaultTasksService } from '../../../../../../services/cna/default-tasks';
import { isResponseOk } from '../../../../../../utility/isResponseOk';
import { periodOptions, quantityOptions, shiftOptions, weekDaysOptions } from '../../constants';
import MultiDatePicker, { DateObject } from 'react-multi-date-picker';

const useInitialFormData = (manageTaskData, shiftOptions, periodOptions, weekDaysOptions, quantityOptions) => {
  return useMemo(() => {
    const defaultData = {
      isPeriodic: false,
      targetType: null,
      selectedShifts: [],
      period: null,
      selectedWeekDays: [],
      selectedCustomDates: [],
      repeatQuantity: null,
      repeatDay: null,
    };

    if (manageTaskData?.schedule) {
      const { schedule } = manageTaskData;
      defaultData.isPeriodic = true;
      defaultData.targetType = schedule.bed_id ? 'bed' : schedule.resident_id ? 'resident' : null;
      defaultData.selectedShifts = shiftOptions.filter((item) => schedule.period_settings.shift_types.includes(item.value));
      defaultData.period = periodOptions.find((item) => item.value === schedule.period_settings.period_type);
      defaultData.selectedWeekDays = weekDaysOptions.filter((item) => schedule.period_settings?.selected_weekdays?.includes(item.value));
      defaultData.selectedCustomDates =
        schedule.period_settings?.custom_dates?.map((day) => new DateObject({ year: 2024, month: 12, day })) || [];
      defaultData.repeatDay = weekDaysOptions.find((item) => item.value === schedule.period_settings.repeat_day);
      defaultData.repeatQuantity = quantityOptions.find((item) => item.value === schedule.period_settings.repeat_quantity);
    }

    return defaultData;
  }, [manageTaskData, shiftOptions, periodOptions, weekDaysOptions, quantityOptions]);
};

const ManageTaskModal = ({ manageTaskData = {}, onClose, onRefetchAssignment }) => {
  const { user } = useAuth();
  const companyID = user?.company?.id;
  const { shiftRecordID, name: manageTaskName, default_task_id: manageTaskDefaultTaskID, schedule, hasResident } = manageTaskData;

  const initialFormData = useInitialFormData(manageTaskData, shiftOptions, periodOptions, weekDaysOptions, quantityOptions);

  const [defaultTasksSuggest, setDefaultTasksSuggest] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [taskTitle, setTaskTitle] = useState(manageTaskName ?? '');
  const [selectedDefaultTaskID, setSelectedDefaultTaskID] = useState(manageTaskData?.is_default ? manageTaskDefaultTaskID : null);

  const options = useMemo(() => {
    return defaultTasksSuggest?.map(({ id, text }) => ({ value: id, label: text }));
  }, [defaultTasksSuggest]);
  const selectedDefaultTaskOption = useMemo(() => {
    let option = null;

    if (typeof selectedDefaultTaskID === 'number' && defaultTasksSuggest?.length) {
      const defaultTask = defaultTasksSuggest.find((item) => item.id === selectedDefaultTaskID);
      if (defaultTask) {
        const { id, text } = defaultTask;
        option = {
          value: id,
          label: text,
        };
      }
    }

    return option;
  }, [defaultTasksSuggest, selectedDefaultTaskID]);

  const isValidTask = useMemo(() => {
    const trimTitle = taskTitle.trim();
    const trimTitleLength = trimTitle.length;

    if (selectedDefaultTaskID) {
      return true;
    }

    return trimTitleLength >= 1 && trimTitleLength <= 255;
  }, [taskTitle, selectedDefaultTaskID]);

  useEffect(() => {
    (async () => {
      try {
        const res = await getCNADefaultTasksService({ companyID });
        if (isResponseOk(res) && res.data) {
          setDefaultTasksSuggest(res.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [companyID]);

  const onInputChange = (value, action) => {
    if (action.action === 'input-change') {
      setSelectedDefaultTaskID(null);
      setTaskTitle(value);
    }
  };

  const handleSelectDefaultTask = ({ value, label }) => {
    setTaskTitle(label);
    setSelectedDefaultTaskID(value);
  };

  const handleSubmit = async () => {
    try {
      let newTaskTitle;
      let newDefaultTaskID;
      if (selectedDefaultTaskID) {
        newDefaultTaskID = selectedDefaultTaskID;
      } else {
        if (selectedDefaultTaskOption) {
          newTaskTitle = selectedDefaultTaskOption.label;
        } else {
          newTaskTitle = taskTitle;
        }
      }
      const data = {
        shiftRecordID,
        taskID: manageTaskData?.id,
        taskTitle: newTaskTitle,
        default_task_id: newDefaultTaskID,
      };

      if (formData.isPeriodic) {
        const periodSettings = {};
        periodSettings.target_type = formData.targetType;
        periodSettings.period_type = formData.period?.value;
        periodSettings.shift_types = formData.selectedShifts.map((item) => item.value);

        switch (formData.period?.value) {
          case 'week_days': {
            periodSettings.selected_weekdays = formData.selectedWeekDays.map((item) => item.value);
            break;
          }
          case 'custom_dates': {
            periodSettings.custom_dates = formData.selectedCustomDates.map((item) => item.day);
            break;
          }
          case 'custom_interval': {
            periodSettings.repeat_quantity = formData.repeatQuantity?.value;
            periodSettings.repeat_day = formData.repeatDay?.value;
            break;
          }
        }

        data.period_settings = periodSettings;
      }

      const res = await updateCNARoomTaskService(data);
      if (isResponseOk(res)) {
        onClose();
        onRefetchAssignment();
      } else {
        setErrors(res.response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleTaskSelectBlur = () => {
    const formattedNewTitle = taskTitle.trim();
    if (formattedNewTitle.length) {
      setSelectedDefaultTaskID(null);
      setTaskTitle(formattedNewTitle);
    }
  };

  const handleTaskSelectKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTaskSelectBlur();
      e.preventDefault();
    }
  };

  const handleFocusToStringEnd = (e) => {
    const valueLength = e.target.value.length;
    e.target.setSelectionRange(valueLength, valueLength);
  };

  return (
    <Modal
      isOpen
      className={cx('modal-default assignment-manage-task-modal')}
      overlayClassName={'modal-overlay'}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <h2 className="modal-header">
        Edit Task
        <button className="btn btn-icon" onClick={onClose}>
          <i className="icon-icon-x" />
        </button>
      </h2>
      <div className="form-wrapper">
        <span className={'group__title'}>
          <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.5" y="2" width="32" height="32" rx="16" fill="#F4EBFF" />
            <rect x="2.5" y="2" width="32" height="32" rx="16" stroke="#F9F5FF" strokeWidth="4" />
            <path
              d="M21.1667 12.6693H22.5001C22.8537 12.6693 23.1928 12.8097 23.4429 13.0598C23.6929 13.3098 23.8334 13.649 23.8334 14.0026V23.3359C23.8334 23.6896 23.6929 24.0287 23.4429 24.2787C23.1928 24.5288 22.8537 24.6693 22.5001 24.6693H14.5001C14.1465 24.6693 13.8073 24.5288 13.5573 24.2787C13.3072 24.0287 13.1667 23.6896 13.1667 23.3359V14.0026C13.1667 13.649 13.3072 13.3098 13.5573 13.0598C13.8073 12.8097 14.1465 12.6693 14.5001 12.6693H15.8334M16.5001 11.3359H20.5001C20.8683 11.3359 21.1667 11.6344 21.1667 12.0026V13.3359C21.1667 13.7041 20.8683 14.0026 20.5001 14.0026H16.5001C16.1319 14.0026 15.8334 13.7041 15.8334 13.3359V12.0026C15.8334 11.6344 16.1319 11.3359 16.5001 11.3359Z"
              stroke="#7F56D9"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Task Information
        </span>

        <div className="form-item mt-2">
          <label className="form-label">Task</label>
          <Select
            isSearchable
            options={options}
            inputValue={selectedDefaultTaskOption ? selectedDefaultTaskOption.label : taskTitle}
            className="form-control-select"
            classNamePrefix="react-select"
            value={selectedDefaultTaskOption}
            styles={{
              input: (provided) => ({
                ...provided,
                input: {
                  opacity: '1 !important',
                },
              }),
              menu: (provided) => ({
                ...provided,
                minWidth: '100%',
              }),
              option: (provided) => ({
                ...provided,
                cursor: 'pointer',
              }),
            }}
            filterOption={null}
            onFocus={handleFocusToStringEnd}
            onKeyDown={handleTaskSelectKeyDown}
            onInputChange={onInputChange}
            onChange={handleSelectDefaultTask}
          />
        </div>
        <div className="form-item">
          <label className="form-label">Repeat this task in the future</label>
          <div className="d-flex is-periodic-radio-group form-radio-group">
            <div className="form-control-radio">
              <input
                type="radio"
                name="is_periodic"
                onChange={(e) => setFormData((state) => ({ ...state, isPeriodic: true }))}
                checked={formData.isPeriodic}
              />
              <span>Yes</span>
            </div>
            <div className="form-control-radio">
              <input
                type="radio"
                name="is_periodic"
                onChange={(e) => setFormData((state) => ({ ...state, isPeriodic: false }))}
                checked={!formData.isPeriodic}
              />
              <span>No</span>
            </div>
          </div>
        </div>
        {!!formData.isPeriodic && (
          <>
            <div className="form-item">
              <label className="form-label">Choose the type</label>
              <div className="d-flex period-type-wrapper form-radio-group">
                <div className="form-control-radio">
                  <input
                    type="radio"
                    name="target_type"
                    onChange={(e) => setFormData((state) => ({ ...state, targetType: 'resident' }))}
                    checked={formData.targetType === 'resident'}
                    disabled={!hasResident}
                  />
                  <span>
                    for the current resident
                    <i className="icon-icon-info ml-2" data-tooltip-id="for-resident-tooltip"></i>
                    <Tooltip opacity={1} id="for-resident-tooltip" place="bottom">
                      If the task is scheduled for the current resident, then regardless of the bed these tasks will appear with the set
                      period for this resident
                    </Tooltip>
                  </span>
                </div>
                <div className="form-control-radio">
                  <input
                    type="radio"
                    name="target_type"
                    onChange={(e) => setFormData((state) => ({ ...state, targetType: 'bed' }))}
                    checked={formData.targetType === 'bed'}
                  />
                  <span>
                    for bed
                    <i className="icon-icon-info ml-2" data-tooltip-id="for-bed-tooltip"></i>
                    <Tooltip opacity={1} id="for-bed-tooltip" place="bottom">
                      If the task is scheduled for the bed, then regardless of the resident these tasks will appear with the set period for
                      this bed.
                    </Tooltip>
                  </span>
                </div>
              </div>
              <span className="form-error">{errors?.period_settings?.target_type && errors.period_settings.target_type[0]}</span>
            </div>
            <div className="form-item">
              <span className="select-label select-shifts-label">Shifts</span>
              <Select
                isMulti
                isSearchable={false}
                placeholder="Select shifts"
                value={formData.selectedShifts}
                hideSelectedOptions={false}
                options={shiftOptions}
                closeMenuOnSelect={false}
                className="form-control-select"
                classNamePrefix="react-select-multi"
                onChange={(value) => setFormData((state) => ({ ...state, selectedShifts: value }))}
              />
              <span className="form-error">{errors?.period_settings?.shift_types && errors.period_settings.shift_types[0]}</span>
              <div className="selected-shifts-list">
                {formData.selectedShifts.map(({ value, label: text }) => {
                  return (
                    <div key={value} className="selected-shift-item">
                      <p>{text}</p>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() =>
                          setFormData((state) => ({
                            ...state,
                            selectedShifts: state.selectedShifts.filter((item) => item.value !== value),
                          }))
                        }
                      >
                        <path
                          d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                          stroke="#FDA29B"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="form-item">
              <span className="select-label">Period</span>
              <Select
                isSearchable={false}
                placeholder="Select"
                value={formData.period}
                options={periodOptions}
                className="form-control-select"
                classNamePrefix="react-select"
                onChange={(value) => setFormData((state) => ({ ...state, period: value }))}
              />
              <span className="form-error">{errors?.period_settings?.period_type && errors.period_settings.period_type[0]}</span>
            </div>
            {formData.period?.value === 'week_days' && (
              <div className="form-item">
                <span className="select-label select-shifts-label">Select days</span>
                <MultiSelect
                  options={weekDaysOptions}
                  value={formData.selectedWeekDays}
                  hideSelectedOptions={false}
                  selectClassName="multi-select-days"
                  placeholder="Name of the day"
                  onChange={(value) => setFormData((state) => ({ ...state, selectedWeekDays: value }))}
                  allName="All"
                />
                <span className="form-error">
                  {errors?.period_settings?.selected_weekdays && errors.period_settings.selected_weekdays[0]}
                </span>
                <div className="selected-weekdays-list">
                  {formData.selectedWeekDays.map(({ value, label: text }) => {
                    return (
                      <div key={value} className="selected-weekdays-item">
                        <p>{text}</p>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() =>
                            setFormData((state) => ({
                              ...state,
                              selectedWeekDays: state.selectedWeekDays.filter((item) => item.value !== value),
                            }))
                          }
                        >
                          <path
                            d="M12.5003 7.4974L7.50033 12.4974M7.50033 7.4974L12.5003 12.4974M18.3337 9.9974C18.3337 14.5998 14.6027 18.3307 10.0003 18.3307C5.39795 18.3307 1.66699 14.5998 1.66699 9.9974C1.66699 5.39502 5.39795 1.66406 10.0003 1.66406C14.6027 1.66406 18.3337 5.39502 18.3337 9.9974Z"
                            stroke="#FDA29B"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {formData.period?.value == 'custom_dates' && (
              <div className={'form-item multi-date-picker disabled-change-month mb-4'}>
                <div className={'clear-datepicker'}>
                  <MultiDatePicker
                    multiple
                    format={'DD'}
                    editable={false}
                    placeholder={'Select dates'}
                    currentDate={
                      new DateObject({
                        year: 2024,
                        month: 12,
                        day: 1,
                      })
                    }
                    value={formData.selectedCustomDates}
                    onChange={(dates) => setFormData((state) => ({ ...state, selectedCustomDates: dates.map((value) => value) }))}
                  />
                  {formData.selectedCustomDates.length > 0 && (
                    <button onClick={() => setFormData((state) => ({ ...state, selectedCustomDates: [] }))} className={'clear'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clipPath="url(#clip0_5205_22763)">
                          <path
                            d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                            stroke="#D92D20"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5205_22763">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  )}
                </div>
                <span className="form-error">{errors?.period_settings?.custom_dates && errors.period_settings.custom_dates[0]}</span>
              </div>
            )}
            {formData.period?.value === 'custom_interval' && (
              <>
                <div className="form-item form-item-half">
                  <span className="select-label">Repeat</span>
                  <Select
                    isSearchable={false}
                    placeholder="Select frequency"
                    value={formData.repeatQuantity}
                    options={quantityOptions}
                    className="form-control-select"
                    classNamePrefix="react-select"
                    onChange={(value) => setFormData((state) => ({ ...state, repeatQuantity: value }))}
                  />
                  <span className="form-error">{errors?.period_settings?.repeat_day && errors.period_settings.repeat_day[0]}</span>
                </div>
                <div className="form-item form-item-half">
                  <span className="select-label"></span>
                  <Select
                    isSearchable={false}
                    placeholder="Select week days"
                    value={formData.repeatDay}
                    options={weekDaysOptions}
                    className="form-control-select"
                    classNamePrefix="react-select"
                    onChange={(value) => setFormData((state) => ({ ...state, repeatDay: value }))}
                  />
                  <span className="form-error">
                    {errors?.period_settings?.repeat_quantity && errors.period_settings.repeat_quantity[0]}
                  </span>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div className="modal-footer">
        <button className="btn btn-outline" onClick={onClose}>
          Cancel
        </button>
        <button className="btn" disabled={!isValidTask} onClick={handleSubmit}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default memo(ManageTaskModal);
