// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cna-default-tasks-layout .open-add-task-modal-btn {
  height: 44px;
  display: inline-flex;
  gap: 4px;
  align-items: center;
}
.cna-default-tasks-layout .tasks__header {
  padding-bottom: 13px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #555F72;
  border-bottom: 1px solid #DADEE4;
}
.cna-default-tasks-layout .tasks__list__item {
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  align-items: center;
  justify-content: space-between;
}
.cna-default-tasks-layout .tasks__list__item:nth-child(n+2) {
  border-top: 1px solid #DADEE4;
}
.cna-default-tasks-layout .tasks__list__item:last-child {
  border-bottom: 1px solid #DADEE4;
}
.cna-default-tasks-layout .tasks .empty-tasks-list-label {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/cna/default-tasks/styles.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,oBAAA;EACA,QAAA;EACA,mBAAA;AAAJ;AAII;EACE,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,gCAAA;AAFN;AAMM;EACE,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,8BAAA;AAJR;AAMQ;EACE,6BAAA;AAJV;AAOQ;EACE,gCAAA;AALV;AAUI;EACE,kBAAA;AARN","sourcesContent":[".cna-default-tasks-layout {\n  .open-add-task-modal-btn {\n    height: 44px;\n    display: inline-flex;\n    gap: 4px;\n    align-items: center;\n  }\n\n  .tasks {\n    &__header {\n      padding-bottom: 13px;\n      font-size: 14px;\n      font-weight: 500;\n      line-height: 18px;\n      color: #555F72;\n      border-bottom: 1px solid #DADEE4;\n    }\n\n    &__list {\n      &__item {\n        display: flex;\n        padding-top: 14px;\n        padding-bottom: 14px;\n        align-items: center;\n        justify-content: space-between;\n\n        &:nth-child(n + 2) {\n          border-top: 1px solid #DADEE4;\n        }\n\n        &:last-child {\n          border-bottom: 1px solid #DADEE4;\n        }\n      }\n    }\n\n    .empty-tasks-list-label {\n      text-align: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
